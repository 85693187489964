import IconColumn from "./icons/column.svg";
import TemplateColumn from "./templates/column-template.json";
import IconColumnStacked from "./icons/column-stacked.svg";
import TemplateColumnStacked from "./templates/column-stacked-template.json";
import IconColumnStackedPercent from "./icons/column-stacked-percent.svg";
import TemplateColumnStackedPercent from "./templates/column-stacked-percent-template.json";

import IconLine from "./icons/line.svg";
import TemplateLine from "./templates/line-template.json";
import IconLineStacked from "./icons/line-stacked.svg";
import TemplateLineStacked from "./templates/line-stacked-template.json";
import IconLineStackedPercent from "./icons/line-stacked-percent.svg";
import TemplateLineStackedPercent from "./templates/line-stacked-percent-template.json";

import IconLineMarkers from "./icons/line-markers.svg";
import TemplateLineMarkers from "./templates/line-markers-template.json";
import IconLineStackedMarkers from "./icons/line-stacked-markers.svg";
import TemplateLineStackedMarkers from "./templates/line-stacked-markers-template.json";
import IconLineStackedPercentMarkers from "./icons/line-stacked-percent-markers.svg";
import TemplateLineStackedPercentMarkers from "./templates/line-stacked-percent-markers-template.json";

import IconPie from "./icons/pie.svg";
import TemplatePie from "./templates/pie-template.json";
import IconPieDoughnut from "./icons/pie-doughnut.svg";
import TemplatePieDoughnut from "./templates/pie-doughnut-template.json";

import IconBar from "./icons/bar.svg";
import TemplateBar from "./templates/bar-template.json";
import IconBarStacked from "./icons/bar-stacked.svg";
import TemplateBarStacked from "./templates/bar-stacked-template.json";
import IconBarStackedPercent from "./icons/bar-stacked-percent.svg";
import TemplateBarStackedPercent from "./templates/bar-stacked-percent-template.json";

import IconArea from "./icons/area.svg";
import TemplateArea from "./templates/area-template.json";
import IconAreaStacked from "./icons/area-stacked.svg";
import TemplateAreaStacked from "./templates/area-stacked-template.json";
import IconAreaStackedPercent from "./icons/area-stacked-percent.svg";
import TemplateAreaStackedPercent from "./templates/area-stacked-percent-template.json";

import IconScatterMarkers from "./icons/scatter-markers.svg";
import TemplateScatterMarkers from "./templates/scatter-markers-template.json";

import IconScatterLinesMarkersSmooth from "./icons/scatter-lines-markers-smooth.svg";
import TemplateScatterLinesMarkersSmooth from "./templates/scatter-lines-markers-smooth-template.json";
import IconScatterLinesSmooth from "./icons/scatter-lines-smooth.svg";
import TemplateScatterLinesSmooth from "./templates/scatter-lines-smooth-template.json";
import IconScatterLinesMarkers from "./icons/scatter-lines-markers.svg";
import TemplateScatterLinesMarkers from "./templates/scatter-lines-markers-template.json";
import IconScatterLines from "./icons/scatter-lines.svg";
import TemplateScatterLines from "./templates/scatter-lines-template.json";

import IconBubble from "./icons/bubble.svg";
import IconBubble3D from "./icons/bubble-3d.svg";

const chartTypeDescriptors = {
  column: {
    icon: IconColumn,
    description: "Column Chart",
    template: TemplateColumn,
  },
  columnStacked: {
    icon: IconColumnStacked,
    description: "Stacked Column Chart",
    template: TemplateColumnStacked,
  },
  columnStackedPercent: {
    icon: IconColumnStackedPercent,
    description: "Stacked Percentage Column Chart",
    template: TemplateColumnStackedPercent,
  },
  line: {
    icon: IconLine,
    description: "Line Chart",
    template: TemplateLine,
  },
  lineStacked: {
    icon: IconLineStacked,
    description: "Stacked Line Chart",
    template: TemplateLineStacked,
  },
  lineStackedPercent: {
    icon: IconLineStackedPercent,
    description: "Stacked Percentage Line Chart",
    template: TemplateLineStackedPercent,
  },
  lineMarkers: {
    icon: IconLineMarkers,
    description: "Line with Markers Chart",
    template: TemplateLineMarkers,
  },
  lineStackedMarkers: {
    icon: IconLineStackedMarkers,
    description: "Line Stacked with Markers Chart",
    template: TemplateLineStackedMarkers,
  },
  lineStackedPercentMarkers: {
    icon: IconLineStackedPercentMarkers,
    description: "Line Percentange with Markers Chart",
    template: TemplateLineStackedPercentMarkers,
  },
  pie: {
    icon: IconPie,
    description: "Pie Chart",
    template: TemplatePie,
  },
  pieDoughnut: {
    icon: IconPieDoughnut,
    description: "Pie Doughnut Chart",
    template: TemplatePieDoughnut,
  },
  bar: {
    icon: IconBar,
    description: "Bar Chart",
    template: TemplateBar,
  },
  barStacked: {
    icon: IconBarStacked,
    description: "Bar Stacked Chart",
    template: TemplateBarStacked,
  },
  barStackedPercent: {
    icon: IconBarStackedPercent,
    description: "Bar Percent Chart",
    template: TemplateBarStackedPercent,
  },
  area: {
    icon: IconArea,
    description: "Area Chart",
    template: TemplateArea,
  },
  areaStacked: {
    icon: IconAreaStacked,
    description: "Stacked Area Chart",
    template: TemplateAreaStacked,
  },
  areaStackedPercent: {
    icon: IconAreaStackedPercent,
    description: "Percentage Area Chart",
    template: TemplateAreaStackedPercent,
  },
  scatter: {
    icon: IconScatterMarkers,
    description: "Scatter Chart with Markers",
    template: TemplateScatterMarkers,
  },
  scatterMarkers: {
    icon: IconScatterMarkers,
    description: "Scatter Chart with Markers",
    template: TemplateScatterMarkers,
  },
  scatterLinesMarkersSmooth: {
    icon: IconScatterLinesMarkersSmooth,
    description: "Scatter Chart with Markers and Smooth Lines",
    template: TemplateScatterLinesMarkersSmooth,
  },
  scatterLinesMarkers: {
    icon: IconScatterLinesMarkers,
    description: "Scatter Chart with Markers and Lines",
    template: TemplateScatterLinesMarkers,
  },
  scatterLinesSmooth: {
    icon: IconScatterLinesSmooth,
    description: "Scatter Chart with Smooth Lines",
    template: TemplateScatterLinesSmooth,
  },
  scatterLines: {
    icon: IconScatterLines,
    description: "Scatter Chart with Lines",
    template: TemplateScatterLines,
  },
  bubble: {
    icon: IconBubble,
    description: "Bubble Chart",
  },
  bubble3D: {
    icon: IconBubble3D,
    description: "3D Bubble Chart",
  },
};

export default function presetChartTypeDescriptor(charyTypeKey) {
  return chartTypeDescriptors[charyTypeKey];
}
