import React, { useMemo } from "react";
import { PropTypes } from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { ChartUtils } from "@sheetxl/models";
import { ChartScatterSeriesShape } from "@sheetxl/models";

import useDAGMProperty from "../hooks/useDAGMProperty";

import BooleanPropertyEditor from "../BooleanPropertyEditor";
import RangePropertyEditor from "../RangePropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

import FillPropertyEditor from "../FillPropertyEditor";
import FillStrokePropertyEditor from "../FillStrokePropertyEditor";
import ChartMarkerPropertyEditor from "./ChartMarkerPropertyEditor";
import ChartLabelsPropertyEditor from "./ChartLabelsPropertyEditor";
import ChartTitlePropertyEditor from "./ChartTitlePropertyEditor";

import ChartTypeScatterPropertyEditor from "./ChartTypeScatterPropertyEditor";
import ChartTypePiePropertyEditor from "./ChartTypePiePropertyEditor";

import ChartDataLabelPropertyEditor from "./ChartDataLabelPropertyEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartSeriesPropertyEditor(props) {
  const { shape, ...other } = props;

  const classes = useStyles();

  const [valueChartType] = useDAGMProperty(shape, "chartType");
  const [valueChartTypeType] = useDAGMProperty(shape, "chartType.type");

  let typeSpecificPanel = <></>;
  if (ChartUtils.isLineType(valueChartTypeType)) {
    typeSpecificPanel = (
      <ChartTypeScatterPropertyEditor shape={valueChartType} />
    );
    // } else if (valueType === 'column' || valueType === 'bar') {
    //   typeSpecificPanel =
    //     <ChartTypeColumnPropertyEditor
    //       shape={valueChartType}
    //     />
  } else if (valueChartTypeType === "pie") {
    typeSpecificPanel = <ChartTypePiePropertyEditor shape={valueChartType} />;
  }

  let varyColors = <></>;
  if (
    shape.seriesLength === 1 ||
    ChartUtils.isSingleSeries(valueChartTypeType)
  ) {
    varyColors = (
      <ExplicitPropertyEditorContainer>
        <BooleanPropertyEditor
          label="Vary Colors"
          shape={valueChartType}
          property="varyColors"
        />
      </ExplicitPropertyEditorContainer>
    );
  }

  const [type] = useDAGMProperty(shape, "chartType.type");
  const [labels] = useDAGMProperty(shape, "labels");
  const childrenLabels = useMemo(function() {
    return (
      <ChartDataLabelPropertyEditor
      shape={labels}
      type={type}
      />
      );
    }, [labels, type]
  );

  return (
    <Box className={classes.root} {...other}>
      {varyColors}
      <FillStrokePropertyEditor
        shape={shape}
        hideFill={ChartUtils.isLineType(valueChartTypeType)}
        hideStroke={true}
      ></FillStrokePropertyEditor>
      { ChartUtils.isLineType(valueChartTypeType) ? <></> :
      <FillPropertyEditor
        {...other}
        property="fillNegative"
        label="Fill (Negative)"
        shape={shape}
      ></FillPropertyEditor>
      }
      <FillStrokePropertyEditor
        shape={shape}
        hideFill={true}
      ></FillStrokePropertyEditor>

      {shape instanceof ChartScatterSeriesShape ? (
        <ExplicitPropertyEditorContainer>
          <RangePropertyEditor
            label="'X' Range"
            shape={shape}
            property="xRange"
            singleRange={true}
          />
        </ExplicitPropertyEditorContainer>
      ) : (
        <></>
      )}
      <ExplicitPropertyEditorContainer>
        <RangePropertyEditor
          label={
            ChartUtils.isSingleSeries(shape.chartType.type)
              ? "'Values' Range"
              : "'Values' Range"
          }
          shape={shape}
          property="valRange"
          singleRange={true}
        />
      </ExplicitPropertyEditorContainer>
      <ChartTitlePropertyEditor
        label="Title"
        shape={shape}
        disableRotation={true}
        disableBackground={true}
      >
      </ChartTitlePropertyEditor>
      {ChartUtils.isSmoothableType(shape.chartType.type) ? (
        <ExplicitPropertyEditorContainer>
          <BooleanPropertyEditor
            label="Smooth"
            shape={shape}
            property="smooth"
          />
        </ExplicitPropertyEditorContainer>
      ) : (
        <></>
      )}
      {typeSpecificPanel}
      {ChartUtils.isLineType(shape.chartType.type) ? (
        <ChartMarkerPropertyEditor
          label="Markers"
          shape={shape}
          property="markers"
        />
      ) : (
        <></>
      )}
      <ChartLabelsPropertyEditor
        shape={shape}
        childrenLabelPosition={childrenLabels}
      ></ChartLabelsPropertyEditor>
    </Box>
  );
}

ChartSeriesPropertyEditor.propTypes = {
  shape: PropTypes.object.isRequired,
};

export default ChartSeriesPropertyEditor;
