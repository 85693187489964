import React from "react";

import useDAGMProperty from "../hooks/useDAGMProperty";

import BooleanPropertyEditor from "../BooleanPropertyEditor";
import TextShapePropertyEditor from "../TextShapePropertyEditor";
import FillStrokePropertyEditor from "../FillStrokePropertyEditor";

import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";
import ExpandingBooleanPropertyEditor from "../ExpandingBooleanPropertyEditor";

import ChartLegendPositionPropertyEditor from "./ChartLegendPositionPropertyEditor";

function ChartLegendPropertyEditor(props) {
  const { shape, ...other } = props;

  const [valueLegend] = useDAGMProperty(shape, "legend");

  return (
    <ExpandingBooleanPropertyEditor
      {...other}
      label={"Chart Legend"}
      shape={valueLegend}
      property={"shown"}
    >
      <ChartLegendPositionPropertyEditor shape={valueLegend} />
      <ExplicitPropertyEditorContainer>
        <BooleanPropertyEditor
          label="Overlay"
          shape={valueLegend}
          property="overlay"
        />
      </ExplicitPropertyEditorContainer>
      {/* <FillPropertyEditor
        label={'Text'}
        shape={valueLegend}
        property={'labels.text.fill'}
      >
      </FillPropertyEditor> */}
      <TextShapePropertyEditor
        shape={valueLegend.labels.text}
        textField={<></>}
      ></TextShapePropertyEditor>
      <FillStrokePropertyEditor
        label={"Background"}
        shape={valueLegend}
      ></FillStrokePropertyEditor>
    </ExpandingBooleanPropertyEditor>
  );
}

export default ChartLegendPropertyEditor;
