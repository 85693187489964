import React from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import { findSchemaDefinition } from "@sheetxl/models";

import PerfectScrollPane from "../PerfectScrollPane";
import HeaderContainer from "../HeaderContainer";
import AdjustableColorPropertyEditor from "./AdjustableColorPropertyEditor";
import DocThemeContext from "./DocThemeContext";
import DynamicSvgIcon from "./DynamicSvgIcon";

import FontPropertyEditor from "../FontPropertyEditor";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
    paddingTop: "8px"
  },
  subGroup: {
    marginLeft: '18px'
  },
  tight: {
    padding: '0px 0px',
    paddingTop: '4px'
  },
  papered: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  presetIcon: {
    fill: "currentColor",
    "& .grey": {
      fill: theme.palette.text.secondary,
    }
  }
}));

const svgCircleIcon = `
<svg class="exhibit-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
  <path opacity="1" d="M12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8z"></path>
  <path opacity=".3" class="grey" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8z"></path>
  <path opacity=".3" d="M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8z"></path>
</svg>
`;

export default function DocumentThemePropertyEditor(props) {
  const {
    model,
    papered,
    className : classNameProp,
    ...other } = props;
  const classes = useStyles();

  const themeColorIcon =
    <DynamicSvgIcon
    className={clsx(classes.presetIcon)}
    svg={svgCircleIcon}
    />
  ;
  let retValue = (
    <Box className={clsx(classNameProp, classes.root)} {...other}>
      <PerfectScrollPane>
        <HeaderContainer label="Colors" type="heading">
        <DocThemeContext.Provider value={model}>
          <Box className={classes.subGroup}>
          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={"Primary Background"} // findSchemaDefinition("lt1").description}
            shape={model}
            property="lt1"
            icon={ themeColorIcon }
          />
          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={"Primary Text"} //findSchemaDefinition("dk1").description}
            shape={model}
            property="dk1"
            icon={ themeColorIcon }
          />
          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={"Secondary Background"} // findSchemaDefinition("lt2").description}
            shape={model}
            property="lt2"
            icon={ themeColorIcon }
          />
          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={"Secondary Text"} // findSchemaDefinition("dk2").description}
            shape={model}
            property="dk2"
            icon={ themeColorIcon }
          />

          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={findSchemaDefinition("accent1").description}
            shape={model}
            property="accent1"
            icon={ themeColorIcon }
          />
          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={findSchemaDefinition("accent2").description}
            shape={model}
            property="accent2"
            icon={ themeColorIcon }
          />
          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={findSchemaDefinition("accent3").description}
            shape={model}
            property="accent3"
            icon={ themeColorIcon }
          />
          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={findSchemaDefinition("accent4").description}
            shape={model}
            property="accent4"
            icon={ themeColorIcon }
          />
          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={findSchemaDefinition("accent5").description}
            shape={model}
            property="accent5"
            icon={ themeColorIcon }
          />
          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={findSchemaDefinition("accent6").description}
            shape={model}
            property="accent6"
            icon={ themeColorIcon }
          />

          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={findSchemaDefinition("hlink").description}
            shape={model}
            property="hlink"
            icon={ themeColorIcon }
          />
          <AdjustableColorPropertyEditor
            className={classes.tight}
            label={findSchemaDefinition("folHlink").description}
            shape={model}
            property="folHlink"
            icon={ themeColorIcon }
          />
        </Box>
        </DocThemeContext.Provider>
        </HeaderContainer>
        <HeaderContainer label="Fonts" type="heading">
          <Box className={classes.subGroup}>
              <FontPropertyEditor
                label="Heading Font"
                shape={model}
                property="majorFontLatin"
              />
              <FontPropertyEditor
                label="Body Font"
                shape={model}
                property="minorFontLatin"
              />
          </Box>
        </HeaderContainer>
        </PerfectScrollPane>
    </Box>
  );

  if (papered)
    retValue = (
      <Paper className={clsx(papered.className, classes.papered)} {...papered}>
        {retValue}
      </Paper>
    );
  return retValue;

}
