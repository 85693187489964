import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import FillStrokePropertyEditor from "../FillStrokePropertyEditor";
import BooleanPropertyEditor from "../BooleanPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

import ChartDataSourcePropertyEditor from "./ChartDataSourcePropertyEditor";
import ChartStylePropertyEditor from "./ChartStylePropertyEditor";
import ChartTitlePropertyEditor from "./ChartTitlePropertyEditor";
import ChartTypesPropertyEditor from "./ChartTypesPropertyEditor";
import ChartLegendPropertyEditor from "./ChartLegendPropertyEditor";
import ChartPlotAreaPropertyEditor from "./ChartPlotAreaPropertyEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartAreaPropertyEditor(props) {
  const { shape, ...other } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root} {...other}>
      <ChartTypesPropertyEditor shape={shape} />
      <ChartStylePropertyEditor shape={shape} />

      <ChartDataSourcePropertyEditor shape={shape} />
      <ChartTitlePropertyEditor label="Chart Title" shape={shape}>
        <ExplicitPropertyEditorContainer>
          <BooleanPropertyEditor
            label="Overlay"
            shape={shape}
            property="title.overlay"
          />
        </ExplicitPropertyEditorContainer>
      </ChartTitlePropertyEditor>

      <ChartLegendPropertyEditor shape={shape} />
      <ChartPlotAreaPropertyEditor shape={shape} />
      <FillStrokePropertyEditor shape={shape}></FillStrokePropertyEditor>
      <ExplicitPropertyEditorContainer>
        <BooleanPropertyEditor
          label="Rounded Corners"
          shape={shape}
          property="roundedCorners"
        />
      </ExplicitPropertyEditorContainer>
    </Box>
  );
}

export default ChartAreaPropertyEditor;
