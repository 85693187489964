import ChartElement from "./ChartElement";

import { GeomUtils } from "@sheetxl/models";
import { ChartUtils } from "@sheetxl/models";

/**
  A widget representing the chartTitle. This has interaction and layout logic
*/

class TitleElement extends ChartElement {
  constructor(chartContainer, resizable) {
    super(chartContainer, resizable);


    this.addProperty("overlay", false);
    this.addProperty("rotation", 0);
    this.addProperty("orientation", "t");
    this.addProperty("alignment", "center");
    this.addProperty("maxWidthPerc", 1.0);
    this.addProperty("maxHeightPerc", 1.0);

    this.addProperty("padding", { top: 0, right: 0, bottom: 0, left: 0 });

    // An oriented margin is a margin that is for the side that matched the orientation.
    this.addProperty("orientedMargin", 7.5);

    this._parentBounds = ChartUtils.ZERO_RECT;
    this._margin = { top: 0, right: 0, bottom: 0, left: 0 };

    this._elementRoot = this.createRootElement();
    chartContainer.addElement(this);

    this._elementBackground = this.container.anychart.standalones.background();
    this._elementBackgrounContainer = this.container.stage.layer();
    this._elementRoot.addChild(this._elementBackgrounContainer);
    this._elementBackground.container(this._elementBackgrounContainer);
    this._elementBackground.disablePointerEvents();

    this._elementTitle = this.container.anychart.standalones.title();
    this._elementTitle.useHtml(true);
    // Note - This doesn't seem to work. We have a hack where we just ceate a hit box and set the zorder above this.
    this._elementTitle.disablePointerEvents();

    // delegate properties
    this.addDelegateProperty("enabled", this._elementTitle);

    this._elementTitle.container(this._elementRoot);

    // TODO - hardcoded for now. Later insets should also read from model (we store them)
    this._elementTitle.margin(this._margin);//ChartUtils.ZERO_MARGIN);
    this._elementTitle.padding().left(4);
    this._elementTitle.padding().top(2);
    this._elementTitle.padding().right(4);
    this._elementTitle.padding().bottom(2);
    this._elementTitle.textOverflow("...");

    this._listeners = [];
    this.setupInternalListeners();
  }

  draw() {
    if (!this.enabled || !this._parentBounds) return;

    let margin = {...this._margin};

    let orientation = this.orientation;
    let alignment = this.alignment;
    // Ensure that the element after rotation fits within the boundaries
    let unrotatedBounds = GeomUtils.rotateRect(
      0,
      0,
      this._parentBounds.width * this.maxWidthPerc,
      this._parentBounds.height * this.maxHeightPerc,
      -this.rotation
    );
    unrotatedBounds = {
      left: unrotatedBounds[0],
      top: unrotatedBounds[1],
      width: unrotatedBounds[2],
      height: unrotatedBounds[3],
    };

    // Ensure that the element after rotation fits within the boundaries
    let textBounds = this.textBoundsForWidth(null);
    let renderWidth = Math.min(textBounds.width, unrotatedBounds.width);

    textBounds = this.textBoundsForWidth(renderWidth);
    let renderHeight = Math.min(textBounds.height, unrotatedBounds.height);

    // TODO - This logic is the same and legend. Should move to base class
    let renderedBounds = GeomUtils.rotateRect(
      0,
      0,
      renderWidth,
      renderHeight,
      this.rotation
    );
    renderedBounds = {
      left: 0,
      top: 0,
      width: renderedBounds[2],
      height: renderedBounds[3],
    };

    this._elementBackground.bounds(renderedBounds);

    let renderX = 0;
    let renderY = 0;

    if (orientation === "l" || orientation === "left") {
      margin.left += this.orientedMargin;
      let effectiveRect = ChartUtils.applyMarginRect(this._parentBounds, {
        top: this.padding.right,
        right: this.padding.bottom,
        bottom: this.padding.left,
        left: this.padding.top,
      });
      renderX = this._parentBounds.left;
      renderY = ChartUtils.applyAlignment(
        effectiveRect.height - (margin.top + margin.bottom),
        effectiveRect.top,
        renderedBounds.height,
        ChartUtils.oppositeAlignment(alignment)
      );
    } else if (orientation === "t" || orientation === "top") {
      margin.top += this.orientedMargin;
      let effectiveRect = ChartUtils.applyMarginRect(
        this._parentBounds,
        this.padding
      );
      renderX = ChartUtils.applyAlignment(
        effectiveRect.width - (margin.left + margin.right),
        effectiveRect.left,
        renderedBounds.width,
        alignment
      );
      renderY = this._parentBounds.top;
    } else if (orientation === "r" || orientation === "right") {
      margin.right += this.orientedMargin || 0;
      let effectiveRect = ChartUtils.applyMarginRect(this._parentBounds, {
        top: this.padding.right,
        right: this.padding.bottom,
        bottom: this.padding.left,
        left: this.padding.top,
      });
      renderX =
        this._parentBounds.left +
        this._parentBounds.width -
        renderedBounds.width -
        margin.right;
      renderY = ChartUtils.applyAlignment(
        effectiveRect.height - (margin.top + margin.bottom),
        effectiveRect.top,
        renderedBounds.height,
        ChartUtils.oppositeAlignment(alignment)
      );
    } else if (orientation === "b" || orientation === "bottom") {
      margin.bottom += this.orientedMargin || 0;
      let effectiveRect = ChartUtils.applyMarginRect(
        this._parentBounds,
        this.padding
      );
      renderX = ChartUtils.applyAlignment(
        effectiveRect.width - (margin.left + margin.right),
        effectiveRect.left,
        renderedBounds.width,
        alignment
      );
      renderY =
        this._parentBounds.top +
        this._parentBounds.height -
        renderedBounds.height -
        margin.bottom;
    }

    this._elementTitle.parentBounds(0, 0, renderWidth, renderHeight);
    this._elementTitle.rotation(0);
    let rotationEffective = this.rotation;
    this.layer().setRotation(
      rotationEffective,
      this._effectiveBounds.left + this._effectiveBounds.width / 2,
      this._effectiveBounds.top + this._effectiveBounds.height / 2
    );

    this._effectiveBounds = {
      left: renderX + margin.left,
      top: renderY + margin.top,
      width: renderedBounds.width + margin.left + margin.right,
      height: renderedBounds.height + margin.top + margin.bottom,
    };

    this.setAbsolutePosition(renderWidth, renderHeight);

//     this.layer().setPosition(
//       this._effectiveBounds.left,
//       this._effectiveBounds.top
//     );

    this._hitBox.setBounds({
      left: 0,
      top: 0,
      width: renderWidth,
      height: renderHeight,
    });

    // for debugging margins
//     this._elementBackground.enabled(true);
//     this._elementBackground.stroke({color : 'red' });
//     this._elementBackground.draw();

//     this._elementTitle.background().enabled(true);
//     this._elementTitle.background().fill({ color: "rgb(0, 0, 0)", opacity: "0" });
//     this._elementTitle.background().stroke({color : 'green', opacity: "1" });

    this._elementTitle.draw();
  }

  addListener(type, func) {
    super.addListener(type, func);
    this._elementRoot.listen(type, func);
    this._elementBackground.listen(type, func);

    this._hitBox.listen(type, func);
    this._listeners.push({ type: type, func: func });

    this._elementTitle.background().listen(type, func);
    this._elementTitle.listen(type, func);
  }

  removeListener(type, func) {
    this._elementRoot.unlisten(type, func);
    this._elementBackground.unlisten(type, func);

    this._hitBox.unlisten(type, func);
    for (let i = this._listeners.length - 1; i >= 0; i--) {
      if (this._listeners[i].type === type && this._listeners[i].func === func)
        this._listeners.splice(i, 1);
    }

    this._elementTitle.unlisten(type, func);
  }

  /*
   * Returms that amount of space allocated for the placement of the control.
   * It is possible that it won't take the entire amount
   */
  parentBounds(left, top, width, height) {
    this._parentBounds = { left: left, top: top, width: width, height: height };
  }

  layer() {
    return this._elementRoot;
  }

  elementTitle() {
    return this._elementTitle;
  }

  extrusions() {
    let retValue = ZERO_MARGIN;

    return retValue;
  }

  textBoundsForWidth(width) {
    // TODO - later we can do this with font math. instead wse use the dom
    let oridinalWidth = this._elementTitle.width();
    let oridinalHeight = this._elementTitle.height();
    let originalBounds = this._elementTitle.parentBounds();
    this._elementTitle.width(width);
    this._elementTitle.height(null);
    this._elementTitle.parentBounds(null);
    this._elementTitle.draw();
    let naturalBounds = this._elementTitle.background().getPixelBounds();
    naturalBounds.height = Math.max(naturalBounds.height, this._elementTitle.fontSize() + 4);
    this._elementTitle.width(oridinalWidth);
    this._elementTitle.height(oridinalHeight);
    this._elementTitle.parentBounds(originalBounds);
    this._elementTitle.draw();
    return naturalBounds;
  }

  naturalBounds() {
    return this.textBoundsForWidth(null);
  }

  background() {
    return this._elementBackground;
  }

  /**
    Returns the parent bounds available after set
  */
  remainingBounds() {
    let parentBounds = this._parentBounds;

    if (!this.enabled || this.overlay) return parentBounds;

    return ChartUtils.trimRect(
      this._parentBounds,
      this._effectiveBounds,
      this.orientation
    );
  }

  /**
    Returns the bounds needed to be displayed in container.
    For this to be acurate draw must have been called.
  */
  renderedBounds() {
    if (!this.enabled || this.overlay) return ChartUtils.ZERO_RECT;

    let bounds = { ...this._effectiveBounds };
    return bounds;
  }

  createRootElement() {
    this._effectiveBounds = { left: 0, top: 0, width: 0, height: 0 };

    let elementRoot = this.container.stage.layer();

//     let rectangle = this.container.stage.unmanagedLayer();
//     rectangle.content('<rect x="30" y="30" width="150" height="100" fill="#90caf9"/>');

    this.enableDragSupport(elementRoot);

    this._hitBox = this.container.stage.rect(
      this._effectiveBounds.left,
      this._effectiveBounds.top,
      this._effectiveBounds.width,
      this._effectiveBounds.height
    );
    this._hitBox.stroke({ color: "none" });
    // This is a hack needed to capture events
    this._hitBox.fill({ color: "rgb(255, 255, 255)", opacity: "0.00001" });
//     this._hitBox.fill({ color: "gba(255, 0, 0)", opacity: "1" });
    this._hitBox.zIndex(1000);

    elementRoot.addChild(this._hitBox);

    return elementRoot;
  }

  remove() {
    super.remove();

    this.container.acgraph.events.removeAll(this._rootElement);
    this.container.acgraph.events.removeAll(this._hitBox);
    this.container.acgraph.events.removeAll(this._elementBackground);

    this.container.acgraph.events.removeAll(this._elementTitle);
  }
}

export default TitleElement;
