/*
  A collection of chart elements
*/

class ChartContainer {
  constructor(anychart, acgraph, stage) {
    this._anychart = anychart;
    this._acgraph = acgraph;
    this._stage = stage;
  }

  get anychart() {
    return this._anychart;
  }

  get acgraph() {
    return this._acgraph;
  }

  get stage() {
    return this._stage;
  }

  addElement(elementComp) {
    this.stage.addChild(elementComp.layer());
  }

  removeElement(elementComp) {
    elementComp.layer().remove();
  }
}

export default ChartContainer;
