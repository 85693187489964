import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import HeaderContainer from "../HeaderContainer";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";
import NumberPropertyEditor from "../NumberPropertyEditor";
import FillStrokePropertyEditor from "../FillStrokePropertyEditor";
import BooleanPropertyEditor from "../BooleanPropertyEditor";

import ChartLabelsPropertyEditor from "./ChartLabelsPropertyEditor";
import ChartTitlePropertyEditor from "./ChartTitlePropertyEditor";
import ChartAxisGridLinePropertyEditor from "./ChartAxisGridLinePropertyEditor";
import ChartAxisLabelPositionPropertyEditor from "./ChartAxisLabelPositionPropertyEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartAxisPropertyEditor(props) {
  const {
    shape,
    children,
    childrenAfterStroke  = <></>,
    childrenLabelsAxis = <></>,
    axisType = null,
    ...other } = props;
  const classes = useStyles();

  const childrenLabels = (
    <>
      {childrenLabelsAxis}
      <HeaderContainer label="Label Position" type="secondaryHeading">
        <ChartAxisLabelPositionPropertyEditor
          shape={shape}
        ></ChartAxisLabelPositionPropertyEditor>
        <ExplicitPropertyEditorContainer>
          <NumberPropertyEditor
            label={"Distance from Axis"}
            shape={shape}
            property="labelOffset"
            minNumber={0}
            maxNumber={1000}
          />
        </ExplicitPropertyEditorContainer>
      </HeaderContainer>
    </>
  );

  return (
    <Box className={classes.root} {...other}>
      <FillStrokePropertyEditor
        hideFill={true}
        shape={shape}
      ></FillStrokePropertyEditor>
      {childrenAfterStroke}
      <ChartTitlePropertyEditor label="Title" shape={shape}>
        <ExplicitPropertyEditorContainer>
          <BooleanPropertyEditor
            label="Overlay"
            shape={shape}
            property="title.overlay"
          />
        </ExplicitPropertyEditorContainer>
      </ChartTitlePropertyEditor>
      <ChartLabelsPropertyEditor
        shape={shape}
        childrenLabelPosition={childrenLabels}
        enabledFormatting={axisType !== 'ord'}
      ></ChartLabelsPropertyEditor>
      <ChartAxisGridLinePropertyEditor
        shape={shape}
        label="Major Gridlines"
        property="gridLinesMajor"
      ></ChartAxisGridLinePropertyEditor>
      <ChartAxisGridLinePropertyEditor
        shape={shape}
        label="Minor Gridlines"
        property="gridLinesMinor"
      ></ChartAxisGridLinePropertyEditor>
      {children}
    </Box>
  );
}

export default ChartAxisPropertyEditor;
