import React from "react";

import { CommonUtils } from "@sheetxl/models";

import InputPropertyEditor from "./InputPropertyEditor";

const createRoundValue = function (sigFigs) {
  return function (value) {
    return CommonUtils.roundAccurately(value, sigFigs);
  };
};

const NumberPropertyEditor = (props) => {
  const { sigFigs = 5, ...other } = props;

  const roundFunction = createRoundValue(sigFigs);
  return (
    <InputPropertyEditor
      type="number"
      adjustGetValue={roundFunction}
      {...other}
    />
  );
};

NumberPropertyEditor.propTypes = { ...InputPropertyEditor.propTypes };

export default NumberPropertyEditor;
