import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import ThemeColorButton from "./ThemeColorButton";

const useStyles = makeStyles(function (theme) {
  return {
   root: {
     display: 'flex',
     flexDirection: 'row',
     gap: '6px'
    //  justifyContent: 'space-between'
   },
   col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    //border: `solid ${theme.palette.divider} 1px`,
    boxShadow: theme.shadows[2],
    borderRadius: `${2}px`,
    overflow: 'hidden'
   },
   row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
   }
  }
});

const ThemePresetColors = function(props) {
  const {
    theme,
    colors,
    onSwatchHover,
    className: propClassName,
    onChange,
    onClick,
    selectedColor,
    ...other
  } = props;

  const classes = useStyles({});
  let colorCols;
  // if it's a single array then it's a single row
  // but if it's an array of array then it's a set of columns
  if (Array.isArray(colors[0])) {
    colorCols = colors;
  } else {
    colorCols = [];
    for (let i=0; i<colors.length; i++)
      colorCols.push([colors[i]]);
  }

  const handlePresetClick = function(data, e) {
    if (onClick)
      onClick(data, e);
  };

  const buttonCols = useMemo(function() {
    let retValue = [];
    for (let i=0; i<colorCols.length; i++) {
        let buttonRows = [];
        let colorCol = colorCols[i];
        for (let j=0; j<colorCol.length; j++) {
            let c = colorCol[j];
            buttonRows.push((
                <ThemeColorButton
                  theme={ theme }
                  colorDef={ c }
                  key={  i + ' ' + j  }
                  activeColor={ selectedColor }
                  onClick={ handlePresetClick }
                  onSwatchHover={ onSwatchHover }
                />
            ));
        }
        let buttonCol = (
          <div className={clsx(classes.col)}
              key={'' + i}>
              {buttonRows}
          </div>
        );
        retValue.push(buttonCol);
    }
    return retValue;
  }, [colorCols]);

  return (
    <Box className={clsx(propClassName, classes.root)} {...other}>
        {buttonCols}
    </Box>
  )
};

ThemePresetColors.propTypes = {
  colors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any))]),
};


export default ThemePresetColors;