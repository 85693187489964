import React from "react";

import { ChartUtils } from "@sheetxl/models";

// import useDAGMProperty from "../hooks/useDAGMProperty";

import AutocompletePropertyEditor from "../AutocompletePropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

function DataLabelSeparatorPropertyEditor(props) {
  const { shape, property = "separator", disabled = false, ...other } = props;

  // const [displayUnits] = useDAGMProperty(shape, property);

  // let values = [];
  // for (let i = 0; i < ChartUtils.builtInDisplayUnitsKeys.length; i++) {
  //   values.push({
  //     key: ChartUtils.builtInDisplayUnitsKeys[i],
  //     description:
  //       ChartUtils.builtInDisplayUnits[ChartUtils.builtInDisplayUnitsKeys[i]]
  //         .description,
  //   });
  // }
  let values = [
    {
      key: ', ',
      description: ', ',
      isCustom: false,
      meta: '(comma)',
    },
    {
      key: '; ',
      description: '; ',
      isCustom: false,
      meta: '(semicolon)',
    },
    {
      key: '. ',
      description: '. ',
      isCustom: false,
      meta: '(period)',
    },
    {
      key: '\n',
      description: ' ',
      isCustom: false,
      meta: '(new line)',
    },
    {
      key: ' ',
      description: ' ',
      isCustom: false,
      meta: '(space)',
    },
  ];


  const onSelectHandler = function (event, newValue) {
    if (event) event.stopPropagation();
  };

  // adjustGetValue={adjustGetValue}
  // adjustSetValue={adjustSetValue}
  // onClick={(event) => event.stopPropagation()}
  // onFocus={(event) => event.stopPropagation()}
  // onSelect={onSelectHandler}

  return (
    <ExplicitPropertyEditorContainer {...other}>
    <AutocompletePropertyEditor
      label={"Separator"}
      values={values}
      shape={shape}

      disabled={disabled}

      property={property}

    ></AutocompletePropertyEditor>
    </ExplicitPropertyEditorContainer>
  );
}

export default DataLabelSeparatorPropertyEditor;
