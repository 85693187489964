import AbstractComponent from "../AbstractComponent";
import ChartRenderer from "./ChartRenderer";

class ChartShapeRenderer extends AbstractComponent {
  constructor(chartModel, chartContainer, options) {
    super();
    this.chartModel = chartModel;
    this._chartContainer = chartContainer;
    this._options = options || {};
    // TODO - add error handling here.
  }

  toString() {
    return "ChartShapeRenderer:[" + this.chartModel + "]";
  }

  destroy() {
    if (this._unListener) this._unListener();
    if (this.chartRender) {
      this.chartRender.dispose();
      while (this._chartContainer.firstChild) {
        this._chartContainer.firstChild.remove();
      }
    }
  }

  get chartModel() {
    return this._chartModel;
  }

  set chartModel(newValue) {
    if (this._unListener) this._unListener();

    this._chartModel = newValue;

    //         let _handlerDataModel = this._handlerDataModel.bind(this);

    //         this._unListener = this._chartModel.sheet.addDataListener({
    //             onUpdate : _handlerDataModel,
    //         });
  }

  //     _handlerDataModel(event) {
  //         console.log('handleChartDataModelChanged from ChartAbstractComponent!', event.x, event.y, event.value);
  //         if (event.y === undefined || event.x == undefined)
  //             return;
  //         var row = this.dataSet.row(event.y);
  //         var rowUpdated = (row ? [...row] : []);
  //         //if (Number(event.x) === 0 || Number(event.x) === 1) {
  //         rowUpdated[event.x] = event.value;
  //         this.dataSet.row(event.y, rowUpdated);
  //         //}
  //     }

  updateChart(chartModel, renderOptions) {
    if (this.chartRender) {
      this.chartRender.dispose();
      while (this._chartContainer.firstChild) {
        this._chartContainer.firstChild.remove();
      }
    }

    let allOptions = Object.assign({}, this._options, renderOptions || {});
    if (this.libs) {
      this.chartRender = new ChartRenderer(
        this.libs.anychart,
        this.libs.acgraph,
        this._chartContainer,
        allOptions
      );
      this.chartRender.renderChart(this.chartModel); //, this.dataSet);
    }
  }

  init() {
    const CHART_ADDRESS = process.env.CHART_DEBUG;
    return new Promise((resolve, reject) => {
      var libs = [];
      if (!CHART_ADDRESS) {
        libs.push(import("anychart/dist/js/anychart-base.min.js"));
        // libs.push(import('anychart/dist/js/anychart-bundle.min')); // full bundle
      } else
        libs.push(
          (function () {
            return new Promise(function (resolve, reject) {
              import("./ChartShapeRendererDevResolver").then(
                function (resolveCharts) {
                  resolveCharts.default(CHART_ADDRESS).then(resolve);
                },
                function (error) {
                  reject(error);
                }
              );
            });
          })()
        );

      Promise.all(libs)
        .then((values) => {
          resolve({
            anychart: values[0].default,
            acgraph: values[0].graphics,
          });
        })
        .catch((error) => {
          console.log(error);
          // Note - Should we also resolve here with an error indicator (so that we can render with error in ui)
          reject(error);
        });
    });
  }

  render(renderOptions) {
    return new Promise((resolve, reject) => {
      this.init().then(
        (libs) => {
          this.libs = libs;
          // let chartDescriptor = CommonUtils.cloneObject(this.chartModel.json);
          // let data = CommonUtils.cloneObject(this.chartModel.sheet.data);

          //                 this.dataSet = libs.anychart.data.set();
          //                 // Note - This can happend after which is very nice
          //                 this.dataSet.data(this.chartModel.sheet.data);

          this.updateChart(this.chartModel, renderOptions);

          resolve(this);
        },
        (error) => {
          console.log(error);
          reject(this);
        }
      );
    });
  }
}

export default ChartShapeRenderer;
