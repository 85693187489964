import React, { useMemo } from "react";
import { useState } from "react";
import { PropTypes } from "prop-types";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { deepmerge } from '@material-ui/utils';

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { themedStyleWithScrollbar } from "./ThemedScrollbarWrapper";

import useDAGMProperty from "./hooks/useDAGMProperty";

const useStyles = makeStyles(function(theme) {
    let customizations = {
      root: {
        display: "flex",
        flex: "1 1 100%",
        flexDirection: "row",
        alignItems: "center",
        "& > *": {
          margin: theme.spacing(0),
        },
      },
      selectMenu: themedStyleWithScrollbar(theme, {
        // border: 'red solid 2px'
      }),
      selectControl: {
        margin: theme.spacing(1),
        marginLeft: "0px",
        marginRight: "0px",
        minWidth: 0,
        flex: function ({ inputWidth, outlined }) {
          if (outlined) return `1 0 ${100 - inputWidth}%`;
          else return `1 0 ${inputWidth}%`;
        },
      },
      input: {
        padding: "10.5px 14px",
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        paddingRight: "4px",
        flex: function ({ inputWidth }) {
          return `1 0 ${100 - inputWidth}%`;
        },
      },
      selectValue: {
        flexDirection: "row",
        display: "flex",
      },
      automatic: {
        color: function () {
          return theme.palette.text.secondary;
        }
      }
    };
    customizations = deepmerge(customizations, theme.overrides.SmartInputField || {});
    return customizations;
});

const AUTOMATIC_VALUE = {};
function SelectPropertyEditor(props) {
  const {
    shape,
    property,
    values,
    renderer,
    keyToValue,
    valueToKey,
    showAuto = true,
    onSelect,
    label,
    outlined = true,
    inputWidth = "60",
    adjustGetValue,
    disabled,
    adjustSetValue,
    ...other
  } = props;
  const classes = useStyles({ inputWidth: inputWidth, outlined: outlined });

  const [value, setValue, propertyValue] = useDAGMProperty(shape, property);

  let defaultAsKey = propertyValue.defaultValue;
  if (adjustGetValue) defaultAsKey = adjustGetValue(defaultAsKey);
  if (valueToKey) defaultAsKey = valueToKey(defaultAsKey);
  const isExplicit = propertyValue.isExplicit;

  let valueAsKey = value;
  if (adjustGetValue) valueAsKey = adjustGetValue(valueAsKey);
  if (valueToKey) valueAsKey = valueToKey(valueAsKey);

  const selectedValues = [...values];

  const findIndex = function (key) {
    for (let i = 0; i < selectedValues.length; i++) {
      if (selectedValues[i].key === key) return i;
    }
    return -1;
  };

  const [selectedIndex, setSelectedIndex] = useState(findIndex(valueAsKey));

  if (showAuto) {
    let autoChoice = selectedValues[findIndex(defaultAsKey)];
    let description = defaultAsKey;
    if (autoChoice) {
      description = autoChoice.description;
    } else {
      console.warn(
        "defaultValue '" + defaultAsKey + "' not in values set.",
        values
      );
    }
    selectedValues.unshift({
      key: AUTOMATIC_VALUE,
      originalKey: defaultAsKey,
      description: description,
      isAutomatic: true,
    });
    if (!isExplicit) valueAsKey = AUTOMATIC_VALUE;
  }

  const handleSelect = function (event, newValue, index) {
    setSelectedIndex(index);
    if (newValue === AUTOMATIC_VALUE) newValue = undefined;
    else if (keyToValue) newValue = keyToValue(newValue);

    if (adjustSetValue) newValue = adjustSetValue(newValue);
    setValue(newValue);
    if (onSelect) onSelect(event, newValue);
  };

  const renderValue = useMemo(function() {
    return function (valueRender, isMenu) {
        let foundIndex = findIndex(valueRender);
        if (foundIndex === -1) return <div>Invalid Choice</div>;

        let selectedValue = selectedValues[foundIndex];
        let className = clsx(classes.selectValue, {
            [classes.automatic]: selectedValue.isAutomatic
        });

        let retValue = null;
        if (renderer) {
          retValue = renderer({
            key: (selectedValue.isAutomatic ? selectedValue.originalKey: selectedValue.key),
            isMenu: isMenu,
            isAutomatic: selectedValue.isAutomatic,
            className: className});
        } else {
          retValue = (
            <div
              className={className}
            >
              <div>{selectedValue.description}</div>
            </div>
          );
        }

        return retValue;
      };
  }, [renderer, selectedValues]);

  let found = false;

  const menuItems = [];
  for (let i = 0; i < selectedValues.length; i++) {
    let selectedValue = selectedValues[i];
    if (selectedValue.key === valueAsKey) found = true;

    menuItems.push(
      <MenuItem
        key={selectedValue.key}
        value={selectedValue.key}
        selected={i === selectedIndex}
        onClick={(event) => handleSelect(event, selectedValue.key, i)}
      >
        {renderValue(selectedValue.key, true)}
      </MenuItem>
    );
    if (selectedValue.key === AUTOMATIC_VALUE)
      menuItems.push(<Divider key={i} />);
  }

  if (!found) {
    console.warn(
      "invalid value found for " +
        property.propertyName +
        " on property " +
        property,
      value
    );
  }

  let layout;
  if (outlined) {
    layout = (
      <Box className={classes.root} {...other}>
        <FormControl variant="outlined" className={classes.selectControl}>
          {label ? <InputLabel>{label}</InputLabel> : <></>}
          <Select
            value={valueAsKey}
            renderValue={renderValue}
            disabled={disabled}
            input={
              <OutlinedInput label={label} classes={{ input: classes.input }} />
            }
            MenuProps={{ classes: { paper: classes.selectMenu } }}
          >
            {menuItems}
          </Select>
        </FormControl>
      </Box>
    );
  } else {
    layout = (
      <Box className={classes.root} {...other}>
        {label ? (
          <Typography className={classes.heading} component="div">
            {label}
          </Typography>
        ) : (
          <></>
        )}
        <FormControl variant="outlined" className={classes.selectControl}>
          <Select
            value={valueAsKey}
            renderValue={renderValue}
            input={<OutlinedInput classes={{ input: classes.input }} />}
            MenuProps={{ classes: { paper: classes.selectMenu } }}
          >
            {menuItems}
          </Select>
        </FormControl>
      </Box>
    );
  }
  return layout;
}

SelectPropertyEditor.propTypes = {
  shape: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
  values: PropTypes.array,
  label: PropTypes.string,
  showAuto: PropTypes.bool,
};

export default SelectPropertyEditor;
