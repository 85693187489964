import React from "react";

import SelectPropertyEditor from "./SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";

function StrokeLineCapPropertyEditor(props) {
  const { shape, inputWidth, ...other } = props;

  let values = [
    { key: "round", description: "Round" },
    { key: "butt", description: "Flat" }, // OOXML has censored the word butt
    { key: "square", description: "Square" },
  ];

  return (
    <ExplicitPropertyEditorContainer {...other}>
      <SelectPropertyEditor
        label="Cap Type"
        values={values}
        shape={shape}
        inputWidth={inputWidth}
        property="strokeLineCap"
      ></SelectPropertyEditor>
    </ExplicitPropertyEditorContainer>
  );
}

export default StrokeLineCapPropertyEditor;
