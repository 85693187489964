import React, { useState } from "react";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rootDynamicIcon: {
    userSelect: "none",
    width: "1em",
    height: "1em",
    display: "flex",
    fill: "currentColor",
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(24),
    transition: theme.transitions.create("fill", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const DynamicSvgIcon = (props) => {
  const classes = useStyles();

  const {
    color = "inherit",
    svg,
    children,
    className: propClassName,
    ...other
  } = props;
  let newProps = {
    className: clsx(propClassName, classes.rootDynamicIcon),
    ...other,
  };

  newProps.dangerouslySetInnerHTML = {
    __html: svg,
  };
  return React.createElement("div", newProps);
};

export default DynamicSvgIcon;
