import React, { useState, useRef, useEffect } from "react";
import { PropTypes } from "prop-types";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { deepmerge } from '@material-ui/utils';

import Box from "@material-ui/core/Box";

import { KeyboardDatePicker } from "@material-ui/pickers/DatePicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers/MuiPickersUtilsProvider";

import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";

import { DateUtils } from "@sheetxl/models";

import useDAGMProperty from "./hooks/useDAGMProperty";

const displayFormat = "MM/dd/yyyy";

const useStyles = makeStyles(function(theme) {
  let customizations = {
      rootDateProp: {
        display: "flex",
        flex: "1 1 100%",
        flexDirection: "row",
        alignItems: "center",
        "& > *": {
          margin: theme.spacing(0),
        },
      },
      inputfield: {
        marginTop: "8px",
        marginBottom: "8px",
        cursor: "context-menu",
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        paddingRight: "4px",
        flex: function ({ inputWidth }) {
          return `1 0 ${100 - inputWidth}%`;
        },
      },
      alignedRowDate: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 100%",
        minWidth: "0px",
        alignItems: "center"
      },
      automatic: {
        "& input": {
          transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          color: function () {
            return theme.palette.text.secondary;
          }
        }
      },
      dirty: {
         fontStyle: 'italic', // also stylize the label
        "& fieldset": {
    //         borderColor: 'red !important',
        },
        "& input": {
          fontStyle: 'italic', // also stylize the label
        }
      }
    };
    customizations = deepmerge(customizations, theme.overrides.SmartInputField || {});
    if (theme.overrides.SmartInputField?.automatic) {
        customizations.automatic = deepmerge(customizations.automatic, theme.overrides.SmartInputField.automatic);
        customizations.automatic["& input"] = deepmerge(customizations.automatic["& input"], theme.overrides.SmartInputField.automatic.input || theme.overrides.SmartInputField.automatic);
        customizations.automatic["& fieldset"] = deepmerge(customizations.automatic["& fieldset"], theme.overrides.SmartInputField.automatic.fieldset || theme.overrides.SmartInputField.automatic);
    }
    if (theme.overrides.SmartInputField?.dirty) {
        customizations.dirty = deepmerge(customizations.dirty, theme.overrides.SmartInputField.dirty || {});
        customizations.dirty["& input"] = deepmerge(customizations.dirty["& input"], theme.overrides.SmartInputField.dirty.input || theme.overrides.SmartInputField.dirty);
        customizations.dirty["& fieldset"] = deepmerge(customizations.dirty["& fieldset"], theme.overrides.SmartInputField.dirty.fieldset || theme.overrides.SmartInputField.dirty);
    }
    return customizations;
});

function DatePropertyEditor(props) {
  const { shape, property, label, inputWidth = "40", ...other } = props;
  const classes = useStyles({ inputWidth: inputWidth });

  const [value, setValue, propertyValue] = useDAGMProperty(shape, property);
  const valueAsDate = DateUtils.fromExcelDate(value);

  const propertyDef = propertyValue.property;
  const [transientValue, setTransientValue] = useState(() => {
    return {
      value: undefined,
      valueString: undefined,
      property: propertyDef,
      startValue: null,
    };
  });
  const isDirty = !(transientValue.value === undefined || transientValue.value === transientValue.startValue);

  const [error, setError] = useState(() => {
    return {
      value: false,
      property: propertyDef,
    };
  });

  const onValueChange = function (value) {
    if (value instanceof Date && isNaN(value)) throw new Error("invalid date");
    if (value === undefined || value === null)
        return undefined;
    return DateUtils.toExcelDate(value);
  };

  const commit = function (commitValue) {
    if (commitValue === "") commitValue = undefined;
    let valid = true;
    if (onValueChange) {
      try {
        let onValueChangeUpdate = onValueChange(commitValue);
        if (onValueChangeUpdate !== undefined)
          commitValue = onValueChangeUpdate;
      } catch (errorRange) {
        valid = false;
      }
    }

    if (valid) {
      clearTransientStates();
      setValue(commitValue);
    } else {
      setError({
        value: true,
        property: propertyDef,
      });
    }
  };

  const commitTransient = function () {
    if (error.value) return;

    if (transientValue.value === transientValue.startValue) return;
    let valueCommit = transientValue.value;
    if (valueCommit === null)
        valueCommit = undefined;
    commit(valueCommit);
  };

  const clearTransientStates = function () {
    setTransientValue({
      value: undefined,
      property: propertyDef,
      startValue: undefined,
    });
    setError({
      value: false,
      property: propertyDef,
    });
  };

  if (propertyDef !== transientValue.property) {
    clearTransientStates();
  } else if (
    error.value &&
    transientValue.startValue !== undefined &&
    transientValue.startValue !== value
  ) {
    clearTransientStates();
  }

  const formatValue = function (value) {
    if (value === undefined || value === null) return "";
    let retValue = format(value, displayFormat);
    return retValue;
  };


  let effectivePopupValue = valueAsDate;
  if (transientValue.value !== null && transientValue.value !== undefined)
    effectivePopupValue = transientValue.value;
  //let effectiveInputValue = formatValue(effectivePopupValue);

  let placeholder = formatValue(DateUtils.fromExcelDate(propertyValue.defaultValue));

  // const labelFuncTest = function(displayValue, test) {
  //   //       console.log('label', test, test2);
  //         if (placeholder)
  //           return '';
  //         return formatValue(displayValue);
  //     }
  /*
   * This is a hack. I tried several to get the picker to allow me to black out the
   * inputfield.
   * I tried :
   * Creating a TextFieldControl - This was my first choice but this would cause the input to be rerendered loosing focus.
   * Using  a custom labelFunc but this requires you to also handling masking. (perhap this woild work)
   *
   * The current solution requires me to create a class on each render. (I could memoze but would need to watch, value, propertyValue, transient)
   * This also has the potential problem that if the date format is needout of the label it would be blank.
   */
  class PlaceDateFnsUtils extends DateFnsUtils {
    constructor(date) {
      super(date);
    }
    format(date, format) {
      if (transientValue.value === null && placeholder) return "";
      return super.format(date, format);
    }
  }

  const handleDateChange = (valueDate, valueString) => {
    let valid = true;

    // if (commitOnChange) {
    //         if (onValueChange) {
    //           try {
    //             let onValueChangeUpdate = onValueChange(valueDate);
    //             if (onValueChangeUpdate !== undefined)
    //               valueDate = onValueChangeUpdate;
    //           } catch (errorRange) {
    //             valid = false;
    //           }
    //         }
    //   if (valid)
    //     commit(valueDate);
    //   return;
    // }
    setTransientValue({
      value: valueDate,
      valueString: valueString,
      property: propertyDef,
      startValue: transientValue.startValue || value,
    });

    if (valid) {
      setError({
        value: false,
        property: propertyDef,
      });
    }
  };

  const fieldRef = useRef(null);
  const inputRef = useRef(null);
  const popupRef = useRef(null);


  const [isOpen, setOpen] = useState(false);
  const [isClosing, setClosing] = useState(false);
  const [isPopupCanceled, setPopupCanceled] = useState(false);

  useEffect(() => {
    if (isClosing) {
      setOpen(false);
      if (inputRef.current) {
        setTimeout(function () {
          inputRef.current.focus();
        }, 0);
      }

      if (!isPopupCanceled) {
        commitTransient();
      }
      setClosing(false);
      setPopupCanceled(false);
    }
  }, [isClosing]);

  const handleOnClosePopup = function (event, reason) {
    // Note - Handling the closePopup causes the datepicker to NOT hanle so we manually close using useEffect
    if (reason === 'backdropClick') {
        // Note - after we change color picker (soon) We want this to be a commit I guess (like color but we can change later)
        setPopupCanceled(false);
    } else if (reason === 'escapeKeyDown') {
        setPopupCanceled(true);
    }
    setClosing(true);
  };

  const handleOnCloseClick = function (event, reason) {
    setClosing(true);
  };

  const handleOnOpen = function (event) {
    setOpen(true);
    setPopupCanceled(false);
    setClosing(false);
  };

  const handleBlurContainer = (event) => {
    // Because of the popup we have two track to components if both are not true than we lost focus.
    // if the relatedtarget is not contained in either the input or the popup than we consider it lost.
    let blurred = true;
    // If the related is null then it's not a true blur. (then it's didn't set focus elesewhere because the window lost focus)
    if (
      !event.relatedTarget ||
      fieldRef.current.contains(event.relatedTarget) ||
      (popupRef.current && popupRef.current.contains(event.relatedTarget))
    )
      blurred = false;

    if (!blurred) return;
    commitTransient();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode == 13) {
      // ENTER
      commitTransient();
    } else if (event.keyCode == 27) {
      // ESC
      clearTransientStates();
    }
  };

  let inputLabelProps = {
    shrink: true,
  };

  let isTransientInput =
    transientValue.value === null ||
    transientValue.value === transientValue.startValue;

  // We use tried to use our own textfield but a new component was being generated on every change
  // causing focus to be lost
  const textFieldComponent = (props) => {
    //     return (
    //       <TextField
    //         type="text"
    //         variant="outlined"
    //         size="small"
    //         placeholder={placeholder}
    //         label={label}
    //         className={clsx(
    //           classes.alignedRowDate,
    //           classes.inputfield)}
    //         InputProps={{
    //           style: { paddingRight: '0px' },
    //           endAdornment : (props.InputProps? props.InputProps.endAdornment : <></>)
    //         }}
    //         inputRef={inputRef}
    //         value={(placeholder ? '' : props.value)}
    //         onClick={props.onClick}
    //         onChange={props.onChange}
    //         InputLabelProps={inputLabelProps}
    //         style={{ fontStyle: (isTransientInput ? 'inherit' : 'italic') }}
    //       />
    //     );
  };

  const inputProps = {
    style: { paddingRight: "0px", width: "100%" },
  };

  return (
    <Box
      className={classes.rootDateProp}
      {...other}
      ref={fieldRef}
      onBlur={(e) => handleBlurContainer(e)}
    >
      <MuiPickersUtilsProvider utils={PlaceDateFnsUtils}>
        <KeyboardDatePicker
          className={clsx(classes.alignedRowDate, classes.inputfield, {
            [classes.automatic]: !propertyValue.isExplicit,
            [classes.dirty]: isDirty
          })}
          disableToolbar
          autoOk={true}
          placeholder={placeholder}
          value={effectivePopupValue}
          error={error.value}
          label={label}
          variant="inline"
          format={displayFormat}
          margin="none"
          helperText=""
          onKeyDown={handleKeyDown}
          invalidDateMessage={<div style={{ display: "none" }}></div>}

          inputVariant="outlined"
          size="small"
          InputProps={inputProps}
          inputProps={{
            ref: inputRef,
          }}
          onChange={handleDateChange} // input
          onOpen={handleOnOpen} // enter or click on date
          open={isOpen}
          onClose={handleOnCloseClick} // enter or click on date
          PopoverProps={{
            onClose: handleOnClosePopup,
            ref: popupRef,
            PaperProps: {},
          }}
          InputLabelProps={inputLabelProps}
          KeyboardButtonProps={{
            "aria-label": "change date",
            disableFocusRipple: true,
            disableRipple: true,
            tabIndex: "-1",
            style: { paddingRight: "6px", background: "transparent" },
          }}
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
}

DatePropertyEditor.propTypes = {
  shape: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default DatePropertyEditor;
