import React from "react";
import { useCallback, useMemo, memo, useRef } from "react";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import ToggleButton from "@material-ui/lab/ToggleButton";

import MenuPanel from "./MenuPanel.jsx";

import { CommonUtils } from "@sheetxl/models";

import { presetChartTypeDescriptor } from "@sheetxl/presets";

import DynamicSvgIcon from "./theme/DynamicSvgIcon";
import { themedStyleWithScrollbar } from "./ThemedScrollbarWrapper";

import useDAGMProperty from "./hooks/useDAGMProperty";


const useStyles = makeStyles((theme) => ({
  presetMenu: {
    marginTop: "2px",
    zIndex: 5000,
    maxHeight: "80vh",
  },
  presetPanel: {
    overflowY: "auto",
    maxHeight: "80vh",
    padding: "6px 6px",
  },
  presetButtonRow: {
    paddingTop: "8px",
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "row",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  popup: themedStyleWithScrollbar(theme, {
//     border: 'red solid 2px'
  }),
  presetCategoryLabel: {
    padding: "8px 8px",
    paddingBottom: "0px"
  },
  presetButton: {},
  presetGrow: {
    transformOrigin: function ({ placement }) {
      return placement === "bottom-start" ? "left top" : "left bottom";
    },
  },
  presetButtonGap: {
    minWidth: "4px",
  },
  largeIcon: {
    height: "42px",
    width: "42px",
  },
  presetIcon: {
    fill: "currentColor",
    "& .style_HighContrast": {
      fill: "currentColor",
    },
    "& .style_m20": {
      fill: theme.palette.background.paper, // office always makes this white
    },
    "& .style_m21": {
      fill: theme.palette.secondary.light, // office always makes this grey
    },
    "& .style_m22": {
      fill: "currentColor", // This is the lines we want this to be 'grey or white as defined on a regular icon'
    },
    "& .style_m23": {
      fill: theme.palette.secondary.dark,
    },
    "& .style_m24": {
      fill: theme.palette.info.main,
    },
    "& .style_m25": {
      fill: theme.palette.info.dark,
    },
    "& .style_m26": {
      fill: theme.palette.info.light,
    },
  },
  selected: {},
}));

const PresetEditor = (props) => {
  const {
    chartShape,
    onSelect,
    placement = "bottom-start",
    outlined = true,
    ...other
  } = props;

  const classes = useStyles({ outlined: outlined, placement: placement });

  const [isOpen, setOpen] = React.useState(() => false);

  const [chartType] = useDAGMProperty(chartShape, "mainType", null, true);
  const [grouping] = useDAGMProperty(
    chartShape,
    "types[0].grouping",
    null,
    true
  );
  const [scatterStyle] = useDAGMProperty(
    chartShape,
    "types[0].scatterStyle",
    null,
    true
  );
  const [holeSize] = useDAGMProperty(
    chartShape,
    "types[0].holeSize",
    null,
    true
  );

  let effectiveType = "column";
  if (chartType) effectiveType = chartType;
  if (
    chartType === "column" ||
    chartType === "bar" ||
    chartType === "area" ||
    chartType === "line"
  ) {
    if (grouping === "stacked" || grouping === "stacked")
      effectiveType += "Stacked";
    else if (grouping === "percentStacked") effectiveType += "StackedPercent";
    if (
      chartType === "line" &&
      scatterStyle &&
      scatterStyle.toLowerCase().includes("marker")
    ) {
      effectiveType += "Markers";
    }
  } else if (chartType === "scatter") {
    effectiveType = "scatterMarkers";
    if (scatterStyle === "marker") {
      effectiveType = "scatterMarkers";
    } else if (scatterStyle === "lineMarker") {
      effectiveType = "scatterLinesMarkers";
    } else if (scatterStyle === "line") {
      effectiveType = "scatterLines";
    }
    // TODO check if smooth then
    /*
    if (smooth) {
        effectiveType += 'Smooth';
        //'scatterLinesMarkersSmooth'
        //'scatterLinesSmooth'
    }
    */
  } else if (chartType === "pie") {
    if (holeSize > 0) effectiveType = "pieDoughnut";
  }

  // const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);

  const handleClose = useCallback(
    (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    },
    [setOpen, anchorRef]
  );

  const handleListKeyDown = useCallback(
    (event) => {
      if (event.key === "Tab") {
        event.preventDefault();
        handleClose(event);
      }
    },
    [handleClose]
  );

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(isOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen, anchorRef]);

  const handleToggleChartType = useCallback(
    (event, newChartType, chartPreset) => {
      // setChartTypeCurrent(newChartType);
      if (onSelect) {
        onSelect(chartPreset);
      }
    },
    [onSelect]
  );

  // const [chartTypeCurrent, setChartTypeCurrent] = useState('column');

  const IconButton = memo((props) => {
    let chartPreset = presetChartTypeDescriptor(props.value);

    return (
      <ToggleButton
        aria-label={props.value}
        tabIndex="0"
        aria-haspopup="true"
        className={clsx(classes.presetButton, {
          [classes.selected]: effectiveType === props.value,
        })}
        ref={props.anchorRef}
        value={props.value}
        disabled={!chartPreset.template}
        selected={effectiveType === props.value}
        onChange={(event) => {
          handleToggleChartType(
            event,
            props.value,
            CommonUtils.cloneObject(chartPreset.template)
          );
          handleClose(event);
        }}
      >
        <DynamicSvgIcon
          className={clsx(classes.presetIcon, classes.largeIcon)}
          svg={chartPreset.icon}
        ></DynamicSvgIcon>
      </ToggleButton>
    );
  }); // [onSelect] - Note - Why can't I memoize this?

  const chartTypeDescriptor = useMemo(
    () => presetChartTypeDescriptor(effectiveType),
    [effectiveType]
  );

  return (
    <Box {...other}>
      <ToggleButton
        value="isOpen"
        aria-label="Chart Type"
        tabIndex="0"
        aria-haspopup="true"
        className={clsx(classes.presetButton)}
        ref={anchorRef}
        selected={isOpen}
        onChange={() => {
          setOpen(!isOpen);
        }}
      >
        <DynamicSvgIcon
          className={clsx(classes.presetIcon)}
          svg={chartTypeDescriptor.icon}
        ></DynamicSvgIcon>
        <ArrowDropDown />
      </ToggleButton>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        className={clsx(classes.presetMenu)}
        placement={placement}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} className={clsx(classes.presetGrow)}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuPanel
                  autoFocusItem={isOpen}
                  onKeyDown={handleListKeyDown}
                  className={clsx(classes.presetPanel, classes.popup)}
                >
                  <Typography variant="h6" component="h6" className={clsx(classes.presetCategoryLabel)}>
                    Column
                  </Typography>
                  <Divider light/>
                  <Box className={clsx(classes.presetButtonRow)}>
                    <IconButton value="column" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="columnStacked" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="columnStackedPercent" />
                  </Box>
                  <Typography variant="h6" component="h6" className={clsx(classes.presetCategoryLabel)}>
                    Line
                  </Typography>
                  <Divider light/>
                  <Box className={clsx(classes.presetButtonRow)}>
                    <IconButton value="line" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="lineStacked" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="lineStackedPercent" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="lineMarkers" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="lineStackedMarkers" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="lineStackedPercentMarkers" />
                  </Box>
                  <Typography variant="h6" component="h6" className={clsx(classes.presetCategoryLabel)}>
                    Pie
                  </Typography>
                  <Divider light/>
                  <Box className={clsx(classes.presetButtonRow)}>
                    <IconButton value="pie" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="pieDoughnut" />
                  </Box>
                  <Typography variant="h6" component="h6" className={clsx(classes.presetCategoryLabel)}>
                    Bar
                  </Typography>
                  <Divider light/>
                  <Box className={clsx(classes.presetButtonRow)}>
                    <IconButton value="bar" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="barStacked" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="barStackedPercent" />
                  </Box>
                  <Typography variant="h6" component="h6" className={clsx(classes.presetCategoryLabel)}>
                    Area
                  </Typography>
                  <Divider light/>
                  <Box className={clsx(classes.presetButtonRow)}>
                    <IconButton value="area" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="areaStacked" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="areaStackedPercent" />
                  </Box>
                  <Typography variant="h6" component="h6" className={clsx(classes.presetCategoryLabel)}>
                    Scatter
                  </Typography>
                  <Divider light/>
                  <Box className={clsx(classes.presetButtonRow)}>
                    <IconButton value="scatterMarkers" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    {/* <IconButton value="scatterLinesMarkersSmooth"/> <div className={clsx(classes.presetButtonGap)}></div>*/}
                    {/* <IconButton value="scatterLinesSmooth"/> <div className={clsx(classes.presetButtonGap)}></div>*/}
                    <IconButton value="scatterLinesMarkers" />
                    <div className={clsx(classes.presetButtonGap)}></div>
                    <IconButton value="scatterLines" />
                  </Box>
                </MenuPanel>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

PresetEditor.propTypes = {
  // classes: PropTypes.object.isRequired,
};

export default PresetEditor;
