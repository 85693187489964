import React from "react";

import SelectPropertyEditor from "../SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

function ChartStylePropertyEditor(props) {
  const { shape, property, inputWidth, label = "Style", ...other } = props;

  let stylesThemes = [];
  for (let i = 2; i < 48; i++)
    stylesThemes.push({ key: i, description: "Style " + i });

  return (
    <ExplicitPropertyEditorContainer {...other}>
      <SelectPropertyEditor
        label={label}
        values={stylesThemes}
        shape={shape}
        property="styleId"
      ></SelectPropertyEditor>
    </ExplicitPropertyEditorContainer>
  );
}

export default ChartStylePropertyEditor;
