import React from "react";

import useDAGMProperty from "../hooks/useDAGMProperty";

import FillStrokePropertyEditor from "../FillStrokePropertyEditor";

import ExpandingBooleanPropertyEditor from "../ExpandingBooleanPropertyEditor";


function ChartAxisGridLinePropertyEditor(props) {
  const { shape, property, label, ...other } = props;

  const [value] = useDAGMProperty(shape, property);

  return (
    <ExpandingBooleanPropertyEditor
      {...other}
      label={label}
      shape={value}
      property={"shown"}
    >
      <FillStrokePropertyEditor
        hideFill={true}
        shape={value}
      ></FillStrokePropertyEditor>
    </ExpandingBooleanPropertyEditor>
  );
}

export default ChartAxisGridLinePropertyEditor;
