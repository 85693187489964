import React from "react";

import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";
import BooleanPropertyEditor from "./BooleanPropertyEditor";

import HeaderContainer from "./HeaderContainer";
import LabelsFormatCategoryPropertyEditor from "./LabelsFormatCategoryPropertyEditor";
import LabelsFormatCodePropertyEditor from "./LabelsFormatCodePropertyEditor";

function LabelsFormatPropertyEditor(props) {
  const {
    shape,
    showLinkedToSource = true,
    ...other } = props;

  const linkedToSource =
  showLinkedToSource ? (
    <ExplicitPropertyEditorContainer>
      <BooleanPropertyEditor
        label="Linked To Source"
        shape={shape}
        property="sourceLinked"
      />
    </ExplicitPropertyEditorContainer>
  ) : (
    <></>
  );

  return (
    <HeaderContainer {...other} label="Format" type="secondaryHeading">
      <LabelsFormatCategoryPropertyEditor
        shape={shape}
      ></LabelsFormatCategoryPropertyEditor>
      <LabelsFormatCodePropertyEditor
        shape={shape}
      ></LabelsFormatCodePropertyEditor>
      {linkedToSource}
    </HeaderContainer>
  );
}

export default LabelsFormatPropertyEditor;
