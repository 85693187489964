import React, { useState } from "react";
import { PropTypes } from "prop-types";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { ChartUtils } from "@sheetxl/models";

import useDAGMProperty from "../hooks/useDAGMProperty";

import { ChartShape } from "@sheetxl/models";

import { ChartOrdAxisShape } from "@sheetxl/models";
import { ChartValAxisShape } from "@sheetxl/models";
import { ChartDateAxisShape } from "@sheetxl/models";

import { ChartSeriesShape } from "@sheetxl/models";

import PerfectScrollPane from "../PerfectScrollPane";

import ChartAreaPropertyEditor from "./ChartAreaPropertyEditor";
//import ChartTypePropertyEditor from './ChartTypePropertyEditor';
import ChartAxisOrdPropertyEditor from "./ChartAxisOrdPropertyEditor";
import ChartAxisDatePropertyEditor from "./ChartAxisDatePropertyEditor";
import ChartAxisValPropertyEditor from "./ChartAxisValPropertyEditor";

import ChartSeriesPropertyEditor from "./ChartSeriesPropertyEditor";

import ChartElementChoice from "./ChartElementChoice";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    paddingTop: "8px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "0px",
    // border: 'pink solid 5px'
  },
  elementControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "flex",
    flex: "0 0 0",
  },
  input: {
    padding: "10.5px 14px",
  },
  paddingLeft: {
    paddingLeft: "9px"
  },
  headingPropertyEditior: {
    fontSize: theme.typography.pxToRem(15),
    margin: theme.spacing(1),

    flex: "0 0 0",
  },
  panelPropertyEditor: {
    flexDirection: "column",
    display: "flex",
    flexGrow: 1,
    flexBasis: "100%",
    alignItems: "stretch",
  },
  secondaryHeading: {
    fontSize: theme.typography.subtitle2.fontSize,
    color: theme.palette.text.primary,
  },
  selectedValue: {},
}));

function ChartShapePropertyEditor(props) {
  const { chartModel, ...other } = props;

  const classes = useStyles();

  const handleChange = function (event) {
    setSelectedChartElement(event.target.value);
  };

  //Note -
  // Should we also return the custom datapoints and markers?
  // Powerpoint does not. Should it? Let's think about this because PPT is not consistent here.

  // const [chartTypes] = useDAGMProperty(chartModel, 'types');
  const [chartAxes] = useDAGMProperty(chartModel, "axes");
  const [chartSeries] = useDAGMProperty(chartModel, "series");
  const [chartMainType] = useDAGMProperty(chartModel, "mainType");

  const chartElements = [];
  chartElements.push(chartModel);
  //   for (let i = 0; i < types.length; i++) {
  //     chartElements.push(types.getAt(i));
  //   }
  for (let i = 0; chartAxes && i < chartAxes.length; i++) {
    let axis = chartAxes.getAt(i);
    chartElements.push(axis);
  }
  for (
    let i = 0;
    chartSeries &&
    i < (ChartUtils.isSingleSeries(chartMainType) ? 1 : chartSeries.length);
    i++
  ) {
    chartElements.push(chartSeries.getAt(i));
  }

  const [selectedChartElement, setSelectedChartElement] = useState(
    chartElements[0].typeIdId || chartElements[0]
  );
  const elementsByTypeId = {};

  chartElements.forEach((chartElement) => {
    elementsByTypeId[chartElement.typeId || chartElement] =
      chartElement;
  });

  if (!elementsByTypeId[selectedChartElement]) {
    setSelectedChartElement(
      chartElements[0].typeId || chartElements[0]
    );
    return <></>;
  }

  const menuItems = chartElements.map((chartElement) => {
    let eId = chartElement.typeId || chartElement;
    return (
      <MenuItem key={eId} value={eId}>
        <ChartElementChoice shape={chartElement} />
      </MenuItem>
    );
  });

  let panel = <></>;
  if (elementsByTypeId[selectedChartElement] instanceof ChartShape) {
    panel = (
      <ChartAreaPropertyEditor shape={chartModel}></ChartAreaPropertyEditor>
    );
    // } else if (elementsByTypeId[selectedChartElement] instanceof ChartType) {
    //   panel =
    //     <ChartTypePropertyEditor
    //       shape={elementsByTypeId[selectedChartElement]}
    //     >
    //     </ChartTypePropertyEditor>;
  } else if (
    elementsByTypeId[selectedChartElement] instanceof ChartOrdAxisShape
  ) {
    panel = (
      <ChartAxisOrdPropertyEditor
        shape={elementsByTypeId[selectedChartElement]}
      ></ChartAxisOrdPropertyEditor>
    );
  } else if (
    elementsByTypeId[selectedChartElement] instanceof ChartDateAxisShape
  ) {
    panel = (
      <ChartAxisDatePropertyEditor
        shape={elementsByTypeId[selectedChartElement]}
      ></ChartAxisDatePropertyEditor>
    );
  } else if (
    elementsByTypeId[selectedChartElement] instanceof ChartValAxisShape
  ) {
    panel = (
      <ChartAxisValPropertyEditor
        shape={elementsByTypeId[selectedChartElement]}
      ></ChartAxisValPropertyEditor>
    );
  } else if (
    elementsByTypeId[selectedChartElement] instanceof ChartSeriesShape
  ) {
    panel = (
      <ChartSeriesPropertyEditor
        shape={elementsByTypeId[selectedChartElement]}
      ></ChartSeriesPropertyEditor>
    );
  } else {
    panel = (
      <Typography className={classes.secondaryHeading}>
        No Properties
      </Typography>
    );
  }

  const renderValue = function (value) {
    return (
      <ChartElementChoice
        className={clsx(classes.selectedValue)}
        shape={elementsByTypeId[selectedChartElement]}
      />
    );
  };

  return (
    <Paper className={classes.root} {...other} elevation={0}>

      <Box className={classes.panelPropertyEditor}>
        <FormControl variant="outlined" className={classes.elementControl}>
          <InputLabel>Chart Element</InputLabel>
          <Select
            value={selectedChartElement}
            renderValue={renderValue}
            onChange={handleChange}
            input={
              <OutlinedInput
                label="Chart Element"
                classes={{ input: classes.input }}
              />
            }
          >
            {menuItems}
          </Select>
        </FormControl>
        <PerfectScrollPane className={classes.paddingLeft}>
          {panel}
        </PerfectScrollPane>
      </Box>
    </Paper>
  );
}

ChartShapePropertyEditor.propTypes = {
  chartModel: PropTypes.object.isRequired,
};

export default ChartShapePropertyEditor;
