import styled from "@emotion/styled";

const StyledChartRendererContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .chart-tooltip {
    min-width: 90px;
    display: flex;
    position: absolute;
    flex-direction: column;
    background: rgba(33, 33, 33, 0.7);
    border: solid rgba(33, 33, 33, 0.7) 4px;
    background-clip: padding-box;
    border-radius: 4px;
    color: white;
    overflow: hidden;
    z-index: 6000;
    transition: 170ms;
  }

  .chart-tooltip * {
    position: relative;
    white-space: nowrap;
  }

  .chart-tooltip .series {
    display: none;
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 4px;
    text-align: center;
    min-width: 90px;
  }

  .chart-tooltip .legend-border {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .chart-tooltip .legend-container {
    zoom: 1.25;
    width: 100%;
  }

  .chart-tooltip .legend-background {
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .chart-tooltip .legend-panel {
    display: flex;
    justify-content: center;
  }

  .chart-tooltip .divider {
    border-bottom: rgba(206, 206, 206, 0.3);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 6px;
  }

  .chart-tooltip .axis {
    padding: 0px 6px;
    font-size: 12px;
    display: flex;
    align-content: space-around;
    flex-direction: row;
    flex-flow: row;
    align-items: baseline;
  }

  .chart-tooltip .axis .value {
    flex: 1;
  }

  .chart-tooltip .axis .display-value {
    text-align: center;
  }

  .chart-tooltip .axis .right-aligned {
    text-align: right;
  }

  .chart-tooltip .axis .center-aligned {
    text-align: center;
  }

  svg text {
    user-select: none;
  }

  .chart-loading {
    // opacity: 0;
    // opacity: 1;
    // transition-property: opacity;
    // transition-delay: 0.05s;
    // transition-duration: 0.10s;

    color: green;
    width: 100%;
    height: 100%;
    position: absolute;

    box-sizing: border-box;
  }

  .chart-loading.shown {
    opacity: 1;
  }
`;

export default StyledChartRendererContainer;
