import React from "react";

import { makeStyles } from "@material-ui/core/styles";

// import { createSvgIcon } from '@material-ui/icons/utils/createSvgIcon';
import SvgIcon from "@material-ui/core/SvgIcon";

/*
 * This is a terrible implementation. The better approach is to PresetEditor.IconButton.
 *
 */
const useStyles = makeStyles(function (theme) {
  //    console.log('dynamiccolor', theme.palette.action.active);
  return {
    root: {},
    /* Styles applied to the `IconButton` component when `expandIcon` is supplied. */
    iconButton: {
      fill: theme.palette.action.active,
      //             transition: theme.transitions.create('transform', transition),
      "&:hover": {
        // Disable the hover effect for the IconButton,
        // because a hover effect should apply to the entire Expand button and
        // not only to the IconButton.
        backgroundColor: "transparent",
      },
    },
  };
});

function createColoredPath(path, opacity, stroke, dynamicColor) {
  var props = {
    d: path,
  };
  if (opacity === undefined) opacity = 1;

  if (dynamicColor) {
    if (dynamicColor.alpha !== undefined) {
      props.fill =
        "rgba(" +
        dynamicColor.red +
        "," +
        dynamicColor.green +
        "," +
        dynamicColor.blue +
        "," +
        dynamicColor.alpha +
        ")";
      opacity = opacity * dynamicColor.alpha;
    } else {
      props.fill =
        "rgb(" +
        dynamicColor.red +
        "," +
        dynamicColor.green +
        "," +
        dynamicColor.blue +
        ")";
    }
  }

  props.fillOpacity = opacity.toString();

  if (stroke) {
    props.stroke = "currentColor";
    props.strokeOpacity = "0.5";
    props.strokeWidth = "0.5";
  }

  var retValue = React.createElement("path", props);
  return retValue;
}

/**
 * Private module reserved for @material-ui/x packages.
 */
function createSvgIcon(displayName) {
  const Component = (props, ref) => {
    const { dynamicColor, isStroke = false, isText = false, ...other } = props;

    const classes = useStyles();

    //     console.log('dynamicColor', props.dynamicColor);
    const path1 =
      !isStroke && !isText ? (
        createColoredPath("M10 5.21L5.21 10h9.58z", 1, false, dynamicColor)
      ) : (
        <></>
      );

    // default to shape fill
    let imgPath =
      "M19 17c1.1 0 2-.9 2-2 0-1.33-2-3.5-2-3.5s-2 2.17-2 3.5c0 1.1.9 2 2 2zm-10.06-.44c.29.29.68.44 1.06.44s.77-.15 1.06-.44l5.5-5.5c.59-.58.59-1.53 0-2.12L7.62 0 6.21 1.41l2.38 2.38-5.15 5.15c-.59.59-.59 1.54 0 2.12l5.5 5.5zM10 5.21L14.79 10H5.21L10 5.21z";

    if (isStroke && !isText) {
      // shape outline
      imgPath =
        "m 17.28,7.18 -3.75,-3.75 -10,10 v 3.75 h 3.75 z m 2.96,-2.96 c 0.39,-0.39 0.39,-1.02 0,-1.41 l -2.34,-2.34 c -0.39,-0.39 -1.02,-0.39 -1.41,0 l -1.96,1.96 3.75,3.75 z";
    } else if (isText && !isStroke) {
      // text fill
      imgPath =
        "m 9.4687944,10.374318 h 4.2890606 v 2.896484 H 9.4687944 c -0.156472,0 -0.25576,-0.06766 -0.392578,-0.332031 -0.136818,-0.264239 -0.230469,-0.690986 -0.230469,-1.117188 0,-0.426202 0.09365,-0.852924 0.230469,-1.117187 0.136818,-0.264238 0.236157,-0.330078 0.392578,-0.330078 z M 8.0265063,0.9999978 v 0.529297 3.1875 h 1.8574211 3.8027316 c 0.0051,0.03049 0.01172,0.0552 0.01172,0.08594 v 1.865234 H 9.4112708 c -1.4309715,0 -2.5675775,0.68357 -3.2851555,1.666016 -0.717552,0.982471 -1.054687,2.2443962 -1.054687,3.5000002 0,1.255628 0.337135,2.517529 1.054687,3.5 0.717578,0.98247 1.854184,1.666016 3.2851555,1.666016 h 4.8164042 0.380859 1.328125 1.509766 1.853516 v -3.714847 h -1.853516 -0.03516 V 4.8027318 c 0,-0.783266 -0.169423,-1.612242 -0.660156,-2.363281 -0.522912,-0.800122 -1.485451,-1.439453 -2.613282,-1.439453 H 9.8839234 Z";
    } else if (isText && isStroke) {
      // text outline
      imgPath =
        "m 8.0265065,1 v 0.5292969 3.1875 H 9.8839284 13.68666 c 0.0051,0.030488 0.01172,0.055198 0.01172,0.085937 V 6.6679688 H 9.4112722 c -1.4309727,0 -2.5675786,0.6835694 -3.2851563,1.6660156 -0.7175525,0.9824711 -1.0546875,2.2443956 -1.0546875,3.4999996 0,1.255628 0.337135,2.517529 1.0546875,3.5 C 6.8436936,16.316454 7.9802995,17 9.4112722,17 h 4.8164038 0.380859 1.328125 1.509766 1.853516 v -3.714844 h -1.853516 -0.03516 V 4.8027344 c 0,-0.7832656 -0.169423,-1.6122423 -0.660156,-2.3632813 C 16.228202,1.6393309 15.265663,1 14.137832,1 H 9.8839284 Z m 1.0566407,1.0625 h 0.8007812 4.2519506 c 0.702157,0 1.403528,0.4578206 1.728516,0.9550781 0.357088,0.5464163 0.482422,1.1489893 0.482422,1.7851563 v 9.5429686 h 1.097656 0.792969 V 15.9375 H 17.444473 15.934707 14.606582 14.225723 9.409319 c -1.1078247,0 -1.8739476,-0.474946 -2.4257812,-1.230469 -0.5518337,-0.755524 -0.8515625,-1.811332 -0.8515625,-2.873047 0,-1.061715 0.2997288,-2.1175221 0.8515625,-2.8730465 C 7.5353714,8.2053881 8.3014943,7.7304687 9.409319,7.7304688 h 5.349607 V 4.8027344 c 0,-0.2015739 -0.031,-0.3775616 -0.07031,-0.5253906 -0.03956,-0.1479049 -0.07008,-0.2461829 -0.15625,-0.3808594 L 14.376114,3.6542969 H 9.8839284 9.0851003 Z M 9.409319,9.3261719 c -0.6123058,0 -1.0883544,0.4261131 -1.3359375,0.9042971 -0.2476839,0.478133 -0.3496094,1.039839 -0.3496093,1.603515 0,0.563676 0.1021774,1.129264 0.3496093,1.607422 0.2476839,0.478159 0.7236317,0.904297 1.3359375,0.904297 h 5.349607 V 9.3261719 Z m 0,1.0605471 h 4.28906 v 2.896484 h -4.28906 c -0.1564717,0 -0.2557599,-0.06766 -0.3925781,-0.332031 -0.1368183,-0.264239 -0.2304688,-0.690986 -0.2304687,-1.117188 0,-0.426202 0.093651,-0.852924 0.2304687,-1.117187 0.1368182,-0.264238 0.2361568,-0.330078 0.3925781,-0.330078 z";
    }

    const path2 = createColoredPath(imgPath, 1, false);

    // underline stroke
    const path3 = createColoredPath("M0 20h24v4H0z", 1, true, dynamicColor);

    var path = React.createElement(React.Fragment, null, path1, path2, path3);
    return (
      <SvgIcon ref={ref} {...other} className={classes.iconButton}>
        {path}
      </SvgIcon>
    );
  };

  if (process.env.NODE_ENV !== "production") {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`;
  }

  Component.muiName = SvgIcon.muiName;

  return React.forwardRef(Component);
  //   return React.memo(React.forwardRef(Component));
}

const DynamicFormatColorFill = createSvgIcon("FormatColorFillDynamic");

export default DynamicFormatColorFill;
