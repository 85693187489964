import React from "react";
import { PropTypes } from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { AdjustableColor } from "@sheetxl/models";

// import ThemeColorPicker from "react-color/es/Sketch";
import ThemeColorPicker from "./ThemeColorPicker";
import DynamicFormatColorFill from "./DynamicFormatColorFill";

// TODO - Add type - fill/stroke/text and just icon accordingly
// TODO - Add automatic - theme & standard colors & recent

// TODO - Add an 'live preview/ok' button (live needs a debounce)

const useStyles = makeStyles(function (theme) {
  //    console.log('colorPicker', theme.palette.action.active);
  return {
    root: {},
    /* Styles applied to the `IconButton` component when `expandIcon` is supplied. */
    expandIcon: {
      transform: "rotate(0deg)",
      fill: theme.palette.action.active,
      //             transition: theme.transitions.create('transform', transition),
      "&:hover": {
        // Disable the hover effect for the IconButton,
        // because a hover effect should apply to the entire Expand button and
        // not only to the IconButton.
        backgroundColor: "transparent",
        bordedr: "red solid 2px",
      },
      "&.expanded": {
        transform: "rotate(180deg)",
      },
    },
  };
});

const AdjustableColorPicker = (props) => {
  const classes = useStyles();
  const {
    color: storedColor,
    isStroke = false,
    isText = false,
    icon = null,
    onChange: onChange,
    ...other
  } = props;

  // const storedColor = props.color;// : useSelector(state => state.counter.value.json[props.name]);
  const initialColor = storedColor;

  const [colorCurrent, setColorCurrent] = React.useState(() => null);
  const [formats, setFormats] = React.useState(() => []);

  const [isOpen, setOpen] = React.useState(() => false);
  const anchorRef = React.useRef(null);
  const closeReasonRef = React.useRef(false);

  const close = (commit) => {
    if (colorCurrent) {
      if (onChange) {
        onChange(colorCurrent);
      }
    }
    setColorCurrent(null);
    handleFormat(event, "");
  }

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    close(true);
  };

  // Manages closing by
  // 1. setting the value
  // 2. returning focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(isOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      close(true);
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);


  const handleFormat = (event, newFormats) => {
    setOpen(newFormats.length > 0);
    setFormats(newFormats);
  };

  const handleOpen = (ref) => {
    if (ref && ref.current) {
        ref.current.focus();
    }
  };

  const handleColorChange = (color, event) => {
    setColorCurrent(color);
  };

  const handleColorChangeComplete = (color, event) => {
    //         setColorCurrent(color);
//     console.log('colorComplete');
  };

  const handleDone = (color, event) => {
    setOpen(false);
  };

  const colorToRGBA = function(dynamicColor) {
    return dynamicColor.toRGBAColor();
  }

  const colorToString = function(dynamicColor) {
    return colorToRGBA(dynamicColor).toString();
  }

  const iconCurrent = (icon ? (
      <div style={{
        width: '24px',
        height: '24px',
        color: `${colorToString(colorCurrent || initialColor)}`
      }}>
        {icon}
      </div>
      ) : (
      <DynamicFormatColorFill
      color="inherit"
      dynamicColor={colorToRGBA(colorCurrent || initialColor)}
      isStroke={isStroke}
      isText={isText}
    />
  ));
  //     console.log('isOpen', isOpen);
  //     console.log('anchorRef', anchorRef);

  const handleKeyDown = (event) => {
    const key = event.key;
    if (event.which === 27) { // Escape
        // Todo - needs to cancel
        setOpen(false);
    }
  };

  return (
    <Box className={classes.root}>
      <ToggleButtonGroup
        value={formats}
        onChange={handleFormat}
        aria-label="text formatting"
      >
        <ToggleButton
          value="isOpen"
          aria-label="color"
          {...other}
          aria-haspopup="true"
          ref={anchorRef}
        >
          {iconCurrent}
          <ArrowDropDown
            className={`${classes.expandIcon} ${isOpen ? "expanded" : ""}`}
          />
        </ToggleButton>
      </ToggleButtonGroup>
      <Popper
        style={{ marginTop: "2px", zIndex: "1" }}
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
        onKeyDown={handleKeyDown}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Box>
              <ClickAwayListener onClickAway={handleClickAway}>
                <ThemeColorPicker
                    color={colorCurrent || initialColor}
                    onOpen={handleOpen}
                    onChange={handleColorChange}
                    onChangeComplete={handleColorChangeComplete}
                    onDone={handleDone}
                />
              </ClickAwayListener>
            </Box>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

AdjustableColorPicker.propTypes = {
  color: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default AdjustableColorPicker;
