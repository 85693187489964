import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { deepmerge } from '@material-ui/utils';

export function themedScrollbar(theme) {
  let options = {
    track: theme.palette.background.paper,
    thumb: '#6b6b6b',
    active: '#959595',
    corner: theme.palette.background.default
//       outline:  `2px solid ${options.palette.primary.dark}`
  };

  return {
    scrollbarColor: `${options.track} ${options.track}`,
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: options.track,
      width: '15px', //'1.2em',
      height: '15px', //'1.2em'
    },
    "&::-webkit-scrollbar-button, & *::-webkit-scrollbar--button": {},
    "&::-webkit-scrollbar-track, & *::-webkit-scrollbar--track": {},
    "&::-webkit-scrollbar-track-piece, & *::-webkit-scrollbar--track-piece": {},
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: options.thumb,
      minHeight: '24px', //'1.8em',
      minWidth: '24px', //'1.8em',
      border: `2px solid ${options.track}`,
    },
    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
      backgroundColor: options.active,
      border: (options.outline ? options.outline : undefined),
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
      backgroundColor: options.active,
      border: (options.outline ? options.outline : undefined)
      //boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      //webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      backgroundColor: options.active,
      border: (options.outline ? options.outline : undefined),
      //boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      //webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
      backgroundColor: options.corner || options.thumb,
    },
  }
}

export function themedStyleWithScrollbar(theme, input) {
  return deepmerge(input|| {}, themedScrollbar(theme));
}

const useStyles = makeStyles(function (theme) {
    let retValue = {
      themingScroll : {
        width: '100%',
        height: '100%',
        display: 'inherit'
      }
    }
    retValue.themingScroll = deepmerge(retValue.themingScroll, themedScrollbar(theme));
    return retValue;
});

export default function ThemedScrollbarWrapper(props) {
  const {
    children,
  } = props;

  const classes = useStyles();

  return (
  <div className={classes.themingScroll}>
    {children}
  </div>
  );
};