
const EMU_PER_POINT = 12700;

const EMU_PER_ANGLE = 60000;

export function toOOXMLLineCap(value) {
  if ("round" === value) {
      return "rnd";
  } else if ("butt" === value) {
      return "flat";
  } else if ("square" === value) {
      return "sq";
  }
  return;
}


export function toOOXMLAlign(value) {
  return value;
}

export function toOOXMLCompound(value) {
  if ("single" === value) {
      return "sng";
  }
  return value;
}
SheetXL
// SheetXL does not support: rect, shape
export function toOOXMLGradientType(value) {
  if (!value)
    return value;
  if (value === "linear") {
    return value;
  }
  if (value === "radial") {
    return "circle"
  }

  return value;
}

export function toOOXMLPoints(pixels) {
  return pixels * EMU_PER_POINT;
}

export function toOOXMLAngle(deg) {
  return deg * EMU_PER_ANGLE;
}