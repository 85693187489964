import React, { useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import SelectPropertyEditor from "../SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
    alignedRow: {
      display: "flex",
      flexDirection: "row",
      flex: "1 1 100%",
      minWidth: "0px",
      alignItems: "center"
    },
  },
}));

// TODO - add these to the model
const POSITION_ENUMS = [
  { key: "ctr", description: "Center" },
  { key: "inEnd", description: "Inside End" },
  { key: "inBase", description: "Inside Base" },
  { key: "outEnd", description: "Outside End" },
  { key: "bestFit", description: "Best Fit" },
  { key: "l", description: "Left" },
  { key: "r", description: "Right" },
  { key: "t", description: "Top" },    // Above
  { key: "b", description: "Bottom" }, // Below
];

function DataLabelPositionPropertyEditor(props) {
  const { shape, filter=null, property='position', ...other } = props;
  const classes = useStyles();

  const values = useMemo(() => {
    const retValue = [];
    for (let i=0;i<POSITION_ENUMS.length; i++) {
      if (!filter || filter[POSITION_ENUMS[i].key])
        retValue.push(POSITION_ENUMS[i]);
    }
    return retValue;
  }, [filter]);

  return (
    <Box className={classes.root} {...other}>
      <ExplicitPropertyEditorContainer>
        <SelectPropertyEditor
          label="Position"
          values={values}
          shape={shape}
          property={property}
        ></SelectPropertyEditor>
      </ExplicitPropertyEditorContainer>
    </Box>
  );
}

export default DataLabelPositionPropertyEditor;
