
const convertSeries = function(xmlSeries : any, jsonSeries : any, path : string[], jsonRoot: any) {
  console.log('xmlSeries', xmlSeries);
  // TODO - this is not quite how SheetXL does this.
  // TODO - strip the sheet references and the relative elements
  // Note - The cache is storing the 'global data range?! This is very helpful.
  //
  if (xmlSeries['c:cat'] && xmlSeries['c:cat']['c:strref'])
    jsonSeries['titleRange'] = xmlSeries['c:cat']['c:strref']['c:f'];
    if (xmlSeries['c:val'] && xmlSeries['c:val']['c:numref'])
      jsonSeries['valRange'] = xmlSeries['c:val']['c:numref']['c:f'];

// TODO - can have two number ranges for scatter
// TODO - tiles
// TODO - shape properties
};


// TODO - need to add a list element?
const convertPlotArea = function(xmlPlotArea: any, jsonTypes: any, xmlPath: string[], jsonRoot : any) {
  console.log('xmlPlotArea', xmlPlotArea);
  if (xmlPath[xmlPath.length-1] === 'c:barchart') {
    if (xmlPlotArea['c:bardir'] && xmlPlotArea['c:bardir']['$'] && xmlPlotArea['c:bardir']['$'].val === 'bar')
      jsonTypes['type'] = 'bar';
    else
      jsonTypes['type'] = 'column';
  } else if (xmlPath[xmlPath.length-1] === 'c:linechart') {
    jsonTypes['type'] = 'line';
  }
   const jsonSeriesList : any = jsonRoot['series'] = {}; // TODO - this may already exist in a composite
   if (xmlPlotArea['c:ser']) {
  //   // Note - We are setting the index for series inside the parent
     if (Array.isArray(xmlPlotArea['c:ser'])) {
       for (let i =0; i<xmlPlotArea['c:ser'].length; i++) {
         jsonSeriesList[i] = {};
         convertSeries(xmlPlotArea['c:ser'][i], jsonSeriesList[i], [...xmlPath, i.toString()], jsonRoot);
      }
     } else {
       jsonSeriesList[0] = {};
  //     convertSeries(xmlPlotArea['c:ser'][i], jsonSeriesList[0], [...xmlPath, i], jsonRoot);
     }
   }

};

export default class OOXMLToExhibitConverter {
  constructor() {
  }


  fromOOXML(body:string, options?:any):any {
    const jsonChart : any = {};

    const jsonTypes : any = jsonChart['types'] = {};

    // // TODO - this needs to be a list too
    const jsonTypes0 : any = jsonTypes['0'] = {};

     const plotArea = body['c:chartspace']['c:chart']['c:plotarea'];
     if (plotArea['c:barchart']) {
      convertPlotArea(plotArea['c:barchart'], jsonTypes0, ['c:barchart'], jsonChart);
     } else if (plotArea['c:linechart']) {
      convertPlotArea('c:linechart', jsonTypes0, ['c:linechart'], jsonChart);
     }

     return jsonChart;
  }

  toString(): string {
    return 'nothing yet';
  }

}
