import { DOMImplementation, XMLSerializer } from '@xmldom/xmldom';

import xmlFormatter from 'xml-formatter';

import { ChartUtils } from '@sheetxl/models';
import { ModelUtils } from '@sheetxl/models';

import { ColorUtils } from '@sheetxl/models';
import { PresetColors } from '@sheetxl/models';
import { findSchemaDefinition } from '@sheetxl/models';
import { RangeUtils } from '@sheetxl/models';

import * as OOXMLMappings from './toOOXML/OOXMLMappings';
import * as OOXMLUtils from './toOOXML/OOXMLUtils';

//default copy check
const defaultCopyCheck = function(property) {
    return property !== undefined && property.isExplicit && property.value !== undefined;
}

//always copy check
const alwaysCopyCheck = function(property) {
    return property !== undefined && property.value !== undefined;
}

//initialize the execution context
export default class OOXMLToExhibitConverter {
  constructor() {
    this.visitHandlerRegistry = {
        'ChartShape' :  getVisitorInterface(chartShapeVisitor),
        'ChartTitleShape': getVisitorInterface(titleVisitor),
        'ChartAxisTitleShape': getVisitorInterface(titleVisitor),
        'ChartLegendShape' : getVisitorInterface(chartLegendVisitor),
        'ChartSeriesTitleTextShape' : getVisitorInterface(txPrVisitor),
        'ChartTextShape' : getVisitorInterface(txPrVisitor),
        'ManualLayout' : getVisitorInterface(manualLayoutVisitor),
        'ChartType' : getVisitorInterface(chartTypeVisitor),
        'ChartSeriesShape' : getVisitorInterface(chartSeriesVisitor),
        'ChartPieSeriesShape' : getVisitorInterface(chartSeriesVisitor),
        'ChartScatterSeriesShape' : getVisitorInterface(chartSeriesVisitor),
        'ChartTypeDataLabelShape' : getVisitorInterface(dataLabelShapeVisitor),
        'ChartSeriesDataLabelShape' : getVisitorInterface(dataLabelShapeVisitor),
        "ChartSeriesMarkerShape" : getVisitorInterface(chartSeriesMarkerShapeVisitor),
        'ChartLegendShape' : getVisitorInterface(chartLegendVisitor),
        'ChartValAxisShape' : getVisitorInterface(chartAxisVisitor),
        'ChartOrdAxisShape' : getVisitorInterface(chartAxisVisitor),
        'ChartDateAxisShape' : getVisitorInterface(chartAxisVisitor)
    }
    this.rootModelObj = null;
    this.rootXmlDoc = null;
    this.visitedModels = [];
    this.nodePath = [];
    this.axisIds = new Map();
    this.c16UniqueIdCounter = 0;
  }

  setAttribute(xmlNode, attrName, attrValue) {
      //TODO: what is the rule if attrValue === null
      if (attrValue !== undefined)
          xmlNode.setAttribute(attrName, attrValue);
  }

  createNode(xmlNodeName) {
      return this.rootXmlDoc.createElement(xmlNodeName);
  }

  createChildNodes(xmlNode, xpath) {
    let paths = xpath.split('/')
    for (let i = 0; i < paths.length; i++) {
        let path = paths[i];
        let xmlChildNode = this.getNode(xmlNode, path);
        if (xmlChildNode === undefined) {
            //create it
            xmlChildNode = this.createChildNode(xmlNode, path);
            xmlNode = xmlChildNode
        } else {
            xmlNode = xmlChildNode
        }
    }
    return xmlNode;
}

  createTextNode(text) {
      return this.rootXmlDoc.createTextNode(text);
  }

  createChildNode(xmlNode, childNodeName) {
      let childNode = this.createNode(childNodeName);
      this.appendChildNode(xmlNode, childNode);
      return childNode;
  };

  appendChildNode(xmlParentNode, xmlChildNode, condition=true) {
      if (condition)
          xmlParentNode.appendChild(xmlChildNode);
  }

  appendNonEmptyChildNode(xmlParentNode, xmlChildNode) {
      this.appendChildNode(xmlParentNode, xmlChildNode, xmlChildNode.hasChildNodes() || xmlChildNode.hasAttributes());
  }

  copyValue(modelObj, modelPropertyPath, xmlNode, xmlchildNodeName, setterFunction, copyCheck=defaultCopyCheck) {
      let propertyPath = modelPropertyPath.split(".");
      let property = modelObj.getPropertyValue(propertyPath);
      // if (!property) {
        // debugger;
        // console.warn('Unable to find property:' + modelObj.className + ':' + modelPropertyPath);
        // return;
      // }
      if (property && copyCheck(property)) {
          setterFunction(this, xmlNode, xmlchildNodeName, property.value);
      }
  }

  getNode(xmlNode, xpath) {
      if (!xmlNode.getElementsByTagName) {
        debugger;
      }
      let paths = xpath.split('/')
      let xmlNodeCurrent = xmlNode;
      for (let i = 0; i < paths.length; i++) {
          let tagCurrent = paths[i];
          let nodeChildren = xmlNodeCurrent.childNodes;
          let nodeFound = null;
          for (let i = 0; !nodeFound && i<nodeChildren.length; i++) {
              if (nodeChildren[i].nodeName === tagCurrent) {
                nodeFound = nodeChildren[i];
              }
          }
          if (!nodeFound)
            return;

          xmlNodeCurrent = nodeFound;
      }
      return xmlNodeCurrent;
  }

  visit(xmlNode, modelChildObj, xmlChildNodeName, visitorOverride) {
      let visitor = visitorOverride ? visitorOverride : this.visitHandlerRegistry[ModelUtils.getAnyClass(modelChildObj)]
      if (visitor) {
          this.visitedModels.push(modelChildObj);
          this.nodePath.push(xmlNode);
          if (xmlChildNodeName) {
              let xmlChildNode = this.createNode(xmlChildNodeName);
              visitor.visit(this, modelChildObj, xmlChildNode);
              if (xmlChildNode.hasChildNodes() || xmlChildNode.hasAttributes()) {
                  this.appendChildNode(xmlNode, xmlChildNode);
              }
              this.visitedModels.pop(modelChildObj);
              this.nodePath.pop(xmlNode);
              return xmlChildNode;
          } else {
              visitor.visit(this, modelChildObj, xmlNode);
              this.visitedModels.pop(modelChildObj);
              this.nodePath.pop(xmlNode);
              return xmlNode;
          }
      } else {
        console.log('no visitor for ', xmlChildNodeName);
      }

      return null;
  }

  getParentNode() {
    return this.nodePath[this.nodePath.length-1];
  }

  getParentModel() {
      let length = this.visitedModels.length;
      if (length === 1) {
          return this.visitedModels[0];
      } else {
          return this.visitedModels[length - 2];
      }
  }

  toOOXML(chartShape, options = {}) {
    let xmlDoc = new DOMImplementation().createDocument(null, '?xml', null);
    let rootElement = xmlDoc.createElement("c:chartSpace");
    xmlDoc.appendChild(rootElement);

    rootElement.setAttribute('xmlns:c', 'http://schemas.openxmlformats.org/drawingml/2006/chart');
    rootElement.setAttribute('xmlns:a', 'http://schemas.openxmlformats.org/drawingml/2006/main');
    rootElement.setAttribute('xmlns:r', 'http://schemas.openxmlformats.org/officeDocument/2006/relationships');
    rootElement.setAttribute('xmlns:c16r2', 'http://schemas.microsoft.com/office/drawing/2015/06/chart');

    this.rootModelObj = chartShape;
    this.rootXmlDoc = xmlDoc;
    this.visitedModels = [];
    this.visit(rootElement, chartShape, null/*childXmlNodeName*/);

    let retValue = new XMLSerializer().serializeToString(xmlDoc);
    // Note - We remove the xml tag and optional add back a tag that fits standalone style
    retValue = retValue.replace('<?xml/>', '');
    if (options.header === undefined || options.header === true) {
      retValue = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\"?>" + retValue;
    }

    // cleanup
    this.rootModelObj = null;
    this.rootXmlDoc = null;
    this.visitedModels = [];

    if (options?.prettify) {
      let prettyOpts = {
        indentation: '  ',
        collapseContent: true, // don't indent text fields as this would add white space to text
        filter: (node) => {
            // if (node.type == 'Comment') {
            //     return false;
            // }
            return true;
        }
      }
      if (typeof options.prettify === 'object' && !Array.isArray(options.prettify)) {
        Object.assign(prettyOpts, options.prettify);
      }

      if (options?.debug)
        console.log(retValue);

      retValue = xmlFormatter(retValue, prettyOpts);
    }
    return retValue;
  }
} // end class

function getPathVisitorInterface(path, visitHandler, afterVisitHandler) {
    return {
        "path" : path,
        "visit" : visitHandler,
        "afterVisit" : afterVisitHandler
    }
}

function getVisitorInterface(visitHandler, afterVisitHandler) {
    return {
        "visit" : visitHandler,
        "afterVisit" : afterVisitHandler
    }
}

export function chartShapeVisitor(execContext, objChartSpace, xmlChartSpaceNode) {
    let date1904Node = execContext.createChildNode(xmlChartSpaceNode, "c:date1904");
    execContext.setAttribute(date1904Node, "val", "0");
    let langNode = execContext.createChildNode(xmlChartSpaceNode, "c:lang");
    execContext.setAttribute(langNode, "val", "en-US");
    //rounded corners
    execContext.copyValue(objChartSpace, "roundedCorners", xmlChartSpaceNode, "c:roundedCorners", setChildValAttrAsBoolean, alwaysCopyCheck);
    //alternate content
    let xmlAlternateContent = execContext.createChildNode(xmlChartSpaceNode, "mc:AlternateContent");
    execContext.setAttribute(xmlAlternateContent, 'xmlns:mc', 'http://schemas.openxmlformats.org/markup-compatibility/2006');
    //choice
    let xmlChoiceNode = execContext.createChildNode(xmlAlternateContent, "mc:Choice");
    execContext.setAttribute(xmlChoiceNode, "Requires", "c14");
    execContext.setAttribute(xmlChoiceNode, "xmlns:c14", "http://schemas.microsoft.com/office/drawing/2007/8/2/chart");
    execContext.copyValue(objChartSpace, "styleId", xmlChoiceNode, "c14:style", function(execContext, xmlNode, xpath, attrValue) {
        let styleValue = Number(attrValue) + 100;
        setChildValAttribute(execContext, xmlNode, xpath, styleValue);
    }, alwaysCopyCheck);
    //fallback
    let xmlFallback = execContext.createChildNode(xmlAlternateContent, "mc:Fallback");
    execContext.copyValue(objChartSpace, "styleId", xmlFallback, "c:style", setChildValAttribute, alwaysCopyCheck);
    //chart node
    let xmlChartNode = execContext.createChildNode(xmlChartSpaceNode, "c:chart");
    //title
    let chartTitle = objChartSpace.title;
    if (chartTitle && chartTitle.shown === true)
        execContext.visit(xmlChartNode, chartTitle, "c:title");
    //autoTitleDeleted
    execContext.copyValue(objChartSpace, "title.shown", xmlChartNode, "c:autoTitleDeleted", function(execContext, xmlNode, xpath, attrValue) {
        if (attrValue === undefined)//autoTitleDeleted is set to 0 if undefined in the model
            setChildValAttrAsBooleanFlip(execContext, xmlNode, xpath, "1");
        else
            setChildValAttrAsBooleanFlip(execContext, xmlNode, xpath, attrValue);
    }, alwaysCopyCheck);
    //plot area
    let xmlPlotAreaNode = execContext.createChildNode(xmlChartNode, "c:plotArea");
    //TODO: this is hardcoded for now
    let layoutNode = execContext.createChildNode(xmlPlotAreaNode, "c:layout");
    //plot area chart types
    let xmlChartTypeNode =  null;
    if (objChartSpace.types) {
        let arraylength = objChartSpace.types.length;
        for (let i = 0; i < arraylength; i++) {
            let objChartType = objChartSpace.types.getAt(i);
            xmlChartTypeNode = processChartType(execContext, objChartSpace, objChartType, xmlPlotAreaNode, alwaysCopyCheck);
        }
    }

    //generate and set axis ids
    if (objChartSpace.xAxes) {
        setAxisIds(execContext, xmlChartTypeNode, objChartSpace.xAxes);
    }
    if (objChartSpace.yAxes) {
        setAxisIds(execContext, xmlChartTypeNode, objChartSpace.yAxes);
    }
    //TODO: plot area chart xAxes - use "offsetXAxis", "offsetYAxis" in chart type
    if (objChartSpace.xAxes) {
        visitAxes(execContext, objChartSpace.xAxes, xmlPlotAreaNode);
    }
    //plot area chart yAxes
    if (objChartSpace.yAxes) {
        visitAxes(execContext, objChartSpace.yAxes, xmlPlotAreaNode, xmlChartTypeNode);
    }

    //plotarea sh pr
    processShapeProperties(execContext, objChartSpace.plotArea, xmlPlotAreaNode, alwaysCopyCheck);

    //chart legend
    let chartLegend = objChartSpace.legend;
    if (chartLegend && chartLegend.shown === true)
        execContext.visit(xmlChartNode, chartLegend, "c:legend");

    execContext.copyValue(objChartSpace, "plotVisOnly", xmlChartNode, "c:plotVisOnly", setChildValAttrAsBoolean, alwaysCopyCheck);
    execContext.copyValue(objChartSpace, "dispBlanksAs", xmlChartNode, "c:dispBlanksAs", setChildValAttribute, alwaysCopyCheck);

    let extLstNode = execContext.createChildNode(xmlChartNode, "c:extLst");
    let extNode = execContext.createChildNode(extLstNode, "c:ext");
    execContext.setAttribute(extNode, "uri", "{56B9EC1D-385E-4148-901F-78D8002777C0}");
    execContext.setAttribute(extNode, "xmlns:c16r3", "http://schemas.microsoft.com/office/drawing/2017/03/chart");
    execContext.copyValue(objChartSpace, "dispNaAsBlank", extNode, "c16r3:dataDisplayOptions16/c16r3:dispNaAsBlank", setChildValAttrAsBoolean, alwaysCopyCheck);
    execContext.appendChildNode(xmlChartNode, extLstNode);

    execContext.copyValue(objChartSpace, "showDLblsOverMax", xmlChartNode, "c:showDLblsOverMax", setChildValAttrAsBoolean, alwaysCopyCheck);

    //chart shape sh pr
    processShapeProperties(execContext, objChartSpace, xmlChartSpaceNode, alwaysCopyCheck);

    //print settings
    let printSettingsNode = execContext.createChildNode(xmlChartSpaceNode, "c:printSettings");
    let headerFooterNode = execContext.createChildNode(printSettingsNode, "c:headerFooter");
    let pageMarginsNode = execContext.createChildNode(printSettingsNode, "c:pageMargins");
    execContext.setAttribute(pageMarginsNode, "b", "0.75");
    execContext.setAttribute(pageMarginsNode, "l", "0.7");
    execContext.setAttribute(pageMarginsNode, "r", "0.7");
    execContext.setAttribute(pageMarginsNode, "t", "0.75");
    execContext.setAttribute(pageMarginsNode, "header", "0.3");
    execContext.setAttribute(pageMarginsNode, "footer", "0.3");
    let pageSetupNode = execContext.createChildNode(printSettingsNode, "c:pageSetup");
}

function setAxisIds(execContext, xmlChartNode, axes) {
    let arraylength = axes.length;
    for (let i = 0; i < arraylength; i++) {
        let objChartAxis = axes.getAt(i);
        let axisId = OOXMLUtils.getAxisID();
        execContext.axisIds.set(objChartAxis, axisId);
        let axisIdChartNode = execContext.createChildNode(xmlChartNode, "c:axId");
        execContext.setAttribute(axisIdChartNode, "val", axisId);
    }
}

function visitAxes(execContext, axes, xmlPlotAreaNode) {
    let arraylength = axes.length
    for (let i = 0; i < arraylength; i++) {
        let axisType = ModelUtils.getAnyClass(axes.getAt(i));
        let axisNodeName = null;
        if (axisType === "ChartValAxisShape") {
            axisNodeName = "c:valAx";
        } else if (axisType === "ChartOrdAxisShape") {
            axisNodeName = "c:catAx";
        } else if (axisType === "ChartDateAxisShape") {
            axisNodeName = "c:dateAx";
        }
        execContext.visit(xmlPlotAreaNode, axes.getAt(i), axisNodeName);
    }
}

export function chartTypeVisitor(execContext, objChartType, xmlChartTypeNode) {
    if (objChartType.type === "bar" || objChartType.type === "column")
        execContext.copyValue(objChartType, "type", xmlChartTypeNode, "c:barDir", function(execContext, xmlNode, xpath, attrValue) {
            let barDir = attrValue === "column" ? "col" : attrValue;
            setChildValAttribute(execContext, xmlNode, xpath, barDir);
        }, alwaysCopyCheck);

    if (objChartType.type === "bar" || objChartType.type === "column" || objChartType.type === "line")
        execContext.copyValue(objChartType, "grouping", xmlChartTypeNode, "c:grouping", setChildValAttribute, alwaysCopyCheck);

    if (objChartType.type === "bubble") {
        execContext.copyValue(objChartType, "bubbleScale", xmlChartTypeNode, "c:bubbleScale", setChildValAttribute, alwaysCopyCheck);
        execContext.copyValue(objChartType, "showNegativeBubbleValues", xmlChartTypeNode, "c:showNegBubbles", setChildValAttrAsBoolean, alwaysCopyCheck);
        execContext.copyValue(objChartType, "bubbleSizeRepresentsWidth", xmlChartTypeNode, "c:sizeRepresents", setChildValAttrAsBoolean, alwaysCopyCheck);
    }

    if (objChartType.type === "scatter") {
        execContext.copyValue(objChartType, "scatterStyle", xmlChartTypeNode, "c:scatterStyle", setChildValAttribute, alwaysCopyCheck);
    }

    execContext.copyValue(objChartType, "varyColors", xmlChartTypeNode, "c:varyColors", setChildValAttrAsBoolean, alwaysCopyCheck);

    //TODO: chart series - use offsetChart to pick the right objChartType
    if (execContext.getParentModel().series) {
        let arraylength = execContext.getParentModel().series.length
        for (let i = 0; i < arraylength; i++) {
            let objChartSeries = execContext.getParentModel().series.getAt(i);
            let xmlSerNode = execContext.visit(xmlChartTypeNode, objChartSeries, "c:ser");
            if (xmlSerNode !== null) {
                let orderNode = execContext.getNode(xmlSerNode, "c:order");
                execContext.setAttribute(orderNode, "val", i);
            }
        }
    }

    //data label
    let dataLabels = objChartType.dataLabels
    // if (dataLabels && dataLabels.shown === true) {
    if (dataLabels) {
        execContext.visit(xmlChartTypeNode, dataLabels, "c:dLbls");
    }

    if (objChartType.type === "bar" || objChartType.type === "column" || objChartType.type === "line") {
        execContext.copyValue(objChartType, "gapWidth", xmlChartTypeNode, "c:gapWidth", function(execContext, xmlNode, xpath, attrValue) {
            let gapWidth = attrValue*100;
            setChildValAttribute(execContext, xmlNode, xpath, gapWidth);
        }, alwaysCopyCheck);

        execContext.copyValue(objChartType, "overlap", xmlChartTypeNode, "c:overlap", function(execContext, xmlNode, xpath, attrValue) {
            let overlap = attrValue*100;
            setChildValAttribute(execContext, xmlNode, xpath, overlap);
        }, alwaysCopyCheck);
    }

    if (objChartType.type === "line") {
        let scatterStyle = objChartType.scatterStyle;
        if (scatterStyle) {
            if (scatterStyle.toLowerCase().includes("marker")) {
                let markerNode = execContext.createChildNode(xmlChartTypeNode, "c:marker");
                execContext.setAttribute(markerNode, 'val', 1);
            }
            if (scatterStyle.toLowerCase().includes("smooth")) {
                let markerNode = execContext.createChildNode(xmlChartTypeNode, "c:smooth");
                execContext.setAttribute(markerNode, 'val', 1);
            }
        }

        execContext.copyValue(objChartType, "gapWidth", xmlChartTypeNode, "c:gapWidth", function(execContext, xmlNode, xpath, attrValue) {
            let gapWidth = attrValue*100;
            setChildValAttribute(execContext, xmlNode, xpath, gapWidth);
        }, alwaysCopyCheck);

        execContext.copyValue(objChartType, "overlap", xmlChartTypeNode, "c:overlap", function(execContext, xmlNode, xpath, attrValue) {
            let overlap = attrValue*100;
            setChildValAttribute(execContext, xmlNode, xpath, overlap);
        }, alwaysCopyCheck);
    }

    if (objChartType.type === "pie") {
        execContext.copyValue(objChartType, "startAngle", xmlChartTypeNode, "c:firstSliceAng", setChildValAttribute, alwaysCopyCheck);
        if (objChartType.holeSize > 0)
            execContext.copyValue(objChartType, "holeSize", xmlChartTypeNode, "c:holeSize", setChildValAttribute, alwaysCopyCheck);
    }
}

function processDataLabel(execContext, modelDataLabel, nodeDataLabel, processExtendedProperties=true, positionCopy=defaultCopyCheck) {
    //numFmt
    processNumFormat(execContext, modelDataLabel, nodeDataLabel);

    //check if any of the attributes is true
    if (processExtendedProperties && (
        modelDataLabel.showLegendKey ||
        modelDataLabel.showVal ||
        modelDataLabel.showCatName ||
        modelDataLabel.showSerName ||
        modelDataLabel.showPercentage ||
        modelDataLabel.showBubbleSize ||
        modelDataLabel.showLeaderLines)) {
        //shape pr
        processShapeProperties(execContext, modelDataLabel, nodeDataLabel);
        //tx pr
        execContext.visit(nodeDataLabel, modelDataLabel.text, "c:txPr");
    }

    // Note - Excel does not read this from the type defaults even though it sets it.
    execContext.copyValue(modelDataLabel, "position", nodeDataLabel, "c:dLblPos", setChildValAttribute, positionCopy);

    execContext.copyValue(modelDataLabel, "showLegendKey", nodeDataLabel, "c:showLegendKey", setChildValAttrAsBoolean, alwaysCopyCheck);
    execContext.copyValue(modelDataLabel, "showVal", nodeDataLabel, "c:showVal", setChildValAttrAsBoolean, alwaysCopyCheck);
    execContext.copyValue(modelDataLabel, "showCatName", nodeDataLabel, "c:showCatName", setChildValAttrAsBoolean, alwaysCopyCheck);
    execContext.copyValue(modelDataLabel, "showSerName", nodeDataLabel, "c:showSerName", setChildValAttrAsBoolean, alwaysCopyCheck);
    execContext.copyValue(modelDataLabel, "showPercentage", nodeDataLabel, "c:showPercent", setChildValAttrAsBoolean, alwaysCopyCheck);
    execContext.copyValue(modelDataLabel, "showBubbleSize", nodeDataLabel, "c:showBubbleSize", setChildValAttrAsBoolean, alwaysCopyCheck);

    //separator
    execContext.copyValue(modelDataLabel, "separator", nodeDataLabel, "c:separator", function(execContext, xmlNode, xpath, separatorValue) {
        let separatorNode = execContext.createChildNode(nodeDataLabel, "c:separator");
        let separatorValNode = execContext.createTextNode(modelDataLabel.separator);
        execContext.appendChildNode(separatorNode, separatorValNode);
    });

    execContext.copyValue(modelDataLabel, "showLeaderLines", nodeDataLabel, "c:showLeaderLines", setChildValAttrAsBoolean, alwaysCopyCheck);
    let leaderLinesProp = modelDataLabel.getPropertyValue("leaderLines");
    if (leaderLinesProp && leaderLinesProp.isExplicit) {
        let leaderLinesNode = execContext.createNode("c:leaderLines");
        //shape pr
        processShapeProperties(execContext, modelDataLabel.leaderLines, leaderLinesNode);
        execContext.appendNonEmptyChildNode(nodeDataLabel, leaderLinesNode);
    }
}

export function dataLabelShapeVisitor(execContext, objDataLabelShape, dLblsNode) {
//     const copyIfChartType = function(property) {
//         return (property !== undefined && property.isExplicit && property.value !== undefined)
//             || (property !== undefined &&  property.value !== undefined && ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartType');
//     }

//     const copyIfSeriesType = function(property) {
//         return (property !== undefined && property.isExplicit && property.value !== undefined)
//             || (property !== undefined &&  property.value !== undefined
//                 && (ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartSeriesShape')
//                     || (ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartPieSeriesShape')
//                     || (ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartScatterSeriesShape'));
//     }

//     const copyIfPieChartType = function(property) {
//         return (property !== undefined && property.isExplicit && property.value !== undefined)
//                     || (property !== undefined
//                         &&  property.value !== undefined
//                         && ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartType'
//                         && ChartUtils.isSingleSeries(execContext.getParentModel().type));
//     }

    //delete
    if (!objDataLabelShape.shown)
        execContext.copyValue(objDataLabelShape, "shown", dLblsNode, "c:delete", setChildValAttrAsBooleanFlip);

    if ((ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartSeriesShape')
            || (ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartPieSeriesShape')
            || (ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartScatterSeriesShape')) {
        let points = objDataLabelShape.points;
        let pointsLength = points.length;
        for (let i = 0; i < pointsLength; i++) {
            let point  = points.getAt(i);
            if (point) {
                let dLblNode = execContext.createChildNode(dLblsNode, "c:dLbl");
                let idxNode = execContext.createChildNode(dLblNode, "c:idx");
                execContext.setAttribute(idxNode, "val", i);
                processDataLabel(execContext, point, dLblNode);
            }
        }

        let positionProp = objDataLabelShape.getPropertyValue("series.chartType.dataLabels.position");
        const copyIfPositionIsExplict = function(property) {
            return defaultCopyCheck(property) || (positionProp && positionProp.isExplicit);
        }

        processDataLabel(execContext, objDataLabelShape, dLblsNode, true/*processExtendedProperties*/, copyIfPositionIsExplict);
    } else { //(ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartType'
        processDataLabel(execContext, objDataLabelShape, dLblsNode, false/*processExtendedProperties*/);
    }
}

function processTitleText(execContext, objChartTitleShape, xmlParentNode, isSeries) {
    let simpleRunProp = objChartTitleShape.getPropertyValue("text.simpleRun");
    if (!simpleRunProp) {
      debugger;
      return;
    }
    if (simpleRunProp.isExplicit) {
        if (isSeries) { // series write their shape properties to legend entry and only write the value here
            let xmlTxNode = execContext.createChildNode(xmlParentNode, "c:tx");
            let xmlVNode = execContext.createChildNode(xmlTxNode, "c:v");
            execContext.appendChildNode(xmlVNode, execContext.createTextNode(simpleRunProp.value));
        } else
            txRichVisitor(execContext, objChartTitleShape.text, xmlParentNode, false);
    } else if (objChartTitleShape.range) {
        let titleRange = objChartTitleShape.range.toString();
        let xmlTxNode = execContext.createChildNode(xmlParentNode, "c:tx");
        let xmlStrRefNode = execContext.createChildNode(xmlTxNode, "c:strRef");
        let xmlFNode = execContext.createChildNode(xmlStrRefNode, "c:f");
        execContext.appendChildNode(xmlFNode, execContext.createTextNode(titleRange));
        let titleValues = objChartTitleShape.values;
        if (titleValues) {
            // TODO - this can be an array.
            let xmlStrCacheNode = execContext.createChildNode(xmlStrRefNode, "c:strCache");
            setChildAttribute(execContext, xmlStrCacheNode, "c:ptCount", "val", titleValues.length);
            let asCells = titleValues.asCells;
            for (let i=0; i<asCells[0].length; i++) {
                let xmlPointNode = execContext.createChildNode(xmlStrCacheNode, "c:pt");
                execContext.setAttribute(xmlPointNode, "idx", i);
                let xmlVNode = execContext.createChildNode(xmlPointNode, "c:v");
                let xmlValNode = execContext.createTextNode(RangeUtils.stringFromCells([[asCells[0][i]]]));
                execContext.appendChildNode(xmlVNode, xmlValNode);
            }
        }
    }
}


export function chartSeriesVisitor(execContext, objChartSeries, xmlSerNode) {
    const copyIfLineChartType = function(property) {
        return (property !== undefined && property.isExplicit && property.value !== undefined)
                || (property !== undefined
                    &&  property.value !== undefined
                    && ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartType'
                    && ChartUtils.isLineType(execContext.getParentModel().type));
    }
    //c:idx
    execContext.copyValue(objChartSeries, "idx", xmlSerNode, "c:idx", setChildValAttribute, alwaysCopyCheck);
    //placeholder c:order
    let orderNode = execContext.createChildNode(xmlSerNode, "c:order");
    //data points
    let boolVaryColors = (execContext.getParentModel().varyColors === true);
    let pointslength = boolVaryColors ? objChartSeries.renderedPoints.length : objChartSeries.points.length;
    for (let i = 0; i < pointslength; i++) {
        let objPoint = boolVaryColors ? objChartSeries.renderedPoints[i] : objChartSeries.points.getAt(i);
        if (objPoint) {
            let dPtNode = execContext.createChildNode(xmlSerNode, "c:dPt");
            setChildValAttribute(execContext, dPtNode, "c:idx", i);
            processShapeProperties(execContext, objPoint, dPtNode, alwaysCopyCheck);
        }
    }

    processTitleText(execContext, objChartSeries.title, xmlSerNode, true/*isSeries*/);

    //shape pr
    let shapeCopyCheck = alwaysCopyCheck;
    let fillPropName = "fill";
    if (ModelUtils.getAnyClass(objChartSeries) === 'ChartScatterSeriesShape') {
         fillPropName = null;
    }
    processShapeProperties(execContext, objChartSeries, xmlSerNode, shapeCopyCheck, fillPropName);

    //negative fill
    let invNegativeNode = execContext.createChildNode(xmlSerNode, "c:invertIfNegative");
    let fillNegative = objChartSeries.getPropertyValue("fillNegative");
    let strokeNegative = objChartSeries.getPropertyValue("strokeNegative");
    if (fillNegative && fillNegative.isExplicit || (strokeNegative && strokeNegative.isExplicit)) {
        execContext.setAttribute(invNegativeNode, "val", "1");
    } else {
        execContext.setAttribute(invNegativeNode, "val", "0");
    }
    //data label
    let dataLabels = objChartSeries.labels;
    if (dataLabels && dataLabels.shown === true) {
        execContext.visit(xmlSerNode, dataLabels, "c:dLbls");
    }
    //marker
    let objMarkerShape = objChartSeries.markers;
    if (objMarkerShape) {
        execContext.visit(xmlSerNode, objMarkerShape, "c:marker");
    }
    //xRange
    let xmlValNode = (objChartSeries.chartType.type === "scatter") ?  execContext.createNode("c:xVal") : execContext.createNode("c:cat");
    processRange(execContext, objChartSeries, objChartSeries.xRange, "xValues", "pointX", xmlValNode);
    execContext.appendNonEmptyChildNode(xmlSerNode, xmlValNode);
    //valRange
    xmlValNode = (objChartSeries.chartType.type === "scatter") ?  execContext.createNode("c:yVal") : execContext.createNode("c:val");
    processRange(execContext, objChartSeries, objChartSeries.valRange, "valValues", "pointVal", xmlValNode);
    execContext.appendNonEmptyChildNode(xmlSerNode, xmlValNode);

    execContext.copyValue(objChartSeries, "smooth", xmlSerNode, "c:smooth", setChildValAttrAsBoolean, copyIfLineChartType);

    if (fillNegative && fillNegative.isExplicit || (strokeNegative && strokeNegative.isExplicit)) {
        let extListNode = execContext.createChildNode(xmlSerNode, "c:extLst");
        let extNode = execContext.createChildNode(extListNode, "c:ext");
        execContext.setAttribute(extNode, "uri", "{6F2FDCE9-48DA-4B69-8628-5D25D57E5C99}");
        execContext.setAttribute(extNode, "xmlns:c14", "http://schemas.microsoft.com/office/drawing/2007/8/2/chart");
        let invertSolidFillFmtNode = execContext.createChildNode(extNode, "c14:invertSolidFillFmt");

        //shape pr
        let xmlShapePrNode = execContext.createNode("c14:spPr");
        execContext.setAttribute(xmlShapePrNode, "xmlns:c14", "http://schemas.microsoft.com/office/drawing/2007/8/2/chart");
        processFill(execContext, objChartSeries, xmlShapePrNode, "fillNegative");
        let strokeChildNode = execContext.createNode("a:ln");
        processFill(execContext, objChartSeries, strokeChildNode, "strokeFillNegative");
        if (!strokeChildNode.hasChildNodes()) {
             execContext.createChildNode(strokeChildNode, "a:noFill");
        };

        execContext.appendNonEmptyChildNode(xmlShapePrNode, strokeChildNode);
        execContext.appendNonEmptyChildNode(invertSolidFillFmtNode, xmlShapePrNode);
    }

    /*
          <c:extLst>
            <c:ext uri="{C3380CC4-5D6E-409C-BE32-E72D297353CC}" xmlns:c16="http://schemas.microsoft.com/office/drawing/2014/chart">
              <c16:uniqueId val="{0 000 000 0-A9AA-D14C-8179-DC07CEE7D30A}"/>
            </c:ext>
          </c:extLst>
    */
    let extListNode = execContext.createChildNode(xmlSerNode, "c:extLst");
    let extNode = execContext.createChildNode(extListNode, "c:ext");
    execContext.setAttribute(extNode, "uri", "{C3380CC4-5D6E-409C-BE32-E72D297353CC}");
    execContext.setAttribute(extNode, "xmlns:c16", "http://schemas.microsoft.com/office/drawing/2014/chart");
    let c16Node = execContext.createChildNode(extNode, "c16:uniqueId");
    execContext.setAttribute(c16Node, "val", "{" + getC16UriCount(execContext)+"-A9AA-D14C-8179-DC07CEE7D30A}");
}

function getC16UriCount(execContext) {
    let counter = execContext.c16UniqueIdCounter++;
    return String(counter).padStart(8, '0');
}

function processRange(execContext, objSeriesShape, range, literalValProp/*xValues or valValues*/, pointCoordProp/*pointX or pointVal*/, xmlValNode) {
    if (range === null)
        return;
    if (range.isLiteralRange) {
        if (objSeriesShape.xValues?.asCells?.length > 0) {
            let primitives = [];
            let literalValues = objSeriesShape.getPropertyValue(literalValProp).value.asCells[0];
            let isNumericList = true;
            for (let i = 0; i < literalValues.length; i++) {
                primitives.push({"value":literalValues[i].v, "index":i});
                isNumericList = isNumericList && (literalValues[i].t === 'n');
            }
            let xmlValListNode = isNumericList ? execContext.createChildNode(xmlValNode, "c:numLit") : execContext.createChildNode(xmlValNode, "c:strLit");
            OOXMLUtils.createDataList(execContext, primitives, xmlValListNode);
            execContext.appendNonEmptyChildNode(xmlValNode, xmlValListNode);
        }
    } else if (OOXMLUtils.isMultiLevel(objSeriesShape, pointCoordProp)) {//multi level cell range
        //multi level is never numeric
        let xmlRefNode = execContext.createChildNode(xmlValNode, "c:multiLvlStrRef");
        let xmlFNode = execContext.createChildNode(xmlRefNode, "c:f");
        let xmlRangeNode = execContext.createTextNode(range.toString())
        execContext.appendChildNode(xmlFNode, xmlRangeNode);
        let valueAndFormatCodes = OOXMLUtils.getValues(objSeriesShape, pointCoordProp, true);
        let values = valueAndFormatCodes.values;
        if (values.length > 0) {
            //multi level is never numeric
            let xmlCacheNode = execContext.createChildNode(xmlRefNode, "c:multiLvlStrCache");
            setChildAttribute(execContext, xmlCacheNode, "c:ptCount", "val", objSeriesShape.renderedPoints.length);
            for (let i=0; i<values.length; i++) {
                let xmlLvlNode = execContext.createChildNode(xmlCacheNode, "c:lvl");
                OOXMLUtils.createDataList(execContext, values[i], xmlLvlNode);
            }
        }
    } else {//single level cell range
        let valueAndFormatCodes = OOXMLUtils.getValues(objSeriesShape, pointCoordProp);
        let listFormatCode = valueAndFormatCodes.listFormatCode;

        let xmlRefNode = null;
        if (valueAndFormatCodes.isNumericList) {
            xmlRefNode = execContext.createChildNode(xmlValNode, "c:numRef");
        } else {
            xmlRefNode = execContext.createChildNode(xmlValNode, "c:strRef");
        }
        let xmlFNode = execContext.createChildNode(xmlRefNode, "c:f");
        let xmlRangeNode = execContext.createTextNode(range.toString())
        execContext.appendChildNode(xmlFNode, xmlRangeNode);
        //data cache
        let values = valueAndFormatCodes.values;
        if (values[0].length > 0) {
            let xmlCacheNode = null;
            if (valueAndFormatCodes.isNumericList) {
                xmlCacheNode = execContext.createChildNode(xmlRefNode, "c:numCache");
            } else {
                xmlCacheNode = execContext.createChildNode(xmlRefNode, "c:strCache");
            }
            if (valueAndFormatCodes.isNumericList) {
                let xmlFCNode = execContext.createChildNode(xmlCacheNode, "c:formatCode");
                let xmlFCTextNode = execContext.createTextNode(listFormatCode);
                execContext.appendChildNode(xmlFCNode, xmlFCTextNode);
            }
            setChildAttribute(execContext, xmlCacheNode, "c:ptCount", "val", objSeriesShape.renderedPoints.length);
            OOXMLUtils.createDataList(execContext, values[0], xmlCacheNode, (valueAndFormatCodes.allSameFormatCodes === false) && valueAndFormatCodes.isNumericList, valueAndFormatCodes.formatCodes);
        }
    }
}

export function chartSeriesMarkerShapeVisitor(execContext, objMarkerShape, markerNode) {
    const copyIfScatterChartType = function(property) {
        return (property !== undefined && property.isExplicit && property.value !== undefined)
                    || (property !== undefined
                        &&  property.value !== undefined
                        && (ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartSeriesShape'
                            || ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartPieSeriesShape'
                            || ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartScatterSeriesShape')
                        && execContext.getParentModel().chartType.type === "scatter");
    }

    let type = objMarkerShape.type;

    if (type === "none"
            && (ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartSeriesShape'
                || ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartPieSeriesShape'
                || ModelUtils.getAnyClass(execContext.getParentModel()) === 'ChartScatterSeriesShape')
            && ChartUtils.isLineType(execContext.getParentModel().chartType.type) === false) {
        return;
    }
    execContext.copyValue(objMarkerShape, "type", markerNode, "c:symbol", setChildValAttribute, alwaysCopyCheck);
    execContext.copyValue(objMarkerShape, "size", markerNode, "c:size", setChildValAttribute, copyIfScatterChartType);

    if (type !== "none")
        processShapeProperties(execContext, objMarkerShape, markerNode);

    if (objMarkerShape.points) {
        let dPtNodes = execContext.getParentNode().getElementsByTagName("c:dPt");
        let pointsLength = objMarkerShape.points.length;
        for (let i = 0; i < pointsLength; i++) {
            //ChartDataPointMarkerShape
            let point = objMarkerShape.points.getAt(i);
            if (point) {
                let dPtNode = OOXMLUtils.getDPt(execContext, dPtNodes, i);
                if (!dPtNode) {
                    dPtNode = execContext.createChildNode(execContext.getParentNode(), "c:dPt");
                    setChildValAttribute(execContext, dPtNode, "c:idx", i);
                }
                let dPtMarkerNode = execContext.createChildNode(dPtNode, "c:marker");
                chartSeriesMarkerShapeVisitor(execContext, point, dPtMarkerNode);
            }
        }
    }
}

export function chartAxisVisitor(execContext, objChartAxisShape, xmlChartAxisNode) {
    let axisId = execContext.axisIds.get(objChartAxisShape);
    let axisIdNode = execContext.createChildNode(xmlChartAxisNode, "c:axId");
    execContext.setAttribute(axisIdNode, "val", axisId);
    processScaling(execContext, objChartAxisShape, xmlChartAxisNode);
    execContext.copyValue(objChartAxisShape, "shown", xmlChartAxisNode, "c:delete", setChildValAttrAsBooleanFlip, alwaysCopyCheck);
    processOrientation(execContext, objChartAxisShape, xmlChartAxisNode);
    //gridlines
    if (objChartAxisShape.gridLinesMajor.shown) {
        let gridLinesMajorNode = execContext.createChildNode(xmlChartAxisNode, "c:majorGridlines");
        processShapeProperties(execContext, objChartAxisShape.gridLinesMajor, gridLinesMajorNode, alwaysCopyCheck, null/*fillProp*/);
    }
    if (objChartAxisShape.gridLinesMinor.shown) {
        let gridLinesMinorNode = execContext.createChildNode(xmlChartAxisNode, "c:minorGridlines");
        processShapeProperties(execContext, objChartAxisShape.gridLinesMinor, gridLinesMinorNode, alwaysCopyCheck, null/*fillProp*/);
    }
    //title
    let axisTitleProperty = objChartAxisShape.getPropertyValue("title");
    if (axisTitleProperty && axisTitleProperty.value && axisTitleProperty.value.shown === true)
        execContext.visit(xmlChartAxisNode, objChartAxisShape.title, "c:title");
    processNumFormat(execContext, objChartAxisShape.labels, xmlChartAxisNode);
    execContext.copyValue(objChartAxisShape, "majorTickMarks", xmlChartAxisNode, "c:majorTickMark", setChildValAttribute, alwaysCopyCheck);
    execContext.copyValue(objChartAxisShape, "minorTickMarks", xmlChartAxisNode, "c:minorTickMark", setChildValAttribute, alwaysCopyCheck);

    // because office uses the label none to determine if labels are hidden but exhibit also has a shown flag we also check this.
    if (!objChartAxisShape.labels.shown) {
        let tickLblPos = execContext.createChildNode(xmlChartAxisNode, "c:tickLblPos");
        execContext.setAttribute(tickLblPos, "val", "none");
    } else
        execContext.copyValue(objChartAxisShape, "labelPosition", xmlChartAxisNode, "c:tickLblPos", setChildValAttribute, alwaysCopyCheck);

    // for axis the stroke is just the stroke but exhibits 'label.fill' maps to axis.fill
    let xmlShapePrNode = execContext.createNode("c:spPr");
    processFill(execContext, objChartAxisShape, xmlShapePrNode, "labels.fill"/*fillProp*/, alwaysCopyCheck);
    processStroke(execContext, objChartAxisShape, xmlShapePrNode, alwaysCopyCheck);
    execContext.appendNonEmptyChildNode(xmlChartAxisNode, xmlShapePrNode);

    let axisType = ModelUtils.getAnyClass(objChartAxisShape);
    //txPr properties
    let labelsTextProperty = objChartAxisShape.labels.getPropertyValue("text");
    execContext.visit(xmlChartAxisNode, labelsTextProperty.value, "c:txPr");

    let crossAx = objChartAxisShape.crossAx;
    if (crossAx) {
        let crossAxId = execContext.axisIds.get(crossAx);
        let crossAxNode = execContext.createChildNode(xmlChartAxisNode, "c:crossAx");
        execContext.setAttribute(crossAxNode, "val", crossAxId);
    }
    let crosses = objChartAxisShape.crosses;
    if (crosses === "autoZero" || crosses === "min" || crosses === "max") {
        setChildAttribute(execContext, xmlChartAxisNode, "c:crosses", "val", crosses);
    } else {
        setChildAttribute(execContext, xmlChartAxisNode, "c:crossesAt", "val", crosses);
    }
    processLabelAlign(execContext, objChartAxisShape, axisType, xmlChartAxisNode);
    if (axisType === "ChartOrdAxisShape" || axisType === "ChartDateAxisShape") {
        execContext.copyValue(objChartAxisShape, "labelOffset", xmlChartAxisNode, "c:lblOffset", setChildValAttribute, alwaysCopyCheck);
    }
    if (axisType === "ChartOrdAxisShape" || axisType === "ChartDateAxisShape") {
        execContext.copyValue(objChartAxisShape, "tickMarkSkip", xmlChartAxisNode, "c:tickMarkSkip", setChildValAttribute);
        execContext.copyValue(objChartAxisShape, "labelInterval", xmlChartAxisNode, "c:tickLblSkip", setChildValAttribute);
    }
    execContext.copyValue(objChartAxisShape, "labelMultiLevel", xmlChartAxisNode, "c:noMultiLvlLbl", setChildValAttrAsBooleanFlip, alwaysCopyCheck);

    //crossAx
    execContext.copyValue(objChartAxisShape, "crossAx.crossBetween", xmlChartAxisNode, "c:crossBetween", setChildValAttribute, alwaysCopyCheck);

    if (axisType === "ChartDateAxisShape")
        execContext.copyValue(objChartAxisShape, "baseUnitDates", xmlChartAxisNode, "c:baseTimeUnit", setChildValAttribute, alwaysCopyCheck);

    if (axisType === "ChartValAxisShape" || axisType === "ChartDateAxisShape") {
        processTimeUnits(execContext, objChartAxisShape, xmlChartAxisNode, "majorUnitDates", "c:majorTimeUnit", "c:majorUnit");
        processTimeUnits(execContext, objChartAxisShape, xmlChartAxisNode, "minorUnitDates", "c:minorTimeUnit", "c:minorUnit");
    }
    if (axisType === "ChartValAxisShape") {
        let displayUnitsProperty = objChartAxisShape.getPropertyValue("displayUnits");
        if (displayUnitsProperty.isExplicit || objChartAxisShape.displayUnitsLabel.shown === true) {
            let dispUnits = displayUnitsProperty.value;
            let builtin = ChartUtils.builtInDisplayUnits[dispUnits];
            if (builtin)
                execContext.copyValue(objChartAxisShape, "displayUnits", xmlChartAxisNode, "c:dispUnits/c:builtInUnit", setChildValAttribute, alwaysCopyCheck);
            else
                execContext.copyValue(objChartAxisShape, "displayUnits", xmlChartAxisNode, "c:dispUnits/c:custUnit", setChildValAttribute, alwaysCopyCheck);
        }

        if (objChartAxisShape.displayUnitsLabel.shown === true) {
            let dispUnitsLblNode = execContext.createChildNodes(xmlChartAxisNode, "c:dispUnits/c:dispUnitsLbl");
            //tx/rich
            let objDisplayUnitsLabelText = objChartAxisShape.displayUnitsLabel.text;
            txRichVisitor(execContext, objDisplayUnitsLabelText, dispUnitsLblNode, true);
            //sh pr
            processShapeProperties(execContext, objChartAxisShape.displayUnitsLabel, dispUnitsLblNode, alwaysCopyCheck);
        }
    }
}

function processTimeUnits(execContext, objChartAxishape, xmlChartAxisNode, propertyPath, timeUnitXPath, unitXPath) {
    let propertyPathInstance = objChartAxishape.getPropertyValue(propertyPath);
    if (propertyPathInstance && propertyPathInstance.isExplicit && propertyPathInstance.value) {
        let propertyValue = propertyPathInstance.value;
        setChildValAttribute(execContext, xmlChartAxisNode, "c:"+unitXPath, propertyValue.amt);
        setChildValAttribute(execContext, xmlChartAxisNode, "c:"+timeUnitXPath, propertyValue.dem);
    }
}

function processScaling(execContext, objChartAxishape, xmlChartAxisNode) {
    execContext.copyValue(objChartAxishape, "inverted", xmlChartAxisNode, "c:scaling/c:orientation", function(execContext, xmlNode, xpath, attrValue) {
        if (attrValue) {
            setChildAttribute(execContext, xmlNode, xpath, "val", "maxMin")
        } else {
            setChildAttribute(execContext, xmlNode, xpath, "val", "minMax")
        }
    }, function(property) {
        return true;
    });
    //logbase
    if (objChartAxishape.scaleType === "log") {
        execContext.copyValue(objChartAxishape, "logBase", xmlChartAxisNode, "c:scaling/c:logBase", setChildValAttribute, alwaysCopyCheck);
    }
    execContext.copyValue(objChartAxishape, "max", xmlChartAxisNode, "c:scaling/c:max", setChildValAttribute);
    execContext.copyValue(objChartAxishape, "min", xmlChartAxisNode, "c:scaling/c:min", setChildValAttribute);
}

function processOrientation(execContext, objChartAxishape, xmlChartAxisNode) {
    execContext.copyValue(objChartAxishape, "orientation", xmlChartAxisNode, "c:axPos", function(execContext, xmlNode, xpath, attrValue) {
        let orientationTextVal = null;
        if (attrValue === "bottom") {
            orientationTextVal = "b";
        }
        if (attrValue === "left") {
            orientationTextVal = "l";
        }
        if (attrValue === "right") {
            orientationTextVal = "r";
        }
        if (attrValue === "top") {
            orientationTextVal = "t";
        }
        setChildValAttribute(execContext, xmlNode, xpath, orientationTextVal);
    }, alwaysCopyCheck);
}

function processNumFormat(execContext, model, node) {
    let labelFormatCodeProp = model.getPropertyValue("formatCode");
    if (labelFormatCodeProp) {
        let xmlNumFmtNode = execContext.createChildNode(node, "c:numFmt");
        execContext.setAttribute(xmlNumFmtNode, "formatCode", labelFormatCodeProp.value);
        let labelSourceLinked = model.sourceLinked;
        xmlNumFmtNode.setAttribute("sourceLinked", labelSourceLinked === true ? 1 : 0);
    }
}

function processLabelAlign(execContext, objChartAxishape, axisType, xmlChartAxisNode) {
    if (axisType === "ChartOrdAxisShape" || axisType === "ChartDateAxisShape") {
        execContext.copyValue(objChartAxishape, "labelAlign", xmlChartAxisNode, "c:lblAlgn", function(execContext, xmlNode, xpath, attrValue) {
            let labelAlignTextVal = null;
            if (attrValue === "center") {
                labelAlignTextVal = "ctr";
            }
            if (attrValue === "left") {
                labelAlignTextVal = "l";
            }
            if (attrValue === "right") {
                labelAlignTextVal = "r";
            }
            setChildValAttribute(execContext, xmlNode, xpath, labelAlignTextVal);
        }, alwaysCopyCheck);
    }
}

export function titleVisitor(execContext, objChartTitleShape, xmlTitleNode) {
    //manual layout
    processLayout(execContext, objChartTitleShape, xmlTitleNode);
    //overlay
    execContext.copyValue(objChartTitleShape, "overlay", xmlTitleNode, "c:overlay", setChildValAttrAsBoolean, alwaysCopyCheck);

    processTitleText(execContext, objChartTitleShape, xmlTitleNode);

    //sh pr
    processShapeProperties(execContext, objChartTitleShape, xmlTitleNode, alwaysCopyCheck);

    //text
    let titleText = objChartTitleShape.text;
    if (titleText) {
        execContext.visit(xmlTitleNode, titleText, "c:txPr");
    }
}

export function manualLayoutVisitor(execContext, objManualLayout, xmlManualLayoutNode) {
    if (objManualLayout.xMode) {
        let xModeNode = execContext.createChildNode(xmlManualLayoutNode, "c:xMode");
        execContext.setAttribute(xModeNode, "val", objManualLayout.xMode);
    }
    if (objManualLayout.yMode) {
        let yModeNode = execContext.createChildNode(xmlManualLayoutNode, "c:yMode");
        execContext.setAttribute(yModeNode, "val", objManualLayout.yMode);
    }
    if (objManualLayout.wMode) {
        let wModeNode = execContext.createChildNode(xmlManualLayoutNode, "c:wMode");
        execContext.setAttribute(wModeNode, "val", objManualLayout.wMode);
    }
    if (objManualLayout.hMode) {
        let hModeNode = execContext.createChildNode(xmlManualLayoutNode, "c:hMode");
        execContext.setAttribute(hModeNode, "val", objManualLayout.hMode);
    }
    if (objManualLayout.x) {
        let xNode = execContext.createChildNode(xmlManualLayoutNode, "c:x");
        execContext.setAttribute(xNode, "val", objManualLayout.x);
    }
    if (objManualLayout.y) {
        let yNode = execContext.createChildNode(xmlManualLayoutNode, "c:y");
        execContext.setAttribute(yNode, "val", objManualLayout.y);
    }
    if (objManualLayout.w) {
        let wNode = execContext.createChildNode(xmlManualLayoutNode, "c:w");
        execContext.setAttribute(wNode, "val", objManualLayout.w);
    }
    if (objManualLayout.h) {
        let hNode = execContext.createChildNode(xmlManualLayoutNode, "c:h");
        execContext.setAttribute(hNode, "val", objManualLayout.h);
    }
}

function hasExplicitValue(model) {
  let foundExplict = false;
  model.visitModel(
    [],
    new Map(),
    function (property, value, path) {
      if (property.evaled.explicit !== undefined && property.propertyName !== 'simpleRun') {
         foundExplict = true;
      }
    }
  );
  return foundExplict;
}

export function chartLegendVisitor(execContext, objChartLegendShape, xmlLegendNode) {
    execContext.copyValue(objChartLegendShape, "position", xmlLegendNode, "c:legendPos", setChildValAttribute, alwaysCopyCheck);

    //series Title text formatting is mapped to legendEntries
    for (let i=0; i<objChartLegendShape.chartShape.series.length; i++) {
        let series = objChartLegendShape.chartShape.series.getAt(i);
        let legendEntry = series.title.text;
        let isDeleted = !series.title.shown;
        let writeTxPr = hasExplicitValue(legendEntry);

        if (!isDeleted && !writeTxPr)
            continue;

        let legendEntryNode = execContext.createChildNode(xmlLegendNode, "c:legendEntry");
        let idxNode = execContext.createChildNode(legendEntryNode, "c:idx");
        execContext.setAttribute(idxNode, "val", i);
        if (isDeleted) {
            let deleteNode = execContext.createChildNode(legendEntryNode, "c:delete");
            execContext.setAttribute(deleteNode, "val", "1");
        } else if (writeTxPr) { // we don't write explicits if deleted
            execContext.visit(legendEntryNode, legendEntry, "c:txPr");
        }
    }

    processLayout(execContext, objChartLegendShape, xmlLegendNode)
    execContext.copyValue(objChartLegendShape, "overlay", xmlLegendNode, "c:overlay", setChildValAttrAsBoolean, alwaysCopyCheck);

    processShapeProperties(execContext, objChartLegendShape, xmlLegendNode, alwaysCopyCheck);
    // text
    let labelText = objChartLegendShape.labels.text;
    if (labelText && labelText.shown === true)
        execContext.visit(xmlLegendNode, labelText, "c:txPr");
}

export function txRichVisitor(execContext, objChartTextShape, xmlNode, alwaysCopySimpleRun) {
    let xmlTxNode = execContext.createChildNode(xmlNode, "c:tx");
    let richChildNode = execContext.createNode("c:rich");
    textVisitor(execContext, objChartTextShape, richChildNode, alwaysCopySimpleRun);

    let simpleRunProp = objChartTextShape.getPropertyValue("simpleRun");
    if (simpleRunProp.isExplicit || alwaysCopySimpleRun) {
        execContext.copyValue(objChartTextShape, "simpleRun", richChildNode, "a:p/a:r/a:t", function(execContext, xmlNode, xpath, textValue) {
            setChildValue(execContext, xmlNode, xpath, textValue);
        }, alwaysCopyCheck);
    }

    execContext.appendNonEmptyChildNode(xmlTxNode, richChildNode);
}

export function txPrVisitor(execContext, objChartTextShape, xmlTxPrNode) {
    textVisitor(execContext, objChartTextShape, xmlTxPrNode);
}

export function textVisitor(execContext, objChartTextShape, textChildNode) {
    let propRot = objChartTextShape.parent.getPropertyValue("rotation");
    execContext.copyValue(objChartTextShape.parent, "rotation", textChildNode, "a:bodyPr", function(execContext, xmlNode, xpath, attrValue) {
//         if (attrValue === 0 && !propRot.isExplicit) {
//             attrValue = -1000; // office hack
//         }
        let rotation = OOXMLMappings.toOOXMLAngle(attrValue);
        setChildAttribute(execContext, xmlNode, xpath, "rot", rotation);
    }, alwaysCopyCheck);

    let xmlBodyPrNode = execContext.getNode(textChildNode, "a:bodyPr");

    execContext.setAttribute(xmlBodyPrNode, "spcFirstLastPara", "1");
    execContext.setAttribute(xmlBodyPrNode, "vertOverflow", "ellipsis");
    execContext.setAttribute(xmlBodyPrNode, "vert", "horz");
    execContext.setAttribute(xmlBodyPrNode, "wrap", "square");
    execContext.setAttribute(xmlBodyPrNode, "anchor", "ctr");
    execContext.setAttribute(xmlBodyPrNode, "anchorCtr", "1");

    execContext.copyValue(objChartTextShape, "size", textChildNode, "a:p/a:pPr/a:defRPr", function(execContext, xmlNode, xpath, attrValue) {
        let size = attrValue*100.0;
        setChildAttribute(execContext, xmlNode, xpath, "sz", size);
    }, alwaysCopyCheck);

    execContext.copyValue(objChartTextShape, "weight", textChildNode, "a:p/a:pPr/a:defRPr", function(execContext, xmlNode, xpath, attrValue) {
        let weight = attrValue >= 500 ? 1 : 0;
        setChildAttribute(execContext, xmlNode, xpath, "b", weight);
    }, alwaysCopyCheck);

    execContext.copyValue(objChartTextShape, "italic", textChildNode, "a:p/a:pPr/a:defRPr", function(execContext, xmlNode, xpath, attrValue) {
        let italic =  attrValue === true ? 1 : 0;
        setChildAttribute(execContext, xmlNode, xpath, "i", italic);
    }, alwaysCopyCheck);

    execContext.copyValue(objChartTextShape, "underline", textChildNode, "a:p/a:pPr/a:defRPr", function(execContext, xmlNode, xpath, attrValue) {
        setChildAttribute(execContext, xmlNode, xpath, "u", attrValue);
    }, alwaysCopyCheck);

    execContext.copyValue(objChartTextShape, "strike", textChildNode, "a:p/a:pPr/a:defRPr", function(execContext, xmlNode, xpath, attrValue) {
        setChildAttribute(execContext, xmlNode, xpath, "strike", attrValue);
    }, alwaysCopyCheck);

    execContext.copyValue(objChartTextShape, "kern", textChildNode, "a:p/a:pPr/a:defRPr", function(execContext, xmlNode, xpath, attrValue) {
      setChildAttribute(execContext, xmlNode, xpath, "kern", attrValue);
    }, alwaysCopyCheck);

    execContext.copyValue(objChartTextShape, "spc", textChildNode, "a:p/a:pPr/a:defRPr", function(execContext, xmlNode, xpath, attrValue) {
      setChildAttribute(execContext, xmlNode, xpath, "spc", attrValue);
    }, alwaysCopyCheck);

    execContext.copyValue(objChartTextShape, "baseline", textChildNode, "a:p/a:pPr/a:defRPr", function(execContext, xmlNode, xpath, attrValue) {
      setChildAttribute(execContext, xmlNode, xpath, "baseline", attrValue);
    }, alwaysCopyCheck);

    //shape proeprties
    let xmlDefRPrNode = execContext.createChildNodes(textChildNode, "a:p/a:pPr/a:defRPr");
    processFill(execContext, objChartTextShape, xmlDefRPrNode, "fill", alwaysCopyCheck);
    processStroke(execContext, objChartTextShape, xmlDefRPrNode, alwaysCopyCheck);

    processFonts(execContext, objChartTextShape, xmlDefRPrNode, alwaysCopyCheck);
}

function processFont(execContext, parent, font, type, defaultValue) {
    let typeNode = execContext.createNode("a:" + type);
    execContext.setAttribute(typeNode, "typeface", font.isExplicit ? font.value.name : defaultValue);
    if (font.value.panose)
        execContext.setAttribute(typeNode, "panose", font.value.panose);
    if (font.value.pitchFamily)
        execContext.setAttribute(typeNode, "pitchFamily", font.value.pitch);
    if (font.value.charset)
        execContext.setAttribute(typeNode, "charset", font.value.charset);

    execContext.appendNonEmptyChildNode(parent, typeNode);
}

function processFonts(execContext,  objChartTextShape, xmlDefRPrNode, copyCheck) {
  let font = objChartTextShape.getPropertyValue("font");

  if (copyCheck(font)) {
    processFont(execContext, xmlDefRPrNode, font, "latin", "+mn-lt");
  }

  let ea = execContext.createNode("a:ea");
  if (copyCheck(font))
      execContext.setAttribute(ea, "typeface", "+mn-ea");
  execContext.appendNonEmptyChildNode(xmlDefRPrNode, ea);

  let cs = execContext.createNode("a:cs");
  if (copyCheck(font))
      execContext.setAttribute(cs, "typeface", "+mn-cs");
  execContext.appendNonEmptyChildNode(xmlDefRPrNode, cs);

}


//TODO: Immutablerect has x,y, width and height
function processFillToRect(execContext, objImmutableRect, xmlNode) {
    if (objImmutableRect.l) {
        execContext.setAttribute(xmlNode, "l", objImmutableRect.l*1000);
    }
    if (objImmutableRect.t) {
        execContext.setAttribute(xmlNode, "t", objImmutableRect.t*1000);
    }
    if (objImmutableRect.r) {
        execContext.setAttribute(xmlNode, "r", objImmutableRect.r*1000);
    }
    if (objImmutableRect.b) {
        execContext.setAttribute(xmlNode, "b", objImmutableRect.b*1000);
    }
}

function processAlpha(execContext, xmlNode, alpha=1) {
    alpha = ColorUtils.clampAlpha(parseFloat(alpha));
    if (isNaN(alpha) || alpha === 1)
        return;

    let alphaChildNode = execContext.createChildNode(xmlNode, "a:alpha");
    execContext.setAttribute(alphaChildNode, "val", alpha * 100 * 1000);
}

function processColorAdjustments(execContext, adjs, xmlNode) {
    if (!adjs)
        return;

    let arraylength = adjs.length;
    for (let i = 0; i < arraylength; i++) {
        let type = adjs[i].type;
        //create child element
        let typeChildNode = execContext.createChildNode(xmlNode, "a:" + type);
        let val = adjs[i].amount;
        if (typeof val !== "boolean") {
            //create val attribute
            if (type === "hue" || type === "hueOff" || type === "hueMod")
                val = OOXMLMappings.toOOXMLAngle(val);
            else if (type === "gray" || type === "comp" || type === "inv" || type === "gamma" || type === "invGamma")
                val = val*1;
            else
                val = val*1000;
                execContext.setAttribute(typeChildNode, "val", val);
        }
    }
}

function processAdjColor(execContext, color, xmlNode) {
    let colorVal = color.val

    if (colorVal === undefined || colorVal === null)
        return;

    let presetClr = PresetColors.valueOfOoxmlId(colorVal)
    if (presetClr !== undefined && presetClr !== null) {
        let prstClrChildNode = execContext.createChildNode(xmlNode, "a:prstClr");
        execContext.setAttribute(prstClrChildNode, "val", colorVal);
        processColorAdjustments(execContext, color.adjustments(), prstClrChildNode);
        return;
    }

    let schemeClr = findSchemaDefinition(colorVal)
    if (schemeClr !== undefined && schemeClr !== null) {
        let schemeClrChildNode = execContext.createChildNode(xmlNode, "a:schemeClr");
        execContext.setAttribute(schemeClrChildNode, "val", colorVal);
        processColorAdjustments(execContext, color.adjustments(), schemeClrChildNode);
        return;
    }

    let sysClr = PresetColors.valueOfOoxmlId(colorVal);
    if (sysClr !== undefined && sysClr !== null && sysClr.system === true) {
        let sysClrChildNode = execContext.createChildNode(xmlNode, "a:sysClr");
        execContext.setAttribute(sysClrChildNode, "val", colorVal);
        let lastRGB = color.lastRGB;
        if (lastRGB !== undefined && lastRGB !== null) {
            //parse attributes
            lastRGB = lastRGB.replace("rgb(", "")
            lastRGB = lastRGB.replace(")", "")
            let attrs = lastRGB.split(",")
            let lastClr = OOXMLUtils.rgbToHex(attrs[0], attrs[1], attrs[2]);
            execContext.setAttribute(sysClrChildNode, "lastClr", lastClr);
        }
        processColorAdjustments(execContext, color.adjustments(), sysClrChildNode);
        return;
    }

    let parts;
    // hsl(a)
    parts = colorVal.match(ColorUtils.REGEX_HSL) || colorVal.match(ColorUtils.REGEX_HSLA);
    if (parts) {
        let input = ColorUtils.parseParts(parts[1], parts[2], parts[3]);

        let hslClrChildNode = execContext.createChildNode(xmlNode, "a:hslClr");
        //parse attributes
        execContext.setAttribute(hslClrChildNode, "hue", input[0]);
        execContext.setAttribute(hslClrChildNode, "sat", input[1]);
        execContext.setAttribute(hslClrChildNode, "lum", input[2]);
        processAlpha(execContext, srgbClrChildNode, parts[4]);
        processColorAdjustments(execContext, color.adjustments(), hslClrChildNode);
        return;
    }

    // rgba(a)
    parts = colorVal.match(ColorUtils.REGEX_RGB) || colorVal.match(ColorUtils.REGEX_RGBA);
    if (parts) {
        let input = ColorUtils.parseParts(parts[1], parts[2], parts[3]);

        let srgbClrChildNode = execContext.createChildNode(xmlNode, "a:srgbClr");
        let hex = OOXMLUtils.rgbToHex(input[0], input[1], input[2]);

        execContext.setAttribute(srgbClrChildNode, "val", hex);
        processAlpha(execContext, srgbClrChildNode, parts[4]);
        processColorAdjustments(execContext, color.adjustments(), srgbClrChildNode);
        return;
    }

    // lrgb(a)
    parts = colorVal.match(ColorUtils.REGEX_LRGB) || colorVal.match(ColorUtils.REGEX_LRGBA);
    if (parts) {
        let input = ColorUtils.parseParts(parts[1], parts[2], parts[3]);

        let scRgbClrChildNode = execContext.createChildNode(xmlNode, "a:scrgbClr");
        //parse attributes
        execContext.setAttribute(scRgbClrChildNode, "r",  input[0]);
        execContext.setAttribute(scRgbClrChildNode, "g", input[1]);
        execContext.setAttribute(scRgbClrChildNode, "b", input[2]);
        processAlpha(execContext, srgbClrChildNode, parts[4]);
        processColorAdjustments(execContext, color.adjustments(), scRgbClrChildNode);
        return
    }

    // hex(a)
    parts = colorVal.match(ColorUtils.REGEX_HEX) || parts.match(ColorUtils.REGEX_HEXA);
    if (parts) {
        let hex = colorVal;
        let alpha = 1;
        if (parts.length > 4) { // strip alpha
            hex = OOXMLUtils.rgbToHex(
                parseInt(parts[2], 16),
                parseInt(parts[3], 16),
                parseInt(parts[4], 16));
            alpha = parseInt(parts[1], 16) / 255;
        }

      let srgbClrChildNode = execContext.createChildNode(xmlNode, "a:srgbClr");
      execContext.setAttribute(srgbClrChildNode, "val", hex);
      processAlpha(execContext, srgbClrChildNode, alpha);
      processColorAdjustments(execContext, color.adjustments(), srgbClrChildNode);
      return;
    }

}

function processChartType(execContext, objChartSpace, objChartType, xmlPlotAreaNode) {
    let xmlChartNodeName = null;
    if (objChartType.type === "bar" || objChartType.type === "column") {
        xmlChartNodeName = "c:barChart";
    } else {
        switch (objChartType.type) {
            case 'area' :
                xmlChartNodeName = "c:areaChart";
                break;
            case 'bubble' :
                xmlChartNodeName = "c:bubbleChart";
                break;
            case 'pie' :
                let holeSize = objChartType.holeSize
                if (holeSize > 0) {
                    xmlChartNodeName = "c:doughnutChart";
                } else {
                    xmlChartNodeName = "c:pieChart";
                }
                break;
            case 'line' :
                xmlChartNodeName = "c:lineChart";
                break;
            case 'scatter' :
                xmlChartNodeName = "c:scatterChart";
                break;
        }
    }
    return execContext.visit(xmlPlotAreaNode, objChartType, xmlChartNodeName);
}

function processLayout(execContext, objModel, xmlNode) {
    let layoutProp = objModel.getPropertyValue("manualLayout");
    if (layoutProp && layoutProp.isExplicit && objModel.manualLayout !== undefined && objModel.manualLayout !== null) {
        let xmlLayoutNode = execContext.createNode("c:layout");
        let xmlManualLayoutNode = execContext.createNode("c:manualLayout");
        manualLayoutVisitor(execContext, objModel.manualLayout, xmlManualLayoutNode);
        execContext.appendNonEmptyChildNode(xmlLayoutNode, xmlManualLayoutNode);
        execContext.appendNonEmptyChildNode(xmlNode, xmlLayoutNode);
    }
}

function processEffectList(execContext, objModel, xmlNode, copyCheck=defaultCopyCheck) {
    let propEffects = objModel.getPropertyValue("effects");
    if (!copyCheck(propEffects))
        return;
    let xmlLayoutNode = execContext.createNode("a:effectLst");
    execContext.appendChildNode(xmlNode, xmlLayoutNode);
}

function processImageFill(execContext, objFill, xmlPattFillNode) {
    // TODO - implement. Perhaps will default to 'theme color'
}

function processPatternFill(execContext, objFill, xmlPattFillNode) {
    xmlPattFillNode.setAttribute("prst", objFill.patternType)

    let fgClrChildNode = execContext.createNode("a:fgClr");
    processAdjColor(execContext, objFill.foreground, fgClrChildNode);
    execContext.appendNonEmptyChildNode(xmlPattFillNode, fgClrChildNode);

    let bgClrChildNode = execContext.createNode("a:bgClr");
    processAdjColor(execContext, objFill.background, bgClrChildNode);
    execContext.appendNonEmptyChildNode(xmlPattFillNode, bgClrChildNode);
}

function processGradFill(execContext, objFill, xmlGradFillNode) {
    execContext.setAttribute(xmlGradFillNode, "flip", objFill.tile?.mirror)
    if (objFill.isRotatedWithShape !== undefined && objFill.isRotatedWithShape !== null) {
        xmlGradFillNode.setAttribute("rotWithShape", objFill.isRotatedWithShape ? 1 : 0);
    }

    if (objFill.stops) {
        let gsListChildNode = execContext.createNode("a:gsLst");
        let arraylength = objFill.stops.length
        for (let i = 0; i < arraylength; i++) {
            let gsChildNode = execContext.createNode("a:gs");
            gsChildNode.setAttribute("pos", objFill.stops[i].offset*1000);
            processAdjColor(execContext, objFill.stops[i].color, gsChildNode);
            execContext.appendNonEmptyChildNode(gsListChildNode, gsChildNode);
        }
        execContext.appendNonEmptyChildNode(xmlGradFillNode, gsListChildNode);
    }

    if ("linear" === objFill.gradientType) {
        let linChildNode = execContext.createNode("a:lin");
        execContext.setAttribute(linChildNode, "ang", OOXMLMappings.toOOXMLAngle(objFill.angle));
        //TODO:where is scaled attribute in gradfill
        execContext.setAttribute(linChildNode, "scaled", objFill.scaled);
        execContext.appendNonEmptyChildNode(xmlGradFillNode, linChildNode);
    } else {
        let pathChildNode = execContext.createNode("a:path");
        if (objFill.gradientType) {
            execContext.setAttribute(pathChildNode, "path", OOXMLMappings.toOOXMLGradientType(objFill.gradientType));
        }

        if (objFill.fillTo) {
            let fillToRectChildNode = execContext.createNode("a:fillToRect");
            processFillToRect(execContext, objFill.fillTo, fillToRectChildNode);
            execContext.appendNonEmptyChildNode(pathChildNode, fillToRectChildNode);
        }

        execContext.appendChildNode(xmlGradFillNode, pathChildNode);
    }

    if (objFill.tile?.bounds) {
        let tileRectChildNode = execContext.createChildNode(xmlGradFillNode, "a:tileRect");
        // TODO processFillRect is not finished
        processFillToRect(execContext, objFill.tile.bounds, tileRectChildNode);
    }
}


function processFill(execContext, objModel, xmlNode, fillPropName="fill", copyCheck=defaultCopyCheck) {
    if (fillPropName === null)
        return;
    let fillProperty = objModel.getPropertyValue(fillPropName);
    if (!copyCheck(fillProperty))
        return;

    let objFill = fillProperty.value;

    if (objFill.type === "none") {
        let noFillChildNode = execContext.createChildNode(xmlNode, "a:noFill");
        return;
    }

    let nodeFill = null;
    if (objFill.type === "solid") {
        nodeFill = execContext.createNode("a:solidFill");
        processAdjColor(execContext, objFill.color, nodeFill);
    } else if (objFill.type === "gradient") {
        nodeFill = execContext.createNode("a:gradFill");
        processGradFill(execContext, objFill, nodeFill);
    } else if (objFill.type === "pattern") {
        nodeFill = execContext.createNode("a:pattFill");
        processPatternFill(execContext, objFill, nodeFill);
    } else if (objFill.type === "image") {
        nodeFill = execContext.createNode("a:blipFill");
        processImageFill(execContext, objFill, nodeFill);
    }
    execContext.appendNonEmptyChildNode(xmlNode, nodeFill);
}

function processStroke(execContext, objModel, xmlNode, copyCheck=defaultCopyCheck) {
    let strokeFillProperty = objModel.getPropertyValue("strokeFill");
    if (!copyCheck(strokeFillProperty))
        return;

    let strokeFill = strokeFillProperty.value;

    let strokeChildNode = execContext.createNode("a:ln");

    let strokeWidth = objModel.getPropertyValue("strokeWidth");
    if (copyCheck(strokeWidth))
        execContext.setAttribute(strokeChildNode, "w", OOXMLMappings.toOOXMLPoints(strokeWidth.value));

    let strokeLineCap = objModel.getPropertyValue("strokeLineCap");
    if (copyCheck(strokeLineCap))
        execContext.setAttribute(strokeChildNode, "cap", OOXMLMappings.toOOXMLLineCap(strokeLineCap.value));

    let strokeCompound = objModel.getPropertyValue("strokeCompound");
    if (copyCheck(strokeCompound))
        execContext.setAttribute(strokeChildNode, "cmpd", OOXMLMappings.toOOXMLCompound(strokeCompound.value));

    let strokeAlign = objModel.getPropertyValue("strokeAlign");
    if (copyCheck(strokeAlign))
        execContext.setAttribute(strokeChildNode, "algn", OOXMLMappings.toOOXMLAlign(strokeAlign.value));

    //process stroke fill
    processFill(execContext, strokeFillProperty.source, strokeChildNode, "strokeFill", function(property) {
        return copyCheck(strokeFillProperty);
    });

    if (strokeFill.type === "none") {
        execContext.appendNonEmptyChildNode(xmlNode, strokeChildNode);
        return;
    }

    //dash properties
    let strokeDashProperty = objModel.getPropertyValue("strokeDash");
    if (strokeDashProperty.isExplicit && objModel.strokeDash !== undefined && objModel.strokeDash !== null) {
        if (objModel.strokeDash.isPreset) {
            let prstDashChildNode = execContext.createChildNode(strokeChildNode, "a:prstDash");
            execContext.setAttribute(prstDashChildNode, "val", objModel.strokeDash.key);
        } else {//custom
            let custDashChildNode = execContext.createChildNode(strokeChildNode, "a:custDash");
            let dashArray = objModel.strokeDash.dashArray
            let arraylength = dashArray.length
            for (let i = 0; i < arraylength; i++) {
                dashLength = dashArray[i]
                spaceLength = dashArray[++i]
                let dsChildNode = execContext.createChildNode(custDashChildNode, "a:ds");
                execContext.setAttribute(dsChildNode, "a:d", dashLength);
                execContext.setAttribute(dsChildNode, "a:sp", spaceLength);
            }
        }
    }
    //join properties
    let strokeLineJoinProperty = objModel.getPropertyValue("strokeLineJoin");
    if (strokeLineJoinProperty.isExplicit && objModel.strokeLineJoin === "bevel")
        execContext.createChildNode(strokeChildNode, "a:bevel");
    else if (strokeLineJoinProperty.isExplicit && objModel.strokeLineJoin === "round")
        execContext.createChildNode(strokeChildNode, "a:round");

    //head and tail
    let strokeHeadTypeProp = objModel.getPropertyValue("strokeHeadType");
    if (strokeHeadTypeProp && strokeHeadTypeProp.isExplicit && objModel.strokeHeadType && objModel.strokeHeadSize) {
        let headEndNode = execContext.createChildNode(strokeChildNode, "a:headEnd");
        let size = objModel.strokeHeadSize.split("-");
        let w = size[0];
        let len = size[1];
        execContext.setAttribute(headEndNode, "len", len);
        execContext.setAttribute(headEndNode, "type", objModel.strokeHeadType);
        execContext.setAttribute(headEndNode, "w", w);
    }
    let strokeTailTypeProp = objModel.getPropertyValue("strokeTailType");
    if (strokeTailTypeProp && strokeTailTypeProp.isExplicit && objModel.strokeTailType && objModel.strokeTailSize) {
        let tailEndNode = execContext.createChildNode(strokeChildNode, "a:tailEnd");
        let size = objModel.strokeTailSize.split("-");
        let w = size[0];
        let len = size[1];
        execContext.setAttribute(tailEndNode, "len", len);
        execContext.setAttribute(tailEndNode, "type", objModel.strokeTailType);
        execContext.setAttribute(tailEndNode, "w", w);
    }

    execContext.appendNonEmptyChildNode(xmlNode, strokeChildNode);
}

function processShapeProperties(execContext, objModelParent, xmlNodeParent, copyCheck=defaultCopyCheck, fillPropName) {
    let xmlShapePrNode = execContext.createNode("c:spPr");
    processFill(execContext, objModelParent, xmlShapePrNode, fillPropName, copyCheck);
    processStroke(execContext, objModelParent, xmlShapePrNode, copyCheck);
    processEffectList(execContext, objModelParent, xmlShapePrNode, copyCheck);
    execContext.appendNonEmptyChildNode(xmlNodeParent, xmlShapePrNode);
}

export function setChildValue(execContext, xmlNode, xpath, nodeValue) {
    if (nodeValue !== undefined) {
        let xmlChildNode = execContext.createChildNodes(xmlNode, xpath);
        let xmlChildTextNode = nodeValue !== null ? execContext.createTextNode(nodeValue) : execContext.createTextNode("");
        execContext.appendChildNode(xmlChildNode, xmlChildTextNode);
    }
}

export function setChildValAttribute(execContext, xmlNode, xpath, attrValue) {
    setChildAttribute(execContext, xmlNode, xpath, "val", attrValue);
}

function setChildAttribute(execContext, xmlNode, xpath, attrName, attrValue) {
    let xmlChildNode = execContext.createChildNodes(xmlNode, xpath);
    execContext.setAttribute(xmlChildNode, attrName, attrValue)
}

export function setChildValAttrAsBooleanFlip(execContext, xmlNode, xpath, attrValue) {
    setChildValAttrAsBoolean(execContext, xmlNode, xpath, attrValue, -1)
}

export function setChildValAttrAsBoolean(execContext, xmlNode, xpath, attrValue, direction=1) {
    if (attrValue === true) {
        if (direction === 1)
            setChildAttribute(execContext, xmlNode, xpath, "val", "1")
        else
            setChildAttribute(execContext, xmlNode, xpath, "val", "0")
    } else {
        if (direction === 1)
            setChildAttribute(execContext, xmlNode, xpath, "val", "0")
        else
            setChildAttribute(execContext, xmlNode, xpath, "val", "1")
    }
}