import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import useDAGMProperty from "../hooks/useDAGMProperty";

import FillStrokePropertyEditor from "../FillStrokePropertyEditor";
import HeaderContainer from "../HeaderContainer";

const useStyles = makeStyles((theme) => ({
  rootPlotArea: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartPlotAreaPropertyEditor(props) {
  const { shape, ...other } = props;
  const classes = useStyles();

  const [plotArea] = useDAGMProperty(shape, "plotArea");

  return (
    <HeaderContainer
      className={classes.rootPlotArea}
      {...other}
      label="Plot Area"
      type="heading"
      collapsible={true}
    >
      <FillStrokePropertyEditor shape={plotArea}></FillStrokePropertyEditor>
    </HeaderContainer>
  );
}

export default ChartPlotAreaPropertyEditor;
