import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import NumberPropertyEditor from "../NumberPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartTypeColumnPropertyEditor(props) {
  const { shape, ...other } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root} {...other}>
      <ExplicitPropertyEditorContainer>
        <NumberPropertyEditor
          label="Start Angle"
          shape={shape}
          property="startAngle"
        />
      </ExplicitPropertyEditorContainer>
      <ExplicitPropertyEditorContainer>
        <NumberPropertyEditor
          label="Explosion"
          shape={shape}
          property="explosion"
          minNumber={0}
        />
      </ExplicitPropertyEditorContainer>
      <ExplicitPropertyEditorContainer>
        <NumberPropertyEditor
          label="Hole Size"
          shape={shape}
          property="holeSize"
          minNumber={0}
        />
      </ExplicitPropertyEditorContainer>
    </Box>
  );
}

export default ChartTypeColumnPropertyEditor;
