import React from "react";
import { PropTypes } from "prop-types";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import Box from "@material-ui/core/Box";

import ExpandingPropertyEditor from "./ExpandingPropertyEditor";

const useStyles = makeStyles((theme) => ({
  rootHeader: {
    "&:not(:last-child)": {
      marginBottom: function ({ padded }) {
        return padded ? "8px" : "0px";
      },
      border: function ({ padded }) {
        //         if (padded)
        //             return 'purple solid 2px'
      },
    },
  },
  layout: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  header: {
    marginRight: "24px",
    marginLeft: function ({ collapsible }) {
      return collapsible ? "0px" : "18px";
    },
    marginTop: function ({ collapsible }) {
      return collapsible ? "8px" : "0px";
    },
    marginBottom: function ({ collapsible }) {
      return collapsible ? "8px" : "0px";
    },
    cursor: function({ collapsible }) {
      if (collapsible)
        return 'pointer';
      return 'default';
    },
    flex: "1 1 100%",
  },
  secondaryHeading: {
    // fontSize: theme.typography.pxToRem(13),
    fontSize: theme.typography.subtitle2.fontSize,
    color: theme.palette.text.primary,
  },
  heading: {
    // fontSize: theme.typography.h6.fontSize,
    // color: 'red'
    //fontSize: theme.typography.pxToRem(15),
  },
  expandPanel: {
    display: "flex"
  },
}));

const HeaderContainer = (props) => {
  const {
    label,
    type = "secondaryHeading",
    children,
    collapsible = false,
    defaultExpanded = false,
    className : classNameProp,
    ...other
  } = props;

  //  const collapsible = (!!children);
  const classes = useStyles({
    collapsible: collapsible,
    padded: !!children && !collapsible,
  });

  let divComponent = (
    <Typography
      className={clsx(classes.layout, classes.header, classes[type])}
      component="div"
    >
      {label}
    </Typography>
  );

  if (collapsible) {
    divComponent = (
      <ExpandingPropertyEditor
        className={clsx(classes.layout, classes.expandPanel)}
        labelDiv={divComponent}
        allowExpand={true}
        deailsOverlap={8}
        defaultExpanded={defaultExpanded}
      >
        {children}
      </ExpandingPropertyEditor>
    );
  } else {
    divComponent = (
      <Box>
        {divComponent}
        {children}
      </Box>
    );
  }
  return (
    <Box className={clsx(classNameProp, classes.layout, classes.rootHeader)} {...other}>
      {divComponent}
    </Box>
  );
};

HeaderContainer.propTypes = {
  label: PropTypes.string,
};

export default HeaderContainer;
