import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { ChartUtils } from "@sheetxl/models";

import useDAGMProperty from "../hooks/useDAGMProperty";

import NumberPropertyEditor from "../NumberPropertyEditor";
import BooleanPropertyEditor from "../BooleanPropertyEditor";
import HeaderContainer from "../HeaderContainer";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

import ChartGroupingPropertyEditor from "./ChartGroupingPropertyEditor";
import ChartAxisPrefPropertyEditor from "./ChartAxisPrefPropertyEditor";
import ChartTickMarksPropertyEditor from "./ChartTickMarksPropertyEditor";
import ChartAxisPropertyEditor from "./ChartAxisPropertyEditor";
import RangePropertyEditor from "../RangePropertyEditor";

import ChartAxisCatPositionPropertyEditor from "./ChartAxisCatPositionPropertyEditor";

const useStyles = makeStyles((theme) => ({
  rootChartAxisCat: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartAxisCategoryPropertyEditor(props) {
  const {
    shape,
    children,
    childrenAfterType = <></>,
    childrenTicks = <></>,
    childrenLabelsAxis = <></>,
    childrenLastOption = <></>,
    axisType='cat',
    ...other
  } = props;
  const classes = useStyles();

  const [chartType] = useDAGMProperty(shape, "chartType");
  const [chartTypeType] = useDAGMProperty(shape, "chartType.type");

  let typeSpecific = <></>;
  if (ChartUtils.isColumnType(chartTypeType)) {
    typeSpecific = (
      <>
        <ChartGroupingPropertyEditor
          shape={chartType}
          chartTypeType={chartTypeType}
        ></ChartGroupingPropertyEditor>
        <ExplicitPropertyEditorContainer>
          <NumberPropertyEditor
            label="Overlap"
            shape={shape}
            property="chartType.overlap"
          />
        </ExplicitPropertyEditorContainer>
        <ExplicitPropertyEditorContainer>
          <NumberPropertyEditor
            label="Gap Width"
            shape={shape}
            property="chartType.gapWidth"
          />
        </ExplicitPropertyEditorContainer>
      </>
    );
  }

  const childrenXRange = (
    <ExplicitPropertyEditorContainer>
      <RangePropertyEditor
        label="'X' Range"
        shape={shape}
        property="xRange"
        singleRange={true}
      />
    </ExplicitPropertyEditorContainer>
  )

  return (
    <ChartAxisPropertyEditor
      className={classes.rootChartAxisCat}
      {...other}
      shape={shape}
      childrenLabelsAxis={childrenLabelsAxis}
      childrenAfterStroke={childrenXRange} // This is not working at the moment.
      axisType={axisType}
    >
      {typeSpecific}
      <ChartAxisPrefPropertyEditor
        shape={shape}
        property="chartShape.catAxisPref"
      />
      {children}
      <ChartAxisCatPositionPropertyEditor shape={shape} />
      <ExplicitPropertyEditorContainer>
        <BooleanPropertyEditor
          label="Reverse"
          shape={shape}
          property="inverted"
        />
      </ExplicitPropertyEditorContainer>
      {childrenLastOption}
      <HeaderContainer label="Tick Marks" type="secondaryHeading">
        {childrenTicks}
        <ChartTickMarksPropertyEditor
          shape={shape}
          label="Major"
          property="majorTickMarks"
        ></ChartTickMarksPropertyEditor>
        <ChartTickMarksPropertyEditor
          shape={shape}
          label="Minor"
          property="minorTickMarks"
        ></ChartTickMarksPropertyEditor>
      </HeaderContainer>
    </ChartAxisPropertyEditor>
  );
}

export default ChartAxisCategoryPropertyEditor;
