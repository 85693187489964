import React, { useState, useCallback } from 'react';

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import tinycolor from 'tinycolor2';
import debounce from 'lodash-es/debounce';

import { Saturation, Hue, Alpha } from 'react-color/es/components/common';
import SketchFields from 'react-color/es/components/sketch/SketchFields';

import Checkboard from "./Checkboard";

const useStyles = makeStyles(function (theme) {
  return {
    saturation: {
      width: '100%',
      paddingBottom: '75%',
      position: 'relative',
      overflow: 'hidden',
      //cursor: 'crosshair important'
    },
    Saturation: {
      radius: '3px',
      shadow: 'inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)',
    },
    controls: {
      display: 'flex',
    },
    sliders: {
      padding: '4px 0',
      flex: '1',
    },
    colorIndicator: {
      width: '24px',
      height: '24px',
      position: 'relative',
      marginTop: '4px',
      marginLeft: '4px',
      borderRadius: '1px',
      overflow: 'hidden'
    },
    inputFields: {
      '& label': {
         fontFamily() {
          return `${theme.typography.fontFamily} !important`;
         },
         color: function() {
          return `${theme.palette.text.primary} !important`;
        }
      },
      '& *': {
         fontFamily() {
          return `${theme.typography.fontFamily} !important`;
         }
      }
    },
    activeColor: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: function({color}) {
        if (!color)
            return 'transparent';
        return color;
      },
      boxShadow: 'inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)',
    },
    hue: {
      position: 'relative',
      height: '10px',
      overflow: 'hidden',
    },
    Hue: {
      radius: '2px',
      shadow: 'inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)',
    },
    alpha: {
      position: 'relative',
      height: '10px',
      marginTop: '4px',
      overflow: 'hidden',
    },
    Alpha: {
      radius: '2px',
      shadow: 'inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)',
    }
  };
});

export const toState2 = function(data, oldHue) {
  var color = data.hex ? tinycolor(data.hex) : tinycolor(data);
  var hsl = color.toHsl();
  var hsv = color.toHsv();
  var rgb = color.toRgb();
  var hex = color.toHex();
  if (hsl.s === 0) {
    hsl.h = oldHue || 0;
    hsv.h = oldHue || 0;
  }
  var transparent = hex === '000000' && rgb.a === 0;

  return {
    hsl: hsl,
    hex: transparent ? 'transparent' : '#' + hex,
    rgb: rgb,
    hsv: hsv,
    oldHue: data.h || oldHue || hsl.h,
    source: data.source
  };
};

export const CustomColorPanel = function(props) {
  const {
    color : colorSpec,
    onChange,
    className,
    renderers,
    ...other
  } = props;

  const color = colorSpec ? colorSpec : 'transparent';
  const classes = useStyles({color: color});

  const [colorState, setColorState] = useState(toState2(color));

  const [oldHue, setOldHue] = useState(colorState.oldHue);

  const onMergeColorChangeDebounce = useCallback(debounce(function(newState) {
    if (onChange)
       onChange(newState);
  }, 100), [onChange]);

  const onMergeColorChange = useCallback((staticColor) => {
        let newState = toState2(staticColor, oldHue);
        if (newState.oldHue != oldHue) {
            newState.hsl.h = newState.oldHue;
            newState.hsv.h = newState.oldHue;
            setOldHue(newState.oldHue);
        }
        setColorState(newState);
        onMergeColorChangeDebounce(newState);
  }, [onMergeColorChangeDebounce]);

  return (
    <div className={ clsx(className) } {...other}>
       <div className={ clsx(classes.saturation) }>
        <Saturation
          className={ clsx(classes.Saturation) }
          hsl={ colorState.hsl }
          hsv={ colorState.hsv }
          onChange={ onMergeColorChange }
        />
      </div>
      <div className={ clsx(classes.controls) }>
        <div className={ clsx(classes.sliders) }>
          <div className={ clsx(classes.hue) }>
            <Hue
              className={ clsx(classes.Hue) }
              hsl={ colorState.hsl }
              onChange={ onMergeColorChange }
            />
          </div>
          <div className={ clsx(classes.alpha) }>
            <Alpha
              className={ clsx(classes.Alpha) }
              rgb={ colorState.rgb }
              hsl={ colorState.hsl }
              renderers={ renderers }
              onChange={ onMergeColorChange }
            />
          </div>
        </div>
        <div className={ clsx(classes.colorIndicator) }>
          <Checkboard/>
          <div className={ clsx(classes.activeColor) } />
        </div>
      </div>
      <div className={ clsx(classes.inputFields) }>
      <SketchFields
        rgb={ colorState.rgb }
        hsl={ colorState.hsl }
        hex={ colorState.hex }
        onChange={ onMergeColorChange }
      />
      </div>
    </div>
  );
};

export default CustomColorPanel;