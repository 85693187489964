import React, { useState } from "react";
import { PropTypes } from "prop-types";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { deepmerge } from '@material-ui/utils';

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// import useDebounce from './hooks/useDebounce';
import useDAGMProperty from "./hooks/useDAGMProperty";

const initialState = {
  mouseX: null,
  mouseY: null,
};

const useStyles = makeStyles(function (theme) {
  let customizations = {
      root: {
        display: "flex",
        flex: "1 1 100%",
        flexDirection: "row",
        alignItems: "center",
        "& > *": {
          margin: theme.spacing(0),
        }
      },
      inputfield: {
        marginTop: "8px",
        marginBottom: "8px",
        cursor: "context-menu",
      },
      rightToLeft: {
        "& input": {
          textAlign: "right",
        },
        "& ::-webkit-input-placeholder": {
          width: "100%",
        },
        "& ::-webkit-textfield-decoration-container": {
          direction: "rtl",
        },
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        paddingRight: "4px",
        flex: function ({ inputWidth }) {
          return `1 0 ${100 - inputWidth}%`;
        },
      },
      alignedRow: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 100%",
        minWidth: "0px",
        alignItems: "center"
      },
      automatic: {
        "& input": {
          transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          color: function () {
            return theme.palette.text.secondary;
          }
        }
      },
      dirty: {
         fontStyle: 'italic', // also stylize the label
        "& fieldset": {
    //         borderColor: 'red !important',
        },
        "& input": {
          fontStyle: 'italic', // also stylize the label
        }
      },
    }

    customizations = deepmerge(customizations, theme.overrides.SmartInputField || {});
    if (theme.overrides.SmartInputField?.automatic) {
        customizations.automatic = deepmerge(customizations.automatic, theme.overrides.SmartInputField.automatic);
        customizations.automatic["& input"] = deepmerge(customizations.automatic["& input"], theme.overrides.SmartInputField.automatic.input || theme.overrides.SmartInputField.automatic);
        customizations.automatic["& fieldset"] = deepmerge(customizations.automatic["& fieldset"], theme.overrides.SmartInputField.automatic.fieldset || theme.overrides.SmartInputField.automatic);
    }
    if (theme.overrides.SmartInputField?.dirty) {
        customizations.dirty = deepmerge(customizations.dirty, theme.overrides.SmartInputField.dirty || {});
        customizations.dirty["& input"] = deepmerge(customizations.dirty["& input"], theme.overrides.SmartInputField.dirty.input || theme.overrides.SmartInputField.dirty);
        customizations.dirty["& fieldset"] = deepmerge(customizations.dirty["& fieldset"], theme.overrides.SmartInputField.dirty.fieldset || theme.overrides.SmartInputField.dirty);
    }
    return customizations;
});

const InputPropertyEditor = (props) => {
  const {
    shape,
    property,
    type,
    label,
    showAuto = true,
    outlined = true,
    inputWidth = "40",
    onPreValueChange,
    onValueChange,
    adjustGetValue,
    adjustSetValue,
    inputCheck,
    maxNumber, // we should make this more general later
    minNumber, // we should make this more general later
    commitOnChange = false,
    ...other
  } = props;
  const classes = useStyles({ inputWidth: inputWidth });

  //     const [setDebounceInput, , pendingInput] = useDebounce(event => handleChange(event), 300);

  const [value, setValue, propertyValue] = useDAGMProperty(shape, property);
  const propertyDef = propertyValue.property;
  const [transientValue, setTransientValue] = useState(() => {
    return {
      value: null,
      property: propertyDef,
      startValue: null,
    };
  });

  const isDirty = !(transientValue.value === null || transientValue.value === transientValue.startValue);
  const [error, setError] = useState(() => {
    return {
      value: false,
      property: propertyDef,
    };
  });

  const clearTransientStates = function () {
    setTransientValue({
      value: null,
      property: propertyDef,
      startValue: null,
    });
    setError({
      value: false,
      property: propertyDef,
    });
  };

  if (propertyDef !== transientValue.property) {
    clearTransientStates();
  }

  const formatValue = function (value) {
    let retValue = value;
    if (adjustGetValue) retValue = adjustGetValue(retValue);
    if (retValue === undefined || retValue === null) return "";
    return retValue.toString();
  };

  let effectiveValue = "";
  if (transientValue.value !== null) effectiveValue = transientValue.value;
  else if (propertyValue.isExplicit) effectiveValue = formatValue(value);

  let placeholder = formatValue(propertyValue.defaultValue);

  const handleTextChange = (valueText) => {
    let valid = true;
    if (onPreValueChange) {
      try {
        let onValueChangeText = onPreValueChange(valueText);
        if (onValueChangeText !== undefined) valueText = onValueChangeText;
      } catch (errorRange) {
        valid = false;
      }
    }

    if (valid && onValueChange) {
      try {
        let onValueChangeText = onValueChange(valueText);
        if (onValueChangeText !== undefined) valueText = onValueChangeText;
      } catch (errorRange) {
        //console.warn(errorRange);
        valid = false;
      }
    }

    if (commitOnChange) {
      if (valid) commit(valueText);
      return;
    }

    setTransientValue({
      value: valueText,
      property: propertyDef,
      startValue: transientValue.startValue || formatValue(value),
    });

    if (valid) {
      setError({
        value: false,
        property: propertyDef,
      });
    }
  };

  /*
    Note - This doesn't work at all. Research how to do input masking of text in material-ui.
  */
  const handleInputChange = function (event) {
    let allowInput = true;
    if (inputCheck) allowInput = inputCheck(event);

    if (allowInput) handleTextChange(event.target.value);
  };

  const commit = function (commitValue) {
    let adjustedCommitValue = commitValue;
    if (commitValue === "") adjustedCommitValue = undefined;

    try {
      if (type === "number" && adjustedCommitValue !== undefined) {
        adjustedCommitValue = Number(adjustedCommitValue);
      }
      if (adjustSetValue)
        adjustedCommitValue = adjustSetValue(adjustedCommitValue);
      setValue(adjustedCommitValue);
      clearTransientStates();
    } catch (error) {
        // console.warn(error);
      setError({
        value: true,
        property: propertyDef,
      });
    }
  };

  const commitTransient = function () {
    if (transientValue.value === null || error.value) return;

    commit(transientValue.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode == 13) {
      // ENTER
      commitTransient();
    } else if (event.keyCode == 27) {
      // ESC
      clearTransientStates();
    }
  };

  const handleBlur = (event) => {
    commitTransient();
  };

  const [state, setState] = useState(initialState);

  const handleMenuOpen = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setState(initialState);
  };

  let inputLabelProps = {};
  if (showAuto) inputLabelProps.shrink = true;

  let layout;
  if (outlined || !label) {
    layout = <></>;
  } else {
    layout = (
      <Typography className={classes.heading} component="div">
        {label}
      </Typography>
    );
  }

  // console.log('value "' +  transientValue.value + '", startValue "' + transientValue.startValue + '"');
  return (
    <Box className={classes.root} {...other}>
      {layout}
      <TextField
        className={clsx(classes.alignedRow, classes.inputfield, {
          [classes.rightToLeft]: type === "number",
          [classes.automatic]: !propertyValue.isExplicit,
          [classes.dirty]: isDirty
        })}
        label={outlined ? label : undefined}
        error={error.value}
        name={property}
        size="small"
        // helperText=" "
        value={isDirty || effectiveValue !== "" ? effectiveValue : placeholder}
        placeholder={isDirty && effectiveValue === "" ? placeholder : undefined}
        onBlur={(e) => handleBlur(e)}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onContextMenu={handleMenuOpen}
        autoComplete="off"
        spellCheck="false"
        autoCorrect="off"
        disabled={!shape}

        variant="outlined"
        type={type || "text"}
        InputLabelProps={inputLabelProps}
        InputProps={{
          style: { flex: "1 1 100%" },
          inputProps: { min: minNumber, max: maxNumber, step: "1" },
        }}
      />
      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleClose}
        disableAutoFocusItem={true}
        anchorReference="anchorPosition"
        variant="menu"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClose}>Copy</MenuItem>
        <MenuItem onClick={handleClose}>Paste</MenuItem>
        <MenuItem onClick={handleClose}>Select All</MenuItem>
      </Menu>
    </Box>
  );
};

InputPropertyEditor.propTypes = {
  shape: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
  label: PropTypes.string,
  showAuto: PropTypes.bool,
  outlined: PropTypes.bool,
  inputWidth: PropTypes.number,
  onValueChange: PropTypes.func,
  commitOnChange: PropTypes.bool,
};

export default InputPropertyEditor;
