import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import HeaderContainer from "../HeaderContainer";
import NumberPropertyEditor from "../NumberPropertyEditor";
import BooleanPropertyEditor from "../BooleanPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

import ChartTickMarksPropertyEditor from "./ChartTickMarksPropertyEditor";
import ChartAxisPropertyEditor from "./ChartAxisPropertyEditor";
import ChartAxisDisplayUnitsPropertyEditor from "./ChartAxisDisplayUnitsPropertyEditor";
import ChartAxisCrossesPropertyEditor from "./ChartAxisCrossesPropertyEditor";
import ChartScaleTypePropertyEditor from "./ChartScaleTypePropertyEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartAxisValPropertyEditor(props) {
  const { shape, ...other } = props;
  const classes = useStyles();

  return (
    <ChartAxisPropertyEditor className={classes.root} {...other} shape={shape} axisType='val'>
      <HeaderContainer label="Bounds" type="secondaryHeading">
        <ExplicitPropertyEditorContainer>
          <NumberPropertyEditor label="Minimum" shape={shape} property="min" />
        </ExplicitPropertyEditorContainer>
        <ExplicitPropertyEditorContainer>
          <NumberPropertyEditor label="Maximum" shape={shape} property="max" />
        </ExplicitPropertyEditorContainer>
      </HeaderContainer>
      <HeaderContainer label="Units" type="secondaryHeading">
        <ExplicitPropertyEditorContainer>
          <NumberPropertyEditor
            label="Major"
            shape={shape}
            property="majorUnits"
            minNumber={0}
          />
        </ExplicitPropertyEditorContainer>
        <ExplicitPropertyEditorContainer>
          <NumberPropertyEditor
            label="Minor"
            shape={shape}
            property="minorUnits"
            minNumber={0}
          />
        </ExplicitPropertyEditorContainer>
      </HeaderContainer>
      <ChartAxisCrossesPropertyEditor
        shape={shape}
      ></ChartAxisCrossesPropertyEditor>
      <ChartAxisDisplayUnitsPropertyEditor
        shape={shape}
      ></ChartAxisDisplayUnitsPropertyEditor>
      <ChartScaleTypePropertyEditor shape={shape} />
      <ExplicitPropertyEditorContainer>
        <BooleanPropertyEditor
          label="Reverse"
          shape={shape}
          property="inverted"
        />
      </ExplicitPropertyEditorContainer>
      <HeaderContainer label="Tick Marks" type="secondaryHeading">
        <ChartTickMarksPropertyEditor
          shape={shape}
          label="Major"
          property="majorTickMarks"
        ></ChartTickMarksPropertyEditor>
        <ChartTickMarksPropertyEditor
          shape={shape}
          label="Minor"
          property="minorTickMarks"
        ></ChartTickMarksPropertyEditor>
      </HeaderContainer>
    </ChartAxisPropertyEditor>
  );
}

export default ChartAxisValPropertyEditor;
