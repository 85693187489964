import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import SelectPropertyEditor from "../SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
    alignedRow: {
      display: "flex",
      flexDirection: "row",
      flex: "1 1 100%",
      minWidth: "0px",
      alignItems: "center"
    },
  },
}));

function ChartAxisLabelPositionPropertyEditor(props) {
  const { shape, ...other } = props;
  const classes = useStyles();

  let values = [
    { key: "nextTo", description: "Next To Axis" },
    { key: "high", description: "High" },
    { key: "low", description: "Low" },
    { key: "none", description: "None" },
  ];

  return (
    <Box className={classes.root} {...other}>
      <ExplicitPropertyEditorContainer>
        <SelectPropertyEditor
          label="Label Position"
          values={values}
          shape={shape}
          property="labelPosition"
        ></SelectPropertyEditor>
      </ExplicitPropertyEditorContainer>
    </Box>
  );
}

export default ChartAxisLabelPositionPropertyEditor;
