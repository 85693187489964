import React from "react";

import useDAGMProperty from "./hooks/useDAGMProperty";

import { Dash, getPresetDashFromKey } from "@sheetxl/models";

import SelectPropertyEditor from "./SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";

function StrokeDashPropertyEditor(props) {
  const { shape, inputWidth, ...other } = props;

  const [valueCurrent] = useDAGMProperty(shape, "strokeDash");
  if (valueCurrent === null)
     valueCurrent = getPresetDashFromKey('solid');

  let values = []
  values.push(getPresetDashFromKey('solid'));
  values.push(getPresetDashFromKey('sysDot'));
  values.push(getPresetDashFromKey('sysDash'));
  //values.push(getPresetDashFromKey('dot'));
  values.push(getPresetDashFromKey('dash'));

  values.push(getPresetDashFromKey('dashDot'));
  values.push(getPresetDashFromKey('lgDash'));
  values.push(getPresetDashFromKey('lgDashDot'));
  values.push(getPresetDashFromKey('lgDashDotDot'));

  // values.push(getPresetDashFromKey('sysDashDot'));
  // values.push(getPresetDashFromKey('sysDashDotDot'));

  let found = false;
  values.map((type) => {
    if (type.key === valueCurrent.key) found = true;
  });
  if (!found) {
    values.push({ key: valueCurrent.key, description: valueCurrent.description });
  }

  const valueToKey = function (value) {
    if (!value)
      return "solid";
    return value.key;
  };

  const keyToValue = function (key) {
    return new Dash(key);
  };

  return (
    <ExplicitPropertyEditorContainer {...other}>
      <SelectPropertyEditor
        label="Dash Type"
        values={values}
        shape={shape}
        inputWidth={inputWidth}
        valueToKey={valueToKey}
        keyToValue={keyToValue}
        property="strokeDash"
      ></SelectPropertyEditor>
    </ExplicitPropertyEditorContainer>
  );
}

export default StrokeDashPropertyEditor;
