import { DAGM } from '@sheetxl/models';

import { ChartShape } from "@sheetxl/models";
import { SimpleMultiSheetSource } from "@sheetxl/models";
import { MultiSheetModel } from "@sheetxl/models";

import ConverterToOOXML from "./ConverterToOOXML";
import ConverterFromOOXML from "./ConverterFromOOXML";

export const toOOXMLFromJson = function(json: any = {}, sheetSource: any = {}, options: any = {}) {

  let simpleMultiSheetSource = new SimpleMultiSheetSource(sheetSource, options);
  let sheetModel = new MultiSheetModel(simpleMultiSheetSource);
  let chartShapeOptions = {
    range: null,
    sheet: sheetModel
  };
  if (options?.chartShape)
    Object.assign(chartShapeOptions, options.chartShape);

  let chartShape = DAGM.newFromJSON(
    json,
    () => {
      return new ChartShape(chartShapeOptions);
    }
  );

  let converter = new ConverterToOOXML();
  let ooxml = converter.toOOXML(chartShape, options);

  return ooxml;
}

// TODO - this is not implemented. Currently in Java
export const fromOOXMLToJson = function(xml: any = {}, options?: any) {
  let converter = new ConverterFromOOXML();
  let json = converter.fromOOXML(xml, options);
  return json;
}
