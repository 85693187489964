import React, { useMemo, useState, useEffect } from "react";

import { deepmerge } from '@material-ui/utils';

import useMediaQuery from "@material-ui/core/useMediaQuery";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import CssBaseline from "@material-ui/core/CssBaseline";

import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/core/styles";
import { createGenerateClassName } from "@material-ui/core/styles";

import ThemedScrollbarWrapper from "./ThemedScrollbarWrapper";

const generateClassName = createGenerateClassName({
  disableGlobal: false,
  seed: "exhibit",
});

function ThemeWrapper(props) {
  const {
    children,
    darkMode: darkModeOverride = null,
    customizations,
    onChange,
    globalCssReset = false,
    seedKey = 'exhibit',
    ...other
  } = props;

//  const classes = useStyles();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [isDarkMode, setDarkMode] = useState(darkModeOverride === prefersDarkMode ? false : darkModeOverride);
  // because useMediaQuery is async we have to useEffect
  useEffect(function() {
      setDarkMode(darkModeOverride !== null ? darkModeOverride : prefersDarkMode);
  }, [prefersDarkMode, darkModeOverride]);

  const theme = useMemo(
    () => {
      let themeEffective = {
        overrides: {
          MuiSelect: {
              // Name of the slot
              root: {
              },
              iconOutlined: {
                top : 'unset'
              }
          },
          SmartInputField: {
              automatic: {
        //         opacity: "0.42", // setting opacity sets the spinner too
                transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                color: function () {
//                  return 'green';
                    return theme.palette.text.secondary;
        //          return theme.palette.augmentColor('red')
        //          return alpha(theme.text.secondary, 1)
                  }
              },
              dirty: {
                fontStyle: "italic",
                color: function () {
                    // return 'red';
                    return theme.palette.text.primary;
                }
              }
          }
        }
      };
      if (customizations) {
        let customizationsAsArray;
        if (Array.isArray(customizations))
        customizationsAsArray = customizations;
        else
        customizationsAsArray = [customizations];
        for (let i=0; i<customizationsAsArray.length; i++) {
          themeEffective = deepmerge(themeEffective, customizationsAsArray[i]);
        }
      }

      themeEffective = createTheme({
        palette: {
          type: isDarkMode ? "dark" : "light",
        },
        typography: {
            htmlFontSize: parseFloat(getComputedStyle(document.documentElement).fontSize)
        }
      }, themeEffective);

      return themeEffective
    }, [isDarkMode, customizations],
  );

  useEffect(function() {
      if (onChange)
        onChange(theme);
  }, [theme]);

  return (
    <ThemeProvider theme={theme}>
      { globalCssReset ? <CssBaseline /> : <ScopedCssBaseline/> }
      <StylesProvider generateClassName={generateClassName}>
        <ThemedScrollbarWrapper>
          {children}
        </ThemedScrollbarWrapper>
       </StylesProvider>
    </ThemeProvider>
  );
}

export default ThemeWrapper;

