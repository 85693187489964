import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import useDAGMProperty from "../hooks/useDAGMProperty";

import NumberPropertyEditor from "../NumberPropertyEditor";
import SelectPropertyEditor from "../SelectPropertyEditor";
import FillStrokePropertyEditor from "../FillStrokePropertyEditor";

import ExpandingPropertyEditor from "../ExpandingPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

const useStyles = makeStyles(function (theme) {
  return {
    root: {},
  };
});

function ChartMarkerPropertyEditor(props) {
  const { shape, property, ...other } = props;

  const classes = useStyles();

  const [marker] = useDAGMProperty(shape, property);

  let values = [
    { key: "none", description: "None" },
    { key: "square", description: "Square" },
    { key: "diamond", description: "Diamond" },
    { key: "triangle", description: "Triangle" },
    { key: "x", description: "X" },
    { key: "star", description: "Star" },
    { key: "dot", description: "Dot" },
    { key: "dash", description: "Dash" },
    { key: "circle", description: "Circle" },
    { key: "plus", description: "Plus" },
  ];

  const [markerType] = useDAGMProperty(marker, "type");

  const allowExpand = markerType !== "none";
  const defaultExpanded = false;

  const onSelectHandler = function (event, newValue) {
    if (event) event.stopPropagation();
  };

  return (
    <ExpandingPropertyEditor
      {...other}
      label={
        <SelectPropertyEditor
          label="Markers"
          values={values}
          shape={marker}
          onSelect={onSelectHandler}
          property="type"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        ></SelectPropertyEditor>
      }
      allowExpand={allowExpand}
      defaultExpanded={defaultExpanded}
    >
      <ExplicitPropertyEditorContainer>
        <NumberPropertyEditor
          label="Size"
          shape={marker}
          property="size"
          maxNumber={72}
          minNumber={2}
        />
      </ExplicitPropertyEditorContainer>
      <FillStrokePropertyEditor shape={marker}></FillStrokePropertyEditor>
    </ExpandingPropertyEditor>
  );
}

export default ChartMarkerPropertyEditor;
