import React from "react";

import useDAGMProperty from "../hooks/useDAGMProperty";

import SelectPropertyEditor from "../SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

function ChartAxisPrefPropertyEditor(props) {
  const { shape, property, inputWidth, label = "Type", ...other } = props;

  let values = [
    { key: "date", description: "Date" },
    { key: "ord", description: "Text" }, // OOXML calls this ord
  ];

  const [valueCatAxisPref] = useDAGMProperty(shape, property);
  const [valueIsDateAxisEligable] = useDAGMProperty(
    valueCatAxisPref,
    "isDateAxisEligable"
  );

  return (
    <ExplicitPropertyEditorContainer {...other}>
      <SelectPropertyEditor
        label={label}
        values={values}
        shape={valueCatAxisPref}
        inputWidth={inputWidth}
        disabled={!valueIsDateAxisEligable}
        property="type"
      ></SelectPropertyEditor>
    </ExplicitPropertyEditorContainer>
  );
}

export default ChartAxisPrefPropertyEditor;
