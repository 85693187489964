import React from "react";
import { PropTypes } from "prop-types";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';

import LayersClear from "@material-ui/icons/LayersClear";

import useDAGMProperty from "./hooks/useDAGMProperty";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  indented: {
    marginLeft: "18px",
  },
  hidden: {
    visibility: "hidden",
  },
  alignedRow: {
    display: "flex",
    flexDirection: "row",
    flex: "1 1 100%",
    minWidth: "0px",
    alignItems: "center"
  },
  label: {},
  clearButton: {
    padding: "0 0",
    transform: "scale(.7)",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function ExplicitPropertyEditorContainer(props) {
  const {
    children,
    className,
    indented = true,
    shape: shapeProp,
    property: propertyProp,
    ...other
  } = props;

  const shape = shapeProp || children.props.shape;
  const property = propertyProp || children.props.property;

  const classes = useStyles();

  const [, setValue, propertyValue] = useDAGMProperty(shape, property);
  if (!propertyValue) {
    eval('useDAGMProperty(shape, property);');
    throw new Error("propertyValue not found for: " + property ? property : null);
  }

  const isExplicit = propertyValue.isExplicit;

  const clearExplicit = (event) => {
    setValue(undefined);
    event.stopPropagation();
  };

  return (
    <Box className={clsx(classes.root, classes.alignedRow)} {...other}>
      <Box
        className={clsx(classes.label, classes.alignedRow, {
          [classes.indented]: indented,
        })}
      >
        {children}
      </Box>
      <Tooltip title="Clear User Value" placement="right-end">
        <IconButton
          className={clsx(classes.clearButton, {
            [classes.hidden]: !isExplicit,
          })}
          tabIndex={-1}
          disableFocusRipple
          disableRipple
          onClick={(event) => {
            clearExplicit(event);
          }}
          color="primary"
          aria-label="clear auto"
        >
          <LayersClear />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

ExplicitPropertyEditorContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  indented: PropTypes.bool,
};

export default ExplicitPropertyEditorContainer;
