import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import useDAGMProperty from "./hooks/useDAGMProperty";

import BooleanPropertyEditor from "./BooleanPropertyEditor";
import ExpandingPropertyEditor from "./ExpandingPropertyEditor";

const useStyles = makeStyles((theme) => ({
  rootExpandingBool: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  labelExpandingBool : {
    cursor: 'pointer'
  }
}));

/**
 * The expanding boolean toggle is such a common scenario that
 * we wanted it to have some special functionality.
 * If the label is click when the boolean is false it will:
 * Both set the boolean to true AND expand
 * 
 */
function ExpandingBooleanPropertyEditor(props) {
  const {
    label,
    shape,
    property,
    children,
    ...other
  } = props;

  const classes = useStyles();

  const [value, setValue] = useDAGMProperty(shape, property);
  const [defaultExpanded, setDefaultExpanded] = useState(false);

  return (
    <ExpandingPropertyEditor
      {...other}
      className={classes.rootExpandingBool}
      label={
        <BooleanPropertyEditor
        className={classes.labelExpandingBool}
          label={label}
          onLabelClick={function() {
            if (!value) {
                setValue(true);
            }
            setDefaultExpanded(!value);
          }}
          onFocus={(event) => event.stopPropagation()}
          shape={shape}
          property={property}
          isHeader={true}
        />
      }
      allowExpand={value}
      defaultExpanded={defaultExpanded}
      deailsOverlap={0}
    >
      {children}
    </ExpandingPropertyEditor>
  );
}

export default ExpandingBooleanPropertyEditor;
