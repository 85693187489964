import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import useDAGMProperty from "../hooks/useDAGMProperty";

import SelectPropertyEditor from "../SelectPropertyEditor";
import NumberPropertyEditor from "../NumberPropertyEditor";

import ExpandingPropertyEditor from "../ExpandingPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

const useStyles = makeStyles((theme) => ({
  rootScaleType: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  alignedRow: {
    display: "flex",
    flexDirection: "row",
    flex: "1 1 100%",
    alignItems: "center",
    minWidth: "0px",
    alignItems: "center"
  },
}));

let values = [
  { key: "linear", description: "Linear" },
  { key: "log", description: "Logarithmic" },
];

function ChartScaleTypePropertyEditor(props) {
  const {
    shape,
    property = "scaleType",
    label = "Scale Type",
    ...other
  } = props;
  const classes = useStyles();

  const [value] = useDAGMProperty(shape, property);

  let allowExpand = value === "log";

  return (
    <ExpandingPropertyEditor
      {...other}
      className={classes.rootScaleType}
      label={
        <Box
          className={classes.alignedRow}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          shape={shape}
          property={property}
        >
          <SelectPropertyEditor
            label={label}
            values={values}
            shape={shape}
            property={property}
          ></SelectPropertyEditor>
        </Box>
      }
      allowExpand={allowExpand}
      deailsOverlap={8}
    >
      {value === "log" ? (
        <ExplicitPropertyEditorContainer>
          <NumberPropertyEditor
            label="Base"
            shape={shape}
            property="logBase"
            minNumber={2}
            maxNumber={1000}
          />
        </ExplicitPropertyEditorContainer>
      ) : (
        <></>
      )}
    </ExpandingPropertyEditor>
  );
}

export default ChartScaleTypePropertyEditor;
