import { useReducer, useEffect } from "react";

function resolveShapeProp(shape, propertyName, transformer, allowEmptyShape) {
  if (!shape && allowEmptyShape)
    return {
      value: null,
      propertyValue: null,
    };

  let retValue = {
    propertyValue: shape.getPropertyValue(propertyName),
  };

  if (retValue.propertyValue) retValue.value = retValue.propertyValue.value;

  if (retValue.value && transformer) {
    retValue.value = transformer(retValue.value);
  }

  return retValue;
}

function useDAGMProperty(shape, propertyName, transformer, allowEmptyShape) {
  if (!shape && !allowEmptyShape) {
    throw new Error(
      "shape and propertyName must be specified unless allowEmptyShape is true"
    );
  }

  function reducer(state, action) {
    //     if (Array.isArray(action)) {
    //         return {
    //             value: [...action]
    //         };
    //     } else if (action instanceof FetchedPropertyValue) {
    //         return new FetchedPropertyValue(action.source, action.value, action.property, action.isExplicit);
    //     } else if (typeof action === 'object') {
    //         return {
    //             value : Object.assign({}, action)// JSON.parse(JSON.stringify(action));
    //         }
    //     }
    return resolveShapeProp(shape, propertyName, transformer, allowEmptyShape);
//     return action;
  }

  const [state, dispatch] = useReducer(
    reducer,
    resolveShapeProp(shape, propertyName, transformer, allowEmptyShape)
  );

  const instanceRef = shape ? shape : {};

  useEffect(() => {
    let unwatcher;
    function handlePropertyChange(event) {
      dispatch(
        resolveShapeProp(shape, propertyName, transformer, allowEmptyShape)
      );
    }

    if (shape)
      unwatcher = shape.addPropertyListener(propertyName, handlePropertyChange);
    // Need to set the value here because this is triggered when shape or property changes
    dispatch(resolveShapeProp(shape, propertyName, transformer));

    return () => {
      if (unwatcher) {
        unwatcher();
      }
    };
  }, [instanceRef, propertyName]);

  function getPropertyValue() {
    if (!shape)
      throw new Error("can not call getPropertyValue with an empty shape");
    return shape.getPropertyValue(propertyName);
  }

  function setValue(newValue) {
    if (!shape) throw new Error("can not call setValue with an empty shape");
    // This mimics the useState options
    let propertyDef = getPropertyValue().property;
    if (typeof newValue === "function") {
      let prev = propertyDef.value;
      newValue = newValue(prev);
    }

    propertyDef.value = newValue;
    // Note - The update handler should also catch this but we want to decouple the events later
    dispatch(resolveShapeProp(shape, propertyName, transformer));
  }

  return [state.value, setValue, state.propertyValue];
}

export default useDAGMProperty;
