import React from "react";

import SelectPropertyEditor from "../SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

function ChartAxisCatPositionPropertyEditor(props) {
  const {
    shape,
    property,
    inputWidth,
    label = "Axis Position",
    ...other
  } = props;

  let values = [
    { key: "midCat", description: "On tick marks" }, // OOXML calls this ord
    { key: "between", description: "Between tick marks" },
  ];

  return (
    <ExplicitPropertyEditorContainer {...other}>
      <SelectPropertyEditor
        label={label}
        values={values}
        shape={shape}
        inputWidth={inputWidth}
        property="crossBetween"
      ></SelectPropertyEditor>
    </ExplicitPropertyEditorContainer>
  );
}

export default ChartAxisCatPositionPropertyEditor;
