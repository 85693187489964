import React from "react";
import { PropTypes } from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import { SSF } from "@sheetxl/models";

import InputPropertyEditor from "./InputPropertyEditor";
import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
    alignedRow: {
      display: "flex",
      flexDirection: "row",
      flex: "1 1 100%",
      minWidth: "0px",
      alignItems: "center"
    },
  },
}));

const LabelsFormatCodePropertyEditor = (props) => {
  const { shape, ...other } = props;
  const classes = useStyles();

  // const [valueFormat, setValue] = useDAGMProperty(shape, 'formatCode');

  // let valuesPrimary = LabelFormatStyles.lookupPrimaryFormatCodes();

  const onFormatCodeChange = function (valueText) {
    try {
      // TODO - turns out all format codes are valid
      let text = SSF.format(valueText, 0);
    } catch (error) {
      throw new Error("Invalid format code");
    }
    return retValue.toString();
  };

  return (
    <Box className={classes.root} {...other}>
      <ExplicitPropertyEditorContainer>
        <InputPropertyEditor
          shape={shape}
          property="formatCode"
          label="Code"
          onValueChange={onFormatCodeChange}
        />
      </ExplicitPropertyEditorContainer>
    </Box>
  );
};

LabelsFormatCodePropertyEditor.propTypes = { ...InputPropertyEditor.propTypes };
LabelsFormatCodePropertyEditor.propTypes.label = PropTypes.string;
delete LabelsFormatCodePropertyEditor.propTypes.property;

export default LabelsFormatCodePropertyEditor;
