import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import SelectPropertyEditor from "../SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
    alignedRow: {
      display: "flex",
      flexDirection: "row",
      flex: "1 1 100%",
      minWidth: "0px",
      alignItems: "center"
    },
  },
}));

function ChartLegendPositionPropertyEditor(props) {
  const { shape, ...other } = props;
  const classes = useStyles();

  // TODO - also supports custom
  let values = [
    { key: "t", description: "Top" },
    { key: "b", description: "Bottom" },
    { key: "l", description: "Left" },
    { key: "r", description: "Right" },
    { key: "tr", description: "Top Right" },
  ];

  return (
    <Box className={classes.root} {...other}>
      <ExplicitPropertyEditorContainer>
        <SelectPropertyEditor
          label="Position"
          values={values}
          shape={shape}
          property="position"
        ></SelectPropertyEditor>
      </ExplicitPropertyEditorContainer>
    </Box>
  );
}

export default ChartLegendPositionPropertyEditor;
