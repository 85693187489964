import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import DateFnsUtils from "@date-io/date-fns";

import { CommonUtils } from "@sheetxl/models";
import { DateUtils } from "@sheetxl/models";

import HeaderContainer from "../HeaderContainer";
import DatePropertyEditor from "../DatePropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

import DateUnitPropertyEditor from "../DateUnitPropertyEditor";

import ChartAxisCategoryPropertyEditor from "./ChartAxisCategoryPropertyEditor";
import ChartAxisCrossesPropertyEditor from "./ChartAxisCrossesPropertyEditor";

const useStyles = makeStyles((theme) => ({
  rootChartAxisDate: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

const displayFormat = "MM/dd/yyyy";
const DATE_UTILS = new DateFnsUtils();

const numberToDate = function (input) {
  if (!CommonUtils.isNumber(input)) return input;

  let asNumber = CommonUtils.asNumber(input);
  let dateTest = DateUtils.fromExcelDate(asNumber, undefined /*date1904*/);
  if (!dateTest instanceof Date || isNaN(dateTest)) return input;

  return DATE_UTILS.format(dateTest, displayFormat);
};

const dateToNumber = function (input) {
  let dateTest = DATE_UTILS.parse(input, displayFormat);
  if (!dateTest instanceof Date || isNaN(dateTest)) return input;
  return "" + DateUtils.toExcelDate(dateTest, undefined /*date1904*/);
};

function ChartAxisDatePropertyEditor(props) {
  const { shape, ...other } = props;
  const classes = useStyles();

  return (
    <ChartAxisCategoryPropertyEditor
      className={classes.rootChartAxisDate}
      {...other}
      axisType='date'
      shape={shape}
    >
      <HeaderContainer label="Bounds" type="secondaryHeading">
        <ExplicitPropertyEditorContainer>
          <DatePropertyEditor label="Minimum" shape={shape} property="min" />
        </ExplicitPropertyEditorContainer>
        <ExplicitPropertyEditorContainer>
          <DatePropertyEditor label="Maximum" shape={shape} property="max" />
        </ExplicitPropertyEditorContainer>
      </HeaderContainer>
      <HeaderContainer label="Units" type="secondaryHeading">
        <DateUnitPropertyEditor
          label="Major"
          shape={shape}
          property="majorUnitDates"
        />
        <DateUnitPropertyEditor
          label="Minor"
          shape={shape}
          property="minorUnitDates"
        />
        <DateUnitPropertyEditor
          label="Base"
          shape={shape}
          property="baseUnitDates"
          hasAmount={false}
        />
      </HeaderContainer>
      <ChartAxisCrossesPropertyEditor
        adjustGetValue={numberToDate}
        adjustSetValue={dateToNumber}
        shape={shape}
      ></ChartAxisCrossesPropertyEditor>
    </ChartAxisCategoryPropertyEditor>
  );
}

export default ChartAxisDatePropertyEditor;
