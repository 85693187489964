import React from "react";
import { PropTypes } from "prop-types";

import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import { CommonUtils } from "@sheetxl/models";

import { themedStyleWithScrollbar } from "./ThemedScrollbarWrapper";

import useDAGMProperty from "./hooks/useDAGMProperty";

const useStyles = makeStyles((theme) => ({
  rootAutocomplete: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "row",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(0),
    },
    marginTop: "8px",
    marginBottom: "8px",
  },
  popup: themedStyleWithScrollbar(theme, {
    // border: 'red solid 2px'
  }),
  autoInfo: {
    display: "flex",
    flex: "0 0 0%",
    flexDirection: "row",
    alignItems: "center",
  },
  autoInfoLabel: {
    opacity: "0.5",
    whiteSpace: 'nowrap'
  },
  clearIndicator: {
    display: "none",
  },
  popupIndicator: {
    background: "transparent !important",
  },
  popupText: {},
  popupMeta: {
     whiteSpace: 'nowrap'
  },
  Input: {
    padding: "0 0 !important",
  },
  input: {
    padding: "10.5px 14px !important",
  },
  selectValue: {
    flexDirection: "row",
    display: "flex",
  },
  automatic: theme.overrides.SmartInputField?.automatic || {
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    color: function () {
        return theme.palette.text.secondary;
      }
    }
}));

const AUTOMATIC_VALUE = {};

const filter = createFilterOptions();

// TODO - finish
// TODO - clearer way to detect custom than just string
// TODO - does not support all properties
function AutocompletePropertyEditor(props) {
  const {
    shape,
    property,
    values,
    keyToValue,
    valueToKey,
    showAuto = true,
    onSelect,
    label,
    outlined = true,
    inputWidth = "60",
    inputInfo,
    adjustGetValue,
    disabled,
    adjustSetValue,
    ...other
  } = props;
  const classes = useStyles({ inputWidth: inputWidth, outlined: outlined });

  const [value, setValue, propertyValue] = useDAGMProperty(shape, property);

  let defaultAsKey = propertyValue.defaultValue;
  if (adjustGetValue) defaultAsKey = adjustGetValue(defaultAsKey);
  if (valueToKey) defaultAsKey = valueToKey(defaultAsKey);
  const isExplicit = propertyValue.isExplicit;

  let valueAsKey = value;
  if (adjustGetValue) valueAsKey = adjustGetValue(valueAsKey);
  if (valueToKey) valueAsKey = valueToKey(valueAsKey);

  const selectedValues = [...values];

  const findIndex = function (key) {
    for (let i = 0; i < selectedValues.length; i++) {
      if (selectedValues[i].key === key) return i;
    }
    return -1;
  };

  const findIndexByDescrption = function (description) {
    for (let i = 0; i < selectedValues.length; i++) {
      if (
        (selectedValues[i].description === description &&
          !selectedValues[i].isAutomatic) ||
        (selectedValues[i].isAutomatic &&
          (description === "" || description === null))
      )
        return i;
    }
    return -1;
  };

  if (showAuto) {
    let autoChoice = selectedValues[findIndex(defaultAsKey)];
    let description = defaultAsKey;
    if (autoChoice) {
      description = autoChoice.description;
    } else {
      console.warn(
        "defaultValue '" + defaultAsKey + "' not in values set.",
        values
      );
    }
    let autoValue = {
      key: AUTOMATIC_VALUE,
      description: description,
      isAutomatic: true,
    };
    if (autoChoice && CommonUtils.isDefined(autoChoice.meta))
      autoValue.meta = autoChoice.meta;
    selectedValues.unshift(autoValue);
    if (!isExplicit) valueAsKey = AUTOMATIC_VALUE;
  }

  let selectedValue;
  let foundIndex = findIndex(valueAsKey);
  if (foundIndex !== -1) {
    selectedValue = selectedValues[foundIndex];
  } else {
    // add custom to list
    selectedValue = {
      key: valueAsKey,
      description: valueAsKey.toString(),
      isCustom: true,
      meta: "Custom",
    };
    selectedValues.unshift(selectedValue);
  }

  let placeholder = undefined;
  if (selectedValue.isAutomatic) {
    placeholder = selectedValue.description; //formatValue(value);
  }

  let effectiveInfo = inputInfo;
  if (!CommonUtils.isDefined(effectiveInfo) && selectedValue) {
    if (CommonUtils.isDefined(selectedValue.meta))
      effectiveInfo = selectedValue.meta;
  }

  const handleChange = function (event, newValue, reason) {
    let key = undefined;
    if (typeof newValue === "string") {
      if (reason === "blur") {
        let indexOfBlur = findIndexByDescrption(newValue);
        if (indexOfBlur !== -1) newValue = selectedValues[indexOfBlur].key;
      }
      key = newValue;
      if (key === "") key = undefined;
    } else if (newValue) {
      key = newValue.key;
      if (newValue.isAutomatic) key = undefined;
    } else key = undefined;

    if (adjustSetValue) key = adjustSetValue(key);
    try {
      setValue(key);
    } catch (error) {
      //console.warn(error);
      setValue(undefined);
    }
    if (onSelect) onSelect(event, newValue);
  };

  const renderValue = function (valueRender, isMenu) {
    return (
      <Box
        style={{ display: "flex", flex: "1 1 100%" }}
        className={clsx(classes.selectValue, {
          [classes.automatic]: valueRender.isAutomatic,
        })}
      >
        <Typography
          nowrap="true"
          style={{ display: "flex", flexShrink: 0 }}
          className={classes.popupText}
        >
          {valueRender.description}
        </Typography>
        {CommonUtils.isDefined(valueRender.meta) ? (
          <Box style={{ display: "flex", flex: "1 1 100%" }}>
            <Box style={{ flex: "1 1 100%" }}></Box>
            <Typography className={classes.popupMeta}>
              {valueRender.meta}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const filterOptions = function (options, params) {
    try {
      const filtered = filter(options, params);

      // Suggest the creation of a new value
      if (filtered.length === 0 && params.inputValue !== "") {
        filtered.push({
          key: params.inputValue,
          isCustom: true,
          description: params.inputValue,
          meta: "Custom",
        });
      }

      return filtered;
    } catch (error) {
      console.log(error);
    }
  };

  const getOptionLabel = function (option) {
    if (typeof option === "string") {
      return option;
    }

    if (option.isAutomatic) return "";
    return option.description;
  };

  let inputLabelProps = {};
  if (showAuto) inputLabelProps.shrink = true;

  return (
    <Autocomplete
      className={classes.rootAutocomplete}
      {...other}
      popupIcon={<ArrowDropDown />}
      // autoComplete={true}
      // autoHighlight={true}
      autoSelect={false}
      clearOnBlur
      handleHomeEndKeys
      selectOnFocus
      freeSolo={true}
      value={selectedValue}
      onChange={handleChange}
      forcePopupIcon={true}
      fullWidth={true}
      filterOptions={filterOptions}
      options={selectedValues}
      renderOption={renderValue}
      PaperComponent={({ children }) => (
        <Paper className={classes.popup}>{children}</Paper>
      )}
      getOptionLabel={getOptionLabel}
      classes={{
        clearIndicator: classes.clearIndicator,
        popupIndicator: classes.popupIndicator,
      }}
      renderInput={(params) => {
        params.InputProps.classes = {
          input: classes.input,
          root: classes.Input,
        };

        if (CommonUtils.isDefined(effectiveInfo)) {
          let originalAdornment = params.InputProps.endAdornment;
          params.InputProps.endAdornment = (
            <Box className={classes.autoInfo}>
              <Typography
                nowrap="true"
                style={{ display: "flex", flexShrink: 0 }}
                className={classes.autoInfoLabel}
              >
                {effectiveInfo}
              </Typography>
              <Box style={{ width: "calc(24px + 18px)" }}>
                {originalAdornment}
              </Box>
            </Box>
          );
        }
        return (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            placeholder={placeholder}
            InputProps={{ ...params.InputProps }}
            InputLabelProps={inputLabelProps}
          />
        );
      }}
    />
  );
}

AutocompletePropertyEditor.propTypes = {
  shape: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
  values: PropTypes.array,
  label: PropTypes.string,
  showAuto: PropTypes.bool,
};

export default AutocompletePropertyEditor;
