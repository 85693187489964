import { CommonUtils } from "@sheetxl/models";
import { SSF } from "@sheetxl/models";
import { AdjustableColor } from "@sheetxl/models";

import { ChartUtils } from "@sheetxl/models";
import { TextUtils } from "@sheetxl/models";
import { RangeUtils } from "@sheetxl/models";

import { NestedPropertyListener } from "@sheetxl/models";

import { ChartCatAxisShape } from "@sheetxl/models";
import { ChartOrdAxisShape } from "@sheetxl/models";
import { ChartDateAxisShape } from "@sheetxl/models";
import { ChartValAxisShape } from "@sheetxl/models";

import ChartContainer from "./elements/ChartContainer";
import AxisElement from "./elements/AxisElement";
import TitleElement from "./elements/TitleElement";
import LegendElement from "./elements/LegendElement";

const mappingMarker = {
  diamond: "diamond",
  square: "square",
  triangle: "triangle-up",
  x: "diagonal-cross",
  star: "star5",
  circle: "circle",
  plus: "cross",
  dot: "pentagon",
  dash: "line",
  none: "none",
  //             picture: // TODO - pictures
};

const mappingLegendIconType = {
  column: "square",
  bar: "square",
  line: "line",
  area: "square",
  pie: "square",
  scatter: "line",
  bubble: "line",
  waterfall: "square",
};

const mappingPattern = {
  pct5: "percent05",
  pct10: "percent105",
  pct20: "percent20",
  pct25: "percent25",
  pct30: "percent30",
  pct40: "percent40",
  pct50: "percent50",
  pct60: "percent60",
  pct70: "percent70",
  pct75: "percent75",
  pct80: "percent80",
  pct90: "percent90",

  horz: "horizontal",
  //
  vert: "vertical",
  ltHorz: "horizontal",
  ltVert: "vertical",
  //
  dkHorz: "horizontal",
  dkVert: "vertical",
  //
  narHorz: "horizontal",
  //
  narVert: "horizontal",
  //
  dashHorz: "dashed-horizontal",
  //
  dashVert: "dashed-vertical",
  ltDnDiag: "forward-diagonal",
  //
  ltUpDiag: "backward-diagonal",
  //
  dkDnDiag: "forward-diagonal",
  //
  dkUpDiag: "backward-diagonal",
  //
  dnDiag: "forward-diagonal",
  upDiag: "backward-diagonal",
  wdDnDiag: "forward-diagonal",
  wdUpDiag: "backward-diagonal",
  dashDnDiag: "dashed-forward-diagonal",
  dashUpDiag: "dashed-backward-diagonal",
  smCheck: "checkerboard",
  //
  lgCheck: "checkerboard",
  //
  smGrid: "grid",
  //
  lgGrid: "grid",
  //
  dotGrid: "percent25",
  smConfetti: "confetti",
  //
  lgConfetti: "confetti",
  //
  horzBrick: "horizontal-brick",
  diagBrick: "vertical-brick",
  //
  openDmnd: "solid-diamond",
  //
  dotDmnd: "solid-diamond",
  //
  sphere: "percent25",
  weave: "weave",
  divot: "divot",
  shingle: "percent25",
  wave: "percent25",
  trellis: "percent25",
  zigZag: "zigzag",
  solidDmnd: "solid-diamond",
  cross: "diagonal-cross",
  plaid: "plaid",
  diagCross: "diagonal-cross", // vertical-brick
};

const regExpColorFormat = /\[(.*?)\]/;

class ChartRenderer {
  constructor(anychart, acgraph, rendererContainer, options, resourceResolver) {
    this.anychart = anychart;
    this.anychart.DEVELOP = true;
    this.anychart.DEBUG_MEASUREMENTS = true;
    this.anychart.PERFORMANCE_MONITORING = true;
    if (process.env.NODE_ENV === "production") {
      this.anychart.DEVELOP = false;
      this.anychart.DEBUG_MEASUREMENTS = false;
      this.anychart.PERFORMANCE_MONITORING = false;
    }
        else console.log("NODE_ENV", process.env.NODE_ENV);
    this.acgraph = acgraph;
    this._rendererContainer = rendererContainer;
    this._options = Object.assign({
      initialAnimation : 700
    }, options || {});
    if (this._options.handlerRef) {
      this._options.handlerRef.current = this;
    }

    const _self = this;
    this.npl = new NestedPropertyListener({
      _thisRef: _self,
      beforeNotify: function () {
        if (_self._chartDrawn) _self._stage.suspend();
      },
      afterNotify: function () {
        // We also flush any pending series changes to reduce flicker.
        //_self.applyDirtySeries();
        if (_self._chartDrawn) _self._stage.resume();
      },
    });

    this.resourceResolver = resourceResolver;
    this._cacheMarkers = {};
    this._disposeListeners = [];
    if (!this.resourceResolver) {
      this.resourceResolver = {
        resolveRefernce: function () {
          return null;
        },
        loadedRefs: [],
      };
    }
  }

  applyPointStyles(
    data,
    dataOffset,
    dataCount,
    seriesInfo,
    seriesOffset,
    seriesCount,
    chartType
  ) {
    let points = seriesInfo.seriesInstance.renderedPoints;
    if (!points) {
    Object.assign(data, {
        fill: {color: 'rgb(68,114,196)' },
        stroke: { color: 'red', thickness: 0.75 }
    });
    return;
    }
    let point = points[dataOffset];
    let styleSeries = this.createStyleProperties(point);

    styleSeries.marker = {
      enabled: false,
    };
    if (!point) {
        console.log('no point');
        return;
    }

    let markerModel = seriesInfo.seriesInstance.markers;
    if (markerModel && markerModel.shown) {
      if (markerModel.renderedPoints && markerModel.renderedPoints[point.offset]) {
        markerModel = markerModel.renderedPoints[point.offset];
      }

      let markerType = markerModel.type;
      if (markerType !== "none") { // markers don't use shown. they use none
        styleSeries.marker = this.createStyleProperties(markerModel);
        styleSeries.marker.type = mappingMarker[markerType]; // TODO - use custom rendered markers
        let markerSize = Math.max(2, Math.min(72, markerModel.size)) * TextUtils.fontScale();
        styleSeries.marker.size = markerSize / 2; // anycharts uses the radius
      }
    }

    let labelModel = seriesInfo.seriesInstance.labels;
    if (labelModel && labelModel.shown) {
      let pointModel = labelModel.points.getAt(point.offset);
      let labelStyle = {};

      if (pointModel) { // todo - for testing } || point.offset === 2) {
        labelModel = pointModel || labelModel.renderedPoints[point.offset];

        this.applyTextShapeConfig(labelStyle, labelModel.text);
//           labelStyle.fill = {
//             color: 'red',
//             opacity: 1
//           }
      }
      // TODO - set this on the default labels?
      this.applyDataLabelPosition(chartType, data, labelModel.position, labelStyle);
      styleSeries.label = labelStyle;
    }

    styleSeries.normal = { marker: styleSeries.marker };
    styleSeries.hovered = { marker: styleSeries.marker };
    styleSeries.selected = { marker: styleSeries.marker };

    if (point.explosion) {
      styleSeries.state = "selected";
    }

    if (
      data &&
      (data.value < 0 ||
        (data.originalValue !== undefined && data.originalValue < 0))
    ) {
      // Because we apply styles after
      styleSeries.fill = this.createFillValue(point.fillNegative);
      Object.assign(styleSeries.stroke, this.createFillValue(point.strokeFillNegative));
    }

    if (Object.keys(styleSeries).length > 0) {
      styleSeries.normal = styleSeries;
    }

    Object.assign(data, styleSeries);
  }

  toAnyChartColor(color) {
    if (!color)
        return "none";

    let rgba = color.toRGBAColor();
    return {
      color : 'rgb(' + rgba.red + ',' + rgba.green + ',' + rgba.blue + ')',
      opacity: rgba.alpha
    };
  }

  applyShapeStyle(element, shape, minWidth, maxWidth) {
    if (!element || !shape) return;

    let style = this.createStyleProperties(shape, minWidth, maxWidth);
    if (element.stroke)
      element.stroke(
        style.stroke || {
          color: "none",
        }
      );
    if (element.fill) {
      element.fill(
        style.fill || {
          color: "none",
        }
      );
    }
  }

  hasPaint(properties) {
    if (properties.strokeFill && !properties.strokeFill.none) return true;
    if (properties.fill && !properties.fill.none) return true;

    return true;
  }

  toRGBAColor(color) {
    let rgba = color.toRGBAColor();
    return {
      color : 'rgba(' + rgba.red + ',' + rgba.green + ',' + rgba.blue + ',' + rgba.alpha + ')'
    };
  }

  toRGBAString(color) {
    if (!color) {
        console.warn('invalid color');
        return ('rgba(255, 0, 0, 1)');
    }

    let rgba = color.toRGBAColor();
    return 'rgba(' + rgba.red + ',' + rgba.green + ',' + rgba.blue + ',' + rgba.alpha + ')'
  }

  createStyleProperties(properties, minWidth, maxWidth) {
    if (!properties) return {};

    let retValue = {};
    let strokeFill = properties.strokeFill;
    if (strokeFill) {
      let stroke = this.createFillValue(strokeFill);
      stroke.thickness = properties.strokeWidth;
      if (
        minWidth !== undefined &&
        (stroke.thickness === undefined || stroke.thickness < minWidth)
      )
        stroke.thickness = minWidth;
      if (maxWidth !== undefined)
        stroke.thickness = Math.min(stroke.thickness, maxWidth);
      if (properties.strokeLineJoin)
        stroke.lineJoin = properties.strokeLineJoin;
      if (properties.strokeLineCap) stroke.lineCap = properties.strokeLineCap;
      if (properties.strokeDash) {
        stroke.dash = properties.strokeDash.toSVGPath(stroke.thickness);
      }
      retValue.stroke = stroke;
    }
    let fill = properties.fill;
    if (fill) retValue.fill = this.createFillValue(fill);

    return retValue;
  }

  // TODO - because of the resource lookups up this may need to be a promise
  createFillValue(fillProperties, colorConverter=this.toAnyChartColor) {
    if (!fillProperties || fillProperties.type === 'none') {
      return { color : 'none' };
    }
    if (fillProperties.type === 'solid') {
      return colorConverter(fillProperties.color);
    } else if (fillProperties.type === 'gradient') {
      let gradient = {};
      gradient.keys = [];
      for (let i = 0; i < fillProperties.stops.length; i++) {
        let key = colorConverter(fillProperties.stops[i].color);
        key.offset = fillProperties.stops[i].offset / 100.;
        gradient.keys.push(key);
      }
      // angle needs to be absolute so need to figure out what direction this going
      // and adjust
      if (fillProperties.gradientType === "linear") {
      if (fillProperties.angle !== undefined)
        gradient.angle = fillProperties.angle + 180;
      } else if (fillProperties.gradientType === "radial") {
        gradient.cx = 0.5;
        gradient.cy = 0.5;
        gradient.fx = 0.5;
        gradient.fy = 0.5;
        // TODO - fill to is here
        if (fillProperties.fillTo !== undefined) {
            // TODO - This needs to be implemented as AbstractShapeRenderable.createPaintDefs
        }
      }
      return gradient;
    } else if (fillProperties.type === 'pattern') {
      let pattern2 = this._stage.pattern(
        new this.acgraph.math.Rect(0, 0, 2500, 2500)
      );
//       let fillBackGroundRGBA = this.toRGBAString(fillProperties.background);
      pattern2.rect(0, 0, 2500, 2500)
        .fill(colorConverter(fillProperties.background))
        .stroke("none");
      // I am sure that we can customize thes to be the ppt pattern for but now...

      let anyChartPattern = mappingPattern[fillProperties.patternType];
      if (!anyChartPattern)
        anyChartPattern = mappingPattern[mappingPattern.pct25];
      // hatch fills don't behave like everything else in anychart.
      let rgbaForeground = fillProperties.foreground.toRGBAColor();
      let longStepHatch = this.acgraph.hatchFill(
        anyChartPattern,
        'rgb(' + rgbaForeground.red + ',' + rgbaForeground.green + ',' + rgbaForeground.blue + ') ' + rgbaForeground.alpha,
        1,
        8
      );
      pattern2.rect(0, 0, 2500, 2500).fill(longStepHatch).stroke("none");
      return pattern2;
    } else if (fillProperties.type === 'image') {
      let refResolved = fillProperties.ref;
      if (this.resourceResolver.loadedRefs[fillProperties.ref])
        refResolved = this.resourceResolver.loadedRefs[fillProperties.ref].resource.url;
      if (refResolved) {
        let mode = "stretch";
        if (fillProperties.tile) {
          mode = "tile";
          // TODO - we can read the actually sizes here
          //                 mode = this._stage.math.rect(
          //                     fillProperties.tile.bounds.x,
          //                     fillProperties.tile.bounds.y,
          //                     fillProperties.tile.bounds.w,
          //                     fillProperties.tile.bounds.h);
        }

        return {
          src: refResolved,
          mode: mode,
          opacity: fillProperties.alpha || 1,
        };
      } else {
        console.warn(
          "No image for resource '" + fillProperties.ref + "'."
        );
        return {
          color: "red",
        };
      }
    }
  }

  // called while rendering based on height of chart in pixels
  onRenderWithSizes(chartInfo) {
    if (!this._chartDrawn) return;
  }

  dispose() {
    this._chartDrawn = false;
    this._disposeListeners = CommonUtils.unListenAll(this._disposeListeners);

    if (this.resizeHandler) {
      if (process.env.NODE_ENV !== "production")
        console.log("disposing handler");
      this.resizeHandler.observer.unobserve(this.resizeHandler.observed);
      this.resizeHandler.observer.disconnect();
      this.resizeHandler = null;
    }

    if (this._stage) {
      this._stage.removeAllListeners();
      this._stage.dispose();
      this._stage = null;
    }

    if (this._dataSet) {
      this._dataSet.removeAllListeners();
      this._dataSet = null;
    }

    if (this._axesInfosByModel) {
      this._axesInfosByModel.clear();
      delete this._axesInfosByModel;
    }
    if (this._axesBySeries) {
      this._axesBySeries.clear();
      delete this._axesBySeries;
    }
    if (this._seriesInfosByElemement) {
      this._seriesInfosByElemement.clear();
      delete this._seriesInfosByElemement;
    }
    if (this._seriesInfosByModel) {
      this._seriesInfosByModel.clear();
      delete this._seriesInfosByModel;
    }
    if (this._dirtySeries) {
      this._dirtySeries.clear();
      delete this._dirtySeries;
    }
  }

  applyCredits(node) {
    let credits = "credits";
    let elementMaster = node.getElementsByClassName("anychart" + "-" + credits);
    let elementMasterLength = elementMaster.length;
    for (let i = elementMasterLength - 1; i >= 0; i--) {
      let elem = elementMaster[i];
      elem.parentNode.removeChild(elem);
    }
    elementMaster = node.getElementsByClassName("sheetxl" + "-" + credits);
    elementMasterLength = elementMaster.length;
    for (let i = elementMasterLength - 1; i >= 0; i--) {
      let elem = elementMaster[i];
      elem.parentNode.removeChild(elem);
    }
    let support = "ui-support";
    elementMaster = node.getElementsByClassName("anychart" + "-" + support);
    elementMasterLength = elementMaster.length;
    for (let i = elementMasterLength - 1; i >= 0; i--) {
      let elem = elementMaster[i];
      elem.classList.remove("anychart" + "-" + support);
      elem.classList.add("sheetxl" + "-" + support);
    }
  }

  createColoredFormat(formatCode, value) {
    let matches = regExpColorFormat.exec(formatCode);
    if (matches) {
      try {
        const colorStr = matches[1].charAt(0).toUpperCase() + matches[1].slice(1)
        let aj = new AdjustableColor(colorStr);
        return '<span style="color:' + aj.toRGBAColor().toString() + '">' + value + "</span>";
      } catch (error) {
        console.log('invalid color code', error);
        return value;
      }
    }
    return value;
  }

  formatValue(formatCode, value) {
    let retValue = value;
    try {
      //             if (formatCode === 'General') {
      //                 let digits = Math.floor(Math.log(Math.abs(CommonUtils.asNumber(value)))*Math.LOG10E);
      //                 if (digits < -7 || digits > 8)
      //                     formatCode = '0E+00';
      //             }
      retValue = SSF.format(formatCode, value);
    } catch (error) {
      console.warn("Can't format '" + value + "'.", error);
    }
    if (value < 0) {
      return this.createColoredFormat(formatCode, retValue);
    }
    return retValue;
  }

  lookupOrCreatePoint(seriesInstance, dataOffset) {
      let points = seriesInstance.renderedPoints;
      if (!points)
        return null;
      return points[dataOffset];
  }

  getPointFromParam(seriesInstance, params) {
    if (!seriesInstance || !params)
      return null;
    let dataOffset = params.dataOffset;
    if (dataOffset === undefined && params.getData)
      dataOffset = params.getData("dataOffset");
    if (dataOffset === undefined && params.index !== undefined) { // pie behaves differently
        dataOffset = params.index;
    }
    if (dataOffset === undefined)
      return null;

    return this.lookupOrCreatePoint(seriesInstance, dataOffset);
  }

  createDataLabelFormatter(seriesInfo) {
    return function (params, showVal) {
      let retValue = "";

      let chartModel = seriesInfo.seriesInstance.chartShape;

      let valueToFormat = 0;
      let labelModel = seriesInfo.seriesInstance.labels;
      let point = this.getPointFromParam(seriesInfo.seriesInstance, params);
      if (point) {
        valueToFormat = ChartUtils.getValueAsDisplayNumber(
          point.val,
          chartModel.dispNaAsBlank,
          chartModel.dispBlanksAs
        );
        if (labelModel && labelModel.shown && labelModel.renderedPoints[point.offset])
          labelModel = labelModel.renderedPoints[point.offset];
      }

      let formatCodeFull = labelModel.formatCode;
      let separator = labelModel.separator;
      if (separator === '\n')
        separator = '<br>';

      if (labelModel.showSerName) {
        try {
          retValue += seriesInfo.seriesInstance.title.text.simpleRun;
        } catch (error) {
          console.warn("Can't format '" + valueToFormat + "'.", error);
        }
      }

      if (labelModel.showCatName) {
        if (retValue) {
          retValue += separator;
        }
        try {
          retValue += (this.getFormatXValue(point, formatCodeFull) || "");
        } catch (error) {
          console.warn("Can't format '" + valueToFormat + "'.", error);
        }
      }

      if (labelModel.showVal || showVal) {
        let formatCode = formatCodeFull;
        if (retValue) {
          retValue += separator;
        }
        try {
          retValue += SSF.format(formatCode, valueToFormat);
        } catch (error) {
          console.warn("Can't format '" + valueToFormat + "'.", error);
        }
      }

      if (labelModel.showPercentage) {
        if (retValue) {
          retValue += separator;
        }
        let formatCode = formatCodeFull || "0%";
        try {
          retValue += SSF.format(
            formatCode,
            valueToFormat / (seriesInfo.scales.total || 1)
          );
        } catch (error) {
          console.warn("Can't format '" + valueToFormat + "'.", error);
        }
      }

      if (params.value < 0) {
        return this.createColoredFormat(formatCodeFull, retValue);
      }
      return retValue;
    }.bind(this);
  }

  createCustomMarker(type) {
    if (this._cacheMarkers[type]) return this._cacheMarkers[type];
    let getMarkerDrawer = this.anychart.utils.getMarkerDrawer;
    let cachedMarkers = this._cacheMarkers;
    // TODO - fix the cache. (can't cache in function as it already is called)
    return function (path, x, y, radius) {
      // TODO - add custom markers here
      let found = getMarkerDrawer(type);
      if (!found) {
        found = getMarkerDrawer("star5");
      }
      cachedMarkers[type] = found;
      return found(path, x, y, radius);
    };
  }

  createAxisFormatter(axis, level, multiLine = true) {
    let formatCode = axis.labels.formatCode;
    let format = function (value, formatSpecific) {
      return this.formatValue(formatSpecific || formatCode, value);
    }.bind(this);
    return function (params) {
      let valueToFormat = this.value;
      if (axis.limits.scaleType === "log") {
        valueToFormat = Math.pow(axis.limits.logBase, this.value);
      }

      if (axis instanceof ChartOrdAxisShape) {
        let asCells = [[{ v: null }]];
        if (axis.xValues)
          asCells = axis.xValues.asCells;

        try {
          valueToFormat = "";
          if (level === undefined || level === null) {
            if (this.tickValue % (axis.limits.labelInterval || 1) === 0) {
              for (let i = asCells.length - 1; i >= 0; i--) {
                let cell = asCells[i][this.tickValue];
                if (cell) {
                    valueToFormat += format(cell.v, cell.z);
                }
                if (i > 0) valueToFormat += multiLine ? "<br>" : " ";
              }
            }
          } else {
            // office culling for multi-level.
            // 1. Doesn't 'realigned labels just 'clears out fields at the interval.
            // 2. Also Office only clears level 0 if culling is auto but
            //    clears all of the rows if manual.
            let propLabelInterval = axis.getPropertyValue("labelInterval");
            let displayValueShown = true;
            if (propLabelInterval.value !== 1) {
              if (propLabelInterval.isExplicit || level === 0) {
                displayValueShown =
                  this.tickValue % (propLabelInterval.value || 1) === 0;
              }
            }
            if (displayValueShown) {
              let cell = asCells[asCells.length - level - 1][this.tickValue];
              if (cell) {
                valueToFormat = format(cell.v, cell.z);
              }
            }
          }
        } catch (error) {
          console.log("invalid xValues", asCells, error);
        }

        return valueToFormat;
      } else if (axis instanceof ChartDateAxisShape) {
        if (this.tickValue) {
          valueToFormat = this.tickValue;
        } else return;
      } else if (ChartUtils.allowDisplayUnit(axis)) {
        valueToFormat = ChartUtils.adjustForDisplayUnit(
          valueToFormat,
          axis.displayUnits
        );
      }

      return format(valueToFormat);
    };
  }

  createSeriesPainter(seriesInfo, type) {
    let renderer = this;
    return function (params) {
      let displayablePoint = null;
      let style = {};

      if (seriesInfo.displayablePoints)
        displayablePoint = seriesInfo.displayablePoints[this.index];

      if (displayablePoint) style = displayablePoint;
      else {
        style = renderer.createStyleProperties(seriesInfo.seriesInstance);
      }

      let retValue = style[type] || "red";
      return retValue;
    };
  }

  setupAxis(chartInfo, axis, offset, axisInfo, listenerRef) {
    this.npl.listen(
      axis,
      ["labelMultiLevel"],
      function (event, listenerRef) {
        axisInfo.axisElement = new AxisElement(chartInfo.chartContainer, axis);
        let axisElement = axisInfo.axisElement;

        axisInfo.axisElement.shown(axis.shown);
        listenerRef.addDisposable(function () {
          axisElement.remove();
          delete axisInfo.axisElement;
        });

        if (axis instanceof ChartValAxisShape) {
          this.npl.listen(
            axis,
            ["displayUnitsLabel.*", "displayUnits"],
            function (event, listenerRef) {
              if (axis.displayUnitsLabel.shown) {
                let titleElement = new TitleElement(chartInfo.chartContainer);
                axisInfo.displayLabelElement = titleElement;

                titleElement.alignment = "right";
                titleElement.orientation = axis.orientation;
                titleElement.rotation = axis.displayUnitsLabel.rotation;
                this.applyLabel(
                  chartInfo,
                  titleElement.elementTitle(),
                  axis.displayUnitsLabel
                );
                this.setupManuaMoveListener(axis.displayUnitsLabel, titleElement, listenerRef);
              }
              this.invalidateBounds(chartInfo);
              listenerRef.addDisposable(function () {
                if (axisInfo.displayLabelElement) {
                  axisInfo.displayLabelElement.remove();
                  delete axisInfo.displayLabelElement;
                }
              });
            },
            listenerRef
          );
        }

        this.npl.listen(axis, ["xValues.*", "labelInterval"], function (event) {
          // Note - This should not be needed
          if (!axisInfo.axisElement) return;
          axisInfo.axisElement.formatFactory(
            this.createAxisFormatter.bind(this)
          );
          axisInfo.formatter = this.createAxisFormatter(
            axis,
            null,
            false /*multiline*/
          );
          // axisInfo.axisElement.applyTickInfo(axis.limits);
          // Note - This is poorly implemented. Chaining the xValues or the format may cause word wrapping of logic
          this.invalidateBounds(chartInfo);
        });

        this.npl.listen(
          axis,
          "gridLinesMajor.*",
          function (event) {
            let shown = axis.gridLinesMajor.shown;
            if (shown) {
              chartInfo.gridElement[axis.direction + "Grid"]().axis(
                axisElement.ticksMajorElement
              );
              this.applyShapeStyle(
                chartInfo.gridElement[axis.direction + "Grid"](),
                axis.gridLinesMajor,
                0.75
              );
            }
            chartInfo.gridElement[axis.direction + "Grid"]().enabled(shown);
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          "gridLinesMinor.*",
          function (event) {
            let shown = axis.gridLinesMinor.shown;
            if (shown) {
              chartInfo.gridElement[axis.direction + "MinorGrid"]().axis(
                axisElement.ticksMinorElement
              );
              this.applyShapeStyle(
                chartInfo.gridElement[axis.direction + "MinorGrid"](),
                axis.gridLinesMinor,
                0.75
              );
            }
            chartInfo.gridElement[axis.direction + "MinorGrid"]().enabled(
              shown
            );
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          "title.*",
          function (event, listenerRef) {
            if (axis.title.shown) {
              let titleElement = new TitleElement(chartInfo.chartContainer);
              axisInfo.titleElement = titleElement;

              titleElement.overlay = axis.title.overlay;
              titleElement.rotation = axis.title.rotation;
              titleElement.orientation = axis.orientation;
              this.applyLabel(
                chartInfo,
                titleElement.elementTitle(),
                axis.title
              );
              this.setupManuaMoveListener(axis.title, titleElement, listenerRef);
            }
            this.invalidateBounds(chartInfo);

            listenerRef.addDisposable(function () {
              if (axisInfo.titleElement) {
                axisInfo.titleElement.remove();
                delete axisInfo.titleElement;
              }
            });
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          ["orientation"],
          function (event) {
            axisElement.orientation(axis.orientation);
            if (axisInfo.displayLabelElement)
              axisInfo.displayLabelElement.orientation = axis.orientation;
            if (axisInfo.titleElement)
              axisInfo.titleElement.orientation = axis.orientation;
            this.invalidateBounds(chartInfo);
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          ["labelOffset"],
          function (event) {
            axisElement.labelOffset(axis.labelOffset);
            this.invalidateBounds(chartInfo);
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          ["labelPosition"],
          function (event) {
            axisElement.labelPosition(axis.labelPosition);
            this.invalidateBounds(chartInfo);
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          ["labelAlign"],
          function (event) {
            axisElement.labelAlign(axis.labelAlign);
            this.invalidateBounds(chartInfo);
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          ["shown"],
          function (event) {
            axisElement.shown(axis.shown);
            this.invalidateBounds(chartInfo);
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          [
            "fill",
            "strokeFill",
            "strokeWidth",
            "strokeLineJoin",
            "strokeLineCap",
            "strokeDash",
          ],
          function (event) {
            axisElement.applyStyles(this.applyShapeStyle.bind(this));
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          ["majorTickMarks"],
          function (event) {
            axisElement.majorTicks(axis.majorTickMarks);
          },
          listenerRef
        );
        this.npl.listen(
          axis,
          ["minorTickMarks"],
          function (event) {
            axisElement.minorTicks(axis.minorTickMarks);
          },
          listenerRef
        );

        if (axis instanceof ChartCatAxisShape) {
          this.npl.listen(
            axis,
            ["crossBetween"],
            function (event, listenerRef) {
              axisElement.crossBetween(axis.crossBetween);
            },
            listenerRef
          );
        }

        this.npl.listen(
          axis,
          ["inverted"],
          function (event, listenerRef) {
            axisElement.inverted(axis.inverted);
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          ["crossAx.crosses"],
          function (event, listenerRef) {
              this.markSeriesOnAxisDirty(chartInfo, axis);
              this.invalidateBounds(chartInfo);
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          ["labels.*"],
          function (event) {
            this._stage.suspend();
            axisElement.applyLabels(
              chartInfo,
              this.applyLabel.bind(this),
              this.applyShapeStyle.bind(this)
            );
            this._stage.resume();
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          ["labels.formatCode", "displayUnits"],
          function (event) {
            axisElement.formatFactory(this.createAxisFormatter.bind(this));
            axisInfo.formatter = this.createAxisFormatter(
              axis,
              null,
              false /*multiline*/
            );
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          [
            "labels.shown",
            "labels.formatCode",
            "labels.rotation",
            "labels.text.font",
            "labels.text.size",
            "labels.text.weight",
            "labels.text.italic",
            // TODO - add others not support ATM
          ],
          function (event) {
            this.invalidateBounds(chartInfo);
          },
          listenerRef
        );

        this.npl.listen(
          axis,
          ["limits"],
          function (event, listenerRef) {
            // Quick and dirty way to avoid redrawing when the limits haven't changed. Ideally the modelling
            // framework would support deep object comparisons.
            let axisTest = { ...axis.limits };
            let buckets = axisTest.buckets;
            delete axisTest.buckets;
            // if the limits have changed.
            let invalidateAllSeries = false;
            let axisLimitsAsString = JSON.stringify(axisTest);
            let axisBucketsAsString = JSON.stringify(buckets);

            if (axisLimitsAsString !== axisInfo.previousLimit) {
              this.invalidateBounds(chartInfo);
              invalidateAllSeries = true;
            }
            axisInfo.previousLimit = axisLimitsAsString;

            if (!invalidateAllSeries) {
              let grouping = axis.chartType.grouping;
              if (grouping === "stacked" || grouping === "percentStacked") {
                if (axisBucketsAsString !== axisInfo.previousBuckets) {
                  invalidateAllSeries = true;
                }
              }
            }
            axisInfo.previousBuckets = axisBucketsAsString;

            if (invalidateAllSeries) {
              this.markSeriesOnAxisDirty(chartInfo, axis);
//               this._stage.suspend();
//               let seriesList = axis.series;
//               for (let i = 0; i < seriesList.length; i++) {
//                 let seriesInstance = seriesList.getAt(i);
//                 this.markSeriesDirty(chartInfo, seriesInstance);
//               }
//               this._stage.resume();
            }
          },
          listenerRef
        );
      },
      listenerRef
    );
  }

  applyTextShapeConfig(textElement, textShape) {
    if (!textShape)
      return;
    let textFill = textShape.fill;
    if (textFill && textFill.type === 'solid') {
      let rgbaColor = textFill.color.toRGBAColor();
      textElement.fontColor = 'rgb(' + rgbaColor.red + ',' + rgbaColor.green + ',' + rgbaColor.blue + ')';
      textElement.fontOpacity = rgbaColor.alpha;
    } else if (textFill && textFill.type === 'none') {
      textElement.fontColor = 'rgb(0,0,0)';
      textElement.fontOpacity = 0;
    }

    if (CommonUtils.isDefined(textShape.font))
      textElement.fontFamily = textShape.font.name || 'Calibri';
    if (CommonUtils.isDefined(textShape.size)) {
      textElement.fontSize = textShape.size * TextUtils.fontScale();
    }

    //         if (textShape.vAlign)
    //             label.vAlign(textShape.vAlign);
    if (CommonUtils.isDefined(textShape.weight))
      textElement.fontWeight = textShape.weight;
    if (CommonUtils.isDefined(textShape.italic) && textShape.italic)
      textElement.fontStyle = 'italic';
    else
        textElement.fontStyle = 'normal';

    if (textElement.text && CommonUtils.isDefined(textShape.simpleRun)) {
      textElement.text = textShape.simpleRun;
    } else if (textElement.text) {
      textElement.text = "";
    }
  }

  applyLegendItem(modelInstance, chartType, modelText) {
    let legendItem = {};
    legendItem.iconType = mappingLegendIconType[chartType];
    legendItem.vAlign = "middle";

    let styleIcon = this.createStyleProperties(modelInstance, 0.75, 5);
    legendItem.iconStroke = styleIcon.stroke || {
      color: "none",
    };
    legendItem.iconFill = styleIcon.fill || {
      color: "none",
    };

    this.applyTextShapeConfig(legendItem, modelText);

    if (modelInstance.markers && modelInstance.markers.shown && modelInstance.markers.type !== "none") {
      let mapping = mappingMarker[modelInstance.markers.type];
      legendItem.iconMarkerType = mapping;

      let iconSeriesStyle = this.createStyleProperties(
        modelInstance,
        0.75,
        5
      );

      let iconMarkerStyle = this.createStyleProperties(
        modelInstance.markers,
        0.75,
        5
      );
      legendItem.iconMarkerStroke = iconMarkerStyle.stroke || iconSeriesStyle.stroke || {
        color: "none",
      };
      legendItem.iconMarkerFill = iconMarkerStyle.fill || (legendItem.iconType === 'line' ? iconSeriesStyle.stroke : iconSeriesStyle.fill) || {
        color: "none",
      };
    } else {
      legendItem.iconMarkerStroke = {
        color: "none",
      };
      legendItem.iconMarkerFill = {
        color: "none",
      };
    }
    return legendItem;
  }

  applyLegendItemMaxHeight(legendItems, maxHeight) {
    for (let i = 0; i < legendItems.length; i++) {
      if (legendItems[i].iconType === "line") {
        if (
          !legendItems[i].iconStroke ||
          legendItems[i].iconStroke.color === "none"
        )
          legendItems[i].iconStroke = "rgba(0,0,0,0)";
        legendItems[i].iconSize = maxHeight * 2.2;
      } else legendItems[i].iconSize = maxHeight * 0.6;
      legendItems[i].iconTextSpacing = maxHeight * 0.2;
      //legendItems[i].lineHeight(maxHeight);
    }
  }

  // TODO - rationalize this and applyLegendItems
  applySingleLegendItem(
    legendElement,
    chartModel,
    legendForSingleSeries,
    offset
  ) {
    const seriesList = chartModel.series;

    let maxHeight = 0;
    let legendItems = [];

    if (legendForSingleSeries) {
      let seriesInstance = seriesList.getAt(0);
      if (!this._seriesInfosByModel.has(seriesInstance)) return;

      let pointsInstance = seriesInstance.renderedPoints;
      let pointInstance = pointsInstance[offset];
      let textInstance = seriesInstance.title.text;
      let legendItem = this.applyLegendItem(
        pointInstance,
        seriesInstance.chartType.type,
        textInstance
      );
      legendItems.push(legendItem);

      maxHeight = Math.max(textInstance.size, maxHeight);
      legendItem.text = this.getFormatXValue(pointInstance);
    } else {
      let seriesInstance = seriesList.getAt(offset);
      let seriesInfo = this._seriesInfosByModel.get(seriesInstance);
      if (!seriesInfo) return;

      if (seriesInstance.title.shown) {
        let textInstance = seriesInstance.title.text;

        let legendItem = this.applyLegendItem(
          seriesInstance,
          seriesInstance.chartType.type,
          textInstance
        );
        legendItems.push(legendItem);

        maxHeight = Math.max(textInstance.size, maxHeight);
        legendItem.text = textInstance.simpleRun;
      }
    }

    this.applyLegendItemMaxHeight(legendItems, maxHeight);
    legendElement.items(legendItems);
  }

  getFormatXValue(pointInstance, formatCodeFull) {
    let retValue = pointInstance.x;
    try {
      let z = 'General';
      if (pointInstance.pointX === null) {
        if (pointInstance._shapeOptions.getDefaultAxisLabel) {
          let pointPlotting = pointInstance.series.pointPlotting;
          let pointX = pointPlotting.pointValueRanges[pointInstance.x];
          if (pointX) {
            // hack - because we don't have a clear valrange and valRange persistors not convert
            let valRange = pointInstance.series.valRange;
            if (Array.isArray(valRange)) {
              valRange = valRange[0];
            }
            let defaultedLabel = pointInstance._shapeOptions.getDefaultAxisLabel(pointX, valRange, pointInstance.x, pointPlotting.numOfPoints);
            if (defaultedLabel !== null && defaultedLabel !== undefined)
              return defaultedLabel;
          }
        }
        // just an offset. TODO - also check defaultDataLabel
        return SSF.format(formatCodeFull || z, retValue + 1);
      }
      let asCells = pointInstance.pointX.asCells;
      if (asCells && asCells.length > 0 && asCells[0].length > 0) {
        let cell =  asCells[0][0];
        if (cell && cell.z) {
            z = cell.z;
        }
      }
      retValue = SSF.format(z, retValue);
    } catch (error) {
      console.warn("Can't format '" + pointInstance.x + "'.", error);
    }
    return retValue;
  }

  applyLegendItems(legendElement, chartModel, legendForSingleSeries) {
    if (!chartModel.legend.shown) return;

    // leaking listener
    if (!this._seriesInfosByModel) {
      if (process.env.NODE_ENV !== "production")
        console.log("leaking listener");
      return;
    }

    const seriesList = chartModel.series;

    let maxHeight = 0;
    let legendItems = [];

    if (legendForSingleSeries) {
      let seriesInstance = seriesList.getAt(0);
      if (!this._seriesInfosByModel.has(seriesInstance)) return;

      let pointsInstance = seriesInstance.renderedPoints;
      if (!pointsInstance) return;
      for (let i = 0; pointsInstance && i < pointsInstance.length; i++) {
        let pointInstance = pointsInstance[i];
        let textInstance = seriesInstance.title.text;
        let legendItem = this.applyLegendItem(
          pointInstance,
          seriesInstance.chartType.type,
          textInstance
        );
        legendItems.push(legendItem);

        maxHeight = Math.max(textInstance.size, maxHeight);
        legendItem.text = this.getFormatXValue(pointInstance);
      }
    } else {
      for (let i = 0; i < seriesList.length; i++) {
        let seriesInstance = seriesList.getAt(i);

        if (seriesInstance.title.shown) {
          let textInstance = seriesInstance.title.text;

          let legendItem = this.applyLegendItem(
            seriesInstance,
            seriesInstance.chartType.type,
            textInstance
          );
          legendItems.push(legendItem);

          maxHeight = Math.max(textInstance.size, maxHeight);
          legendItem.text = textInstance.simpleRun;
        }
      }
    }

    let isReversed = false;
    let chartTypeLength = chartModel.types.length;
    for (let i = 0; !isReversed && i < chartTypeLength; i++) {
      isReversed = ChartUtils.isVerticalType(chartModel.types.getAt(i).type);
    }
    if (isReversed) legendItems = legendItems.reverse();

    this.applyLegendItemMaxHeight(legendItems, maxHeight);
    legendElement.items(legendItems);
  }

  setupSeriesInfo(chartInfo, seriesInfo, listenerRef) {
    this._seriesInfosByElemement.set(seriesInfo.seriesElement.id(), seriesInfo);
    this._seriesInfosByModel.set(seriesInfo.seriesInstance, seriesInfo);

    if (ChartUtils.supportsMarkers(seriesInfo.seriesInstance.chartType.type))
      // We need to set markers true for any time that supports markers
      seriesInfo.seriesElement.markers(true);
    //          seriesInfo.seriesElement.fill(this.createSeriesPainter(seriesInfo, 'fill'));
    //          seriesInfo.seriesElement.stroke(this.createSeriesPainter(seriesInfo, 'stroke'));

    this.npl.listen(
      seriesInfo.seriesInstance,
      [
        "fill",
        "fillNegative",
        "strokeFill",
        "strokeWidth",
        "strokeLineJoin",
        "strokeLineCap",
        "strokeDash",
        "markers.*",
      ],
      function (event) {
        this.markSeriesDirty(chartInfo, seriesInfo.seriesInstance);
      },
      listenerRef
    );

    /*
                let cherryDrawer = function(values) {

                    // if missing - skip drawing
                    if (this.missing) {
                        return;
                    }

                    // get cherry size or set default
                    let cherry = this.series.meta('cherry') || (this.categoryWidth / 15);
                    // get stem thickness or set default
                    let stem = this.series.meta('stem') || (this.categoryWidth / 50);

                    // get shapes group
                    let shapes = this.shapes || this.getShapesGroup(this.pointState);
                    // calculate the left value of the x-axis
                    let leftX = this.x - stem / 2;
                    // calculate the right value of the x-axis
                    let rightX = leftX + stem / 2;

//                     shapes['path']
//                         // resets all 'path' operations
//                         .clear()
//                         // draw bulb
//                         .moveTo(leftX, this.low - cherry)
//                         .lineTo(leftX, this.high)
//                         .lineTo(rightX, this.high)
//                         .lineTo(rightX, this.low - cherry)
//                         .arcToByEndPoint(leftX, this.low - cherry, cherry, cherry, true, true)
//                         // close by connecting the last point with the first
//                         .close();
                }

//                 if (series.rendering) {
//                     let test = series.rendering().point();
//                     series.rendering().point(cherryDrawer);
//                 }
        */

    seriesInfo.seriesElement.labels().useHtml(true);
    this.npl.listen(
      seriesInfo.seriesInstance,
      "labels.*",
      function (event) {
        let modelLabels = seriesInfo.seriesInstance.labels;
        let elementLabels = seriesInfo.seriesElement.labels();
        this.applyLabel(
          chartInfo,
          elementLabels,
          modelLabels
        );
        this.applyShapeStyle(
          elementLabels.background(),
          modelLabels
        );

        seriesInfo.formatter = this.createDataLabelFormatter(seriesInfo);
        elementLabels.format(seriesInfo.formatter);
        elementLabels.hAlign('center'); //.vAlign('center')
        elementLabels.zIndex(1000);
        // Note - This is a hack. Anychart single series don't honor the label property on the datapoints
        if (ChartUtils.isSingleSeries(seriesInfo.seriesInstance.chartType.type)) {
            if (modelLabels.position === 'outEnd')
                chartInfo.chartElement.labels().position('outside');
            else
                chartInfo.chartElement.labels().position('inside');
        }

        // we don't want a flicker of labels so we enable on callback
//      elementLabels..enabled(modelLabels.shown);
        this.markSeriesDirty(chartInfo, seriesInfo.seriesInstance, function() {
            elementLabels.enabled(modelLabels.shown)
        });
      },
      listenerRef
    );

    this.npl.listen(
      seriesInfo.seriesInstance,
      "points.*",
      function (event) {
        this.markSeriesDirty(chartInfo, seriesInfo.seriesInstance);
      },
      listenerRef
    );
  }

  setupSeries(chartInfo, seriesInstance, listenerRef) {
    if (ChartUtils.isSingleSeries(seriesInstance.chartType.type)) {
      if (seriesInstance.offset > 0) return;

      chartInfo.chartElement.overlapMode("allow-overlap");

      let dataSet = this.anychart.data.set();
      let seriesElement = chartInfo.chartElement.data(dataSet);
      this.setupSeriesInfo(
        chartInfo,
        {
          seriesInstance: seriesInstance,
          seriesElement: seriesElement,
          dataSet: dataSet,
        },
        listenerRef
      );
      return;
    }

    let seriesElement;
    let effectiveChartType = seriesInstance.chartType.type;
    if (!effectiveChartType) {
      console.warn("no effectiveChartType", effectiveChartType);
      return;
    }

    // Note - we have to wrap again because smooth is not any attribute but a seriesType
    this.npl.listen(
      seriesInstance,
      "smooth",
      function (event) {
        let previousSeriesInfo = this._seriesInfosByModel.get(seriesInstance);

        if (effectiveChartType === "column") {
          seriesElement = chartInfo.chartElement.rangeColumn();
        } else if (effectiveChartType === "bar") {
          seriesElement = chartInfo.chartElement.rangeBar();
        } else if (effectiveChartType === "line" && seriesInstance.smooth) {
          seriesElement = chartInfo.chartElement.spline();
        } else if (effectiveChartType === "area") {
          if (seriesInstance.smooth) {
            seriesElement = chartInfo.chartElement.splineArea(); //rangeSplineArea();
          } else {
            seriesElement = chartInfo.chartElement.area(); //rangeArea();
          }
        } else if (effectiveChartType === "scatter") {
          seriesElement = chartInfo.chartElement.line();
        } else if (effectiveChartType === "area3d") {
          // 3d note currently supported
          seriesElement = chartInfo.chartElement.area();
          chartInfo.chartElement.zAspect("50%");
        } else {
          if (!chartInfo.chartElement[effectiveChartType])
            throw new Error("unsupported chart type " + effectiveChartType);
          seriesElement = chartInfo.chartElement[effectiveChartType]();
        }

        // If there was a previousSeries then we need to remove the old one.
        if (previousSeriesInfo) {
          chartInfo.chartElement.removeSeries(
            previousSeriesInfo.seriesElement.id()
          );
          // TODO - this screws up the series order.  Another approach (heavy is to just rebuild all series.)
        }

        // we wrap all series in a dataset to enable eventing.
        let dataSet = this.anychart.data.set();
        seriesElement.data(dataSet);

        // TODO - What about line3D. Test all chart types here.
        /*
            if (effectiveChartType === 'line3D') {
                chartInfo.chartElement.zDistribution(true);
            } else if (!isSupportedChartType(effectiveChartType)) {
                console.log('unsupported chart type ' + effectiveChartType);
                series = chartInfo.chartElement['column'](chartType.points);
            }
            */

        let seriesInfo = {
          seriesInstance: seriesInstance,
          seriesElement: seriesElement,
          dataSet: dataSet,
        };
        this.setupSeriesInfo(chartInfo, seriesInfo, listenerRef);

        this.markSeriesDirty(chartInfo, seriesInstance);
      },
      listenerRef
    );
  }

  setupChartType(chartInfo, typeInstance, listenerRef) {
    const chartModel = chartInfo.chartModel;
    // TODO - rename to typeElement?
    // TODO - this is NOT maintype but rather chartType (because it can be multiple)
    const chartElement = this.anychart[chartModel.mainType]();
    chartInfo.chartElement = chartElement;

    chartElement.interactivity().hoverMode("by-spot");

    // note - animation is currently busted (works for scatter)
    chartElement.animation().duration(this._options.initialAnimation).enabled(true);

    chartElement.padding(0, 0, 0, 0);
    chartElement.background().enabled(false);
    chartElement.margin(0, 0, 0, 0);
    chartElement.title(false);
    chartElement.legend(false);
    //chartElement.contextMenu(false);
    if (chartElement.dataArea) {
      chartElement.dataArea().background().enabled(false);
    }
    if (chartElement.xAxis) this.hideAxis(chartElement.xAxis(0));
    if (chartElement.yAxis) this.hideAxis(chartElement.yAxis(0));

    if (chartElement.radius) chartElement.radius("100%");
    if (chartElement.outline) chartElement.outline(false);

    this.setupTooltip(chartInfo);

    let typeInfo = {
      typeInstance: typeInstance,
      typeElement: chartElement,
    };
    this._typeInfosByModel.set(typeInstance, typeInfo);

    //         chartElement.listen('chartDraw', function (e) {
    //             console.log(e);
    //         });

    // set the container element
    let chartLayer = this._stage.layer();
    this._stage.addChild(chartLayer);
    chartElement.container(chartLayer);
    this.npl.listen(
      typeInstance,
      "overlap",
      function (event) {
        if (chartElement.barsPadding)
          chartElement.barsPadding(typeInstance.overlap * -1);
      },
      listenerRef
    );

    this.npl.listen(
      typeInstance,
      "gapWidth",
      function (event) {
        if (chartElement.barGroupsPadding)
          chartElement.barGroupsPadding(typeInstance.gapWidth);
      },
      listenerRef
    );

    this.npl.listen(
      typeInstance,
      "startAngle",
      function (event) {
        if (chartElement.startAngle)
          chartElement.startAngle(typeInstance.startAngle);
      },
      listenerRef
    );

    this.npl.listen(
      typeInstance,
      "zAngle",
      function (event) {
        if (chartElement.zAngle) chartElement.zAngle(typeInstance.zAngle);
      },
      listenerRef
    );

    this.npl.listen(
      typeInstance,
      "holeSize",
      function (event) {
        if (chartElement.innerRadius)
          chartElement.innerRadius(typeInstance.holeSize + "%");
      },
      listenerRef
    );

    this.npl.listen(
      typeInstance,
      "explosion",
      function (event) {
        if (chartElement.explode) {
          chartElement.explode(typeInstance.explosion);
          for (let i = 0; i < 1; i++) {
            // This is only for the first series
            let seriesInstance = chartInfo.chartModel.series.getAt(i);
            this.markSeriesDirty(chartInfo, seriesInstance);
          }
        }
      },
      listenerRef
    );

    listenerRef.addDisposable(function () {
      if (this._stage) {
        this._stage.removeChild(chartLayer);
      }
      setTimeout(() => {
        chartElement.removeAllListeners();
        try {
          chartElement.dispose();
        } catch (error) {
          if (process.env.NODE_ENV !== "production") {
            // pie charts throw tooltip error
            console.warn("disposeError", error);
          }
        }
      }, 0);
    });
  }

  markSeriesOnAxisDirty(chartInfo, axis) {
    //this._stage.suspend();
    let seriesList = axis.series;
    for (let i = 0; i < seriesList.length; i++) {
      let seriesInstance = seriesList.getAt(i);
      this.markSeriesDirty(chartInfo, seriesInstance);
    }
    //this._stage.resume();
  }

  /*
   * This will read the points from a series and render them on the chart.
   * This depends on the seriesInfo been populated but is safe to call multiple
   * times or during setup.
   */
  markSeriesDirty(chartInfo, seriesInstance, onRender) {
    if (!this._dirtySeries) {
      this._dirtySeries = new Map();
      this.renderDirtySeries = CommonUtils.debounce(
        function () {
          if (!this._stage) return;
          this._stage.suspend();
          this.applyDirtySeries();
          if (onRender) {
              onRender(chartInfo, seriesInstance);
          }
          this._stage.resume();
        }.bind(this)
      );
    }

    this._dirtySeries.set(seriesInstance, chartInfo);
    this.renderDirtySeries();
  }

  applyDirtySeries() {
    if (!this._dirtySeries) return;
    this._dirtySeries.forEach(
      function (value, key) {
        this.applySeries(value, key);
      }.bind(this)
    );
    this._dirtySeries.clear();
  }

  applySeries(chartInfo, seriesInstance) {
    // This when only render when everything is setup correctly. This
    // allows this to be called from a variety of locations saftely.
    if (!this._seriesInfosByModel) return;
    let seriesInfo = this._seriesInfosByModel.get(seriesInstance);
    if (!seriesInfo) return;
    let axisInfo = this._axesBySeries.get(seriesInstance);
    let isSingle = ChartUtils.isSingleSeries(seriesInstance.chartType.type);
    if (!isSingle && (!axisInfo || !axisInfo.axisX || !axisInfo.axisVal))
      return;

    let axisX;
    let axisVal;
    if (!isSingle) {
      axisX = axisInfo.axisX.axis;
      axisVal = axisInfo.axisVal.axis;
    }

    // use the axis information to determine if this is done correctly
    let displayablePoints = [];
    let seriesOffset = seriesInstance.offset;
    let seriesLength = seriesInstance.seriesLength;

    let points = seriesInfo.seriesInstance.renderedPoints;
    for (let i = 0; points && i < points.length; i++) {
      let pointModel = points[i];
      if (!pointModel)
        continue;
      let point = {
        x: pointModel.x,
        value: pointModel.val,
        size: pointModel.size,
      };
      displayablePoints.push(point);
    }
    let chartType = seriesInfo.seriesInstance.chartType.type;
    for (let i = 0; i < displayablePoints.length; i++) {
      this.applyPointTransform(
        chartInfo.chartModel,
        axisX,
        axisVal,
        displayablePoints[i],
        i,
        displayablePoints.length,
        seriesInfo,
        seriesOffset,
        seriesLength,
        chartType
      );
      // ? Should we do two full cycles? Does it mater?
      if (displayablePoints[i].value !== null)
        this.applyPointStyles(
          displayablePoints[i],
          i,
          displayablePoints.length,
          seriesInfo,
          seriesOffset,
          seriesLength,
          chartType
        );
    }

    // Note -
    // We set this as a variable on the seriesInfo for convenience.
    // Currently on the seriesPainter (not used) has a reference to this.
    seriesInfo.displayablePoints = displayablePoints;
    seriesInfo.dataSet.data(displayablePoints);

    // Note -
    // This is needed because anychart does not allow duplicates for ordinals by default
    // but scatter will not work if xMode is not set to ordinal.
    if (!isSingle) {
      if (axisInfo.axisX.axis instanceof ChartCatAxisShape)
        seriesInfo.seriesElement.xMode("scatter");
      else seriesInfo.seriesElement.xMode("ordinal");

      // We have to ensure the series has the correct scales
      if (seriesInfo.seriesElement) {
        seriesInfo.seriesElement.xScale(
          axisInfo.axisX.axisElement.scaleElement
        );
        seriesInfo.seriesElement.yScale(
          axisInfo.axisVal.axisElement.scaleElement
        );
      }
    }

    this.onRenderWithSizes(chartInfo);
  }

  applyPointTransform(
    chartModel,
    axisX,
    axisVal,
    data,
    dataOffset,
    dataCount,
    seriesInfo,
    seriesOffset,
    seriesCount,
    _chartType
  ) {

    data.dataOffset = dataOffset;
    if (axisX) data.x = axisX.limits.fitToTick(data.x, dataOffset);

    if (axisVal instanceof ChartValAxisShape) {
      data.value = ChartUtils.getValueAsDisplayNumber(
        data.value,
        chartModel.dispNaAsBlank,
        chartModel.dispBlanksAs,
        axisVal.scaleType
      );
      if (axisVal.limits.pointMap) {
        let rangeInfo = axisVal.limits.pointMap.getPoint(
          seriesOffset,
          dataOffset
        );
        if (rangeInfo) {
          data.originalValue = data.value;
          data.low = rangeInfo.low;
          data.high = rangeInfo.high;
          data.value = rangeInfo.value;
        }

        //                 else
        //                     console.log('invalid rangeInfo not found in pointMap', data);
      }

      if (!axisVal.groupInfo.stacked) {
          let limits = axisVal.limits;
          let crossesAt = ChartUtils.calcAxisCrossValue(
            axisX.crosses,
            limits.min,
            limits.max,
            limits.autoZero
          );
          if (data.value >= crossesAt) {
            data.low = crossesAt;
            data.high = data.value;
          } else {
            data.low = data.value;
            data.high = crossesAt;
          }
      }

      if (axisVal.scaleType === "log") {
        let divider = Math.log(axisVal.logBase);
        if (data.low !== undefined) {
          if (data.low === 0) data.low = axisVal.limits.min;
          data.low = Math.log(data.low) / divider;
        }
        if (data.high !== undefined) {
          if (data.high === 0) data.high = axisVal.limits.min;
          data.high = Math.log(data.high) / divider;
        }
        if (data.value !== null) {
          data.value = Math.log(data.value) / divider;
        }
      }
    }

    if (axisX instanceof ChartValAxisShape) {
      if (axisX.scaleType === "log") {
        data.x = Math.log(data.x) / Math.log(axisX.logBase);
      }
    }

    let seriesInstance = seriesInfo.seriesInstance;
    if (ChartUtils.isSingleSeries(seriesInstance.chartType.type)) {
      let asNumber = CommonUtils.asNumber(data.value);
      if (asNumber < 0) {
        data.originalValue = asNumber;
        asNumber = asNumber * -1;
      }
      data.value = asNumber;

      // NOTE - This assumes all single sets are of logical axis type 'number' (fix this with rationalized createDisplaybeDataSets)
    }

    // TODO - ADD THIS BACK. It is needed for bubbles and scatters.
    //         this.visitAllDisplayableDataSets(this._seriesInfos, (data, dataOffset, dataCount, seriesInfo, seriesOffset, seriesCount) => {
    //             if (CommonUtils.isDefined(data.size)) {
    //                 if (data.size < 0) {
    //                     if (this._seriesInfos[seriesOffset].seriesInstance.chartType.showNegativeBubbleValues) {
    //                         data.size = Math.abs(data.size);
    //                     } else
    //                         data.size = null;;
    //                 }
    //                 this.adjustMinMax(this._scales, 'minAbsSize', 'maxAbsSize', data.size);
    //             }
    //         });

    if (this._debug)
        console.log('rendering', data);
  }

  applyLabel(chartInfo, labelElement, labelShape, sizing) {
    let isDefined = CommonUtils.isDefined(labelShape);
    labelElement.enabled(isDefined && labelShape.shown);
    if (!isDefined || !labelShape.shown) return;

    if (labelElement.vAlign) labelElement.vAlign("middle");
    this.applyTextShape(labelElement, labelShape.text);
    if (labelElement.rotation) labelElement.rotation(labelShape.rotation);

    let hasPaint = this.hasPaint(labelShape);
    labelElement.background().enabled(hasPaint);
    if (hasPaint) {
      this.applyShapeStyle(labelElement.background(), labelShape);
    }
  }

  /*
    https://api.anychart.com/anychart.core.ui.LabelsFactory#position
    (be sure to expand (i)Detailed description under postion() )

    https://api.anychart.com/anychart.enums.Position
    https://api.anychart.com/anychart.enums.Anchor
    https://playground.anychart.com/1QxgV4ZK
  */
  applyDataLabelPosition(chartType, data, position, label) {
    delete label.anchor;
    label.position = 'center';
    if (chartType === 'pie') {
        if (position === "outEnd")
            label.position = "outside";
        else
            label.position = "inside";
    } else if (chartType === 'area') {
        // nothing
    } else if (chartType === 'column' || chartType === 'bar') {
        label.anchor = chartType === 'column' ? 'center-bottom' : 'left-center';
        if (position === 'ctr') {
            label.position = 'center';
            label.anchor = 'center';
        } else if (position === 'inBase') {
            if (data.value >= 0) {
                label.position = 'low';
                label.anchor = chartType === 'column' ? 'center-bottom' : 'left-center';
            } else {
                label.position = 'high';
                label.anchor = chartType === 'column' ? 'center-top' : 'right-center';
            }
        } else if (position === 'inEnd') {
            if (data.value >= 0) {
                label.position = 'high';
                label.anchor = chartType === 'column' ? 'center-top' : 'right-center';
            } else {
                label.position = 'low';
                label.anchor = chartType === 'column' ? 'center-bottom' : 'left-center';
            }
        } else if (position === 'outEnd') {
            if (data.value >= 0) {
                label.position = 'high';
                label.anchor = chartType === 'column' ? 'center-bottom' : 'left-center';
            } else {
                label.position = 'low';
                label.anchor = chartType === 'column' ? 'center-top' : 'right-center';
            }
        }
    } else if (chartType === 'line' || chartType === 'scatter') {
        if (position === 'l') {
            label.position ='left-center';
            label.anchor ='right-center';
        } else if (position === 'r') {
            label.position ='right-center';
            label.anchor ='left-center';
        } else if (position === 't') {
            label.position ='center-top';
            label.anchor ='center-bottom';
        } else if (position === 'b') {
            label.position ='center-bottom';
            label.anchor ='center-top';
        }
    }
  }

  invalidateBounds(chartInfo, rect, syncValidate) {
    if (!this._stage || (!this._chartDrawn && !syncValidate)) return;
    if (this.validatingBounds || this._stage.isRendering()) return;
    if (!rect)
      rect = {
        x: 0,
        y: 0,
        width: this._stage.width(),
        height: this._stage.height(),
      };

    const setupBoundWithManual = function(model, element, rectOriginal, rectParent) {
        element.drag({ ...rectOriginal });
        if (rectParent)
            element.parentBounds(
              rectParent.left,
              rectParent.top,
              rectParent.width,
              rectParent.height
            );
        element.manualBounds(model.manualLayout || null);
    }
    const validationBounds = function (rect, onSyncValidate) {
      this.validatingBounds =
        this.validatingBounds === undefined ? 1 : this.validatingBounds + 1;
      this.npl.suspend();
      if (!this._stage)
        // because it's async this can occur
        return;
      // Note - we already suspended the paint on invalidateBounds
      this._stage.resize(rect.width, rect.height);
      let gridRectOriginal = {
        left: rect.x,
        top: rect.y,
        height: rect.height,
        width: rect.width,
      };
      let gridRect = { ...gridRectOriginal };

      /*
              -Chart Title has a margin of 7.5 on orientation (top)
              -Chart Legend has a margin of 7.5 on orientation

              -Plot Area & Adornments. (this is plotArea & axis labels & axis titles & axis display labels)
                -Capture the plotarea&Adorments size
                -First Pass. (calc plot area) (subtract from plotare for each of the following)
                  -Axis Titles have only a margin of 7.5 on side of orientation
                  -Display Unit Titles have a margin of 7.5 on orientation (but these are always )...
                  -The spacing for display units is created but display units are always place beside axis labels.
                  -Axis Labels have margin of 1 on opposite side
                  -Place Axis Label
                  -Determine label extrusions size (trim  Math.min(14px - extrusion, 6px)
                -Second Pass
                  -place all items 'relative to plotArea'
                  -display labels are always beside axis labels even though the spacing is there)
            */

      this.setBackground(chartInfo, gridRect);
      this.applyShapeStyle(chartInfo.mainBackground, chartInfo.chartModel);

      if (chartInfo.chartModel.title.shown) {
        setupBoundWithManual(chartInfo.chartModel.title, chartInfo.titleElement, gridRectOriginal, gridRect);
        chartInfo.titleElement.draw();
        gridRect = chartInfo.titleElement.remainingBounds();
      }

      // We want to make room for the legend.
      if (chartInfo.chartModel.legend.shown) {
        setupBoundWithManual(chartInfo.chartModel.legend, chartInfo.legendElement, gridRectOriginal, gridRect);
        chartInfo.legendElement.draw();
        gridRect = chartInfo.legendElement.remainingBounds();
      }

      // Apply min margins
      let minMargins = { top: 6, right: 7.5, bottom: 8, left: 7.5 };
      gridRect = ChartUtils.applyMarginRect(gridRect, minMargins);
      let postMargins = { ...gridRect };

      let sizeTitle = function (titleElement) {
        titleElement.parentBounds(
          gridRect.left,
          gridRect.top,
          gridRect.width,
          gridRect.height
        );
        titleElement.draw();
        gridRect = titleElement.remainingBounds();
      }.bind(this);

      // Now we trim the plotAre for each title. (We place later)
      let trimForLabels = function (axis, axesTitles, axesDisplayLabels) {
        let axisInfo = this._axesInfosByModel.get(axis);
        if (
          axisInfo.displayLabelElement &&
          ChartUtils.allowDisplayUnit(axisInfo.axis)
        ) {
          sizeTitle(axisInfo.displayLabelElement);
          axesDisplayLabels.push({
            axis: axis,
            title: axisInfo.displayLabelElement,
          });
        }
        if (axisInfo.titleElement) {
          sizeTitle(axisInfo.titleElement);
          axesTitles.push({ axis: axis, title: axisInfo.titleElement });
        }
      }.bind(this);

      let axesTitles = [];
      let axesDisplayLabels = [];
      for (let i = 0; i < chartInfo.chartModel.yAxes.length; i++)
        trimForLabels(
          chartInfo.chartModel.yAxes.getAt(i),
          axesTitles,
          axesDisplayLabels
        );
      for (let i = 0; i < chartInfo.chartModel.xAxes.length; i++)
        trimForLabels(
          chartInfo.chartModel.xAxes.getAt(i),
          axesTitles,
          axesDisplayLabels
        );

      // TODO -
      // We can just give width and heights directly to setShape and then call apply tick info.
      // this is a very roundabout way to use the original calcs that we manually do now.

      chartInfo.gridElement.bounds(gridRect);
      chartInfo.gridElement.draw();
      // plotarea is not resizing nicely.
      //chartInfo.gridElement.dataArea().background().draw();
      let chartBounds = this._stage.getBounds();

      // Note
      // The axis labels are calculated from the axis using:

      // In order we have to:
      // 1. Calculate Y axis width (they don't auto-rotate so their width's do not change)
      // 2. Calculate X axis height (width is based on remainder after Y axes applied)
      // 3. Calculate Y axis height (because X height may change Y which would need to rebound)

      // For each axis we have to do several things
      // 1. skrink by the axisWidth
      // 2. potentially shrink again for cross axis labels:
      // check the max axisWidth and the max cross label width
      // 3. if the axis labels are 'nextTo' the expand plotAre by maximum axis 'overlap'
      // Note - the current algo only allows for expanding axis one side per direction.
      //        OOXML seems to have the same limitation.
      // 4. place the axis

      let gridPlotArea = { ...gridRect };
      let titlePlacementArea = { ...gridPlotArea };

      let expandPlotArea = function (layout) {
        // TODO - find the smallest expand for each and save it (for multiple axis support)
        let expand;
        let maxLength = layout.horizontal ? gridRect.height : gridRect.width;
        if (layout.edge === "top") {
          expand = Math.min(
            maxLength,
            layout.plotLength / layout.percentLabels
          );
        } else if (layout.edge === "left") {
          expand = Math.min(
            maxLength,
            layout.plotLength / (1 - layout.percentLabels)
          );
        } else if (layout.edge === "right") {
          expand = Math.min(
            maxLength,
            layout.plotLength / layout.percentLabels
          );
        } else if (layout.edge === "bottom") {
          expand = Math.min(
            maxLength,
            layout.plotLength / (1 - layout.percentLabels)
          );
        }
        gridPlotArea = ChartUtils.expandRect(gridPlotArea, expand, layout.edge);
      };

      let layoutAxis = function (layout, stackedOffsets) {
        layout.axisInfo.axisElement.layout(
          gridPlotArea,
          stackedOffsets,
          layout.percentLabels,
          layout.percentAxisLine
        );
        layout.axisInfo.axisElement.draw();
      };

      let calcAxesLayout = function (axis) {
        let axisInfo = this._axesInfosByModel.get(axis);
        let layout = {
          axis: axis,
          axisInfo: axisInfo,
          offset: 0,
          edge: axis.orientation,
          horizontal: axis.horizontal,
        };

        axisInfo.axisElement.applyTickInfo(axis.limits);
        axisInfo.axisElement.draw();
        let axisBounds = { ...axisInfo.axisElement.naturalDims() };
        layout.adjustedDims = axisBounds;

        if (layout.horizontal) {
          axisBounds.left = gridPlotArea.left;
          axisBounds.width = gridPlotArea.width;
        } else {
          axisBounds.top = gridPlotArea.top;
          axisBounds.height = gridPlotArea.height;
        }

        // We have to reapply the limits because the size recomputes these
        axis.dims = {
          chartArea: chartBounds,
          plotArea: gridPlotArea,
          axis: axisBounds,
        };
        axisInfo.axisElement.applyTickInfo(axis.limits, axisBounds);
        // Once we calculate these bounds ourselves we can quit relying on the dom.
        axisInfo.axisElement.layoutLabels(axisBounds);
        axisInfo.axisElement.drawLabels();

        layout.axisWidth = 0; // the narrow size of the axis (as in line width)
        layout.orientation = axis.orientation;
        if (!layout.orientation) {
          // Note - This should not happend but a current memory leak fires a 'removed event'
          return layout;
        }
        layout.percentAxisLine =
          axisInfo.axisElement.calcPercentageAxisLine(axis);
        layout.percentLabels = layout.percentAxisLine;
        if (axis.labelPosition === "high") layout.percentLabels = 1;
        else if (axis.labelPosition === "low") layout.percentLabels = 0;
        else if (axis.labelPosition === "none") layout.percentLabels = 0;
        if (axis.crossAx.inverted) {
          layout.percentAxisLine = 1 - layout.percentAxisLine;
          layout.percentLabels = 1 - layout.percentLabels;
        }

        // This will include any rotations. Once we calc ourselves we can stop relying on the dom
        if (layout.horizontal) {
          axisBounds.height = axisInfo.axisElement.naturalDims().height;
          axisBounds.width = gridPlotArea.width;
          layout.axisWidth = axisBounds.height;
        } else {
          axisBounds.width = axisInfo.axisElement.naturalDims().width;
          layout.axisWidth = axisBounds.width;
        }
        return layout;
      }.bind(this);

      // We do Y-Axes first because the width does not change based on layout
      let axesYLayouts = [];
      for (let i = 0; i < chartInfo.chartModel.yAxes.length; i++)
        axesYLayouts.push(calcAxesLayout(chartInfo.chartModel.yAxes.getAt(i)));
      for (let i = 0; i < axesYLayouts.length; i++) {
        gridPlotArea = ChartUtils.trimRect(
          gridPlotArea,
          axesYLayouts[i].adjustedDims,
          axesYLayouts[i].edge
        );
        axesYLayouts[i].plotLength = axesYLayouts[i].horizontal
          ? gridPlotArea.height
          : gridPlotArea.width;
      }

      // X-axes width is gridRect - all Y axis widths
      let axesXLayouts = [];
      for (let i = 0; i < chartInfo.chartModel.xAxes.length; i++)
        axesXLayouts.push(calcAxesLayout(chartInfo.chartModel.xAxes.getAt(i)));
      for (let i = 0; i < axesXLayouts.length; i++) {
        gridPlotArea = ChartUtils.trimRect(
          gridPlotArea,
          axesXLayouts[i].adjustedDims,
          axesXLayouts[i].edge
        );
        axesXLayouts[i].plotLength = axesXLayouts[i].horizontal
          ? gridPlotArea.height
          : gridPlotArea.width;
      }

      for (let i = 0; i < axesYLayouts.length; i++)
        expandPlotArea(axesYLayouts[i]);
      for (let i = 0; i < axesXLayouts.length; i++)
        expandPlotArea(axesXLayouts[i]);

      // Axis dimensions are all based on the tick marks but labels can extrude.
      // We can them all looking for the max extrusions
      let extrusions = { left: 0, top: 0, right: 0, bottom: 0 };
      for (let i = 0; i < axesYLayouts.length; i++)
        extrusions = ChartUtils.maxMargin(
          extrusions,
          axesYLayouts[i].axisInfo.axisElement.extrusions()
        );
      for (let i = 0; i < axesXLayouts.length; i++)
        extrusions = ChartUtils.maxMargin(
          extrusions,
          axesXLayouts[i].axisInfo.axisElement.extrusions()
        );

      /*
                We check each side to see if there has been any change.
                If there has been no change then we add an aditional 7.5.
                We max with extrustions because these are 'technical' changes too.
            */
      let marginChangeTop = gridPlotArea.top - postMargins.top;
      let marginChangeLeft = gridPlotArea.left - postMargins.left;
      let marginChangeRight =
        postMargins.width -
        gridPlotArea.width -
        (gridPlotArea.left - postMargins.left);
      let marginChangeBottom =
        postMargins.height -
        gridPlotArea.height -
        (gridPlotArea.top - postMargins.top);
      let extrudeMargins = {
        top: Math.max(
          Math.abs(gridPlotArea.top - postMargins.top) <= 0.01 ? 7.5 : 0,
          Math.max(0, extrusions.top - marginChangeTop)
        ), // top doesn't have min
        right: Math.max(
          Math.abs(marginChangeRight) <= 0.01 ? 7.5 : 0,
          Math.max(0, extrusions.right - marginChangeRight + minMargins.right)
        ),
        bottom: Math.max(
          Math.abs(marginChangeBottom) <= 0.01 ? 7.5 : 0,
          Math.max(0, extrusions.bottom - marginChangeBottom)
        ), // bottom doesn't have min
        left: Math.max(
          Math.abs(gridPlotArea.left - postMargins.left) <= 0.01 ? 7.5 : 0,
          Math.max(0, extrusions.left - marginChangeLeft + minMargins.left)
        ),
      };

      // Trim the plot Area AND the titlePlacementArea (since this is used for placing titles)
      gridPlotArea = ChartUtils.applyMarginRect(gridPlotArea, extrudeMargins);

      // Note - v doesn't trim the titlePlacementArea
      //titlePlacementArea = ChartUtils.applyMarginRect(titlePlacementArea, extrudeMargins);

      let stackedOffsets = { left: 0, top: 0, right: 0, bottom: 0 };
      for (let i = 0; i < axesYLayouts.length; i++)
        layoutAxis(axesYLayouts[i], stackedOffsets);
      for (let i = 0; i < axesXLayouts.length; i++)
        layoutAxis(axesXLayouts[i], stackedOffsets);

      // Now do the Y Axis a second time so that it culls correctly
      // Put axis where they belong relative to plotOffset
      for (let i = 0; i < axesYLayouts.length; i++) {
        let layout = axesYLayouts[i];
        // We have to reapply the limits because the size recomputes these
        layout.axis.dims = {
          chartArea: chartBounds,
          plotArea: gridPlotArea,
          axis: layout.adjustedDims,
        };
        layout.axisInfo.axisElement.applyTickInfo(
          layout.axis.limits,
          layout.adjustedDims
        );
        layout.axisInfo.axisElement.drawLabels();
      }

      /**
              Place the axis titles
              The also here is to either:
              a. place the title relative to axis BEFORE they are adjusted (axis titles)
              or
              b. place the title relative to axis AFTER they are adjusted (display Labels)

              Note - OOXML STILL pads for the displayLabels even though they are shifted.
            */
      let labelOrientationOffset = { left: 0, top: 0, bottom: 0, right: 0 };
      let placeTitle = function (titleElement, axis) {
        let axisBounds;
        if (axis) {
          let axisLayout = axis.horizontal
            ? axesXLayouts[axis.offset]
            : axesYLayouts[axis.offset];
          axisBounds = axisLayout.axisInfo.axisElement.renderedBounds();
        }
        let bounds = titleElement.renderedBounds();
        // Note -
        // We use the dimensions before we place the axis for the opposite direction.
        // But the dimensions after we place the axis for the current direction
        if (titleElement.orientation === "top") {
          labelOrientationOffset.top =
            labelOrientationOffset.top + bounds.height;
          if (axisBounds)
            titleElement.parentBounds(
              gridPlotArea.left,
              axisBounds.top - bounds.height,
              gridPlotArea.width,
              axisBounds.height + bounds.height
            );
          else
            titleElement.parentBounds(
              gridPlotArea.left,
              titlePlacementArea.top - labelOrientationOffset.top,
              gridPlotArea.width,
              titlePlacementArea.height
            );
        } else if (titleElement.orientation === "bottom") {
          labelOrientationOffset.bottom =
            labelOrientationOffset.bottom + bounds.height;
          if (axisBounds)
            titleElement.parentBounds(
              gridPlotArea.left,
              axisBounds.top,
              gridPlotArea.width,
              axisBounds.height + bounds.height
            );
          else
            titleElement.parentBounds(
              gridPlotArea.left,
              titlePlacementArea.top,
              gridPlotArea.width,
              titlePlacementArea.height + labelOrientationOffset.bottom
            );
        } else if (titleElement.orientation === "left") {
          labelOrientationOffset.left =
            labelOrientationOffset.left + bounds.width;
          if (axisBounds)
            titleElement.parentBounds(
              axisBounds.left - bounds.width,
              gridPlotArea.top,
              axisBounds.width + bounds.width,
              gridPlotArea.height
            );
          else
            titleElement.parentBounds(
              titlePlacementArea.left - labelOrientationOffset.left,
              gridPlotArea.top,
              titlePlacementArea.width + labelOrientationOffset.left,
              gridPlotArea.height
            );
        } else if (titleElement.orientation === "right") {
          labelOrientationOffset.right =
            labelOrientationOffset.right + bounds.width;
          if (axisBounds)
            titleElement.parentBounds(
              axisBounds.left,
              gridPlotArea.top,
              axisBounds.width + bounds.width,
              gridPlotArea.height
            );
          else
            titleElement.parentBounds(
              titlePlacementArea.left,
              gridPlotArea.top,
              titlePlacementArea.width + labelOrientationOffset.right,
              gridPlotArea.height
            );
        }
      }.bind(this);
      for (let i = 0; i < axesDisplayLabels.length; i++) {
        placeTitle(axesDisplayLabels[i].title, axesDisplayLabels[i].axis);
        setupBoundWithManual(axesDisplayLabels[i].axis.displayUnitsLabel, axesDisplayLabels[i].title, gridRectOriginal, null);
      }
      for (let i = 0; i < axesTitles.length; i++) {
        placeTitle(axesTitles[i].title, null);
        setupBoundWithManual(axesTitles[i].axis.title, axesTitles[i].title, gridRectOriginal, null);
      }

      chartInfo.gridElement.bounds(gridPlotArea);

      // Anycharts doesn't update if there is no data so to 'force' an invlidation we toggle a value.
      //chartInfo.gridElement.dataArea().parentBounds(gridPlotArea.top, gridPlotArea.left, gridPlotArea.width, gridPlotArea.height);
      let originalDataEnabled = chartInfo.gridElement.dataArea().enabled();
      chartInfo.gridElement.dataArea().enabled(!originalDataEnabled);
      chartInfo.gridElement.draw();
      chartInfo.gridElement.dataArea().enabled(originalDataEnabled);

      let plotBounds = chartInfo.gridElement.getPlotBounds
        ? chartInfo.gridElement.getPlotBounds()
        : null;

      // Finally we update the the actually series
      this._typeInfosByModel.forEach(function (typeInfo, type) {
        typeInfo.typeElement.bounds(plotBounds);
      });

      if (onSyncValidate) {
        onSyncValidate();
      }

      this.validatingBounds = this.validatingBounds - 1;
      this.npl.resume();

      this._validBoundsScheduled = false;
      setTimeout(
        function () {
          if (!this._stage) return;
          this._stage.resume();
          // Hack - to get the titleElement to shift. At some point we will use our own
          // editor and font metrics so ok for now.
          chartInfo.titleElement.draw();
          for (let i = 0; i < axesTitles.length; i++)
            axesTitles[i].title.draw();
          for (let i = 0; i < axesDisplayLabels.length; i++)
            axesDisplayLabels[i].title.draw();
          for (let i = 0; i < axesXLayouts.length; i++)
            axesXLayouts[i].axisInfo.axisElement.draw();
          for (let i = 0; i < axesYLayouts.length; i++)
            axesYLayouts[i].axisInfo.axisElement.draw();

          this._typeInfosByModel.forEach(function (typeInfo, type) {
            typeInfo.typeElement.draw(true);
          });
        }.bind(this),
        0
      );
    }.bind(this);

    if (!this._validateBounds)
      this._validateBounds = CommonUtils.debounce(validationBounds, 120);

    if (!this._validBoundsScheduled) {
      this._validBoundsScheduled = true;
      this._stage.suspend();
    }

    if (syncValidate) {
      validationBounds(rect, syncValidate);
    } else {
      this._validateBounds(rect, null);
    }
  }

  applyTextShape(textElement, textShape) {
    let isDefined = CommonUtils.isDefined(textShape);
    if (!isDefined) return;
    if (textElement.vAlign) {
      textElement.vAlign("middle");
    }
    let textFill = textShape.fill;
    if (textFill && textFill.type === 'solid') {
      let rgbaColor = textFill.color.toRGBAColor();
      textElement.fontColor('rgb(' + rgbaColor.red + ',' + rgbaColor.green + ',' + rgbaColor.blue + ')');
      textElement.fontOpacity(rgbaColor.alpha);
    } else if (textFill && textFill.type === 'none') {
      textElement.fontColor("rgb(0,0,0)");
      textElement.fontOpacity(0);
    }

    if (CommonUtils.isDefined(textShape.font))
      textElement.fontFamily(textShape.font.name || 'Calibri');
    if (CommonUtils.isDefined(textShape.size))
      textElement.fontSize(textShape.size * TextUtils.fontScale());

    //         if (textShape.vAlign)
    //             label.vAlign(textShape.vAlign);
    if (CommonUtils.isDefined(textShape.weight))
      textElement.fontWeight(textShape.weight);
    if (CommonUtils.isDefined(textShape.italic) && textShape.italic)
      textElement.fontStyle('italic');
    else
        textElement.fontStyle('normal');

    if (textElement.text && CommonUtils.isDefined(textShape.simpleRun)) {
      textElement.text(textShape.simpleRun);
    } else if (textElement.text) {
      textElement.text("");
    }
    if (textElement.padding) {
      const rect = textShape.insets;
      textElement.padding(rect.top, rect.right, rect.bottom, rect.left); // anychart rect orders are different than css
    }
  }

  export(type='png', scale) {
    if (type === 'svg') {
      return this.asSVG(scale);
    } else if (type === 'png') {
      return this.asPNG(scale);
    } else {
      throw new Error('unknown export type :' + type);
    }
  }

  walkDom(node, func) {
   var children = node.childNodes;
   for (var i = 0; i < children.length; i++)  // Children are siblings to each other
       this.walkDom(children[i], func);
   func(node);
  }

  asSVG() {
    return new Promise((resolve, reject) => {
      try {
        let cloned = this._stage.container().querySelector('svg.sheetxl-ui-support').cloneNode(true);
        cloned.setAttribute('width', this._stage.width());
        cloned.setAttribute('height', this._stage.height());
        this.walkDom(cloned, function(node) {
          if (!node.removeAttribute) {
            return;
          }
          node.removeAttribute("ac-id");
          node.removeAttribute("data-ac-wrapper-id");
          node.removeAttribute("aria-label");
        });

        let svgMainChart = new XMLSerializer().serializeToString(cloned);
        let sheetxlCredits = this._stage.container().getElementsByClassName('sheetxl-credits')[0];
        if (sheetxlCredits) {
          let svgCredits = new XMLSerializer().serializeToString(sheetxlCredits);
          // TODO - add support for credit (multiple svgs)
        }

        let mimeType = "image/svg+xml";
        let blob = new Blob([svgMainChart], {type: mimeType + ";charset=utf-8"});
        resolve({ mimeType: mimeType, blob: blob, dataBlob: svgMainChart });
      } catch (error) {
        reject (error);
      }
    })
  }

  asPNG(scale=1) {
    return new Promise((resolve, reject) => {
      this.asSVG().then(exported => {
        let canvas = document.createElement("canvas");
        canvas.width = this._stage.container().clientWidth * scale;
        canvas.height = this._stage.container().clientHeight * scale;

        let ctx = canvas.getContext("2d");
        ctx.scale(scale,scale);
        let DOMURL = self.URL || self.webkitURL || self;
        let img = new Image();
        let url = DOMURL.createObjectURL(exported.blob);
        img.onload = function() {
            ctx.drawImage(img, 0, 0);
            let pngBlob = canvas.toDataURL("image/png");
            canvas.toBlob((blob) => {
              DOMURL.revokeObjectURL(pngBlob);
              let dataBlob = pngBlob.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
              resolve({ mimeType: "image/png", blob: blob, dataBlob: dataBlob });
            }, "image/png");
        };
        img.src = url;
      }).catch(error => {
        reject(error);
      });
    });
  }

  copyToClipboard(mimeType, blob) {
    navigator.permissions.query({name: "clipboard-write", allowWithoutGesture: false }).then(result => {
      console.log("navigator.permissions", result);
      if (result.state == "granted" || result.state == "prompt") {
        // We could also write text using the pasting logic
        /* write image to the clipboard */
        navigator.clipboard.write([new ClipboardItem({ [mimeType] : blob })]).then(function(event) {
            //console.log('copied succeeded');
        }, function(error) {
            console.warn('copied failed', error);
        });
      } else {
          console.warn('no permissions to copy must be https or localhost', result);
      }
    }, error => {
      console.warn('copied permissions failed', error);
    });
  }

  copyTextToClipboard(text) {
    navigator.permissions.query({name: "clipboard-write", allowWithoutGesture: false }).then(result => {
      console.log("navigator.permissions", result);
      if (result.state == "granted" || result.state == "prompt") {
        // We could also write text using the pasting logic? Why use writeTet and not just write?
        navigator.clipboard.writeText(text).then(function(event) {
            //console.log('copied succeeded');
        }, function(error) {
            console.warn('copied failed', error);
        });
      } else {
          console.warn('no permissions to copy must be https or localhost', result);
      }
    }, error => {
      console.warn('copied permissions failed', error);
    });
  }

  renderChart(chartModel, dataSet) {
    try {
      this.renderChartSafely(chartModel, dataSet);
    } catch (error) {
      console.warn("Unable to render chart", error);
      // this.renderChartError(error);
    }
  }

  renderChartSafely(chartModel, dataSet) {
    this._dataSet = dataSet;
    this._stage = this.anychart.graphics.create(this._rendererContainer);

    // If the same change defintions are reponed In another viewer then
    // we need to update the references. This is for the same reason that
    // we call updateSVGReferences.
    // Note - It seems like a memory leak that charts are being cached at all. - MTF
    try {
      this.anychart.graphics.updateReferences();
    } catch (error) {
      console.warn("unable to updateReference ", error);
    }

    this._stage.listenOnce("renderstart", () => {
      try {
        // We do this to get the charts to allow overflow (this shouldn't be needed but the labels sometimes 'hang' over)
        if (
          this._rendererContainer.firstChild &&
          this._rendererContainer.firstChild.nextSibling
        ) {
          this._rendererContainer.firstChild.nextSibling.style.overflowX =
            "visible";
          this._rendererContainer.firstChild.nextSibling.style.overflowY =
            "visible";
        }
        let support = "ui-support";
        let elementMaster = document.getElementsByClassName(
          "anychart" + "-" + support
        );
        if (elementMaster && elementMaster.length > 0) {
          let elem = elementMaster[0];
          elem.classList.remove("anychart" + "-" + support);
          elem.classList.add("sheetxl" + "-" + support);
        }
      } catch (error) {
        console.warn("rendering error", error);
        this.renderChartError(error);
      }
    });

    this._stage.listenOnce("stagerendered", () => {
      // console.log('2 - stagerendered');
      //                 setTimeout(() => {
      if (
        !this._stagerendered &&
        this._options &&
        this._options.onStageRender
      ) {
        this._options.onStageRender();
      }
      this._stagerendered = true;
    });

    const listenerKeyEvent = function (event) {
      // console.log('keyEvent', event);
    }

    const listenerKeyDown = function (event) {
      const ctrlDown = event.ctrlKey || event.metaKey;
      const shiftDown = event.shiftKey;
      const altDown = event.altKey;
      // TODO - Mac support
      // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
      // SVG to canvas may not work.
      // if (ctrlDown && event.altKey) return true;
//       console.log(event.keyCode);
      if (event.keyCode === 67 && ctrlDown) { // 'c'
        this.export('png').then(result => {
          this.copyToClipboard(result.mimeType, result.blob);
        });
      } else if (event.keyCode === 68 && ctrlDown) { // 'd'
          this._debug = this._debug ? false : true ;
          console.log('debugmode: ', this._debug);
      } else if (this._debug &&  event.keyCode === 69 && ctrlDown) { // 'e'
        // TODO - put this code somewhere else as a call back
        if (!chartModel)
          return;
        event.preventDefault();
        const self = this;
        import('@sheetxl/io').then(function(io) {
          let converter = new io.ConverterToOOXML();
          let xmlSource = converter.toOOXML(chartModel, {
            header: true,
            prettify: true
          });

          self.copyTextToClipboard(xmlSource);
        });
      } else if (this._debug && event.keyCode === 74 && ctrlDown) { // 'j'
        console.log(chartModel.toJSON());
        event.preventDefault();
      } else if (this._debug && event.keyCode === 65 && ctrlDown) { // 'a'
        // Note - for testing of API purposes. This is not used directly in the application
        if (!chartModel)
          return;
        event.preventDefault();
        const self = this;
        import('@sheetxl/io').then(function(io) {
          let apiOutput = {};
          let docTheme = chartModel.docTheme;
          apiOutput.docTheme = {
            name: docTheme.name,
            dk1: docTheme.dk1,
            lt1: docTheme.lt1,
            dk2: docTheme.dk2,
            lt2: docTheme.lt2,
            accent1: docTheme.accent1,
            accent2: docTheme.accent2,
            accent3: docTheme.accent3,
            accent4: docTheme.accent4,
            accent5: docTheme.accent5,
            accent6: docTheme.accent6,
            hlink: docTheme.hlink,
            folHlink: docTheme.folHlink,
            majorFontLatin: docTheme.majorFontLatin,
            minorFontLatin: docTheme.minorFontLatin
          };
          apiOutput.chartShape = chartModel.toJSON();
          // Build a sheetSource from the references;
          let sheetSource = {
            sheets : {}
          };
          let cellRefs = io.ApiUtils.findCellRefsForChart(apiOutput.chartShape);

          let sheetNames = Object.keys(cellRefs);
          for (let i=0; i<sheetNames.length; i++) {
            let sheetName = sheetNames[i];
            let sheet = {
                cells: {}
            };

            sheetSource.sheets[sheetName] = sheet;
            for (let j=0; j<cellRefs[sheetName].length; j++) {
                let range = RangeUtils.decode_cell(cellRefs[sheetName][j]);
                let cell = chartModel.sheet.getPropertyAt(sheetName, range.c, range.r);
                if (cell)
                  sheet.cells[cellRefs[sheetName][j]] = cell.value;
            }
          }
          apiOutput.sheetSource = sheetSource;

          apiOutput.options = {
            chartShape: {
              appContext: chartModel.options.appContext
            },
            debug: true,
            prettify: true,
            id: 'testing',
            viewport: {
              width: CommonUtils.roundAccurately(self._stage.width(), 0),
              height: CommonUtils.roundAccurately(self._stage.height(), 0),
              deviceScaleFactor: 1
            }
          }
          self.copyTextToClipboard(JSON.stringify(apiOutput, null/*replacer*/, 2));
        });
      }
    }.bind(this);
    this._rendererContainer.setAttribute("tabindex", 0); // make us focusable
    this._rendererContainer.addEventListener("keydown", listenerKeyDown);

    this._rendererContainer.addEventListener("keypress", listenerKeyEvent);
    this._rendererContainer.addEventListener("keyup", listenerKeyEvent);
    this._rendererContainer.addEventListener("keydown", listenerKeyEvent);
    // Note - Anycharts doesn't allow up to call stopImmediatePropagation so we do it at the container level if we prevented default
    const listenerMouseEvent = function (event) {
      // if (event.defaultPrevented) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
      // }
    }

    this._rendererContainer.addEventListener("mousemove", listenerMouseEvent);

    // Note - The are 'globally' scoped because the tooltip and resize handler needs them.
    // TODO -
    // Figure out how to move these to chart Info.
    // We do not want to mix chartElement parts in the same
    // maps.
    // By having these be global when elements are transitioning
    // we can get into a state where the map has new AND old elements.
    // This is not causing any KNOWN defects but is message.
    // Once we move the size based logic out review how tooltips 'find' the models.
    // and revisit this
    this._axesInfosByModel = new Map(); // Used by propertyListeners
    this._axesBySeries = new Map(); // To find the axis for a given series
    this._seriesInfosByElemement = new Map(); // Used by tooltips
    this._seriesInfosByModel = new Map(); // Used by propertyListeners
    this._typeInfosByModel = new Map(); // Used by propertyListeners

    const unListenerProperties = [];

    this.setupMainChart(chartModel, unListenerProperties);

    this._disposeListeners.push(
      function () {
        CommonUtils.unListenAll(unListenerProperties);
        this._rendererContainer.removeEventListener("keydown", listenerKeyDown);
        this._rendererContainer.removeEventListener("keydown", listenerKeyEvent);
        this._rendererContainer.removeEventListener("keyup", listenerKeyEvent);
        this._rendererContainer.removeEventListener("keypress", listenerKeyEvent);

        this._rendererContainer.removeEventListener("mouseMove", listenerMouseEvent);
      }.bind(this)
    );
  }

  // Anycharts does not have a way to remove axis and also creates two defaults.
  // We 'hide these'
  hideAxis(axisElement) {
    axisElement.labels().enabled(false);
    axisElement.ticks().enabled(false);
    axisElement.minorTicks().enabled(false);
    axisElement.title().enabled(false);
    axisElement.stroke({
      color: "none",
    });
    axisElement.remove();
  }

  // assumes:
  //  models has show and manualLayout properties
  //  elements have manualBounds
  setupManuaMoveListener(model, element, listenerRef) {
      const onManualMoveListener = function (event) {
        let manualBounds = element.manualBounds();
        model.manualLayout = {
          x: manualBounds.x,
          y: manualBounds.y,
          width: manualBounds.width,
          height: manualBounds.height,
        };
      };
      element.addListener("manualMove", onManualMoveListener);
      listenerRef.addDisposable(function (ref) {
        element.removeListener(
          "manualMove",
          onManualMoveListener
        );
      });
      // If legend is 'hidden' then reset a few fieelds
      if (!model.shown) {
        if (model.manualLayout)
          model.manualLayout = undefined;
      }
  }

  setupTitle(chartInfo, unListenerProperties) {
    const chartModel = chartInfo.chartModel;

    let titleElement = new TitleElement(chartInfo.chartContainer, true/*resizable*/);
    titleElement.maxWidthPerc = 0.8;
    titleElement.maxHeightPerc = 0.5;
    // titleElement.orientation = 't';
    chartInfo.titleElement = titleElement;

    unListenerProperties.push(
      this.npl.listen(chartModel, "title.*", function (event, listenerRef) {
        titleElement.overlay = chartModel.title.overlay;
        titleElement.rotation = chartModel.title.rotation;
        this.applyLabel(
          chartInfo,
          titleElement.elementTitle(),
          chartModel.title
        );

        this.setupManuaMoveListener(chartModel.title, titleElement, listenerRef);
        this.invalidateBounds(chartInfo);
      })
    );
  }

  // legend items
  // Note to handle the use case where there is a single series that varyColors we have two
  // listeners
  isLegendForSingleSeries(chartModel) {
    const chartTypes = chartModel.types;
    if (
      chartTypes.length === 1 &&
      (ChartUtils.isSingleSeries(chartTypes.getAt(0).type) ||
        (chartModel.series.length === 1 && chartTypes.getAt(0).varyColors))
    )
      return true;

    return false;
  }

  setupLegend(chartInfo, unListenerProperties) {
    const chartModel = chartInfo.chartModel;

    unListenerProperties.push(
      this.npl.listen(
        chartModel,
        ["legend.shown"],
        function (event, listenerRef) {
          const legendShown = chartModel.legend.shown;
          if (!legendShown) return;
          let legendElement = new LegendElement(chartInfo.chartContainer);
          chartInfo.legendElement = legendElement;

          listenerRef.addDisposable(function () {
            if (chartInfo.legendElement) {
              chartInfo.legendElement.remove();
              delete chartInfo.legendElement;
            }
          });

          this.npl.listen(
            chartModel,
            ["legend.*", "types", "types[*]"],
            function (event, listenerRef) {
              const onManualMoveListener = function (event) {
                let manualBounds = legendElement.manualBounds();
                chartModel.legend.manualLayout = {
                  x: manualBounds.x,
                  y: manualBounds.y,
                  width: manualBounds.width,
                  height: manualBounds.height,
                };
              };
              legendElement.addListener("manualMove", onManualMoveListener);
              listenerRef.addDisposable(function (ref) {
                legendElement.removeListener(
                  "manualMove",
                  onManualMoveListener
                );
              });
              // If legend is 'hidden' then reset a few fieelds
              if (!chartModel.legend.shown) {
                if (chartModel.legend.manualLayout)
                  chartModel.legend.manualLayout = undefined;
              }

              legendElement.orientation = chartModel.legend.position;
              legendElement.overlay = chartModel.legend.overlay;
              this.applyShapeStyle(
                legendElement.background(),
                chartModel.legend
              );
              legendElement.background().draw();
              this.invalidateBounds(chartInfo);

              this.npl.listen(
                chartModel,
                ["series[*].title.*", "series[*].strokeWidth", "legend.shown"],
                function (event) {
                  // Why is this in a single series check?
                  if (!this.isLegendForSingleSeries(chartModel))
                    this.applyLegendItems(legendElement, chartModel, false);
                  this.invalidateBounds(chartInfo);
                }.bind(this),
                listenerRef
              );

              this.npl.listen(
                chartModel,
                [
                  "series[0].*",
                  "chartTypes[*].varyColors",
                  "series[0].title.*",
                  "series[*].strokeWidth",
                  "legend.shown",
                ],
                function (event) {
                  if (this.isLegendForSingleSeries(chartModel))
                    this.applyLegendItems(legendElement, chartModel, true);
                }.bind(this),
                listenerRef
              );

              this.npl.listen(
                chartModel,
                [
                  "series[*].markers.*",
                  "series[*].fill",
                  "series[*].strokeFill",
                  "series[*].strokeLineJoin",
                  "series[*].strokeLineCap",
                  "series[*].strokeDash",
                  "chartTypes[*].isVaryColor",
                ],
                function (event) {
                  if (!this.isLegendForSingleSeries(chartModel))
                    this.applyLegendItems(legendElement, chartModel, false);
                },
                listenerRef
              );
            }.bind(this)
          );
        }
      )
    );
  }

  setupTooltip(chartInfo) {
    let chartElement = chartInfo.chartElement;
    chartElement.tooltip(false);
    let tooltipInfo = {
      seriesElement: null,
      elementTooltip: null,
    };

    let render = this;
    function updateLocation(clientX, clientY) {
      if (tooltipInfo.elementTooltip) {
        let elem =
          tooltipInfo.elementTooltip.getElementsByClassName(
            "legend-background"
          );
        let boundsLegend = { left: 0, top: 0, width: 5000, height: 5000 };
        if (elem.length === 1) {
          let clientRect = tooltipInfo.elementTooltip
            .getElementsByClassName("legend-background")[0]
            .getBoundingClientRect();
          boundsLegend.width = clientRect.width;
          boundsLegend.height = clientRect.height;
        }
        tooltipInfo.tooltipBackground.setBounds(boundsLegend);
        let toolTiprect = tooltipInfo.elementTooltip.getBoundingClientRect();

        let offsetX = 20;
        let offsetY = offsetX / 2;
        let chartBounds = render._stage.getBounds();
        // corner. prefers top/left
        if (
          toolTiprect.height + clientY + offsetY >=
          chartBounds.top + chartBounds.height
        )
          clientY = clientY - (toolTiprect.height + offsetY);
        else clientY = clientY + offsetY;
        if (
          toolTiprect.width + clientX + offsetX >=
          chartBounds.left + chartBounds.width
        )
          clientX = clientX - (toolTiprect.width + offsetX);
        else clientX = clientX + offsetX;

        let scale = 1; //shape.context.getScale();
        if (scale > 1) {
          tooltipInfo.elementTooltip.style.left = clientX / scale + "px";
          tooltipInfo.elementTooltip.style.top = clientY / scale + "px";
        } else {
          tooltipInfo.elementTooltip.style.left = clientX / 1 + "px";
          tooltipInfo.elementTooltip.style.top = clientY / 1 + "px";
        }
      }
    }

    chartElement.listen("mouseMove", (e) => {
      if (e.sliceIndex !== undefined && !tooltipInfo.elementTooltip) {
        let newEvent = {
          point: {
            original: e,
            index: e.sliceIndex,
            chart: e.target,
          },
          actualTarget: e.target,
          originalEvent: e,
        };
        showTooltip.call(render, newEvent);
      } else updateLocation(e["offsetX"], e["offsetY"]);
    });

    function closeTooltip(e) {
      if (!this) return;
      // TODO - later make this an animation
      if (tooltipInfo.elementTooltip) {
        this._rendererContainer.removeChild(tooltipInfo.elementTooltip);
        tooltipInfo.elementTooltip = null;
      }
      if (tooltipInfo.stage) {
        tooltipInfo.stage.removeAllListeners();
        tooltipInfo.stage.dispose();
        tooltipInfo.stage = null;
      }
      if (tooltipInfo.stageLegendBackground) {
        tooltipInfo.stageLegendBackground.removeAllListeners();
        tooltipInfo.stageLegendBackground.dispose();
        tooltipInfo.stageLegendBackground = null;
      }
      tooltipInfo.seriesElement = null;
    }

    function showTooltip(e) {
      // series instance with all its API
      let tooltipHtml = "";
      let seriesElement;
      let seriesInfo;

      function createTooltip(point, seriesInfo, axisInfoX, axisInfoVal, pointInstance, renderer) {
        let tooltipHtml =
          '<div class="legend-border"><div class="legend-container"><div class="legend-background"></div><div class="legend-panel"></div></div>';
        tooltipHtml += '<div class="labels-container">';
        tooltipHtml += '<div class="divider"></div>';

        //                     let maxWidth = axisInfoX.axis.limits.tickWidth;
        //                     if (maxWidth) {
        // TODO - set max width (after we have finished rendering using text)
        //                     }
        let valueX = point.x;
        let formatedXValue = valueX;
        let xStyles = "";
        if (axisInfoX) {
          formatedXValue = axisInfoX.formatter.call({
            index: point.index,
            value: valueX,
            tickValue: valueX,
          });
          xStyles = ` style="font-family: ${axisInfoX.axis.labels.text.font.name}; font-size: ${axisInfoX.axis.labels.text.size * TextUtils.fontScale()}px;"`;
          //xStyles.fontColor = axisInfoX.axis.labels.text.fill;
       } else {
           formatedXValue = null;//renderer.getFormatXValue(pointInstance);
       }

        let alignClass;
        if (axisInfoX && axisInfoX.axis.axisType === "ord") {
          alignClass = "";
        } else {
          alignClass = " right-aligned";
        }

        if (axisInfoX != null && formatedXValue && formatedXValue.length > 0) {
            tooltipHtml += '<div class="axis">';
            tooltipHtml +=
              '<div class="value' + alignClass + '"' + xStyles + '>' + formatedXValue + "</div>";
            tooltipHtml += "</div>";
            tooltipHtml += '<div class="divider"></div>';
        }

        let valStyles = "";
        let formatedValue = point.value;
        if (seriesInfo) {
            formatedValue = seriesInfo.formatter(point, true/*showVal*/);
            valStyles = ` style="font-family: ${seriesInfo.seriesInstance.labels.text.font.name}; font-size: ${seriesInfo.seriesInstance.labels.text.size * TextUtils.fontScale()}px;"`;
            //valStyles.fontColor = axisInfoX.axis.labels.text.fill;
        }
        tooltipHtml += '<div class="axis">';
        tooltipHtml +=
          '<div class="value display-value"' + valStyles + '>' + formatedValue + "</div>";
        tooltipHtml += "</div>";

        // If stacked then show the 'relative value'
        let grouping = null;
        if (axisInfoX && axisInfoVal)
          grouping = axisInfoX.axis.chartType.grouping;
        if (grouping === "stacked" || grouping === "percentStacked") {
          let originalValue = point.originalValue;
          let valueTipAux = point.value;
          if (originalValue !== null && grouping === "percentStacked") {
            let bucket = axisInfoVal.axis.groupInfo.buckets[point.dataOffset];
            if (!bucket) bucket = axisInfoVal.axis.groupInfo.buckets[point.x];
            if (bucket) valueTipAux = originalValue * bucket.factor || 1;
          }

          let formatedAuxValue = axisInfoVal.formatter.call({
            index: point.index,
            value: valueTipAux,
            tickValue: valueTipAux,
          });
          let auxStyles = ` style="font-family: ${axisInfoVal.axis.labels.text.font.name}; font-size: ${axisInfoVal.axis.labels.text.size * TextUtils.fontScale()}px;"`;

          if (formatedValue !== formatedAuxValue) {
            tooltipHtml += '<div class="axis">';
            tooltipHtml +=
              '<div class="value display-value"' + auxStyles + '>' + formatedAuxValue + "</div>";
            tooltipHtml += "</div>";
          }
        }

        tooltipHtml += "</div></div>";
        return tooltipHtml;
      }
      let legendOffset = 0;
      if (!e.series) {
        seriesInfo = this._seriesInfosByElemement.get(e.actualTarget.id());
        if (!seriesInfo) {
          console.warn("no series for tooltip", e.actualTarget.id());
          closeTooltip();
          return;
        }

        let points = seriesInfo.seriesInstance.renderedPoints;
        if (!points)
          return;
        let pointInstance = points[e.point.index];
        legendOffset = e.point.index;
        let point = {
          x: pointInstance.x,
          index: e.point.index,
          dataOffset: e.point.index,
          value: pointInstance.val,
        };
        tooltipHtml = createTooltip(point, seriesInfo, null, null, pointInstance, this);
      } else {
        let seriesElement = e.series;
        seriesInfo = this._seriesInfosByElemement.get(seriesElement.id());
        legendOffset = seriesInfo.seriesInstance.offset;
        if (!seriesInfo) {
          console.warn("no series for tooltip", seriesElement.id());
          closeTooltip();
          return;
        }

        if (tooltipInfo.seriesElement !== seriesElement.id()) {
          tooltipInfo.seriesElement = seriesElement.id();
          let axiesBySeriesInfo = this._axesBySeries.get(
            seriesInfo.seriesInstance
          );
          let point = {
            x: e.point.get("x"),
            index: e.point.index,
            dataOffset: e.point.get("dataOffset"),
            originalValue: e.point.get("originalValue"),
            value: e.point.get("value"),
          };
          tooltipHtml = createTooltip(
            point,
            seriesInfo,
            axiesBySeriesInfo.axisX,
            axiesBySeriesInfo.axisVal,
            null/*pointInstance*/,
            this);
        }
      }

      closeTooltip();
      if (tooltipHtml) {
        tooltipHtml = '<div class="chart-tooltip">' + tooltipHtml + "</div>";
        let div = document.createElement("div");
        div.innerHTML = tooltipHtml;
        tooltipInfo.elementTooltip = div.firstChild;
        this._rendererContainer.appendChild(tooltipInfo.elementTooltip);

        let legendBackgroundHtmlElement =
          tooltipInfo.elementTooltip.getElementsByClassName(
            "legend-background"
          );
        tooltipInfo.stageLegendBackground = this.anychart.graphics.create();
        tooltipInfo.stageLegendBackground.container(
          legendBackgroundHtmlElement[0]
        );
        tooltipInfo.tooltipBackground = tooltipInfo.stageLegendBackground.rect(
          0,
          0,
          5000,
          5000
        );
        tooltipInfo.stageLegendBackground.addChild(
          tooltipInfo.tooltipBackground
        );
        this.applyShapeStyle(
          tooltipInfo.tooltipBackground,
          chartInfo.chartModel
        );

        let legendHtmlElement =
          tooltipInfo.elementTooltip.getElementsByClassName("legend-panel");
        tooltipInfo.stage = this.anychart.graphics.create();
        tooltipInfo.stage.container(legendHtmlElement[0]);

        let legend = this.anychart.standalones.legend();
        legend.vAlign("middle");
        // Hmm. This should not be needed some sort of anychart issue?
        legend.padding().top(8);

        legend.container(tooltipInfo.stage);

        legend.background().enabled(true);
        this.applyShapeStyle(legend.background(), chartInfo.chartModel.legend);

        this.applySingleLegendItem(
          legend,
          chartInfo.chartModel,
          this.isLegendForSingleSeries(chartInfo.chartModel),
          legendOffset
        );

        this.applyCredits(tooltipInfo.elementTooltip);
        legend.draw();

        let naturalDims = legend.background().getPixelBounds();
        tooltipInfo.stage.resize(naturalDims.width + 4, naturalDims.height + 4);
        legend.parentBounds(2, 2, naturalDims.width, naturalDims.height);

        updateLocation(e.originalEvent["offsetX"], e.originalEvent["offsetY"]);
      }
    }

    chartElement.listen("pointMouseOver", function (e) {
      showTooltip.call(render, e);
    });

    chartElement.listen("pointMouseOut", function (e) {
      closeTooltip.call(render, e);
    });
  }

  setBackground(chartInfo) {
    let mainBackground;
    if (chartInfo.chartModel.roundedCorners) {
      let mainBackgroundRect = new this.acgraph.math.Rect(
        0,
        0,
        this._stage.width(),
        this._stage.height()
      );
      mainBackground = this._stage.roundedRect(mainBackgroundRect, 10);
    } else {
      mainBackground = this._stage.rect(
        0,
        0,
        this._stage.width(),
        this._stage.height()
      );
    }
    chartInfo.mainBackground = mainBackground;
    chartInfo.backgroundLayer.removeChildren();
    chartInfo.backgroundLayer.addChild(mainBackground);
    this.applyShapeStyle(mainBackground, chartInfo.chartModel);
  }

  setupMainChart(chartModel, unListenerProperties) {
    let chartInfo = {
      chartModel: chartModel,
    };

    this._stage.suspend();
    this.npl.suspend();
    this._chartDrawn = false;

    chartInfo.chartContainer = new ChartContainer(
      this.anychart,
      this.acgraph,
      this._stage
    );
    let mainLayer = this._stage.layer();
    chartInfo.mainLayer = mainLayer;
    this._stage.addChild(mainLayer);

    chartInfo.backgroundLayer = this._stage.layer();
    mainLayer.addChild(chartInfo.backgroundLayer);

    // To support axis create a single column chart that we use for grids/axes
    let gridElement = this.anychart["column"]();
    // Anychart generates 1 x and y automatically and doesn't allow us to
    // remove them so we 'hide them and count'
    this.hideAxis(gridElement.xAxis(0));
    this.hideAxis(gridElement.yAxis(0));

    let layerGrid = this._stage.layer();
    this._stage.addChild(layerGrid);
    gridElement.container(layerGrid);
    chartInfo.gridElement = gridElement;
    gridElement.padding(0, 0, 0, 0);
    chartInfo.gridElement.background().enabled(false);
    //gridElement.background().fill('pink');

    //         let customContextMenu = anychart.ui.contextMenu();
    //         customContextMenu.attach(chart);
    //         let contextMenu = chartElement.contextMenu();
    //         contextMenu.setup({enabled: true});
    //         document.oncontextmenu = function(event){
    //          if(event.preventDefault != undefined)
    //           event.preventDefault();
    //          if(event.stopPropagation != undefined)
    //           event.stopPropagation();
    //         }

    this._stage.listenOnce("renderstart", () => {
      this.resizeHandler = {};
      if (process.env.NODE_ENV !== "production")
        console.log("creating handler");

      let firstEvent = true;
      this.resizeHandler.observer = new ResizeObserver(
        function (entries, observer) {
          if (firstEvent) {
            firstEvent = false;
            return;
          }
          this.invalidateBounds(chartInfo, entries[0].contentRect);
        }.bind(this)
      );
      this.resizeHandler.observed = this._rendererContainer;
      this.resizeHandler.observer.observe(this.resizeHandler.observed);
      //             unListenerProperties.push(function() {
      //                 //this.unobserve();
      //             }.bind(this));
    });

    unListenerProperties.push(
      this.npl.listen(
        chartModel,
        ["types", "types[*].type"],
        function (event, listenerRef) {
          for (let i = 0; i < chartModel.types.length; i++) {
            let chartType = chartModel.types.getAt(i);
            this.setupChartType(chartInfo, chartType, listenerRef);
          }

          this.npl.listen(
            chartModel,
            "series",
            function (event, listenerRef) {
              let seriesList = chartModel.series;

              for (let i = 0; i < seriesList.length; i++) {
                let seriesInstance = seriesList.getAt(i);
                this.setupSeries(chartInfo, seriesInstance, listenerRef);
              }
              listenerRef.addDisposable(
                function () {
                  this._seriesInfosByModel.clear();
                  this._seriesInfosByElemement.clear();
                  if (chartInfo.chartElement.removeAllSeries)
                    // single series doesn't have a remove all
                    try {
                        chartInfo.chartElement.removeAllSeries();
                    } catch (error) {
                        console.warn(error);
                    }
                }.bind(this)
              );
            },
            listenerRef
          );

          this.npl.listen(
            chartModel,
            ["axes", "dispNaAsBlank", "dispBlanksAs"],
            function (event, listenerRef) {
              let axes = chartModel.axes;
              for (let i = 0; i < axes.length; i++) {
                let axis = axes.getAt(i);
                let axisInfo = this._axesInfosByModel.get(axis);
                if (!axisInfo) {
                  axisInfo = {
                    axis: axis,
                    count: 1,
                  };
                  this._axesInfosByModel.set(axis, axisInfo);
                }
                for (let j = 0; j < axis.series.length; j++) {
                  let seriesInstance = axis.series.getAt(j);
                  let axiesBySeriesInfo =
                    this._axesBySeries.get(seriesInstance);
                  if (!axiesBySeriesInfo) {
                    axiesBySeriesInfo = {};
                    this._axesBySeries.set(seriesInstance, axiesBySeriesInfo);
                  }
                  if (axis.direction === "x")
                    axiesBySeriesInfo.axisX = axisInfo;
                  else if (axis.direction === "y")
                    axiesBySeriesInfo.axisVal = axisInfo;
                  this.markSeriesDirty(chartInfo, seriesInstance);
                }
                this.setupAxis(
                  chartInfo,
                  axis,
                  axis.offset /*offset*/,
                  axisInfo,
                  listenerRef
                );
                // Add it to the types (be sure to remove too)
                // TODO - only assign if the axis has the correct offset (not yet implemented)
                //this._typeInfosByModel.forEach(function (typeInfo, type) {
                //typeInfo.typeElement[axis.direction + 'Axis'](0).scale(axisInfo.axisElement._scaleElementMajor);
                //});
              }
              listenerRef.addDisposable(function () {
                this._axesInfosByModel.clear();
                this._axesBySeries.clear();

                chartInfo.gridElement["xGrid"]().enabled(false);
                chartInfo.gridElement["yGrid"]().enabled(false);
                chartInfo.gridElement["xMinorGrid"]().enabled(false);
                chartInfo.gridElement["yMinorGrid"]().enabled(false);
              });
            },
            listenerRef
          );

          listenerRef.addDisposable(function () {
            this._typeInfosByModel.clear();
          });
        }
      )
    );

    // order affects z-order
    this.setupLegend(chartInfo, unListenerProperties);
    this.setupTitle(chartInfo, unListenerProperties);

    unListenerProperties.push(
      this.npl.listen(
        chartModel,
        [
          "roundedCorners",
          "fill",
          "strokeFill",
          "strokeWidth",
          "strokeLineJoin",
          "strokeLineCap",
          "strokeDash",
        ],
        function (event) {
          this.setBackground(chartInfo);
        }
      )
    );

    unListenerProperties.push(
      this.npl.listen(chartModel, "plotArea.*", function (event) {
        gridElement.dataArea().background().enabled(true);
        this.applyShapeStyle(
          gridElement.dataArea().background(),
          chartModel.plotArea
        );
      })
    );

    this.invalidateBounds(
      chartInfo,
      undefined,
      function () {
        this.applyDirtySeries();
        this._chartDrawn = true;
        this.npl.resume();
        this._stage.resume();
      }.bind(this)
    );
  }

  renderChartError(error) {
    while (this._rendererContainer.firstChild) {
      this._rendererContainer.firstChild.remove();
    }
    let errorHtml =
      `
        <div class="chart-error" style="display: flex; width:100%;height:100%;z-index:1; align-items: center;justify-content: center; flex-direction: column;background:rgba(0,0,0, .20);position:absolute">
            <div style="position:relative">Unable to load</div>
            <div style="position:relative">` +
      error +
      `</div>
        </div>
        `;

    let div = document.createElement("div");
    div.innerHTML = errorHtml;
    let elementError = div.firstChild;
    this._rendererContainer.appendChild(elementError);
  }
}

export default ChartRenderer;