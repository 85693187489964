import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import useDAGMProperty from "../hooks/useDAGMProperty";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  heading: {
    //     fontSize: theme.typography.pxToRem(15),
    //     margin: theme.spacing(1),
  },
}));

function ChartElementChoice(props) {
  const { chartElement, eId, shape, ...other } = props;

  const classes = useStyles();

  const [value, setValue, propertyValue] = useDAGMProperty(
    shape,
    "description"
  );

  return <Typography className={classes.heading}>{value}</Typography>;
}

export default ChartElementChoice;
