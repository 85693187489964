import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import BooleanPropertyEditor from "../BooleanPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";
import HeaderContainer from "../HeaderContainer";

import DataLabelSeparatorPropertyEditor from "./DataLabelSeparatorPropertyEditor";
import DataLabelPositionPropertyEditor from "./DataLabelPositionPropertyEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartDataLabelPropertyEditor(props) {
  const { shape, type, ...other } = props;
  const classes = useStyles();

  /*
      col/bar - { 'ctr' : true, 'inEnd' : true, 'inBase' : true,   'outEnd' : true   }
      line -    { 'ctr' : true, 'l' : true,      'r' : true,       't' : true,      'b' : true}
      scatter - { 'ctr' : true, 'l' : true,      'r' : true,       't' : true,      'b' : true}
      pie -     { 'ctr' : true, 'inEnd' : true,  'outEnd' : true,  'bestFit' : true  }
      area -    { }
  */
  let filterPositions = null;
  if (type === 'bar' || type === 'column') {
    filterPositions = { 'ctr': true, 'inEnd': true, 'inBase': true, 'outEnd': true };
  } else if (type === 'line') {
    filterPositions = { 'ctr': true, 'l': true, 'r': true, 't': true, 'b': true }
  } else if (type === 'scatter') {
    filterPositions = { 'ctr': true, 'l': true, 'r': true, 't': true, 'b': true };
  } else if (type === 'pie') {
    filterPositions = { 'ctr': true, 'inEnd': true, 'outEnd': true, 'bestFit': true };
  } else if (type === 'area') {
    // nothing
  }

  return (
    <>
      {filterPositions ? (
        <DataLabelPositionPropertyEditor
          shape={shape}
          property="position"
          filter={filterPositions}>
        </DataLabelPositionPropertyEditor>
      ) : <></>}
      <HeaderContainer
        className={classes.flexCol}
        label="Contains"
        type="heading"
        collapsible={true}
      >
        <ExplicitPropertyEditorContainer>
          <BooleanPropertyEditor
            label="Series Name"
            shape={shape}
            property="showSerName"
          />
        </ExplicitPropertyEditorContainer>
        <ExplicitPropertyEditorContainer>
          <BooleanPropertyEditor
            label="Category Name"
            shape={shape}
            property="showCatName"
          />
        </ExplicitPropertyEditorContainer>
        <ExplicitPropertyEditorContainer>
          <BooleanPropertyEditor
            label="Value"
            shape={shape}
            property="showVal"
          />
        </ExplicitPropertyEditorContainer>
        <DataLabelSeparatorPropertyEditor
          shape={shape}
          property="separator">
        </DataLabelSeparatorPropertyEditor>
      </HeaderContainer>
    </>
  );

}

export default ChartDataLabelPropertyEditor;
