import React from 'react';

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Tooltip from '@material-ui/core/Tooltip';

import { AdjustableColor } from "@sheetxl/models";

import Swatch from './Swatch';

const useStyles = makeStyles(function (theme) {
  return {
      'active': {},
      button: {
        background: function({color}) {
          return color;
        },
        width: '15px',
        height: '15px',
        position: 'relative',
        boxSizing: 'border-box',
        cursor: 'pointer',
        outline: 'none',
//         '&$active': {
//           boxShadow: 'inset 0 0 0 1px #ddd'
//         },
      },
      dot: {
        position: 'absolute',
        width: '5px',
        height: '5px',
        top: 'calc(50% - 2.5px)',
        left: 'calc(50% - 2.5px)',
        // left: '50%',
        // top: '50%',
        // transform: 'translate(-50%,-50%)',
        pointerEvents: 'none',
        background: function({color}) {
          // return the contrast color with full alpha
           return new AdjustableColor(color, [{ inv: true }, { alpha: 100 }]).toRGBAColor().toString();
        },
        borderRadius: '50%',
        opacity: '0',
        '&$active': {
          opacity: '1'
        }
      }
    }
});

export const ThemeColorButton = function(props) {
  const {
    theme,
    colorDef,
    onClick,
    onSwatchHover,
    activeColor,
    className,
    ...other
  } = props;

  const color = new AdjustableColor(colorDef.color.val, colorDef.color.adjs, function(value) {
      return theme.schemeLookup(value);
  });

  const active = JSON.stringify(activeColor.toJSON()) === JSON.stringify(color.toJSON());
  const classes = useStyles({color: color.toRGBAColor().toString()});

  return (
    <Tooltip title={colorDef.description} placement="bottom-start">
    <div
       className={clsx(className, classes.button, {
        [classes.active] : active
       })} {...other}>
        <Swatch
          color={color.toRGBAColor()}
          title={null}
          onClick={(e) => {
              if (onClick)
                onClick(color, e);
          }}
          onHover={onSwatchHover}
          // focusStyle={{ boxShadow: '0 0 4px ' + color }}
        >
        <div
          className={clsx(classes.outline)}
        />
        <div
          className={clsx(classes.dot, {
            [classes.active] : active
          })}
        />
        </Swatch>
    </div>
    </Tooltip>
  );
};

export default ThemeColorButton;