import React, { useMemo } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import useDAGMProperty from "./hooks/useDAGMProperty";

import SelectPropertyEditor from "./SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";

// import { Font } from "@sheetxl/models";
import { FontLibrary } from "@sheetxl/models";

const useStyles = makeStyles(function(theme) {
    return {
      fontRenderer: {
        display: "flex",
        flex: "1 1 100%",
        flexDirection: "row",
        alignItems: "center",
      },
      meta: {
        fontStyle: 'italic',
        color: theme.palette.text.hint
      }
    }
});

const fontsRegistered = FontLibrary.getFontList();

function FontPropertyEditor(props) {
  const {
    shape,
    property="font",
    label="Font",
    className : classNameProp,
    inputWidth,
    ...other } = props;

  const [value] = useDAGMProperty(shape, property);

  const mapFont = useMemo(function() {
      const retValue = new Map();
      for (let i=0; i<fontsRegistered.length; i++) {
          retValue.set(fontsRegistered[i].fontRef.name, fontsRegistered[i]);
      }
      return retValue;
  }, [value]);

  const values = useMemo(function() {
      const retValue = [];
      for (let i=0; i<fontsRegistered.length; i++) {
          retValue.push({
            key: fontsRegistered[i].fontRef.name,
            description: fontsRegistered[i].fontRef.name
          });
      }
      if (!mapFont.get(value.name)) {
        retValue.unshift({
            key: value.name,
            description: value.name
        });
      }
      return retValue;
  }, [value, mapFont]);


  const valueToKey = function (value) {
    if (!value)
      return "Arial";
    return value.name || 'Undefined';
  };

  const keyToValue = function (key) {
    let retValue = mapFont.get(key);
    return retValue;
  };

  const adjustSetValue = function(value) {
      return value.fontRef;
  }

  const classes = useStyles({ });

  const fontRenderer = useMemo(function() {
    return function (propsfont) {
      let fontHandle = mapFont.get(propsfont.key);
      let fontName;
      if (fontHandle) {
        fontName = fontHandle.fontRef.name;
      } else {
          // Trying to render a font that was not in the set. This can occur if the default is unregistered
          fontName = propsfont.key;
          fontHandle = {
            registered: false,
            loaded: false
          }
      }

      let meta = <></>;
      if (fontHandle.registered === false)
        meta = <div className={clsx(classes.meta, classes.unregistered)}>Unregistered</div>;
      else if (!fontHandle.loaded)
        meta = <div className={clsx(classes.meta, classes.unavailable)}>Unavailable</div>;

      return (
          <div
            className={clsx(classes.fontRenderer, propsfont.className)}
          >
            <div style={{fontFamily: fontName}}>{fontName}</div>
            <div style={{flex: "1 1 100%"}}></div>
            {meta}
          </div>
      );
      }
  }, [classes, mapFont]);

  return (
    <ExplicitPropertyEditorContainer className={classNameProp} {...other}>
      <SelectPropertyEditor
        label={label}
        values={values}
        shape={shape}
        renderer={fontRenderer}
        inputWidth={inputWidth}
        property={property}
        valueToKey={valueToKey}
        keyToValue={keyToValue}
        adjustSetValue={adjustSetValue}
      ></SelectPropertyEditor>
    </ExplicitPropertyEditorContainer>
  );
}

export default FontPropertyEditor;
