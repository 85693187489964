import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import { LabelFormatStyles } from "@sheetxl/models";

import useDAGMProperty from "./hooks/useDAGMProperty";

import SelectPropertyEditor from "./SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
    alignedRow: {
      display: "flex",
      flexDirection: "row",
      flex: "1 1 100%",
      minWidth: "0px",
      alignItems: "center"
    },
  },
}));

function LabelsFormatCategoryPropertyEditor(props) {
  const { shape, ...other } = props;
  const classes = useStyles();

  const [valueFormat, setValue] = useDAGMProperty(shape, "formatCode");

  let valuesPrimary = LabelFormatStyles.lookupPrimaryFormatCodes();

  // TODO Replace the current value with this
  let valuesReplace = LabelFormatStyles.lookupFormatType(valueFormat);

  let values = [];
  let customKey = valueFormat;
  for (let i = 0; i < valuesPrimary.length; i++) {
    values.push({
      key: valuesPrimary[i].formatCode,
      description: valuesPrimary[i].formatType,
    });
    if (valuesPrimary[i].formatCode === valueFormat) {
      customKey = "#,##0.00;[Red]#,##0.00"; // if we have selected a value we change custom to something else.
    }
  }

  values.push({ key: customKey, description: "Custom" });

  // TODO - add a textfield and a boolean field (but not property editors)
  // TODO - add a negative color toggle (or just a boolean for red)
  return (
    <Box className={classes.root} {...other}>
      <ExplicitPropertyEditorContainer>
        <SelectPropertyEditor
          label="Category"
          values={values}
          shape={shape}
          property="formatCode"
        ></SelectPropertyEditor>
      </ExplicitPropertyEditorContainer>
    </Box>
  );
}

export default LabelsFormatCategoryPropertyEditor;
