import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { SolidFill, NoneFill, GradientFill, PatternFill, ImageFill } from "@sheetxl/models";
import { AdjustableColor } from "@sheetxl/models";

import useDAGMProperty from "./hooks/useDAGMProperty";

import SelectPropertyEditor from "./SelectPropertyEditor";

import AdjustableColorPicker from "./theme/AdjustableColorPicker";

// import NumberPropertyEditor from './NumberPropertyEditor';
import ExpandingPropertyEditor from "./ExpandingPropertyEditor";

const useStyles = makeStyles(function (theme) {
  return {
    root: {
      display: "flex",
      flex: "1 1 100%",
      flexDirection: "column",
      alignItems: "stretch",
    },
    alignedRow: {
      display: "flex",
      flexDirection: "row",
      flex: "1 1 100%",
      minWidth: "0px",
      alignItems: "center"
    },
  };
});

function FillPropertyEditor(props) {
  const classes = useStyles();

  const {
    shape,
    property = "fill",
    label = "Fill",
    isStroke = false,
    isText = false,
    outlined = true,
    renderAdditionalFields,
    ...other
  } = props;

  const [value, setValue] = useDAGMProperty(shape, property);

  let values = [
    { key: "none", description: "None" },
    { key: "solid", description: "Solid" },
    //     { key: 'gradient', description: 'Gradient' },
    //     { key: 'pattern', description: 'Pattern' },
    //     { key: 'image', description: 'Image' }
  ];

  // We don't have these in the default list yet because they are not very clean
  if (value && value.type === 'gradient')
    values.push({ key: 'gradient', description: 'Gradient' });
  else if (value && value.type === 'pattern')
    values.push({ key: 'pattern', description: 'Pattern' });
  else if (value && value.type === 'image')
    values.push({ key: 'image', description: 'Image' });

  const valueToKey = function (value) {
    if (!value)
      return "none";
    return value.type;
//     if (value.none) {
//       return "none";
//     } else if (value.solid) {
//       return "solid";
//     }
  };

  let key = valueToKey(value);

  const keyToValue = function (key) {
    if (key === "none")
      return new NoneFill();

    if (key === "solid") {
      // TODO - lookup the theme and return accent 1
      return new SolidFill("rgba(255, 0, 0, 1)");
    }
  };

  let allowExpand = false;

  let additionalFields = <></>;
  if (renderAdditionalFields) {
    additionalFields = renderAdditionalFields(value, key);
    if (additionalFields) allowExpand = true;
  }

  const onSelectHandler = function (event, newValue) {
    if (event) event.stopPropagation();
  };

  const handleFillValChange = (change) => {
    //       if (props.shape)
    //         props.shape[props.property] = change;
    setValue(new SolidFill(change));
   // setValue(change);
  };

  let quickPreview = <></>;
  // TODO - none should have a different icon
  if (value && (value.type === 'none' || value.type === 'solid')) {
    quickPreview = (
      <AdjustableColorPicker
        style={{ padding: "7px 7px" }}
        color={value.type === 'solid' ? value.color : new AdjustableColor("transparent")}
        isStroke={isStroke}
        isText={isText}
        onChange={handleFillValChange}
      ></AdjustableColorPicker>
    );
  }

  return (
    <ExpandingPropertyEditor
      {...other}
      className={classes.root}
      label={
        <Box
          className={classes.alignedRow}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          shape={shape}
          property={property}
        >
          <SelectPropertyEditor
            label={label}
            values={values}
            valueToKey={valueToKey}
            keyToValue={keyToValue}
            shape={shape}
            property={property}
            outlined={outlined}
            onSelect={onSelectHandler}
          ></SelectPropertyEditor>
          <div style={{ flex: "0 0 0%", minWidth: "4px" }}></div>
          {quickPreview}
        </Box>
      }
      allowExpand={allowExpand}
    >
      {/*
      TODO - add colors specific properties here (gradient, pattern, ect)
      <NumberPropertyEditor display="flex" flexDirection="row" justifyContent="flex-start" style={{ marginTop: '8px' }}
        label="Size"
        shape={marker}
        property="size"
      /> */}
      {additionalFields}
    </ExpandingPropertyEditor>
  );
}

export default FillPropertyEditor;
