import React from "react";

import styled from "@emotion/styled";
import logo from "../assets/images/logo.png";

const StyledLoadingDiv = styled.div`
  background: rgba(80, 80, 80, 0.12);
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  .loading-error {
    overflow: auto;
    color: rgba(205, 0, 0, 1);
  }

  img {
    max-height: 80%;
    max-width: 80%;
  }

  img.spin {
    animation-name: spin-with-pause;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  img.spin-pause-90 {
    animation-name: spin-with-pause;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-with-pause {
    0% {
      transform: rotate(0deg);
    }
    8.33%,
    25% {
      transform: rotate(90deg);
    }
    33.33%,
    50% {
      transform: rotate(180deg);
    }
    58.33%,
    75% {
      transform: rotate(270deg);
    }
    83.33%,
    100% {
      transform: rotate(360deg);
    }
  }
`;

function LoadingComponent(props) {
  const { loadingIcon, ...other } = props;

  const _loadingIcon = loadingIcon || logo;

  return (
    <StyledLoadingDiv {...other}>
      <img src={_loadingIcon}></img>
    </StyledLoadingDiv>
  );
}

export default LoadingComponent;
