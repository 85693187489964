import { withStyles } from "@material-ui/core/styles";
import Accordion  from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionSummary from './AccordionSummary';

export const StyledAccordion = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "unset",
    },
  },
  expanded: {},
}))(Accordion);

export const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    margin: "0 0",
    overflow: "hidden",
    "&$expanded": {
      margin: "0 0",
    },
  },
  expandIcon: {
    marginRight: "7px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  expanded: {},
}))(AccordionSummary);

export const StyledAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    marginBottom: '8px'
  },
}))(AccordionDetails);
