import React from "react";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

import { ChartShape } from "@sheetxl/models";

import ChartShapePropertyEditor from "./chart/ChartShapePropertyEditor";

const useStyles = makeStyles((theme) => ({
  wrapped: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "stretch",
    justifyItems: "stretch",
    borderRadius: "0px",
    overflow: "hidden"
  },
  start: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "stretch",
    justifyItems: "stretch",
  },
  centered: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
}));

// TODO - make this support other shape types besides charts
function DynamicShapePropertyEditor(props) {
  const { shape, disableDarkMode = false, ...other } = props;

  const classes = useStyles();

  let propertyPane = (
    <Box className={classes.centered}>
      <Typography className={classes.heading}>No Properties</Typography>
    </Box>
  );

  if (shape instanceof ChartShape) {
    propertyPane = (
      <Box className={classes.start}>
        <ChartShapePropertyEditor chartModel={shape}></ChartShapePropertyEditor>
      </Box>
    );
  }

  return (
    <Paper className={classes.wrapped} {...other}>
      {propertyPane}
    </Paper>
  );
}

export default DynamicShapePropertyEditor;
