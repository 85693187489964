import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import SelectPropertyEditor from "../SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartTypeScatterPropertyEditor(props) {
  const { shape, ...other } = props;
  const classes = useStyles();

  let scatterStyles = [
    { key: "none", description: "None" },
    { key: "line", description: "Line" },
    { key: "lineMarker", description: "Line Marker" },
    { key: "marker", description: "Marker" },
    { key: 'smooth', description: 'Smooth' },
    { key: 'smoothMarker', description: 'Smooth Marker' },
  ];

  return (
    <Box className={classes.root} {...other}>
      <ExplicitPropertyEditorContainer>
        <SelectPropertyEditor
          label="Scatter Style"
          values={scatterStyles}
          shape={shape}
          property="scatterStyle"
        ></SelectPropertyEditor>
      </ExplicitPropertyEditorContainer>
    </Box>
  );
}

export default ChartTypeScatterPropertyEditor;
