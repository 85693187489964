import React from "react";
import { PropTypes } from "prop-types";

import { RangeUtils } from "@sheetxl/models";

import InputPropertyEditor from "./InputPropertyEditor";


const RangePropertyEditor = (props) => {
  const { singleRange = false, ...other } = props;

  const onPreValueChange = function (valueText) {
    let sheet = null;
    let idx = valueText.lastIndexOf("!");
    if (idx !== -1) {
      sheet = valueText.slice(0, idx);
      valueText = valueText.slice(idx + 1);
    }

    let partsRange = valueText.match(RangeUtils.REGEX_CELL_RANGE);

    if (partsRange && partsRange[2]) {
      valueText = partsRange[1] + ":" + partsRange[2];
      if (partsRange[3]) valueText += partsRange[3];
    }
    if (sheet)
        valueText = sheet + '!' + valueText;
    return valueText;
  };

  const onValueChange = function (valueText) {
    return valueText;
    // This confuses typing Sheet1 with just a single cell
//     let retValue = createRangeSelection(valueText, singleRange);
//     return retValue.toString();
  };

  return (
    <InputPropertyEditor
      {...other}
      onValueChange={onValueChange}
      onPreValueChange={onPreValueChange}
    />
  );
};

RangePropertyEditor.propTypes = { ...InputPropertyEditor.propTypes };
RangePropertyEditor.propTypes.singleRange = PropTypes.bool;

export default RangePropertyEditor;
