import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import useDAGMProperty from "../hooks/useDAGMProperty";

import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";
import AutocompletePropertyEditor from "../AutocompletePropertyEditor";

const useStyles = makeStyles((theme) => ({
  rootCrosses: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

/*
 This takes a custom property editor for custom. That is either a date picker or a number picker
 // TODO - allow this to use a date picker instead. We don't have a datepicker with a dropdown

 TODO - allow custom labels ()

*/
function ChartAxisCrossesPropertyEditor(props) {
  const {
    shape,
    property = "crossAx.crosses",
    propertyLimits = "limits",
    adjustGetValue,
    adjustSetValue,
    disabled,
    ...other
  } = props;

  const [valueLimits] = useDAGMProperty(shape, propertyLimits);
  const [value, setValue, propertyValue] = useDAGMProperty(shape, property);

  let adjust = adjustGetValue
    ? adjustGetValue
    : (input) => {
        return input;
      };
  let values = [];
  if (
    value !== "autoZero" &&
    value !== "max" &&
    value !== "min" &&
    value !== undefined
  ) {
    //let asNumber = CommonUtils.asNumber(value);
    values.push({
      key: adjust(value),
      description: adjust(value).toString(),
      isCustom: true,
      meta: "Custom",
    });
  }
  values.push({
    key: "autoZero",
    description: "Auto Zero",
    meta: adjust(valueLimits.autoZero),
  });
  values.push({
    key: "max",
    description: "Maximum",
    meta: adjust(valueLimits.max),
  });

  const onSelectHandler = function (event, newValue) {
    if (event) event.stopPropagation();
  };

  return (
    <ExplicitPropertyEditorContainer {...other}>
      <AutocompletePropertyEditor
        label={(shape.horizontal ? "Vertical" : "Horizontal") + " Crosses"}
        values={values}
        shape={shape}
        adjustGetValue={adjustGetValue}
        adjustSetValue={adjustSetValue}
        disabled={disabled}
        onSelect={onSelectHandler}
        property={property}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
      ></AutocompletePropertyEditor>
    </ExplicitPropertyEditorContainer>
  );
}

export default ChartAxisCrossesPropertyEditor;
