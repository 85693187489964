import React from "react";
import { PropTypes } from "prop-types";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import useDAGMProperty from "../hooks/useDAGMProperty";

import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";
import AdjustableColorPicker from "./AdjustableColorPicker";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 000%",
    flexDirection: "column",
    alignItems: "stretch",
    padding: '8px 0px'
  },
  labelContainer: {
    flex: "1 1 100%",
    minWidth: "0px",
    userSelect: "none",
    cursor: function({onLabelClick}) {
      return 'pointer';
    }
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  alignedRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: "1 1 0%",
    minWidth: "0px",
    cursor: 'default',
    paddingLeft: function (propsStyle) {
      return propsStyle.isHeader ? "0px" : "14px";
    },
  },
}));
function AdjustableColorPropertyEditor(props) {
  const {
    shape,
    property,
    label,
    icon,
    className : classNameProp,
    ...other
  } = props;
  const classes = useStyles({ isHeader: true });

  const [value, setValue, propertyValue] = useDAGMProperty(shape, property);

  const handleValChange = (change) => {
    setValue(change);
  };

  return (
    <Box className={clsx(classNameProp, classes.root)} {...other}>
      <ExplicitPropertyEditorContainer
        shape={shape}
        property={property}
      >
       <Typography
        className={clsx(classes.alignedRow)}
        >
          {label}
        </Typography>
        <div style={{ flex: "0 0 0%", minWidth: "4px" }}></div>
        <AdjustableColorPicker
          style={{ padding: "7px 7px" }}
          color={value}
          isStroke={false}
          isText={false} // TODO - change to icon
          icon={icon}
          onChange={handleValChange}
        >
        </AdjustableColorPicker>
      </ExplicitPropertyEditorContainer>
    </Box>
  );

}

AdjustableColorPropertyEditor.propTypes = {
  shape: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default AdjustableColorPropertyEditor;
