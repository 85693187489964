import React, { useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { ChartUtils } from "@sheetxl/models";

import useDAGMProperty from "../hooks/useDAGMProperty";

import SelectPropertyEditor from "../SelectPropertyEditor";

import ExpandingPropertyEditor from "../ExpandingPropertyEditor";
import ExpandingBooleanPropertyEditor from "../ExpandingBooleanPropertyEditor";

import ChartDataLabelPropertyEditor from "./ChartDataLabelPropertyEditor";
import ChartTypeScatterPropertyEditor from "./ChartTypeScatterPropertyEditor";
import ChartTypePiePropertyEditor from "./ChartTypePiePropertyEditor";

const useStyles = makeStyles(function (theme) {
  return {
    root: {
      display: "flex",
      flex: "1 1 100%",
      flexDirection: "column",
      alignItems: "stretch",
    },
    alignedRow: {
      display: "flex",
      flexDirection: "row",
      flex: "1 1 100%",
      minWidth: "0px",
      alignItems: "center"
    },
  };
});

function ChartTypesPropertyEditor(props) {
  const {
    shape,
    property, // to ignore
    inputWidth,
    label = "Chart Type",
    ...other
  } = props;
  const classes = useStyles();

  const [valueChartType] = useDAGMProperty(shape, "types[*]");
  // TODO - This needs to be greatly enhanced to support multipe chart types
  const chartType = valueChartType[0];

  let typeSpecificPanel = <></>;
  if (ChartUtils.isLineType(chartType.type)) {
    typeSpecificPanel = (
      <ChartTypeScatterPropertyEditor shape={chartType} />
    );
    // } else if (valueType === 'column' || valueType === 'bar') {
    //   typeSpecificPanel =
    //     <ChartTypeColumnPropertyEditor
    //       shape={valueChartType}
    //     />
  } else if (chartType.type === "pie") {
    typeSpecificPanel = <ChartTypePiePropertyEditor shape={chartType} />;
  }

  let chartTypes = [
    { key: "column", description: "Column" },
    { key: "line", description: "Line" },
    { key: "bar", description: "Bar" },
    { key: "pie", description: "Pie" },
    { key: "area", description: "Area" },
    { key: "scatter", description: "Scatter" },
  ];

  const [labels] = useDAGMProperty(chartType, "dataLabels");
  const [type] = useDAGMProperty(chartType, "type");
  const childrenLabels = useMemo(function() {
    return (
      <ChartDataLabelPropertyEditor
      shape={labels}
      type={type}
      />
    );
    }, [labels, type]
  );

  return (
    <ExpandingPropertyEditor
      {...other}
      className={classes.root}
      label={
        <Box
          className={classes.alignedRow}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          shape={chartType}
          property="type"
        >
          <SelectPropertyEditor
            label={label}
            values={chartTypes}
            shape={chartType}
            property="type"
          />
        </Box>
      }
      allowExpand={true}
    >
      {typeSpecificPanel}
      <ExpandingBooleanPropertyEditor
        label={"Data Labels"}
        shape={labels}
        property={"shown"}
        >
        {childrenLabels}
      </ExpandingBooleanPropertyEditor>
    </ExpandingPropertyEditor>
  );

}

export default ChartTypesPropertyEditor;
