import React from "react";

import SelectPropertyEditor from "./SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";

function StrokeLineJoinPropertyEditor(props) {
  const { shape, inputWidth, ...other } = props;

  let values = [
    { key: "round", description: "Round" },
    { key: "bevel", description: "Bevel" },
    { key: "miter", description: "Miter" },
  ];

  return (
    <ExplicitPropertyEditorContainer {...other}>
      <SelectPropertyEditor
        label="Join Type"
        values={values}
        shape={shape}
        inputWidth={inputWidth}
        property="strokeLineJoin"
      ></SelectPropertyEditor>
    </ExplicitPropertyEditorContainer>
  );
}

export default StrokeLineJoinPropertyEditor;
