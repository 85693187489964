import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import FillPropertyEditor from "./FillPropertyEditor";
import StrokePropertyEditor from "./StrokePropertyEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function FillStrokePropertyEditor(props) {
  const {
    shape,
    hideFill = false,
    hideStroke = false,
    label = null,
    ...other
  } = props;

  const classes = useStyles();
  let labelFill = label ? label + " Fill" : undefined;
  let labelStroke = label ? label + " Stroke" : undefined;

  let fillSummary = <></>;
  if (!hideFill) {
    fillSummary = (
      <FillPropertyEditor
        {...other}
        shape={shape}
        label={labelFill}
      ></FillPropertyEditor>
    );
  }

  let strokeSummary = <></>;
  if (!hideStroke) {
    strokeSummary = (
      <StrokePropertyEditor
        {...other}
        shape={shape}
        label={labelStroke}
      ></StrokePropertyEditor>
    );
  }

  return (
    <Box className={classes.root}>
      {fillSummary}
      {strokeSummary}
    </Box>
  );
}

export default FillStrokePropertyEditor;
