import React, { isValidElement } from 'react'

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import * as checkboard from './CheckboardUtils';

const useStyles = makeStyles(function (theme) {
  return {
    grid: {
      borderRadius: function({borderRadius}) {
         return borderRadius;
      },
      boxShadow: function({boxShadow}) {
        return boxShadow;
      },
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: function({white, grey, size: size, renderers, checkboard}) {
        return `url(${ checkboard.get(white, grey, size, renderers.canvas) }) center left`;
      }
    },
  }
});

export const Checkboard = (props) => {
  const theme = useTheme();

  const {
    className,
    white='transparent',
    grey=theme.palette.text.secondary,
    size= 8,
    renderers={},
    borderRadius, boxShadow, children,
    ...other
  } = props;
  const classes = useStyles({borderRadius: borderRadius, boxShadow: boxShadow, white: white, grey: grey, size: size, renderers: renderers, checkboard: checkboard});

  return isValidElement(children)?React.cloneElement(children, { ...children.props, style: {...children.props.style,}}):<div className={ clsx(className, classes.grid) } {...other}/>;
}

export default Checkboard;