/*
A shape component is responsible for rendering a model onto onto a dom element.

In the MVC model this is the V. 
 
*/
class AbstractComponent {
  constructor() {
    if (new.target === AbstractComponent)
      throw TypeError("can not create an abstract class Shape");
  }

  toString() {
    return "AbstractComponent";
  }

  render(animate) {}
}

// TODO - add container
// TODO - add asElement (with canvas not all shapes have elements. We are not doing this ATM)
// All shapes have a containerElement (what about nested shapes?)

// Shapes have a parent shape (parent?, domElement)

export default AbstractComponent;
