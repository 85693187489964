import ChartElement from "./ChartElement";

import { CommonUtils } from "@sheetxl/models";
import { UnitCalcs } from "@sheetxl/models";
import { ChartUtils } from "@sheetxl/models";

import { ChartCatAxisShape } from "@sheetxl/models";
import { ChartOrdAxisShape } from "@sheetxl/models";
import { ChartDateAxisShape } from "@sheetxl/models";
import { ChartValAxisShape } from "@sheetxl/models";

/**
  TODO - Think about this.
         Remove all dependeny on models. This ONLY belongs in the ChartRender (To rename to ChartModelBindings)
         Currently it's confused. Is it a standalone widget or is it bound to the axis.
         If it is standalone then it should not have references to the axis
         If it is bound then it doesn't need it's own setters.

  A widget representing an Axis. The current implemntation wraps Anycharts but could be replaced.
*/

class AxisElement extends ChartElement {
  constructor(chartContainer, axis) {
    super(chartContainer, axis);
    this._axis = axis;
    this._horizontal = axis.horizontal;

    // An oriented margin is a margin that is for the side that matched the orientation.
    this.addProperty("orientedMargin", 1.5);

    this._labelOffset = 0;
    this._labelPosition = "nextTo";
    this._crossBetween = "between";
    this._tickPositionMajor = "none";
    this._tickPositionMinor = "none";

    this._effectiveBounds = { left: 0, top: 0, width: 0, height: 0 };
    this._axisLineElement = this.createAxisLineElement();
    this._axisLineOppElement = this.createAxisLineElement();

    this._axisTickElement = this.createAxisLineElement();
    this._axisTickElement.ticks().length(5);
    this._axisTickElement.stroke({
      color: "none",
    });
    this._axisTickOppElement = this.createAxisLineElement();
    this._axisTickOppElement.ticks().length(5);
    this._axisTickOppElement.stroke({
      color: "none",
    });

    this._axisTickMinorElement = this.createAxisLineElement();
    this._axisTickMinorElement.ticks().length(4);
    this._axisTickMinorElement.stroke({
      color: "none",
    });
    this._axisTickMinorOppElement = this.createAxisLineElement();
    this._axisTickMinorOppElement.ticks().length(4);
    this._axisTickMinorOppElement.stroke({
      color: "none",
    });

    this._axisLabelsElements = this.createAxisLabelElements();
    this._axisDividersElements = this.createAxisDividerElements();

    this._layerElement = this.createLayerElement();

    this._scaleElementPlotArea = this.createScaleElement();

    this._scaleElementMajor = this.container.anychart.scales.linear(); //this.createScaleElement();
    this._scaleElementMinor = this.container.anychart.scales.linear(); //this.createScaleElement();

    this._axisLineElement.scale(this._scaleElementMajor);
    this._axisLineOppElement.scale(this._scaleElementMajor);

    this._axisTickElement.scale(this._scaleElementMajor);
    this._axisTickOppElement.scale(this._scaleElementMajor);
    this._axisTickMinorElement.scale(this._scaleElementMinor);
    this._axisTickMinorOppElement.scale(this._scaleElementMinor);

    this._scaleElementLabels = this.createScaleElement();
    this._axisLabelsElements.forEach((element) =>
      element.scale(this._scaleElementLabels)
    );

    this.setupInternalListeners();
    chartContainer.addElement(this);
  }

  /**
    layout just the label elements
  */
  layoutLabels(plotAreaBounds) {
    let offset = 0;
    let runningOffset = 0;
    let runningOffsetCeil = 0;
    let horizontal = this._horizontal;
    let cachedDims = this._cachedDims;

    let calcedOffset = this.calcLabelOffset(
      this.labelOffset(),
      this._axisLabelsElements[0].labels().fontSize()
    );
    let inverted =
      this.orientation() === "top" || this.orientation() === "left";
    if (inverted) calcedOffset = calcedOffset * -1;

    for (let i = 0; i < this._axisLabelsElements.length; i++) {
      let index = inverted ? this._axisLabelsElements.length - 1 - i : i;
      let element = this._axisLabelsElements[index];
      let elementDividier = this._axisDividersElements[index];

      if (horizontal) {
        offset = cachedDims ? cachedDims[index].height : 0;
        element.labels().offsetY(calcedOffset);
        element.parentBounds(
          plotAreaBounds.left,
          plotAreaBounds.top + runningOffset - calcedOffset,
          plotAreaBounds.width,
          offset
        );
        if (elementDividier)
          // without the offset
          elementDividier.parentBounds(
            plotAreaBounds.left,
            plotAreaBounds.top + runningOffset,
            plotAreaBounds.width,
            offset
          );
      } else {
        offset = cachedDims ? cachedDims[index].width : 0;
        element.labels().offsetX(calcedOffset);
        element.parentBounds(
          plotAreaBounds.left + runningOffset - calcedOffset,
          plotAreaBounds.top,
          offset,
          plotAreaBounds.height
        );
        if (elementDividier)
          // without the offset
          elementDividier.parentBounds(
            plotAreaBounds.left + runningOffset,
            plotAreaBounds.top,
            offset,
            plotAreaBounds.height
          );
      }
      runningOffset = runningOffset + offset;
      runningOffsetCeil = runningOffsetCeil + Math.ceil(offset);
      if (elementDividier)
        elementDividier
          .ticks()
          .length(
            i !== this._axisLabelsElements.length - 1
              ? offset
              : offset + runningOffsetCeil - runningOffset + 0.5
          );
    }
  }

  /**
    Returns the bounds needed to be displayed in container.
    For this to be acurate draw must have been called.
  */
  renderedBounds() {
    let bounds = { ...this._renderedBounds };
    return bounds;
  }

  drawLabels() {
    this._axisLabelsElements.forEach((element) => element.draw());
    this.applyLabelWidth();
  }

  draw() {
    // do nothing
    //this._layerElement.draw();
    //this._hitBox.draw();
    this._axisLineElement.draw();
    this._axisLineOppElement.draw();
    this._axisTickElement.draw();
    this._axisTickOppElement.draw();
    this._axisTickMinorElement.draw();
    this._axisTickMinorOppElement.draw();
    if (this._labelLevels > 1)
        this._axisDividersElements.forEach((element) => element.draw());
    this.drawLabels();
  }

  layout(plotAreaBounds, stackedOffsets, percentLabels, percentAxisLine) {
    this._effectiveBounds = { ...plotAreaBounds };

    let labelDims = this.naturalDims();

    let orientation = this.orientation(); // the orientation of the label relative to the axis

    let outsidePlotArea =
      (percentLabels === 0 &&
        (orientation === "bottom" || orientation === "left")) ||
      (percentLabels === 1 &&
        (orientation === "top" || orientation === "right"));

    if (this._horizontal) {
      percentAxisLine = 1 - percentAxisLine; // y direction has negative at the bottom
      percentLabels = 1 - percentLabels; // y direction has negative at the bottom
    }

    let labelBounds;
    if (orientation === "top") {
      if (outsidePlotArea) {
        labelBounds = {
          left: plotAreaBounds.left,
          top:
            plotAreaBounds.top -
            labelDims.height +
            this.orientedMargin +
            stackedOffsets.top,
          width: plotAreaBounds.width,
          height: labelDims.height - this.orientedMargin,
        };
        stackedOffsets.top = stackedOffsets.top - labelDims.height;
      } else {
        labelBounds = {
          left: plotAreaBounds.left,
          top:
            plotAreaBounds.top -
            labelDims.height +
            this.orientedMargin +
            plotAreaBounds.height * percentLabels,
          width: plotAreaBounds.width,
          height: labelDims.height - this.orientedMargin,
        };
      }
    } else if (orientation === "left") {
      if (outsidePlotArea) {
        labelBounds = {
          left:
            plotAreaBounds.left -
            labelDims.width +
            this.orientedMargin +
            stackedOffsets.left,
          top: plotAreaBounds.top,
          width: labelDims.width - this.orientedMargin,
          height: plotAreaBounds.height,
        };
        stackedOffsets.left = stackedOffsets.left - labelDims.width;
      } else {
        labelBounds = {
          left:
            plotAreaBounds.left +
            plotAreaBounds.width * percentLabels -
            labelDims.width +
            this.orientedMargin,
          top: plotAreaBounds.top,
          width: labelDims.width - this.orientedMargin,
          height: plotAreaBounds.height,
        };
      }
    } else if (orientation === "right") {
      if (outsidePlotArea) {
        labelBounds = {
          left:
            plotAreaBounds.left + plotAreaBounds.width + stackedOffsets.right,
          top: plotAreaBounds.top,
          width: labelDims.width - this.orientedMargin,
          height: plotAreaBounds.height,
        };
        stackedOffsets.right = stackedOffsets.right + labelDims.width;
      } else {
        labelBounds = {
          left: plotAreaBounds.left + plotAreaBounds.width * percentLabels,
          top: plotAreaBounds.top,
          width: labelDims.width - this.orientedMargin,
          height: plotAreaBounds.height,
        };
      }
    } else if (orientation === "bottom") {
      if (outsidePlotArea) {
        labelBounds = {
          left: plotAreaBounds.left,
          top:
            plotAreaBounds.top + plotAreaBounds.height + stackedOffsets.bottom,
          width: plotAreaBounds.width,
          height: labelDims.height - this.orientedMargin,
        };
        stackedOffsets.bottom = stackedOffsets.bottom + labelDims.height;
      } else {
        labelBounds = {
          left: plotAreaBounds.left,
          top: plotAreaBounds.top + plotAreaBounds.height * percentLabels,
          width: plotAreaBounds.width,
          height: labelDims.height - this.orientedMargin,
        };
      }
    }

    this.layoutLabels(labelBounds);

    let lineOffset = 0;
    let axisBounds;
    if (this._horizontal) {
      axisBounds = {
        left: plotAreaBounds.left,
        top:
          plotAreaBounds.top +
          plotAreaBounds.height * percentAxisLine -
          lineOffset,
        width: plotAreaBounds.width,
        height: 0,
      };
    } else {
      axisBounds = {
        left:
          plotAreaBounds.left +
          plotAreaBounds.width * percentAxisLine -
          lineOffset,
        top: plotAreaBounds.top,
        width: 0,
        height: plotAreaBounds.height,
      };
    }

    this._axisLineElement.parentBounds(axisBounds);
    this._axisLineOppElement.parentBounds(axisBounds);
    this._axisTickElement.parentBounds(axisBounds);
    this._axisTickOppElement.parentBounds(axisBounds);
    this._axisTickMinorElement.parentBounds(axisBounds);
    this._axisTickMinorOppElement.parentBounds(axisBounds);

    this._effectiveBounds = ChartUtils.unionRect(labelBounds, axisBounds);
    let extrusions = this.extrusions();
    this._effectiveBounds = {
      left: this._effectiveBounds.left - extrusions.left,
      top: this._effectiveBounds.top - extrusions.top,
      width: this._effectiveBounds.width + (extrusions.left + extrusions.right),
      height:
        this._effectiveBounds.height + (extrusions.top + extrusions.bottom),
    };

    this._renderedBounds = { ...this._effectiveBounds };

    if (orientation === "top") {
      this._renderedBounds.top -= this.orientedMargin;
      this._renderedBounds.height += this.orientedMargin;
    } else if (orientation === "left") {
      this._renderedBounds.left -= this.orientedMargin;
      this._renderedBounds.width += this.orientedMargin;
    } else if (orientation === "right") {
      this._renderedBounds.width += this.orientedMargin;
    } else if (orientation === "bottom") {
      this._renderedBounds.height += this.orientedMargin;
    }

    this._hitBox.setBounds(this._effectiveBounds);
  }

  calcLabelOffset(offset = 0, fontSize = 12) {
    return fontSize / 2.5 + (offset / 100) * (fontSize / 2.5);
  }

  labelOffset(value) {
    if (value === undefined) return this._labelOffset;

    this._labelOffset = value;
    // TODO - adjust layout with this?
  }

  labelPosition(value) {
    if (value === undefined) return this._labelPosition;

    this._labelPosition = value;
    this._axisLabelsElements.forEach((element) =>
      element.enabled(value !== "none")
    );
  }

  labelAlign(value) {
    if (value === undefined) return this._labelAlign;

    this._labelAlign = value;
    // TODO - adjust layout with this
  }

  /**
   * Returns the exta padding need to fully show this.
   * In the case of axis labels this is the
   * first/last labels
   */
  extrusions() {
    let retValue = ChartUtils.ZERO_MARGIN;

    if (!this._currentTickInfo) return retValue;

    if (
      !this.shown() ||
      this.labelPosition() === "none" ||
      this._currentTickInfo.crossBetween === "between"
    )
      return retValue;
    if (
      !this._currentTickInfo.values ||
      this._currentTickInfo.values.length === 0
    )
      return retValue;
    let metricsFirst = UnitCalcs.getMetricsForLabel(
      this._currentTickInfo.values[0],
      this._axis.labels,
      this._horizontal
    );
    let metricsLast = UnitCalcs.getMetricsForLabel(
      this._currentTickInfo.values[this._currentTickInfo.values.length - 1],
      this._axis.labels,
      this._horizontal
    );

    if (this._horizontal) {
      return {
        left: metricsFirst.width / 2,
        top: 0,
        right: metricsLast.width / 2,
        bottom: 0,
      };
    } else {
      return {
        left: 0,
        top: metricsFirst.height / 2,
        right: 0,
        bottom: metricsLast.height / 2,
      };
    }
  }

  /**
    Returns the natural height and width
  */
  naturalDims() {
    let totalBounds = { left: 0, top: 0, width: 0, height: 0 };
    if (this.labelPosition() === "none") {
      return totalBounds;
    }

    let calcedOffset = this.calcLabelOffset(
      this.labelOffset(),
      this._axisLabelsElements[0].labels().fontSize()
    );

    this._cachedDims = [];
    for (let i = 0; i < this._axisLabelsElements.length; i++) {
      let originalBounds = this._axisLabelsElements[i].getPixelBounds();
      this._axisLabelsElements[i].parentBounds(null);
      let naturalBounds = this._axisLabelsElements[i].getPixelBounds();
      this._axisLabelsElements[i].parentBounds(originalBounds);

      let thisDims = { width: 0, height: 0 };
      this._cachedDims.push(thisDims);
      if (
        !this._axisLabelsElements[i].enabled() ||
        !this._axisLabelsElements[i].labels().enabled()
      )
        continue;

      if (this._horizontal) {
        thisDims.width = naturalBounds.width;
        thisDims.height = naturalBounds.height + calcedOffset;

        totalBounds.width = Math.max(totalBounds.width, thisDims.width);
        totalBounds.height =
          totalBounds.height + thisDims.height + this.orientedMargin;
      } else {
        thisDims.width = naturalBounds.width + calcedOffset;
        thisDims.height = naturalBounds.height;

        totalBounds.width =
          totalBounds.width + thisDims.width + this.orientedMargin;
        totalBounds.height = Math.max(totalBounds.height, thisDims.height);
      }
    }

    return totalBounds;
  }

  layer() {
    return this._layerElement;
  }

  get axisElement() {
    return this._axisLineElement;
  }

  get ticksMajorElement() {
    return this._axisTickElement;
  }

  get ticksMinorElement() {
    return this._axisTickMinorElement;
  }

  get axis() {
    return this._axis;
  }

  /*
   * Scale element used for rendering series
   */
  get scaleElement() {
    return this._scaleElementPlotArea;
  }

  enabled(value) {
    if (value === undefined) return this._axisLineElement.enabled();

    this._axisLabelsElements.forEach((element) => element.enabled(value));
    this._axisLineOppElement.enabled(value);

    this._axisTickElement.enabled(value);
    this._axisTickOppElement.enabled(value);
    this._axisTickMinorElement.enabled(value);
    this._axisTickMinorOppElement.enabled(value);

    this._axisDividersElements.forEach((element) => enabled(value));

    return this._axisLineElement.enabled(value);
  }

  title(value) {
    //return this._axisLineElement.title(value);
  }

  orientation(value) {
    if (value === undefined) return this._axisLineElement.orientation();

    this._axisLabelsElements.forEach((element) => element.orientation(value));
    this._axisLineOppElement.orientation(ChartUtils.oppositeOrientation(value));
    this._axisTickElement.orientation(value);
    this._axisTickOppElement.orientation(ChartUtils.oppositeOrientation(value));
    this._axisTickMinorElement.orientation(value);
    this._axisTickMinorOppElement.orientation(
      ChartUtils.oppositeOrientation(value)
    );

    this._axisDividersElements.forEach((element) => element.orientation(value));

    return this._axisLineElement.orientation(value);
  }

  shown(value) {
    if (value === undefined)
      return this._shown;

    this._shown = value;

    this._axisLabelsElements.forEach((element) =>
      element.labels().enabled(value)
    );
    this._axisDividersElements.forEach((element) => element.enabled(value));

    this.renderStyles();
    // because ppt doesn't model axis labels as a seperate we have to do it here and in the stroke
  }

  majorTicks(value) {
    if (value === undefined) {
      return this._tickPositionMajor;
    }

    this._ticks(
      value,
      this._axisTickElement,
      this._axisTickOppElement,
      "ticks"
    );
    this._tickPositionMajor = value;

    if (
      this._currentTickInfo &&
      this._currentDims &&
      this._labelLevels > 1
    ) {
      this._multiLevelinfo = this.applyMultilevel(false);
    }

    return value;
  }

  minorTicks(value) {
    if (value === undefined) {
      return this._tickPositionMinor;
    }

    this._ticks(
      value,
      this._axisTickMinorElement,
      this._axisTickMinorOppElement,
      "minorTicks"
    );
    return (this._tickPositionMinor = value);
  }

  _ticks(value, ticksElem, ticksOppsElem, func) {
    ticksElem[func]().enabled(false);
    ticksOppsElem[func]().enabled(false);

    if (value === "in" || value === "cross") {
      ticksElem[func]().enabled(true);
      ticksElem[func]().position("inside");
    }
    let hasZeroDivider = this._labelLevels > 1;
    if ((value === "out" || value === "cross") && !hasZeroDivider) {
      ticksOppsElem[func]().enabled(true);
      ticksOppsElem[func]().position("inside");
    }
  }

  crossBetween(value) {
    if (value === undefined) return this._crossBetween;
    this._crossBetween = value;
    if (
      this._axis instanceof ChartCatAxisShape &&
      this._scaleElementPlotArea.mode
    ) {
      // OOXML ignores this for multilevel axis
      if (value === "midCat" && !this._axis.labelMultiLevel) {
        this._scaleElementPlotArea.mode("continuous");
        if (this._scaleElementLabels.mode)
          this._scaleElementLabels.mode("continuous");
      } else {
        // between
        this._scaleElementPlotArea.mode("discrete");
        if (this._scaleElementLabels.mode)
          this._scaleElementLabels.mode("discrete");
      }
    }
  }

  inverted(value) {
    if (this._scaleElementPlotArea.inverted) {
      this._scaleElementMajor.inverted(value);
      this._scaleElementMinor.inverted(value);
      this._scaleElementLabels.inverted(value);

      return this._scaleElementPlotArea.inverted(value);
    }
  }

  renderStyles() {
          // This is a bit weird. OOXML uses the stroke for the axis for the stroke which
    // is correct but uses the fill for the labels. As a model it's more flexible
    // and consistent to model labels as their own styled node like data labels
    // but....
    let applyStyleFunc = function(element) {
        if (element.stroke)
           element.stroke({ color: "none" });
        if (element.fill)
           element.fill({ color: "none" });
    };
    if (this._shown && this._styleFunc) {
        applyStyleFunc = this._styleFunc;
    }

    applyStyleFunc(this._axisLineElement, this._axis, 0.75);
    applyStyleFunc(this._axisLineOppElement, this._axis, 0.75);

    applyStyleFunc(this._axisTickElement.ticks(), this._axis, 0.75);
    applyStyleFunc(this._axisTickOppElement.ticks(), this._axis, 0.75);

    applyStyleFunc(this._axisTickMinorElement.minorTicks(), this._axis, 0.75);
    applyStyleFunc(this._axisTickMinorOppElement.minorTicks(), this._axis, 0.75);

    this._axisDividersElements.forEach((element) => {
      applyStyleFunc(element.ticks(), this._axis);
    });
  }

  applyStyles(applyStyleFunc) {
    this._styleFunc = applyStyleFunc;
    this.renderStyles();
  }

  formatFactory(value) {
    if (value === undefined) return this._formatFactory;
    this._formatFactory = value;

    this.applyLabelFormat();
  }

  applyLabelFormat() {
    const labelMultiLevel = this._axis.labelMultiLevel;
    let factory = this._formatFactory;
    let axis = this._axis;
    this._axisLabelsElements.forEach(function (element, level) {
      let format = factory(axis, labelMultiLevel ? level : null);
      element.labels().padding().set(0,0,0,0); // labels in excel always have 0 padding/margin
      element.labels().format(format);
    });
  }

  applyLabels(chartInfo, applyLabelFunc, applyStyleFunc) {
    let horizontal = this._horizontal;
    this._axisLabelsElements.forEach(
      function (element, index) {
        let axisLabels = element.labels();
        applyLabelFunc(chartInfo, axisLabels, this._axis.labels);
        if (axisLabels.rotation) {
          if (index > 0) {
            axisLabels.rotation(horizontal ? 0 : -90);
          } else if (this._currentTickInfo !== undefined) {
            axisLabels.rotation(this._currentTickInfo.rotation || 0);
          }
        }
        applyStyleFunc(axisLabels.background(), this._axis.labels);
      }.bind(this)
    );
    this.applyLabelFormat();
  }

  calcPercentageAxisLine(axis) {
    let limitsCrosses = axis.crossAx.limits;

    let crossesAt = ChartUtils.calcAxisCrossValue(
      axis.crosses,
      limitsCrosses.min,
      limitsCrosses.max,
      limitsCrosses.autoZero
    );

    if (limitsCrosses.scaleType === "log") {
      let divider = Math.log(limitsCrosses.logBase);
      return ChartUtils.calcPercent(
        Math.log(crossesAt) / divider,
        Math.log(limitsCrosses.min) / divider,
        Math.log(limitsCrosses.max) / divider
      );
    }

    return ChartUtils.calcPercent(
      crossesAt,
      limitsCrosses.min,
      limitsCrosses.max
    );
  }

  convertLogToLinear(isLog, value, divider) {
    if (!isLog) return value;
    return CommonUtils.roundAccurately(Math.log(value) / divider);
  }

  convertLogToLinArray(isLog, ticks, divider) {
    if (!isLog || !ticks || ticks.length === 0) return ticks;

    let logTicks = [];
    for (let i = 0; i < ticks.length; i++) {
      logTicks.push(this.convertLogToLinear(true, ticks[i], divider));
    }
    return logTicks;
  }

  applyLabelWidth() {
    // Special case. Office seeems to wrap multi-level vertical labels
    if (this._currentDims && this._axisLabelsElements.length > 1) {
      // HACK - anychart is not maintaining the label width so we manually tracking it.
      for (let i = 1; i < this._axisLabelsElements.length; i++) {
        // We still don't wrap level 0
        let width = this._horizontal
          ? this._currentDims.width
          : this._currentDims.height;
        let defaultTickWidth = width / this._multiLevelinfo.labelColCount;
        //             if (i === 1)
        //                 this._axisLabelsElements[i].labels().background().stroke({color : 'red'}); // debugging
        //             if (i === 2)
        //                 this._axisLabelsElements[i].labels().background().stroke({color : 'green'}); // debugging
        this._axisLabelsElements[i].labels().width(defaultTickWidth - 8);
        for (
          let j = 0;
          j < this._axisLabelsElements[i].labels().getLabelsCount();
          j++
        ) {
          let width = defaultTickWidth * this._multiLevelinfo.spans[i][j] - 8; // let insets = labels.text.insets;
          let label = this._axisLabelsElements[i].labels().getLabel(j);
          label.width(width);
        }
      }
    }
  }

  applyMultilevel(allLevels = true) {
    if (!this._currentTickInfo) return;
    let spans = [];
    let spansValues = [];
    let labelColCount = 1;
    let isMultiLabel = false;
    const labelMultiLevel = this._axis.labelMultiLevel;
    if (labelMultiLevel) {
      const levels = this._labelLevels;
      if (levels > 1) {
        isMultiLabel = true;
        let primitivesOriginal = this._axis.xValues.asPrimitives;
        // xvalues are always in row format but below algo traverses in col so transpose

        // We do this for the ticks and for the labels. We could be smarter and
        // do this in a single loop. but... we don't...
        let primitives = CommonUtils.transpose(primitivesOriginal);
        for (let level = 0; level < levels; level++) {
          let scaleElement = this.createScaleElement();
          scaleElement.inverted(this._scaleElementLabels.inverted());
          let spannedOffset = [];
          let spannedTicks = [];
          let spannedWeights = [];
          for (let i = 0; i < primitives.length; i++) {
            // iterate for ticks
            if (
              i === 0 || level === 0 ||
              (i % (this._currentTickInfo.tickInterval || 1) === 0 &&
                !ChartUtils.isEmptyAtDepth(level, primitives[i]))
            ) {
              spannedOffset.push(i);
              spannedTicks.push(spannedTicks.length);
              spannedWeights.push(1);
            } else {
              spannedWeights[spannedWeights.length - 1]++;
            }
          }

          scaleElement.values(spannedOffset);
          scaleElement.ticks(spannedTicks);
          scaleElement.weights(spannedWeights);
          if (allLevels || level === 0) {
            this._axisDividersElements[level].scale(scaleElement);
          }
        }

        for (let level = 0; level < levels; level++) {
          let scaleElement = this.createScaleElement();
          scaleElement.inverted(this._scaleElementLabels.inverted());
          let spannedOffset = [];
          let spannedTicks = [];
          let spannedWeights = [];
          let values = [];
          spans.push(spannedWeights);
          spansValues.push(values);
          labelColCount = Math.max(labelColCount, primitives.length);
          for (let i = 0; i < primitives.length; i++) {
            if (i === 0 || level === 0 || !ChartUtils.isEmptyAtDepth(level, primitives[i])) {
              values[i] = primitives[i][levels - 1 - level];
              spannedOffset.push(i);
              spannedTicks.push(spannedTicks.length);
              spannedWeights.push(1);
            } else {
              values[i] = values[i - spannedWeights[spannedWeights.length - 1]];
              spannedWeights[spannedWeights.length - 1]++;
            }
          }

          scaleElement.values(spannedOffset);
          scaleElement.ticks(spannedTicks);
          scaleElement.weights(spannedWeights);
          if (allLevels || level === 0) {
            this._axisLabelsElements[level].scale(scaleElement);
          }
        }

        if (this.majorTicks() === "none") {
          this._axisDividersElements[0].scale(
            this._axisDividersElements[1].scale()
          );
        }
      }
    }

    return {
      spans: spans,
      spansValues: spansValues,
      labelColCount: labelColCount,
      isMultiLabel: isMultiLabel,
    };
  }

  /**
    There are multiple concepts here
    There are:
     1. plotArea scale (This is provided to the charting engine for max/min/values/ticks)
     2. majorTicks
     3. minorTicks
     4. labels

  */
  applyTickInfo(tickInfo, dims) {
    if (this._currentTickInfo === tickInfo) {
      return;
    }

    this._currentTickInfo = tickInfo;
    this._currentDims = dims;
    // Note - These are not really tick Fields
    if (
      tickInfo.bubbleMaxSize !== undefined &&
      this._chartElement.maxBubbleSize
    )
      this._chartElement.maxBubbleSize(tickInfo.bubbleMaxSize);
    if (
      tickInfo.minBubbleSize !== undefined &&
      this._chartElement.maxBubbleSize
    )
      this._chartElement.minBubbleSize(tickInfo.bubbleMaxSize);

    this._multiLevelinfo = this.applyMultilevel();

    this._axisLabelsElements[0].labels().rotation(tickInfo.rotation || 0);
    this._axisLabelsElements.forEach((element) =>
      element.labels().hAlign("center")
    ); // add to initialization
    if (tickInfo.tickWidth) {
      this._axisLabelsElements.forEach((element) =>
        element.labels().width(tickInfo.tickWidth)
      );
    } else {
      this._axisLabelsElements.forEach((element) =>
        element.labels().width(null)
      );
      this.applyLabelWidth();
    }

    if (!this._scaleElementPlotArea) return;

    let divider = Math.log(tickInfo.logBase || 10);
    let isLog = tickInfo.scaleType === "log";

    if (tickInfo.min !== undefined) {
      if (this._scaleElementPlotArea.minimum) {
        let min = this.convertLogToLinear(isLog, tickInfo.min, divider);
        this._scaleElementPlotArea.minimum(min);
        this._scaleElementLabels.minimum(min);
      }
    }
    if (tickInfo.max !== undefined) {
      if (this._scaleElementPlotArea.maximum) {
        let max = this.convertLogToLinear(isLog, tickInfo.max, divider);
        this._scaleElementPlotArea.maximum(max);
        this._scaleElementLabels.maximum(max);
      }
    }
    if (tickInfo.plotValues !== undefined) {
      this._scaleElementPlotArea.values(tickInfo.plotValues);
    }

    if (tickInfo.labelValues !== undefined) {
      if (this._scaleElementLabels.values)
        this._scaleElementLabels.values(
          this.convertLogToLinArray(isLog, tickInfo.labelValues, divider)
        );
    }
    if (tickInfo.labelTicks !== undefined) {
      this._scaleElementLabels.ticks(
        this.convertLogToLinArray(isLog, tickInfo.labelTicks, divider)
      );
    }

    if (tickInfo.majorTicks !== undefined) {
      if (tickInfo.ticks && this._scaleElementMajor.minimum)
        this._scaleElementMajor.minimum(
          this.convertLogToLinear(isLog, tickInfo.ticks[0], divider)
        );
      if (tickInfo.ticks && this._scaleElementMajor.maximum)
        this._scaleElementMajor.maximum(
          this.convertLogToLinear(
            isLog,
            tickInfo.ticks[tickInfo.ticks.length - 1],
            divider
          )
        );
      let majorTicks = this.convertLogToLinArray(
        isLog,
        tickInfo.majorTicks,
        divider
      );
      this._scaleElementMajor.ticks().set(majorTicks);
    }
    if (tickInfo.minorTicks !== undefined) {
      if (tickInfo.ticks && this._scaleElementMinor.minimum)
        this._scaleElementMinor.minimum(
          this.convertLogToLinear(isLog, tickInfo.ticks[0], divider)
        );
      if (tickInfo.ticks && this._scaleElementMinor.maximum)
        this._scaleElementMinor.maximum(
          this.convertLogToLinear(
            isLog,
            tickInfo.ticks[tickInfo.ticks.length - 1],
            divider
          )
        );
      this._scaleElementMinor
        .minorTicks()
        .set(this.convertLogToLinArray(isLog, tickInfo.minorTicks, divider));
    }
  }

  createBaseAxisElement() {
    let axisElement = this.container.anychart.standalones.axes.linear();

    axisElement.orientation(this.axis.position);
    axisElement.overlapMode("allow-overlap");
    axisElement.drawLastLabel(true);
    axisElement.title(null);
    return axisElement;
  }

  createAxisLineElement() {
    let axisElement = this.createBaseAxisElement();
    axisElement.stroke({
      color: "none",
    });
    axisElement.labels().enabled(false);
    axisElement.ticks().enabled(false);
    axisElement.minorTicks().enabled(false);

    return axisElement;
  }

  createAxisLabelElements() {
    let retValue = [];

    let axisElement = this.createBaseAxisElement();
    this.setupAxisLabelElement(axisElement);
    retValue.push(axisElement);

    const labelMultiLevel = this._axis.labelMultiLevel;
    this._labelLevels = 1;
    if (labelMultiLevel) {
      // We determine the orientation of the axis labels always making them opposite the first
      // series direction.
//       let valRange = null;
//       if (this._axis.series && this._axis.series.length > 0)
//           valRange = this._axis.series.getAt(0).valRange;
      this._labelLevels = this._axis.xValues.height;
      //const levels = this._axis.xValues.levels;
      for (let i = 1; i < this._labelLevels; i++) {
        let axisElement = this.createBaseAxisElement();
        this.setupAxisLabelElement(axisElement);
        retValue.push(axisElement);
      }
    }

    return retValue;
  }

  createAxisDividerElements() {
    let retValue = [];

    const labelMultiLevel = this._axis.labelMultiLevel;
    if (labelMultiLevel) {
      for (let i = 0; i < this._labelLevels; i++) {
        let axisElement = this.createBaseAxisElement();
        axisElement.labels().enabled(false);
        axisElement.ticks().enabled(true);
        axisElement.ticks().position("inside");
        axisElement.minorTicks().enabled(false);
        axisElement.stroke({
          color: "none",
        });
        retValue.push(axisElement);
      }
    }

    return retValue;
  }

  setupAxisLabelElement(axixLabelElement) {
    axixLabelElement.stroke({
      color: "none",
    });
    axixLabelElement.ticks().enabled(false);
    axixLabelElement.minorTicks().enabled(false);

    axixLabelElement.enabled(this.labelPosition() !== "none");
    axixLabelElement.labels().background().stroke({
      color: "none",
    });
    axixLabelElement.labels().useHtml(false);
    axixLabelElement.labels().wordWrap("break-word");
  }

  addListener(type, func) {
    super.addListener(type, func);
    this._layerElement.listen(type, func);
    this._axisLineElement.listen(type, func);
    this._axisLineOppElement.listen(type, func);
    this._axisLabelsElements.forEach((element) => element.listen(type, func));
    this._hitBox.listen(type, func);
  }

  createLayerElement() {
    let axisElement = this.container.stage.layer();

    let axisPartsElement = this.container.stage.layer();
    axisElement.addChild(axisPartsElement);

    this._axisLineElement.container(axisPartsElement);
    this._axisLineOppElement.container(axisPartsElement);

    this._axisTickElement.container(axisPartsElement);
    this._axisTickOppElement.container(axisPartsElement);
    this._axisTickMinorElement.container(axisPartsElement);
    this._axisTickMinorOppElement.container(axisPartsElement);

    this._axisDividersElements.forEach((element) =>
      element.container(axisPartsElement)
    );

    this._axisLabelsElements.forEach((element) =>
      element.container(axisPartsElement)
    );

    this._hitBox = this.container.stage.rect(
      this._effectiveBounds.left,
      this._effectiveBounds.top,
      this._effectiveBounds.width,
      this._effectiveBounds.height
    );
    this._hitBox.stroke({ color: "none" });
    // This is a hack needed to capture events
    this._hitBox.fill({ color: "rgb(255, 255, 255)", opacity: "0.00001" });

    axisElement.addChild(this._hitBox);

    return axisElement;
  }

  createScaleElement() {
    let scaleElement;
    if (this.axis instanceof ChartOrdAxisShape) {
      scaleElement = this.container.anychart.scales.ordinal();
    } else if (this.axis instanceof ChartDateAxisShape) {
      scaleElement = this.container.anychart.scales.ordinal();
    } else if (this.axis instanceof ChartValAxisShape) {
      scaleElement = this.container.anychart.scales.linear();
    }

    return scaleElement;
  }

  remove() {
    super.remove();
    this._axisLineElement.enabled(false);
    this._axisLineElement.remove();
    this._axisLineOppElement.enabled(false);
    this._axisLineOppElement.remove();

    this._axisTickElement.enabled(false);
    this._axisTickElement.remove();
    this._axisTickOppElement.enabled(false);
    this._axisTickOppElement.remove();

    this._axisTickMinorElement.enabled(false);
    this._axisTickMinorElement.remove();
    this._axisTickMinorOppElement.enabled(false);
    this._axisTickMinorOppElement.remove();

    this._axisLabelsElements.forEach((element) => {
      element.enabled(false);
      element.remove();
      this.container.acgraph.events.removeAll(element);
    });
    this._axisDividersElements.forEach((element) => {
      element.enabled(false);
      element.remove();
      this.container.acgraph.events.removeAll(element);
    });

    this.container.acgraph.events.removeAll(this._layerElement);
    this.container.acgraph.events.removeAll(this._hitBox);

    this.container.acgraph.events.removeAll(this._axisLineElement);
    this.container.acgraph.events.removeAll(this._axisLineOppElement);
    this.container.acgraph.events.removeAll(this._axisTickElement);
    this.container.acgraph.events.removeAll(this._axisTickOppElement);
    this.container.acgraph.events.removeAll(this._axisTickMinorElement);
    this.container.acgraph.events.removeAll(this._axisTickMinorOppElement);
  }
}

export default AxisElement;
