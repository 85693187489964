import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import useDAGMProperty from "../hooks/useDAGMProperty";

import HeaderContainer from "../HeaderContainer";
import NumberPropertyEditor from "../NumberPropertyEditor";
import BooleanPropertyEditor from "../BooleanPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

import ChartAxisCategoryPropertyEditor from "./ChartAxisCategoryPropertyEditor";
import ChartAxisCrossesPropertyEditor from "./ChartAxisCrossesPropertyEditor";

const useStyles = makeStyles((theme) => ({
  rootAxisOrd: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartAxisOrdPropertyEditor(props) {
  const { shape, ...other } = props;
  const classes = useStyles();

  const [asPrimitives] = useDAGMProperty(shape, "xValues.asPrimitives");
  const levels = asPrimitives.length;

  let childrenTicks = (
    <ExplicitPropertyEditorContainer>
      <NumberPropertyEditor
        label="Intervals"
        shape={shape}
        property="tickMarkSkip"
        minNumber={1}
      />
    </ExplicitPropertyEditorContainer>
  );

  const multiLevelOptions =
    levels > 1 ? (
      <ExplicitPropertyEditorContainer>
        <BooleanPropertyEditor
          label="Mutli-level"
          shape={shape}
          property="labelMultiLevel"
        />
      </ExplicitPropertyEditorContainer>
    ) : (
      <></>
    );

  const childrenLabelsAxis = (
    <HeaderContainer label="Options" type="secondaryHeading">
      {multiLevelOptions}
      <ExplicitPropertyEditorContainer>
        <NumberPropertyEditor
          label="Intervals"
          shape={shape}
          property="labelInterval"
          minNumber={1}
        />
      </ExplicitPropertyEditorContainer>
    </HeaderContainer>
  );

  const childrenLastOption =
    levels > 1 ? (
      <ExplicitPropertyEditorContainer>
        <BooleanPropertyEditor
          label="Label Mutli-level"
          shape={shape}
          property="labelMultiLevel"
        />
      </ExplicitPropertyEditorContainer>
    ) : (
      <></>
    );

  return (
    <ChartAxisCategoryPropertyEditor
      className={classes.rootAxisOrd}
      {...other}
      shape={shape}
      childrenTicks={childrenTicks}
      childrenLabelsAxis={childrenLabelsAxis}
      childrenLastOption={childrenLastOption}
      axisType={'ord'}
    >
      <ChartAxisCrossesPropertyEditor shape={shape} />
    </ChartAxisCategoryPropertyEditor>
  );
}

export default ChartAxisOrdPropertyEditor;
