import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import TextFieldPropertyEditor from "./TextFieldPropertyEditor";
import NumberPropertyEditor from "./NumberPropertyEditor";
import FillPropertyEditor from "./FillPropertyEditor";
import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";
import FontPropertyEditor from "./FontPropertyEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function TextShapePropertyEditor(props) {
  const {
    shape,
    label = "Text",
    textField,
    children,
    ...other
  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root} {...other}>
      {textField ?
        textField :
       (
        <ExplicitPropertyEditorContainer>
          <TextFieldPropertyEditor
            label={label}
            shape={shape}
            property="simpleRun"
          />
        </ExplicitPropertyEditorContainer>
      )}
      {children}
      <FillPropertyEditor
        label={label + " Fill"}
        isText={true}
        shape={shape}
      ></FillPropertyEditor>
      <FontPropertyEditor shape={shape}></FontPropertyEditor>
      <ExplicitPropertyEditorContainer>
        <NumberPropertyEditor
          label={"Font Size"}
          shape={shape}
          property="size"
          minNumber={1}
        />
      </ExplicitPropertyEditorContainer>
    </Box>
    // TODO - add font name
  );
}

export default TextShapePropertyEditor;
