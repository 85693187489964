import React from "react";

import InputPropertyEditor from "./InputPropertyEditor";

const allowEmptyFunction = function(value) {
  // if (value === "'") {
  //   return "";
  // }
  return value;
};
const TextFieldPropertyEditor = (props) => {
  const { ...other } = props;

  return (
    <InputPropertyEditor {...other}
      commitOnChange={false}
      adjustGetValue={allowEmptyFunction}
    />
  );
};

TextFieldPropertyEditor.propTypes = { ...InputPropertyEditor.propTypes };

export default TextFieldPropertyEditor;
