import React from "react";

import { ChartUtils } from "@sheetxl/models";

import useDAGMProperty from "../hooks/useDAGMProperty";

import SelectPropertyEditor from "../SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

function ChartGroupingPropertyEditor(props) {
  const {
    shape,
    inputWidth,
    chartTypeType,
    label = "Grouping",
    property = "grouping",
    ...other
  } = props;

  const [value] = useDAGMProperty(shape, property);

  let values = [ ];
  // Office uses this for some chart types but it has the same behavior as clustered
  if (ChartUtils.isColumnType(chartTypeType) && !ChartUtils.isLineType(chartTypeType) || value === 'clustered')
    values.push({ key: 'clustered', description: 'Clustered' });
  else
    values.push({ key: 'standard', description: 'Standard' });

  values.push({ key: "stacked", description: "Stacked" });
  values.push({ key: "percentStacked", description: "Percent Stacked" });

  return (
    <ExplicitPropertyEditorContainer {...other}>
      <SelectPropertyEditor
        label={label}
        values={values}
        shape={shape}
        inputWidth={inputWidth}
        property={property}
      ></SelectPropertyEditor>
    </ExplicitPropertyEditorContainer>
  );
}

export default ChartGroupingPropertyEditor;
