import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import useDAGMProperty from "../hooks/useDAGMProperty";

import TextFieldPropertyEditor from "../TextFieldPropertyEditor";
import TextShapePropertyEditor from "../TextShapePropertyEditor";
import FillStrokePropertyEditor from "../FillStrokePropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

import NumberPropertyEditor from "../NumberPropertyEditor";
import RangePropertyEditor from "../RangePropertyEditor";

import ExpandingBooleanPropertyEditor from "../ExpandingBooleanPropertyEditor";
import ExpandingPropertyEditor from "../ExpandingPropertyEditor";

const useStyles = makeStyles(function (theme) {
  return {
    title: {
      display: "flex",
      flex: "1 1 100%",
      flexDirection: "column",
      alignItems: "stretch",
    },
    alignedRow: {
      display: "flex",
      flexDirection: "row",
      flex: "1 1 100%",
      minWidth: "0px",
      alignItems: "center"
    },
  };
});

function ChartTitlePropertyEditor(props) {
  const {
    shape,
    label = "Title",
    property = "title",
    disableRotation = false,
    disableBackground = false,
    children,
    ...other
  } = props;
  const classes = useStyles();

  const [valueTitleShape] = useDAGMProperty(shape, property);
  const [valueText] = useDAGMProperty(valueTitleShape, "text");
  // not all title have range support
  const [valueTitleRange] = useDAGMProperty(valueTitleShape, "range");

  const textField = (
    <ExpandingPropertyEditor
      {...other}
      className={classes.title}
      label={
        <Box
          className={classes.alignedRow}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          shape={valueText}
          property={"simpleRun"}
        >
            <TextFieldPropertyEditor
              label={label}
              shape={valueText}
              property="simpleRun"
            />
        </Box>
      }
      allowExpand={valueTitleRange !== undefined}
    >
      { valueTitleRange !== undefined ?
      <ExplicitPropertyEditorContainer>
          <RangePropertyEditor
            label="Range"
            shape={valueTitleShape}
            property="range"
            singleRange={true}
          />
        </ExplicitPropertyEditorContainer>
      : <></> }
    </ExpandingPropertyEditor>
  );

  return (
    <ExpandingBooleanPropertyEditor
      {...other}
      label={label}
      shape={valueTitleShape}
      property={"shown"}
    >
      <TextShapePropertyEditor
        textField={textField}
        shape={valueText}
      >
        {children}
      </TextShapePropertyEditor>
      {disableRotation ? (
        <></>
      ) : (
        <ExplicitPropertyEditorContainer>
          <NumberPropertyEditor
            label={"Rotation"}
            shape={valueTitleShape}
            property="rotation"
          />
        </ExplicitPropertyEditorContainer>
      )}
      {disableBackground ? (
        <></>
      ) : (
        <FillStrokePropertyEditor
          label="Background"
          shape={valueTitleShape}
        ></FillStrokePropertyEditor>
      )}
    </ExpandingBooleanPropertyEditor>
  );
}

export default ChartTitlePropertyEditor;
