import React from 'react'

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Checkboard from "./Checkboard";

const ENTER = 13

const useStyles = makeStyles(function (theme) {
  return {
    swatch: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0px',
      left: '0px',
      cursor: 'pointer',
      outline: 'none'
    },
    checkerBoard: {
      position: 'absolute',
      width: 'calc(100% - 1.5px)',
      height: 'calc(100% - 1.5px)',
      top: '0.75px',
      left: '0.75px'
    },
    activeColor: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      height: '100%',
      width: '100%',
      background: function({color}) {
        if (!color)
            return 'transparent';
        return color;
      }
    },
  }
});

export const Swatch = (props) => {
  const {
    color, onClick = () => {},
    onHover,
    className,
    children,
    ...other
  } = props;

  const classes = useStyles({color: color});

  const handleClick = e => onClick(color, e)
  const handleKeyDown = e => e.keyCode === ENTER && onClick(color, e)
  const handleHover = e => onHover(color, e)

  const optionalEvents = {}
  if (onHover) {
    optionalEvents.onMouseOver = handleHover
  }

  return (
    <div className={clsx(className, classes.swatch)} {...other}
      onClick={ handleClick }
      tabIndex={ 0 }
      onKeyDown={ handleKeyDown }
      { ...optionalEvents }
    >
      <Checkboard size={7} className={clsx(classes.checkerBoard)} />
      <div className={clsx(classes.activeColor)}>
        { children }
      </div>
    </div>
  )
}

export default Swatch