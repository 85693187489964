import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import clsx from "clsx";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { StyledAccordion } from "./StyledAccordion";
import { StyledAccordionSummary } from "./StyledAccordion";
import { StyledAccordionDetails } from "./StyledAccordion";

import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";

const useStyles = makeStyles(function (theme) {
  const transition = {
    duration: theme.transitions.duration.shortest,
  };

  return {
    root: {
      display: "flex",
      flex: "1 1 100%",
      flexDirection: "column",
      alignItems: "stretch",
      marginBottom: function ({ expanded }) {
        return "0px";
        //return expanded ? "4px" : "0px";
      }
    },
    summary: {
      flex: "1 1 100%",
      minHeight: "40px",
      cursor: "default",
      "&:hover:not($disabled)": {
        cursor: "default",
      },
    },
    alignedRow: {
      display: "flex",
      flexDirection: "row",
      flex: "1 1 100%",
      minWidth: "0px",
      alignItems: "center"
    },
    focused: {
      // TODO - remove or figure out how to make work
      border: "red solid 1px",
      background: "transparent",
    },
    focusVisible: {
      // // TODO - remove or figure out how to make work
      border: "orange solid 1px",
      background: "transparent",
    },
    expanded: {},
    disabled: {},
    active: {
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hidden: {
      visibility: "hidden",
    },
    /* Styles applied to the `expandIcon`'s wrapper element. */
    expandIconWrapper: {
      display: "flex",
      flex: "0 0 0",
      marginLeft: "-6px",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.action.active,
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", transition),
      "&$expanded": {
        transform: "rotate(90deg)",
      },
      "&$active": {
        color: `${theme.palette.action.active}`,
      },
      "&:focus": {
        outline: "initial",
      },
      "& > svg": {
        // display: 'flex',
        // flex: '1 0 0',
        // border: 'red solid 1px'
      },
      "&:hover:not($disabled)": {
        cursor: "pointer",
      },
    },
    details: {},
    detailsLine: {
      marginLeft: "5.5px",
      maxWidth: "0px",
      height: "100%",
      // marginTop: function ({ deailsOverlap }) {
      //   return `-${deailsOverlap}px`;
      // },
      marginBottom: "1px",
      borderLeft: `${theme.palette.divider} solid 1px`,
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.leavingScreen,
      }),
      "&$active": {
        borderLeft: `${theme.palette.action.active} solid 1px`,
      },
    },
    outlined: {
      paddingLeft: "8px",
      flexDirection: "column",
      flex: "1 1 100%",
      borderBottom: `${theme.palette.divider} solid 1px`,
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.leavingScreen,
      }),
      "&$active": {
        borderBottom: `${theme.palette.action.active} solid 1px`,
      },
    },
  };
});

function ExpandingPropertyEditor(props) {
  const {
    label: label,
    labelDiv,
    children: childrenProp,
    className : classNameProp,
    defaultExpanded = false,
    allowExpand = true,
    disabled = false,
    expanded: expandedProp,
    deailsOverlap = 16,
    onChange, // Rename onExpand/onCollapse?
    ...other
  } = props;
  const [...children] = React.Children.toArray(childrenProp);

  const labelDivEffective = labelDiv ? (
    labelDiv
  ) : (
    <ExplicitPropertyEditorContainer indented={false}>
      {label}
    </ExplicitPropertyEditorContainer>
  );

  const [expanded, setExpanded] = useState(defaultExpanded);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const classes = useStyles({ deailsOverlap: deailsOverlap, expanded: expanded });

  useEffect(() => {
      setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const handleToggleExpanded = (event) => {
    setExpanded(!expanded);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 32 || event.keyCode === 13) {
      // If spacebar fired the event
      handleToggleExpanded(event);
    }
  };

  const allowsChildren = children && children.length > 0 && allowExpand;
  return (
    <StyledAccordion
      expanded={expanded && allowExpand}
      onChange={handleToggleExpanded}
      {...other}
      className={clsx(classes.root, classNameProp)}
    >
      <StyledAccordionSummary
        className={clsx(classes.summary)}
        aria-controls="expand"
        tabIndex="-1"
      >
        <Box
          className={clsx(classes.alignedRow, {
            [classes.disabled]: disabled,
            [classes.expanded]: expanded,
          })}
        >
          <Box
            className={clsx(classes.expandIconWrapper, {
              [classes.disabled]: disabled,
              [classes.expanded]: expanded,
              [classes.active]: isHovered || isFocused,
              [classes.hidden]: !allowsChildren,
            })}
            tabIndex={allowsChildren ? 0 : -1}
            onClick={handleToggleExpanded}
            onKeyDown={(e) => handleKeyPress(e)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          >
            <KeyboardArrowRight />
          </Box>
          {labelDivEffective}
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {allowsChildren ? (
          <Box className={clsx(classes.details, classes.alignedRow)}>
            <Box
              className={clsx(classes.detailsLine, {
                [classes.active]: isHovered || isFocused,
              })}
            ></Box>
            <Box
              className={clsx(classes.outlined, {
                [classes.active]: isHovered || isFocused,
              })}
            >
              {expanded ? children : <></>}
            </Box>
          </Box>
        ) : (
          <> </>
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

export default ExpandingPropertyEditor;
