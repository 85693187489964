function attachScript(url) {
  return new Promise(function (resolve, reject) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = false;
    script.async = false;
    script.onload = function (value) {
      script.onload = null;
      resolve(script);
    };

    var head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
    script.src = url;
  });
}

function docReady(fn) {
  //         fn();
  //         /*
  // see if DOM is already available
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
  //         */
}

function waitOnClosureDep() {
  return new Promise(function (resolve, reject) {
    var script = document.querySelector(
      'script[src="' + window.goog.basePath + 'deps.js"]'
    );
    script.onload = function (value) {
      script.onload = null;
      resolve(script);
    };
  });
}

function waitOnFinalScript() {
  return new Promise(function (resolve, reject) {
    var scripts = document.querySelectorAll("head script");
    if (!scripts || scripts.length === 0) {
      resolve(null);
      return;
    }

    var lastScript = scripts[scripts.length - 1];
    lastScript.onload = function (value) {
      lastScript.onload = null;
      resolve(value);
    };
  });
}

var loadedValue = null;

window.CLOSURE_UNCOMPILED_DEFINES = {
  "goog.ENABLE_CHROME_APP_SAFE_SCRIPT_LOADING": true,
};

// This is the biggest hack ever. I am not able to find a way to determine when goog has resolved scripts.
// They don't implement promises or anything that I can find and the documentation online is very poor.
export default function resolveCharts(addressArg) {
  if (loadedValue) return loadedValue;

  loadedValue = new Promise(function (resolveAnyChart, rejectAnyChart) {
    docReady(function () {
      const address = (addressArg === true ? `127.0.0.1:8080` : addressArg);
      console.log('Resolve debuggable charts @:', address);
      // This is useful for if debugging local builds or unminified charts
      attachScript(
        `http://${address}/libs/closure-library/closure/goog/base.js`
      ).then(function () {
        waitOnClosureDep().then(function () {
          attachScript(`http://${address}/src/deps.js`).then(function () {
            window.goog.require("anychart.bundle.entry");
            window.goog.require("anychart.themes.defaultTheme.entry");
            waitOnFinalScript().then(function () {
              var timerCancel = window.setInterval(function () {
                if (window.anychart) {
                  window.clearTimeout(timerCancel);
                  let value = {
                    default: window.anychart,
                    graphics: window.acgraph,
                  };
                  resolveAnyChart(value);
                }
              }, 1);
            });
          });
        });
      });
    });
  });
  return loadedValue;
}
