import { ChartUtils } from "@sheetxl/models";

/*
  This is a element that is rendered in a chartcontainer.
  All chart elements take a model and have a few known methods

  Margin Rules:
  -Chart Title has a marging of 7.5
  -Axis Titles have only a margin of 7.5 on side of orientation
  -Display Unit Titles have a margin of 7.5 on orientation (but these are always )...
      -The spacing for display units is created but display units are always place beside axis labels.
  -Axis Labels have margin of 1 on opposite side
  -PlotArea has a margin of 0 unless there is something on it's edge then 15 (14/16) is used.
*/


// TODO - This has to have a concept of boundsInvalidated
// TODO - rename draw to render?
class ChartElement {
  constructor(chartContainer, resizable) {
    this._chartContainer = chartContainer;
    if (resizable)
        this._resizable = true;

    let properties = {};
    this.addProperty = function (name, defaultValue, delegate) {
      Object.defineProperty(this, name, {
        configurable: false,
        get: function () {
          if (delegate) return delegate[name]();
          let retValue = properties[name];
          if (retValue === undefined) return defaultValue;
          return retValue;
        },
        set: function (newValue) {
          if (delegate) return delegate[name](newValue);
          properties[name] = newValue;
        },
      });
    };

    this.addDelegateProperty = function (name, delegate) {
      this.addProperty(name, undefined, delegate);
    };

  }

  setupInternalListeners() {
    // This should belong to chart container since it sits on top of all the elements but for now.
    this.addListener(
      "mouseover",
      function (e) {
        this._isMouseOver = true;
        this.showSelect(this._hitBox);
      }.bind(this),
      true
    );
    this.addListener(
      "mouseout",
      function (e) {
        this._isMouseOver = false;
        if (!this._dragging && !this._isMouseOver) this.clearSelect(this._hitBox);
      }.bind(this),
      true
    );
    this.addListener(
      "contextmenu",
      function (e) {
        console.log("context menu", e);
      }.bind(this),
      true
    );
    this.addListener(
      "click",
      function (e) {
        console.log("click", e);
      }.bind(this),
      true
    );
    this.addListener(
      "dblclick",
      function (e) {
        console.log("dblclick", e);
      }.bind(this),
      true
    );
  }

  clearSelect(hitBox) {
    hitBox.stroke({ color: "rgb(255, 255, 255)", opacity: "0.00001" });
  }

  showSelect(hitBox) {
    hitBox.stroke({ color: "blue" });
  }

  setAbsolutePosition(renderWidth, renderHeight) {
    let dragBounds = this._elementRoot.drag();
    if (this._manualBounds && dragBounds) {
      // Note - EffectiveBounds is used for remainingbounds calcs so we don't want to adjust it.
      let width = dragBounds.width - dragBounds.left;
      let height = dragBounds.height - dragBounds.top;
      let absoluteBounds = {
        left: this._manualBounds.x !== undefined ? this._manualBounds.x * width : 0,
        top: this._manualBounds.y !== undefined ? this._manualBounds.y * height : 0,
        width: this._manualBounds.width !== undefined ? this._manualBounds.width * width : renderWidth,
        height: this._manualBounds.height !== undefined ? this._manualBounds.height * height : renderHeight,
      };
      this.layer().setPosition(absoluteBounds.left, absoluteBounds.top);
      this._hitBox.setBounds({
        left: 0,
        top: 0,
        width: absoluteBounds.width,
        height: absoluteBounds.height,
      });
    } else {
      this.layer().setPosition(
        this._effectiveBounds.left,
        this._effectiveBounds.top
      );
      this._hitBox.setBounds({
        left: 0,
        top: 0,
        width: renderWidth,
        height: renderHeight,
      });
    }
  }


  drag(opt_value) {
    let bounds = this.container.acgraph.math.rect(
      opt_value.left,
      opt_value.top,
      opt_value.width,
      opt_value.height
    );
    //this._dragZone.setBounds(bounds);
    this._elementRoot.drag(bounds);
  }

  manualBounds(value) {
    if (value === undefined) return this._manualBounds;
    return (this._manualBounds = value);
  }

  enableDragSupport(elementRoot) {
    elementRoot.drag(false);
    elementRoot.listen(
      "mouseDown",
      function (e) {
        let dragBounds = elementRoot.drag();
        if (!dragBounds) return;

        let moveHandler = function (e) {
          this._dragging = true;
          e.preventDefault();
        }.bind(this);

        elementRoot.listen("mouseMove", moveHandler);
        window.addEventListener(
          "mouseup",
          function (event) {
            elementRoot.unlisten("mouseMove", moveHandler);
            this._dragging = false;
            if (!this._dragging && !this._isMouseOver)
              this.clearSelect(this._hitBox);

            let newBounds = elementRoot.getBounds();
            let width = dragBounds.width - dragBounds.left;
            let height = dragBounds.height - dragBounds.top;
            let percentBounds = {
              x: newBounds.left / width,
              y: newBounds.top / height,
              width: newBounds.width / width,
              height: newBounds.height / height,
            };
            if (
              !this._manualBounds ||
              this._manualBounds.x !== percentBounds.x ||
              this._manualBounds.y !== percentBounds.y ||
              this._manualBounds.width !== percentBounds.width ||
              this._manualBounds.height !== percentBounds.height
            ) {
              if (!this._resizable) {
                  delete percentBounds.width;
                  delete percentBounds.height;
              }

              this._manualBounds = percentBounds;

              for (let i = 0; i < this._listeners.length; i++) {
                if (this._listeners[i].type === "manualMove") {
                  // Note - not a proper event
                  this._listeners[i].func({
                    source: this,
                  });
                }
              }
            }
          }.bind(this),
          { once: true, passive: true }
        );
      }.bind(this)
    );
  }

  get container() {
    return this._chartContainer;
  }

  remove() {
    this.container.removeElement(this);
  }

  draw() {
    // do nothing
  }

  position() {
    return null; // not shown - choices are left, right, top, bottom, tr
  }

  /**
    Returns the bounds as it would be without any container restraints or transforms.
    This includes scaling or rotating
  */
  naturalBounds() {
    return ChartUtils.ZERO_RECT;
  }

  /**
    Returns the bounds within the container. This includes transforms
    For this to be acurate draw must have been called.
  */
  renderedBounds() {
    return ChartUtils.ZERO_RECT;
  }

  /**
    Returns the needed to render given the containerBounds (this may be larger if there were margins or smaller if there
    are extrusions)
  */
  renderedBounds() {
    return ChartUtils.ZERO_RECT; //
  }


  addListener(type, func) {}
}

export default ChartElement;
