import ChartElement from "./ChartElement";

import { GeomUtils } from "@sheetxl/models";
import { ChartUtils } from "@sheetxl/models";

/**
  A widget representing the LegendElement. This has interaction and layout logic
*/

class LegendElement extends ChartElement {
  constructor(chartContainer, resizable = true) {
    super(chartContainer, resizable);

    this.addProperty("overlay", false);
    this.addProperty("rotation", 0);
    this.addProperty("orientation", "t");
    this.addProperty("alignment", "center");
    this.addProperty("padding", ChartUtils.ZERO_MARGIN); // padding for legend
    this.addProperty("maxWidthPerc", 1.0); // 0.70
    this.addProperty("maxHeightPerc", 1.0); // 0.40

    // An oriented margin is a margin that is for the side that matched the orientation.
    this.addProperty("orientedMargin", 7.5);
    this.addProperty("enabled", true);

    this._parentBounds = ChartUtils.ZERO_RECT;
    this._margin = { top: 1, right: 0, bottom: 1, left: 0 };

    this._elementRoot = this.createRootElement();
    chartContainer.addElement(this);

    this._elementBackground = this.container.anychart.standalones.background();
    this._elementBackgrounContainer = this.container.stage.layer();
    this._elementRoot.addChild(this._elementBackgrounContainer);
    this._elementBackground.container(this._elementBackgrounContainer);

    this._elementLegendItemsContainer = this.container.stage.layer();
    this._elementRoot.addChild(this._elementLegendItemsContainer);

    this._legendItems = [];

    this._listeners = [];
    this.setupInternalListeners();
  }

  draw() {
    if (
      !this.enabled ||
      !this._parentBounds ||
      this._parentBounds === ChartUtils.ZERO_RECT
    )
      return;

    let orientation = this.orientation;
    let alignment = this.alignment;
    // Ensure that the element after rotation fits within the boundaries
    let unrotatedBounds = GeomUtils.rotateRect(
      0,
      0,
      this._parentBounds.width * this.maxWidthPerc,
      this._parentBounds.height * this.maxHeightPerc,
      -this.rotation
    );
    unrotatedBounds = {
      left: unrotatedBounds[0],
      top: unrotatedBounds[1],
      width: unrotatedBounds[2],
      height: unrotatedBounds[3],
    };

    let margin = {...this._margin};

    if (orientation === "tr") {
      margin.right += this.orientedMargin || 0;
      alignment = "right";
      orientation = "t";
    }

    let naturalbounds = this.naturalBounds();

    let renderWidth = naturalbounds.width;
    let renderHeight = naturalbounds.height;

    // TODO - This logic is the same and title. Should move to base class
    let renderedBounds = GeomUtils.rotateRect(
      0,
      0,
      renderWidth,
      renderHeight,
      this.rotation
    );
    renderedBounds = {
      left: 0,
      top: 0,
      width: renderedBounds[2],
      height: renderedBounds[3],
    };

    this._elementBackground.bounds(renderedBounds);
    // for debugging margins
//       this._elementBackground.enabled(true);
//       this._elementBackground.fill({ color: "rgb(0, 0, 0)", opacity: "0" });
//       this._elementBackground.stroke({color : 'red' });

    let renderX = 0;
    let renderY = 0;

    if (orientation === "l" || orientation === "left") {
      margin.left += this.orientedMargin;
      let effectiveRect = ChartUtils.applyMarginRect(this._parentBounds, {
        top: this.padding.right,
        right: this.padding.bottom,
        bottom: this.padding.left,
        left: this.padding.top,
      });
      renderX = this._parentBounds.left;
      renderY = ChartUtils.applyAlignment(
        effectiveRect.height,
        effectiveRect.top,
        renderedBounds.height,
        ChartUtils.oppositeAlignment(alignment)
      );
    } else if (orientation === "t" || orientation === "top") {
      margin.top += this.orientedMargin;
      let effectiveRect = ChartUtils.applyMarginRect(
        this._parentBounds,
        this.padding
      );
      renderX = ChartUtils.applyAlignment(
        effectiveRect.width,
        effectiveRect.left,
        renderedBounds.width,
        alignment
      );
      renderY = this._parentBounds.top;
    } else if (orientation === "r" || orientation === "right") {
      margin.right += this.orientedMargin || 0;
      let effectiveRect = ChartUtils.applyMarginRect(this._parentBounds, {
        top: this.padding.right,
        right: this.padding.bottom,
        bottom: this.padding.left,
        left: this.padding.top,
      });
      renderX =
        this._parentBounds.left +
        this._parentBounds.width -
        renderedBounds.width -
        margin.right;
      renderY = ChartUtils.applyAlignment(
        effectiveRect.height - (margin.top + margin.bottom),
        effectiveRect.top,
        renderedBounds.height,
        ChartUtils.oppositeAlignment(alignment)
      );
    } else if (orientation === "b" || orientation === "bottom") {
      margin.bottom += this.orientedMargin || 0;
      let effectiveRect = ChartUtils.applyMarginRect(
        this._parentBounds,
        this.padding
      );
      renderX = ChartUtils.applyAlignment(
        effectiveRect.width,
        effectiveRect.left,
        renderedBounds.width,
        alignment
      );
      renderY =
        this._parentBounds.top +
        this._parentBounds.height -
        renderedBounds.height -
        margin.bottom;
    }

    this._effectiveBounds = {
      left: renderX,
      top: renderY + margin.top,
      width: renderedBounds.width,
      height: renderedBounds.height + margin.top + margin.bottom,
    };

    // this._elementTitle.rotation(0);
    let rotationEffective = this.rotation;
    this.layer().setRotation(
      rotationEffective,
      this._effectiveBounds.left + this._effectiveBounds.width / 2,
      this._effectiveBounds.top + this._effectiveBounds.height / 2
    );

    this.setAbsolutePosition(renderWidth, renderHeight);

    this._hitBox.setBounds({
      left: 0,
      top: 0,
      width: renderWidth,
      height: renderHeight,
    });
  }

  addListener(type, func) {
    super.addListener(type, func);
    this._elementRoot.listen(type, func);
    this._elementBackground.listen(type, func);

    this._hitBox.listen(type, func);
    this._listeners.push({ type: type, func: func });

    for (let i = 0; i < this._legendItems.length; i++) {
      this._legendItems[i].item.listen(type, func);
    }

  }

  removeListener(type, func) {
    this._elementRoot.unlisten(type, func);
    this._elementBackground.unlisten(type, func);

    this._hitBox.unlisten(type, func);
    for (let i = this._listeners.length - 1; i >= 0; i--) {
      if (this._listeners[i].type === type && this._listeners[i].func === func)
        this._listeners.splice(i, 1);
    }

    for (let i = 0; i < this._legendItems.length; i++) {
      this._legendItems[i].item.unlisten(type, func);
    }
  }

  /*
   * Returms that amount of space allocated for the placement of the control.
   * It is possible that it won't take the entire amount
   */
  parentBounds(left, top, width, height) {
    this._parentBounds = { left: left, top: top, width: width, height: height };
  }

  layer() {
    return this._elementRoot;
  }

  extrusions() {
    let retValue = ChartUtils.ZERO_MARGIN;

    return retValue;
  }

  naturalBounds() {
    let itemNaturalBounds = this.layoutLegendItems();
    return itemNaturalBounds;
  }

  layoutLegendItems() {
    if (
      !this.enabled ||
      !this._legendItems ||
      !this._parentBounds ||
      this._parentBounds === ChartUtils.ZERO_RECT
    )
      return;

    // Now we layout the items
    let isVertical = this.orientation !== "t" && this.orientation !== "b";

    let maxWidth = 0;
    let maxHeight = 0;
    let isWrapped = false;
    if (isVertical) {
      maxWidth = this._parentBounds.width * 0.4;
      maxHeight = this._parentBounds.height * 0.9;
    } else {
      maxWidth = Math.min(
        this._parentBounds.width * 0.9,
        this._parentBounds.width - 30 * 2
      ); //- (14 * 2)) * 0.90;
      maxHeight = this._parentBounds.height * 0.7;
    }

    // First ensure that all items have a bounds and find measures
    let totalItemWidth = isWrapped ? 0 : 1;
    let totalItemHeight = 0;
    let maxItemWidth = 0;
    let maxItemHeight = 0;

    for (let i = 0; i < this._legendItems.length; i++) {
      //if (!this._legendItems[i].bounds) {
      this._legendItems[i].item.parentBounds(null);
      this._legendItems[i].item.maxWidth(null);
      this._legendItems[i].item.draw();
      this._legendItems[i].bounds = this._legendItems[i].item.getPixelBounds();
      //}
      if (this._legendItems[i].bounds.width > maxWidth) {
        this._legendItems[i].item.textSettings("maxWidth", maxWidth);
        this._legendItems[i].item.textSettings("useHtml", true);
        this._legendItems[i].item.textSettings("textOverflow", "...");
        this._legendItems[i].item.parentBounds(null);
        this._legendItems[i].item.maxWidth(maxWidth);
        this._legendItems[i].item.draw();
        //items[i].maxWidth = '75px';
        //items[i].textOverflow = '...';
        //             this._legendItems[i].item.width(maxWidth);
        //             this._legendItems[i].item.draw();
        this._legendItems[i].bounds =
          this._legendItems[i].item.getPixelBounds();
        //             this._legendItems[i].item.parentBounds(this._legendItems[i].bounds);
        //             this._legendItems[i].item.draw();
      }
      totalItemWidth += this._legendItems[i].bounds.width;
      totalItemHeight += this._legendItems[i].bounds.height;
      maxItemWidth = Math.max(maxItemWidth, this._legendItems[i].bounds.width);
      maxItemHeight = Math.max(
        maxItemHeight,
        this._legendItems[i].bounds.height
      );
    }

    const marginWrapped = { top: 4, right: 0, bottom: 4, left: 6 };

    maxWidth = Math.max(maxItemWidth, maxWidth);
    maxHeight = Math.max(maxItemHeight, maxHeight);
    isWrapped = totalItemWidth + (this._legendItems.length - 1) * 3 > maxWidth; // 3 is the min spacing before wrapping

    if (isWrapped) {
      maxItemWidth = maxItemWidth += marginWrapped.left;

      // We want the rows to try to align by the greatest common multiple for example we want 2x2 not 3x1 items
      let maxItemsPerRow = Math.floor(maxWidth / maxItemWidth);
      let rows = Math.ceil(this._legendItems.length / maxItemsPerRow);
      if (this._legendItems.length % rows === 0) {
        let minItemsPerRow = Math.floor(this._legendItems.length / rows);
        maxWidth = minItemsPerRow * maxItemWidth;
      }
    }
    let margin;
    if (isWrapped)
      // horizontal
      margin = marginWrapped;
    else if (isVertical) {
      margin = { top: 3, right: 4, bottom: 3, left: 5 };
    } else {
      let marginDynamic = Math.min(
        (totalItemWidth * 0.3) / (this._legendItems.length + 1),
        (maxWidth - totalItemWidth) / (this._legendItems.length + 1)
      );
      margin = { top: 3, right: marginDynamic, bottom: 3, left: marginDynamic };
    }

    let largestWidth = 0;
    let xStart = isWrapped ? 0 : 1;
    let currentY = 0;
    let currentX = xStart;
    let maxEffectiveHeight =
      Math.floor(maxHeight / maxItemHeight) * maxItemHeight;
    for (let i = 0; i < this._legendItems.length; i++) {
      let offsetY =
        (maxItemHeight - this._legendItems[i].bounds.height) / 2 + margin.top;
      let offsetX = margin.left;

      let placingX =
        +(isWrapped ? maxItemWidth : this._legendItems[i].bounds.width) +
        margin.right;
      if (
        (isVertical && i > 0) ||
        (currentX + placingX > maxWidth && i !== 0)
      ) {
        currentY = currentY + maxItemHeight + margin.bottom;
        currentX = xStart;
      }

      if (
        currentY + offsetY + this._legendItems[i].bounds.height <
        maxEffectiveHeight
      ) {
        this._legendItems[i].item.enabled(true);
        this._legendItems[i].item.parentBounds(
          currentX + offsetX,
          currentY + offsetY,
          this._legendItems[i].bounds.width,
          this._legendItems[i].bounds.height
        );
      } else {
        this._legendItems[i].item.enabled(false);
      }
      currentX = currentX + placingX;
      largestWidth = Math.max(largestWidth, currentX);
    }

    // only vertical legends have right padding in office
    if (isVertical) largestWidth += margin.right;

    return {
      left: 0,
      top: 0,
      width: largestWidth + margin.left,
      height: 0 + Math.min(
        maxEffectiveHeight,
        currentY + maxItemHeight + margin.top + margin.bottom
      ),
    };
  }

  items(items) {
    this.removeLegendItems();
    for (let i = 0; i < items.length; i++) {
      let legendItem = this.createLegendItem(items[i]);
      legendItem.container(this._elementLegendItemsContainer);
      this._legendItems.push({
        item: legendItem,
      });
    }

    this.draw();

    return items;
  }

  createLegendItem(item) {
    let legendItem = this.container.anychart.standalones.legend();

    legendItem.enabled(true);
    legendItem.useHtml(true);
    legendItem.wordWrap("break-word");
    legendItem.background().enabled(false);

    // for debugging margins
//         legendItem.background().enabled(true);
//         legendItem.background().fill({ color: "rgb(0, 0, 0)", opacity: "0" }});
//         legendItem.background().stroke({color : 'green', opacity: "1" });

    legendItem.paginator().enabled(false);

    legendItem.margin(this._margin);//ChartUtils.ZERO_MARGIN);
    legendItem.padding({ top: 0, right: 0, bottom: 0, left: 0 });
    legendItem.position("center");
    legendItem.vAlign("middle");

    legendItem.items([item]);

    for (let i = 0; i < this._listeners.length; i++) {
      legendItem.listen(this._listeners[i].type, this._listeners[i].func);
    }

    return legendItem;
  }

  removeLegendItems() {
    this._elementLegendItemsContainer.removeChildren();
    if (!this._legendItems) return;

    for (let i = 0; i < this._legendItems.length; i++) {
      this.container.acgraph.events.removeAll(this._legendItems[i].item);
    }
    this._legendItems = [];
  }

  background() {
    return this._elementBackground;
  }

  /**
    Returns the parent bounds available after set
  */
  remainingBounds() {
    let parentBounds = this._parentBounds;

    if (!this.enabled || this.overlay) return parentBounds;

    let bounds = this._effectiveBounds;
    let remainingBounds;

    if (this.orientation === "tr") {
      remainingBounds = ChartUtils.trimRect(parentBounds, bounds, "t");
      remainingBounds = ChartUtils.trimRect(remainingBounds, bounds, "r");
    } else {
      remainingBounds = ChartUtils.trimRect(
        parentBounds,
        bounds,
        this.orientation
      );
    }

    return remainingBounds;
  }


  /**
    Returns the bounds needed to be displayed in container.
    For this to be acurate draw must have been called.
  */

  renderedBounds() {
    if (!this.enabled || this.overlay) return ChartUtils.ZERO_RECT;

    let bounds = { ...this._effectiveBounds };
    return bounds;
  }

  createRootElement() {
    this._effectiveBounds = { left: 0, top: 0, width: 0, height: 0 };

    let elementRoot = this.container.stage.layer();

    this.enableDragSupport(elementRoot);

    this._hitBox = this.container.stage.rect(
      this._effectiveBounds.left,
      this._effectiveBounds.top,
      this._effectiveBounds.width,
      this._effectiveBounds.height
    );
    this._hitBox.stroke({ color: "none" });
    // This is a hack needed to capture events
    this._hitBox.fill({ color: "rgb(255, 255, 255)", opacity: "0.00001" });

    elementRoot.addChild(this._hitBox);

    return elementRoot;
  }

  remove() {
    super.remove();

    this.container.acgraph.events.removeAll(this._rootElement);
    this.container.acgraph.events.removeAll(this._hitBox);
    this.container.acgraph.events.removeAll(this._elementBackground);

    this.container.acgraph.events.removeAll(this._elementLegend);
    this.removeLegendItems();
  }
}

export default LegendElement;
