import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Box from "@material-ui/core/Box";

import useResizeObserver from "@react-hook/resize-observer";
import PerfectScrollbar from "react-perfect-scrollbar";
import { PerfectScrollPaneCss } from "./PerfectScrollPaneCss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch"
  },
  scrollbarContainer: {
    flexBasis: "100%",
    position: "relative",
    display: "block",
  },
  scrollable: {
    paddingRight: "15px", // to accomodate scrollbar
    position: "absolute",
    display: "flex",
    minWidth: '100%',
    flexDirection: "column",
    flex: "1 1 100%",
    boxSizing: "border-box",
  },
}));

export default function PerfectScrollPane(props) {
  const {
    children,
    className : classNameProp,
    ...other } = props;

  const classes = useStyles();

  const refResize = React.useRef(null);
  const refResizeOuter = React.useRef(null);
  const refScrollBar = React.useRef(null);

  useResizeObserver(refResize, function (entry) {
    if (refScrollBar.current.updateScroll) refScrollBar.current.updateScroll();
  });
  useResizeObserver(refResizeOuter, function (entry) {
    if (refScrollBar.current.updateScroll) refScrollBar.current.updateScroll();
  });

  return (
    <Box className={clsx(classNameProp, classes.root) } {...other}>
      <div className={classes.scrollbarContainer} ref={refResizeOuter}>
      <PerfectScrollPaneCss style={{ height: "100%" }}>
        <PerfectScrollbar
          ref={(ref) => {
            refScrollBar.current = ref;
          }}
        >
        <Box ref={refResize} className={classes.scrollable}>
          {children}
          <div style={{flex: "1 1 100%" }}></div>
        </Box>
        </PerfectScrollbar>
      </PerfectScrollPaneCss>
      </div>
    </Box>
  );
}