import React from "react";

import { ChartUtils } from "@sheetxl/models";

import useDAGMProperty from "../hooks/useDAGMProperty";

import AutocompletePropertyEditor from "../AutocompletePropertyEditor";
import ExpandingPropertyEditor from "../ExpandingPropertyEditor";

import ChartTitlePropertyEditor from "./ChartTitlePropertyEditor";

function ChartAxisDisplayUnitsPropertyEditor(props) {
  const { shape, property = "displayUnits", ...other } = props;

  const [displayUnits] = useDAGMProperty(shape, property);

  let values = [];
  for (let i = 0; i < ChartUtils.builtInDisplayUnitsKeys.length; i++) {
    values.push({
      key: ChartUtils.builtInDisplayUnitsKeys[i],
      description:
        ChartUtils.builtInDisplayUnits[ChartUtils.builtInDisplayUnitsKeys[i]]
          .description,
    });
  }

  const allowExpand = displayUnits !== "none";
  const defaultExpanded = false;

  const onSelectHandler = function (event, newValue) {
    if (event) event.stopPropagation();
  };

  return (
    <ExpandingPropertyEditor
      {...other}
      label={
        <AutocompletePropertyEditor
          label="Display Units"
          values={values}
          shape={shape}
          onSelect={onSelectHandler}
          property="displayUnits"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        ></AutocompletePropertyEditor>
      }
      allowExpand={allowExpand}
      defaultExpanded={defaultExpanded}
    >
      <ChartTitlePropertyEditor
        label="Display Units Label"
        property="displayUnitsLabel"
        disableRotation={true}
        shape={shape}
      />
    </ExpandingPropertyEditor>
  );
}

export default ChartAxisDisplayUnitsPropertyEditor;
