import React from "react";
import { PropTypes } from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import useDAGMProperty from "./hooks/useDAGMProperty";

import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";
import NumberPropertyEditor from "./NumberPropertyEditor";
import SelectPropertyEditor from "./SelectPropertyEditor";

const useStyles = makeStyles((theme) => ({
  rootUnits: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
    "& > *": {
      margin: theme.spacing(0),
    },
  },
}));

const valuesDem = [
  { key: "days", description: "Days" },
  { key: "months", description: "Months" },
  { key: "years", description: "Years" },
];

const REG_POSITIVE_WHOLE_NUMBER = /[0-9]+/g;

const DateUnitPropertyEditor = (props) => {
  const { shape, property, label, hasAmount = true, ...other } = props;
  const classes = useStyles();

  const [valueFull /*setValue*/, , propertyValue] = useDAGMProperty(
    shape,
    property
  );

  let defaultValue = { ...propertyValue.defaultValue };

  const addAmt = function (amt) {
    let retValue = { ...valueFull };
    retValue.amt = amt !== undefined ? amt : defaultValue.amt;
    return retValue;
  };

  const stripAmt = function (valueFull) {
    if (!valueFull) return valueFull;

    return valueFull.amt;
  };

  const addDem = function (dem) {
    let retValue = { ...valueFull };
    retValue.dem = dem !== undefined ? dem : defaultValue.dem;
    return retValue;
  };

  const stripDem = function (valueFull) {
    if (!valueFull) return valueFull;

    return valueFull.dem;
  };

  const inputCheck = function (event) {
    if (
      event.target.value === "" ||
      !REG_POSITIVE_WHOLE_NUMBER.test(event.target.value)
    )
      return true;
  };

  return (
    <Box className={classes.rootUnits} {...other}>
      <ExplicitPropertyEditorContainer shape={shape} property={property}>
        <Box style={{ minWidth: "60px", flex: "1 1 50%" }}>
          {hasAmount ? (
            <NumberPropertyEditor
              shape={shape}
              property={property}
              adjustGetValue={stripAmt}
              adjustSetValue={addAmt}
              minNumber={1}
            />
          ) : (
            <></>
          )}
        </Box>
        <div style={{ flex: "0 0 0%", minWidth: "4px" }}></div>
        <SelectPropertyEditor
          label={label}
          values={valuesDem}
          shape={shape}
          property={property}
          adjustGetValue={hasAmount ? stripDem : undefined}
          adjustSetValue={hasAmount ? addDem : undefined}
        ></SelectPropertyEditor>
      </ExplicitPropertyEditorContainer>
    </Box>
  );
};

DateUnitPropertyEditor.propTypes = { ...NumberPropertyEditor.propTypes };
DateUnitPropertyEditor.propTypes.label = PropTypes.string;
delete DateUnitPropertyEditor.propTypes.property;

export default DateUnitPropertyEditor;
