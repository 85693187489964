import React, { useEffect, useMemo, useRef, useContext, useCallback, useState } from 'react';

import PropTypes from 'prop-types'

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import Colorize from '@material-ui/icons/Colorize';

// import ToggleButton from "@material-ui/lab/ToggleButton";

import { AdjustableColor } from "@sheetxl/models";

import { DocumentTheme } from "@sheetxl/models";

import ThemePresetColors from "./ThemePresetColors";
import DocThemeContext from "./DocThemeContext";
import CustomColorPanel from "./CustomColorPanel";

const useStyles = makeStyles(function (theme) {
  return {
    'active': {},
    picker: {
      width: function({width}) {
          return width;
      },
      outline: 'none',
      padding: '10px 10px',
      boxSizing: 'content-box',
      boxShadow: '0 0 0 1px rgba(0,0,0,.15), 0 8px 16px rgba(0,0,0,.15)',
    },
    quickColors : {
      opacity: '0',
      display: 'none',
      '&$active': {
        opacity: '1',
        display: 'initial',
      }
//         border: 'green solid 1px'
    },
    actionButton: {
      minWidth: '75px'
    },
    customColors : {
      opacity: '0',
      display: 'none',
      '&$active': {
        opacity: '1',
        display: 'initial',
      }
//         border: 'green solid 1px'
    },
    hidden : {
      opacity: '0',
      display: 'none'
    },
    presets: {
      marginBottom: '14px',
      // "&:last-child": {
      //     marginBottom: '0px',
      // }
    },
    presetLabel: {
      display: "flex",
      flexShrink: '0',
      paddingLeft: '6px',
      paddingBottom: '2px',
      paddingTop: '2px',
      userSelect: 'none'
    },
    divider: {
      // marginTop: '6px',
      marginBottom: '10px'
    },
    spacer: {
      height: '20px',
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    }
  };
});

const adjustColor = function(entry, description, lumMod, lumOff) {
    let colorCloned = JSON.parse(JSON.stringify(entry.color));
    if (!colorCloned.adjs) {
        colorCloned.adjs = [];
    }
    if (lumMod)
        colorCloned.adjs.push({ lumMod: lumMod});
    if (lumOff)
        colorCloned.adjs.push({ lumOff: lumOff});

    return {
        description: entry.description + ", " + description,
        color: colorCloned
    }
}

const buildModifiedColors = function(colors, docTheme) {
    let modifiedColors = [];
    for (let i=0; i<colors.length; i++) {
        let modified = [];
        modifiedColors.push(modified);
        let adjustedColor = new AdjustableColor(colors[i].color.val, colors[i].color.adjs, docTheme.schemeLookup);
        let lum = adjustedColor.toHSLAColor().l;
        if (lum === 0) {
          modified.push(adjustColor(colors[i], "Lighter 50%", 50, 50));
          modified.push(adjustColor(colors[i], "Lighter 35%", 65, 35));
          modified.push(adjustColor(colors[i], "Lighter 25%", 75, 25));
          modified.push(adjustColor(colors[i], "Lighter 15%", 85, 15));
          modified.push(adjustColor(colors[i], "Lighter 5%", 95, 5));
        } else if (lum === 100) {
          modified.push(adjustColor(colors[i], "Darker 5%", 95, 0));
          modified.push(adjustColor(colors[i], "Darker 15%", 85, 0));
          modified.push(adjustColor(colors[i], "Darker 25%", 75, 0));
          modified.push(adjustColor(colors[i], "Darker 35%", 65, 0));
          modified.push(adjustColor(colors[i], "Darker 50%", 50, 0));
        } else if (lum <= 20) {
          modified.push(adjustColor(colors[i], "Lighter 90%", 10, 90));
          modified.push(adjustColor(colors[i], "Lighter 75%", 25, 75));
          modified.push(adjustColor(colors[i], "Lighter 50%", 50, 50));
          modified.push(adjustColor(colors[i], "Lighter 25%", 75, 25));
          modified.push(adjustColor(colors[i], "Lighter 10%", 90, 10));
        } else if (lum >= 75) {
          modified.push(adjustColor(colors[i], "Darker 10%", 90, 0));
          modified.push(adjustColor(colors[i], "Darker 25%", 75, 0));
          modified.push(adjustColor(colors[i], "Darker 50%", 60, 0));
          modified.push(adjustColor(colors[i], "Darker 75%", 25, 0));
          modified.push(adjustColor(colors[i], "Darker 90%", 10, 0));
        } else {
          modified.push(adjustColor(colors[i], "Lighter 80%", 20, 80));
          modified.push(adjustColor(colors[i], "Lighter 60%", 40, 60));
          modified.push(adjustColor(colors[i], "Lighter 40%", 60, 40));
          modified.push(adjustColor(colors[i], "Darker 25%", 75, 0));
          modified.push(adjustColor(colors[i], "Darker 50%", 50, 0));
        }
    }
    return modifiedColors;
}

const createThemeDescription = function(description, val) {
  return {
    description: description,
    color: {
      "val": val,
      "adjs": []
     }
  }
}

export const ThemeColorPicker = React.forwardRef((props, ref) => {
    const {
      color,
      recentColors = [],
      width,
      onChange,
      onChangeComplete,
      onDone,
      onOpen,
      onCancel,
      onSwatchHover,
      renderers,
      className,
      source,
      onClick,
      onTouchEnd,
      ...other
    } = props;

  const classes = useStyles({width: width, color: color});

  // TODO - should we make this a property and put this somewhere else? It's a bit of a hack
  const docTheme = useContext(DocThemeContext);

  const docThemeEffective = useMemo(function() {
    if (docTheme)
      return docTheme;

    return new DocumentTheme();
  }, [docTheme]);

  // TODO - useDagmProperty with wildcard to detect changes

  const colorPalettes = useMemo(function() {
      const primaryThemeColors = [];
      primaryThemeColors.push(createThemeDescription("Primary Background", "bg1"));
      primaryThemeColors.push(createThemeDescription("Primary Text", "tx1"));
      primaryThemeColors.push(createThemeDescription("Secondary Background", "bg2"));
      primaryThemeColors.push(createThemeDescription("Secondary Text", "tx2"));
      primaryThemeColors.push(createThemeDescription("Accent 1", "accent1"));
      primaryThemeColors.push(createThemeDescription("Accent 2", "accent2"));
      primaryThemeColors.push(createThemeDescription("Accent 3", "accent3"));
      primaryThemeColors.push(createThemeDescription("Accent 4", "accent4"));
      primaryThemeColors.push(createThemeDescription("Accent 5", "accent5"));
      primaryThemeColors.push(createThemeDescription("Accent 6", "accent6"));

      const modifiedPresets = buildModifiedColors(primaryThemeColors, docThemeEffective)

      const standardColors = [];
      // Note - Office doesn't use the presets but rather the HEX
      standardColors.push(createThemeDescription("Dark Red", "C00000")); // dkRed
      standardColors.push(createThemeDescription("Red", "FF0000")); // red
      standardColors.push(createThemeDescription("Orange", "FFC000")); // orange
      standardColors.push(createThemeDescription("Yellow", "FFFF00")); // yellow
      standardColors.push(createThemeDescription("Light Green", "92D050")); // ltGreen
      standardColors.push(createThemeDescription("Green", "00B050")); // green
      standardColors.push(createThemeDescription("Light Blue", "00B0F0")); // ltBlue
      standardColors.push(createThemeDescription("Blue", "0070C0")); // blue
      standardColors.push(createThemeDescription("Dark Blue", "002060")); // dkBlue
      // standardColors.push(createThemeDescription("Purple", "7030A0")); // purple
      // Note part of office but more 'useful' than purple
      standardColors.push(createThemeDescription("Transparent", "00000000")); // transparent

      // TODO - limit to 10.
      const recentColorsLimited = recentColors;
//       const recentColorsLimited = standardColors;
    return {
      primaryThemeColors,
      modifiedPresets,
      standardColors,
      recentColorsLimited
    }
  }, [docThemeEffective, recentColors]);

  useEffect(() => {
    if (onOpen)
        onOpen(ref);
  }, []);

  const onStaticColorChange = useCallback((newState) => {
    let adjustedColor = new AdjustableColor(`rgba(${newState.rgb.r},${newState.rgb.g},${newState.rgb.b},${newState.rgb.a})`);

    if (onChange)
        onChange(adjustedColor);
    if (onChangeComplete)
        onChangeComplete(adjustedColor);
  }, [onChange, onChangeComplete]);

  const [isCustomColors, setCustomColors] = useState(false);

  const handleToggleCustomColors = function(event) {
    setCustomColors(!isCustomColors);
  };

  const eyeDropper = useMemo(() => {
    if (window.EyeDropper === undefined)
      return (<></>);

      const onEyeDropClick = async function() {
        await new EyeDropper()
        .open()
        .then((results) => {
          // Set color in box
          console.log('setColor', results);

          let adjustedColor = new AdjustableColor(results.sRGBHex);

          if (onChange)
              onChange(adjustedColor);
          if (onChangeComplete)
              onChangeComplete(adjustedColor);
        })
        .catch((err) => {
          console.error(err);
        });
      };

      return (
      <IconButton aria-label="eye dropper" size="small"
        onClick={onEyeDropClick}
      >
        <Colorize/>
      </IconButton>
      );
  }, [window.EyeDropper]);

  return (
    <Paper
      tabIndex="1"
      ref={ref}
      className={ clsx(className, classes.picker) } {...other}>
      <div
        className={clsx(classes.quickColors,
        {
          [classes.active] : !isCustomColors
        })}
      >
          <Typography
            nowrap="true"
            className={classes.presetLabel}
          >
            Theme Colors
          </Typography>
          <ThemePresetColors
            className= { classes.presets}
            theme = { docThemeEffective }
            colors={ colorPalettes.primaryThemeColors }
            onChange={ (e) => { onChange(e); onChangeComplete(e); onDone(e) } }
            onClick={ (e) => { onChange(e); onChangeComplete(e); onDone(e) } }
            selectedColor= { color }
            onSwatchHover={ onSwatchHover }
          />
          <ThemePresetColors
            className= { classes.presets}
            theme = { docThemeEffective }
            colors={ colorPalettes.modifiedPresets }
            onChange={ (e) => { onChange(e); onChangeComplete(e); onDone(e) } }
            onClick={ (e) => { onChange(e); onChangeComplete(e); onDone(e) } }
            selectedColor= { color }
            onSwatchHover={ onSwatchHover }
          />
          <Typography
            nowrap="true"
            className={classes.presetLabel}
          >
            Standard Colors
          </Typography>
          <ThemePresetColors
            className= { classes.presets}
            theme = { docThemeEffective }
            colors={ colorPalettes.standardColors }
            onChange={ (e) => { onChange(e); onChangeComplete(e); onDone(e) } }
            onClick={ (e) => { onChange(e); onChangeComplete(e); onDone(e) } }
            selectedColor= { color }
            onSwatchHover={ onSwatchHover }
          />
          <div
            className={clsx(
              {
                [classes.hidden] : colorPalettes.recentColorsLimited.length === 0
              })}>
          <Typography
            nowrap="true"
            className={classes.presetLabel}
          >
            Recent Colors
          </Typography>
          <ThemePresetColors
            className= { classes.presets}
            theme = { docThemeEffective }
            colors={ colorPalettes.recentColorsLimited }
            onChange={ (e) => { onChange(e); onChangeComplete(e); onDone(e) } }
            onClick={ (e) => { onChange(e); onChangeComplete(e); onDone(e) } }
            selectedColor= { color }
            onSwatchHover={ onSwatchHover }
          />
          </div>
      </div>
      <CustomColorPanel
        className= { clsx(classes.customColors,
        {
          [classes.active] : isCustomColors
        })}
        color={ color.toRGBAColor().toString() }
        onChange={ onStaticColorChange }
      />
      {/* <div className={classes.spacer}/> */}
      <Divider light className={ clsx(classes.divider) }/>
      <div className={classes.buttonRow}>
      {eyeDropper}
      <Button
          className={classes.actionButton}
          value="check"
          variant="outlined"
          size="small"
          onClick={(event) => {
            handleToggleCustomColors(event);
          }}
       >
        { !isCustomColors ? <div>Custom</div> : <div>Preset</div> }
      </Button>
      <Button
        variant="contained"
        size="small"
        //color="primary"
        className={classes.actionButton}
        onClick={ (e) => { onDone(e) } }>
        Done
      </Button>
      </div>
    </Paper>
  )
});

ThemeColorPicker.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
ThemeColorPicker.defaultProps = {
  width: 205
}

export default ThemeColorPicker;