import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import BooleanPropertyEditor from "../BooleanPropertyEditor";
import RangePropertyEditor from "../RangePropertyEditor";
import SelectPropertyEditor from "../SelectPropertyEditor";
import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";

import HeaderContainer from "../HeaderContainer";

const useStyles = makeStyles((theme) => ({
  rootDataSource: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ChartDataSourcePropertyEditor(props) {
  const { shape, inputWidth, ...other } = props;
  const classes = useStyles();

  let valuesDispBlanksAs = [
    { key: "zero", description: "Zero" },
    { key: "gap", description: "Gap" },
    // ,
    // { key: 'span', description: 'Connect points with line' }
  ];

  return (
    <HeaderContainer
      className={classes.rootDataSource}
      {...other}
      label="Data Source"
      type="heading"
      collapsible={true}
    >
      <ExplicitPropertyEditorContainer>
        <RangePropertyEditor label="Range" shape={shape} property="range" />
      </ExplicitPropertyEditorContainer>
      <ExplicitPropertyEditorContainer>
        <BooleanPropertyEditor
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          label="Switch Rows/Columns"
          shape={shape}
          property="transpose"
        />
      </ExplicitPropertyEditorContainer>
      <ExplicitPropertyEditorContainer>
        <SelectPropertyEditor
          label="Display empty values as"
          values={valuesDispBlanksAs}
          shape={shape}
          inputWidth={inputWidth}
          property="dispBlanksAs"
        ></SelectPropertyEditor>
      </ExplicitPropertyEditorContainer>
      <ExplicitPropertyEditorContainer>
        <BooleanPropertyEditor
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          label="Display NA as blank"
          shape={shape}
          property="dispNaAsBlank"
        />
      </ExplicitPropertyEditorContainer>
    </HeaderContainer>
  );
}

export default ChartDataSourcePropertyEditor;
