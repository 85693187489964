import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";

import FillPropertyEditor from "./FillPropertyEditor";
import NumberPropertyEditor from "./NumberPropertyEditor";
import ExplicitPropertyEditorContainer from "./ExplicitPropertyEditorContainer";

import StrokeDashPropertyEditor from "./StrokeDashPropertyEditor";
import StrokeLineCapPropertyEditor from "./StrokeLineCapPropertyEditor";
import StrokeLineJoinPropertyEditor from "./StrokeLineJoinPropertyEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function StrokePropertyEditor(props) {
  const { shape, label = "Stroke", inputWidth = 70, ...other } = props;

  const classes = useStyles();

  const renderStrokeFields = function (value, key) {
    if (key === "none") return null;
    return (
      <Box className={classes.root}>
        <ExplicitPropertyEditorContainer>
          <NumberPropertyEditor
            label={"Width"}
            shape={shape}
            inputWidth={inputWidth}
            property="strokeWidth"
            minNumber={0}
          />
        </ExplicitPropertyEditorContainer>

        <StrokeDashPropertyEditor shape={shape} inputWidth={inputWidth} />
        <StrokeLineCapPropertyEditor shape={shape} inputWidth={inputWidth} />
        <StrokeLineJoinPropertyEditor shape={shape} inputWidth={inputWidth} />
      </Box>
    );
  };

  return (
    <FillPropertyEditor
      className={classes.root}
      {...other}
      label={label}
      shape={shape}
      property="strokeFill"
      isStroke={true}
      renderAdditionalFields={renderStrokeFields}
    ></FillPropertyEditor>
  );
}

export default StrokePropertyEditor;
