import React from "react";
import { PropTypes } from "prop-types";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

import useDAGMProperty from "./hooks/useDAGMProperty";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  labelContainer: {
    flex: "1 1 100%",
    minWidth: "0px",
    userSelect: "none",
    cursor: function({onLabelClick}) {
      return 'pointer';
    }
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  alignedRowBoolean: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: "1 1 100%",
    minWidth: "0px",
    paddingLeft: function (propsStyle) {
      return propsStyle.isHeader ? "0px" : "14px";
    },
  },
}));
function BooleanPropertyEditor(props) {
  const {
    shape,
    property,
    label,
    isHeader = false,
    onLabelClick,
    onSwitchlick,
    className : classNameProp,
    ...other
  } = props;
  const classes = useStyles({ isHeader: isHeader, onLabelClick: onLabelClick });

  const [value, setValue, propertyValue] = useDAGMProperty(shape, property);

  const toggleValue = () => {
    setValue((prev) => {
      return !prev;
    });
  };

  return (
    <Typography
      component="div"
      {...other}
      className={clsx(classes.alignedRowBoolean, classNameProp)}
    >
      <div className={clsx(classes.labelContainer)}
        onClick={function(event) {
            if (onLabelClick) 
              onLabelClick(event);
            else 
              toggleValue();
        }}
      >
        <div className={clsx(classes.label)}>
          {label}
        </div>
      </div>
      <Switch
        color="primary"
        style={{ flex: "0" }}
        checked={value}
        onChange={toggleValue}
      />
    </Typography>
  );
}

BooleanPropertyEditor.propTypes = {
  shape: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default BooleanPropertyEditor;
