import { CommonUtils } from '@sheetxl/models';

let ID_LLIMIT = 200000000;
let ID_ULIMIT = 300000000;
let LAST_ID = null;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function getAxisID() {
  if (LAST_ID === null)
      LAST_ID = getRandomInt(ID_LLIMIT, ID_ULIMIT);
  else
      LAST_ID = LAST_ID + 1;

  if (LAST_ID > ID_ULIMIT)
      LAST_ID = LAST_ID - 10000;

  return LAST_ID;
}

function componentToHex(c) {
  let hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  return componentToHex(Number(r)).toUpperCase() + componentToHex(Number(g)).toUpperCase() + componentToHex(Number(b)).toUpperCase();
}

export function isMultiLevel(objChartSeries, pointCoord) {
    let isMultiLevel = false;
    let arraylength = objChartSeries.renderedPoints.length;
    for (let i = 0; i < arraylength; i++) {
        let point  = objChartSeries.renderedPoints[i];
        let pointCoordProp = point.getPropertyValue(pointCoord);
        if (pointCoordProp?.value?.asCells?.length > 0 && pointCoordProp.value.asCells[0].length > 1)
          isMultiLevel = true;
    }
    return isMultiLevel;
}

export function getValues(objChartSeries, pointCoord, isMultiLevel=false) {
    if (isMultiLevel) {
        return getMultiLevelValues(objChartSeries, pointCoord);
    } else {
        return getSingleLevelValues(objChartSeries, pointCoord);
    }
}

export function getMultiLevelValues(objChartSeries, pointCoord) {
  let values = [];
  let arraylength = objChartSeries.renderedPoints.length;
  for (let i = 0; i < arraylength; i++) {
      let point  = objChartSeries.renderedPoints[i];
      let pointCoordProp = point.getPropertyValue(pointCoord);
      if (pointCoordProp?.value?.asCells?.length > 0 && pointCoordProp.value.asCells[0].length > 0) {
          values[i] = [];
          let col = 0
          for (let j=pointCoordProp.value.asCells[0].length; j>0; j--) {//process in reverse order
             let cell = pointCoordProp.value.asCells[0][j-1];
             values[i][col++] = {"value":cell ? cell.v : null, "index":i};
          }
      }
  }

  return {
    isNumericList : false,
    values : CommonUtils.transpose(values),
    formatCodes :  new Map(),
    listFormatCode : null,
    allSameFormatCodes : true
  };
}

function getSingleLevelValues(objChartSeries, pointCoord) {
  let values = [];
  values[0] = [];//make it a 2D array
  let formatCodes = new Map();
  let allSameFormatCodes = true;
  let prevFormatCode = null;
  let isNumericList = true;
  let arraylength = objChartSeries.renderedPoints.length;
  for (let i = 0; i < arraylength; i++) {
      let point  = objChartSeries.renderedPoints[i];
      //format code
      let formatCode = null;
      let pointCoordProp = point.getPropertyValue(pointCoord);
      if (pointCoordProp?.value?.asCells?.length > 0 &&
          pointCoordProp.value.asCells[0].length > 0 &&
          pointCoordProp.value.asCells[0][0]) {
          formatCode = pointCoordProp.value.asCells[0][0].z;
          if (!formatCode)
              formatCode = "General";
          formatCodes.set(i, formatCode);
          values[0].push({"value":pointCoordProp.value.asCells[0][0].v, "index":i});
          isNumericList = isNumericList && (pointCoordProp.value.asCells[0][0].t === 'n');
      }
      if (prevFormatCode && formatCode !== null)
          allSameFormatCodes = allSameFormatCodes && (prevFormatCode === formatCode);
      prevFormatCode = formatCode;
  }

  // let listFormatCode = null;
  if (formatCodes.size > 1) {
    let entryCount = 0;
    for (let [index, formatCode] of formatCodes) {
      if (entryCount++ === 1) {
        return {
          isNumericList : isNumericList,
          values : values,
          formatCodes : formatCodes,
          listFormatCode : formatCode,
          allSameFormatCodes : allSameFormatCodes
        };
      }
    }
  } else if (formatCodes.size == 1) {
    // let entryCount = 0;
    for (let [index, formatCode] of formatCodes) {
        return {
          isNumericList : isNumericList,
          values : values,
          formatCodes : formatCodes,
          listFormatCode : formatCode,
          allSameFormatCodes : allSameFormatCodes
        };
    }
  } else { // 0
    return {
      isNumericList : isNumericList,
      values : values,
      formatCodes : formatCodes,
      listFormatCode : null,
      allSameFormatCodes : true
    };
  }
}

export function createDataList(execContext, values, xmlNode, setFormatCodes=false, formatCodes) {
  for (let i=0; i<values.length; i++) {
      if (!values[i].value)
        continue;
      let xmlPtNode = execContext.createChildNode(xmlNode, "c:pt");
      execContext.setAttribute(xmlPtNode, "idx", values[i].index);
      if (setFormatCodes)
          execContext.setAttribute(xmlPtNode, "formatCode", formatCodes.get(values[i].index));
      let xmlVNode = execContext.createChildNode(xmlPtNode, "c:v");
      let xmlValueNode = execContext.createTextNode(values[i].value);
      execContext.appendChildNode(xmlVNode, xmlValueNode);
  }
}

export function getDPt(execContext, dPtNodes, idx) {
  if (!dPtNodes)
    return;
  let dPtLength = dPtNodes.length;
  for (let i = 0; i < dPtLength; i++) {
      let dPtNode = dPtNodes.item(i);
      let idxNode = execContext.getNode(dPtNode, "c:idx");
      let idxVal = idxNode.getAttribute("val");
      if (idxVal === idx.toString()) {
          return dPtNode;
      }
  }
}
