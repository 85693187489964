
import React, { useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Box from "@material-ui/core/Box";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { themedStyleWithScrollbar } from "@sheetxl/chart";
import { Swatch } from "@sheetxl/chart";

import { AdjustableColor } from "@sheetxl/models";

export const DefaultTheme = {
  name: "Standard Office",
  dk1: "windowText",
  lt1: "window",
  dk2: "44546A",
  lt2: "E7E6E6",
  accent1: "4472C4",
  accent2: "ED7D31",
  accent3: "A5A5A5",
  accent4: "FFC000",
  accent5: "5B9BD5",
  accent6: "70AD47",
  hlink: "0563C1",
  folHlink: "954F72",
  majorFontLatin: "Calibri",
  minorFontLatin: "Calibri"
};

const useStyles = makeStyles((theme) => ({
  themeSelector: {
    display: "flex",
    flex: "1 1 0%",
    flexDirection: "row",
    alignItems: "center",
    margin: "8px",
    marginBottom: "0px"
  },
  popup: themedStyleWithScrollbar(theme),
  selectControl: {
    margin: theme.spacing(1),
    marginLeft: "0px",
    marginRight: "0px",
    minWidth: 0,
    flex: function ({ inputWidth }: any) {
      return `1 0 ${inputWidth}%`;
    },
  },
  input: {
    padding: "10.5px 14px",
  },
  selectValue: {
    flexDirection: "row",
    display: "flex",
    width: '100%'
  },
  colorStripContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  colorStrip: {
    display: 'flex',
    boxShadow: theme.shadows[1],
    borderRadius: `${2}px`,
    overflow: 'hidden'
  },
  swatch: {
    width: '15px',
    height: '15px',
    position: 'relative',
    boxSizing: 'border-box',
    cursor: 'pointer',
    outline: 'none',
  },
  textContainer: {
    display: 'flex',
    flex: '1 1 100%',
    width: '0px'
  },
  textContained: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export interface ThemeSelectProps {
  themes: any[];
  selectedTheme: any;
  onThemeUpdate: any;
  className?: string;
  inputWidth?: string;
}

const ThemeSelect: React.FC<ThemeSelectProps> = (props) => {
  const {
    className : classNameProp,
    inputWidth = "60",
    selectedTheme,
    onThemeUpdate,
    themes,
    ...other
  } = props;

  const classes = useStyles({ inputWidth: inputWidth });

  const createSwatch = useMemo(() => function(theme, key) {
    let color = theme[key];
    if (!(color instanceof AdjustableColor))
        color = new AdjustableColor(color);
    return (
      <Swatch
        key={key}
        className={classes.swatch}
        color={color.toRGBAColor()}
        title={null}
        // onClick={(e) => {
        //     // if (onClick)
        //     //   onClick(color, e);
        // }}
        // onHover={onSwatchHover}
        // focusStyle={{ boxShadow: '0 0 4px ' + color }}
      >
      </Swatch>
    );
  }, [classes]);

  const renderValue = useMemo(function() {
    return function (valueRender:any) {
      let className = clsx(classes.selectValue, {});
      const colorPalette = [];
      colorPalette.push(createSwatch(valueRender, 'dk1'));
      colorPalette.push(createSwatch(valueRender, 'lt1'));
      colorPalette.push(createSwatch(valueRender, 'dk2'));
      colorPalette.push(createSwatch(valueRender, 'lt2'));
      colorPalette.push(createSwatch(valueRender, 'accent1'));
      colorPalette.push(createSwatch(valueRender, 'accent2'));
      colorPalette.push(createSwatch(valueRender, 'accent3'));
      colorPalette.push(createSwatch(valueRender, 'accent4'));
      colorPalette.push(createSwatch(valueRender, 'accent5'));
      colorPalette.push(createSwatch(valueRender, 'accent6'));
//       colorPalette.push(createSwatch(valueRender, 'hlink'));
//       colorPalette.push(createSwatch(valueRender, 'folHlink'));

      // Todo - have name be in theme font style?
      return (
          <div className={className}>
              <div className={classes.textContainer}>
                <div className={classes.textContained}>{valueRender.name}</div>
              </div>
              <div className={classes.colorStripContainer}>
                <div className={classes.colorStrip}>
                    {colorPalette}
                </div>
              </div>
          </div>
        );
      };
  }, [classes]);


  const handleSelectTheme = useMemo(() => function(event, theme, index) {
    if (onThemeUpdate)
      onThemeUpdate(theme, event, index);
  }, [onThemeUpdate]);

  const themesAvailable = useMemo(() => {
      let retValue = [];
      for (let i=0; i<themes.length; i++) {
        let theme = themes[i];
        if (Array.isArray(theme)) {
          let effectiveTheme = Object.assign({}, DefaultTheme);
          let keys = ['name', 'dk1', 'lt1', 'dk2', 'lt2', 'accent1', 'accent2', 'accent3', 'accent4', 'accent5', 'accent6', 'hlink', 'folHlink'];
          let start = 0;
          if (theme.length === 7)
            start = 4;
          for (let j=start; j<keys.length; j++) {
            if (theme[j-start+1])
                effectiveTheme[keys[j+1]] = theme[j-start+1];
          }
          effectiveTheme['name'] = theme[0];
          theme = effectiveTheme;
        }
        retValue.push(theme);
      }
      return retValue;
  }, [themes]);

  const foundTheme = useMemo(() => {
      for (let i=0; i<themesAvailable.length; i ++) {
          if (themesAvailable[i].name === selectedTheme.name)
            return themesAvailable[i];
      }
      return null;
  }, [selectedTheme, themesAvailable]);

  const createMenu = function(theme, index) {
    return (
       <MenuItem
         key={theme.name}
         value={theme}
         selected={theme === foundTheme}
         onClick={(event) => handleSelectTheme(event, theme, index)}
       >
         {renderValue(theme)}
       </MenuItem>
     );
   };

  const menuItems = useMemo(function() {
    const createMenu = function(theme, index) {
      return (
         <MenuItem
           key={theme.name}
           value={theme}
           selected={theme === foundTheme}
           onClick={(event) => handleSelectTheme(event, theme, index)}
         >
           {renderValue(theme)}
         </MenuItem>
       );
     };

     const retValue = [];
     if (!foundTheme)
       retValue.push(createMenu(selectedTheme, -1));

     for (let i = 0; i < themesAvailable.length; i++) {
       retValue.push(createMenu(themesAvailable[i], i));
     }
     return retValue;

  }, [themesAvailable, handleSelectTheme, foundTheme]);

  return (
    <Box className={clsx(classNameProp, classes.themeSelector)} {...other}>
      <FormControl variant="outlined" className={classes.selectControl}>
        <InputLabel>Selected Theme</InputLabel>
        <Select
          value={foundTheme || selectedTheme}
          renderValue={renderValue}
          disabled={false}
          input={
            <OutlinedInput label={"Selected Theme"} classes={{ input: classes.input }} />
          }
          MenuProps={{ classes: { paper: classes.popup } }}
        >
          {menuItems}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ThemeSelect;
