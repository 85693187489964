import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Fade from "@material-ui/core/Fade";

import ChartShapeRenderer from "./ChartShapeRenderer";
import StyledChartRendererContainer from "./StyledChartRendererContainer";

import LoadingComponent from "../LoadingComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
}));

function ChartShapeComponent(props) {
  // Note - This needs to be a memoized callback
  const {
    handleLoaded,
    handlerRef,
    onShapeSelect,
    onShapeDeselect,
    onFocus,
    initialAnimation = 1200,
    hideLoading,
    chartModel,
    ...other
  } = props;
  const classes = useStyles();

  const ref = useRef({
    container: null,
    rendererChartShape: null,
  });

  const [isLoaded, setLoaded] = useState(false);

  const renderOptions = {
    initialAnimation: initialAnimation,
    handlerRef: handlerRef
  };

  useEffect(() => {
    let current = ref.current;
    current.rendererChartShape = new ChartShapeRenderer(
      chartModel,
      current.container
    );
    current.rendererChartShape
      .render(renderOptions)
      .then(() => {
        // The component unmounted
        if (!current.rendererChartShape) return;
        setLoaded(true);
        if (typeof handleLoaded === "function") {
          handleLoaded(current.rendererChartShape);
        }
      })
      .catch((error) => {
        // Note - Should we also resolve here with an error indicator (so that we can render with error in ui)
        console.warn("do something about", error);
      });

    return () => {
      if (current.rendererChartShape) {
        if (process.env.NODE_ENV !== "production")
          console.log("disposed", current.rendererChartShape);
        // current.instance.dispose();
        current.rendererChartShape.destroy();
        current.rendererChartShape = null;
        // unListener();
      }
    };
  }, [chartModel, handleLoaded]);

  let loading = (
    <Fade
      in={!isLoaded}
      unmountOnExit={true}
      timeout={{
        appear: 200,
        enter: 100,
        exit: 500,
      }}
    >
      <LoadingComponent className={classes.root}></LoadingComponent>
    </Fade>
  );
  if (hideLoading) loading = <></>;

  function handleFocus(e) {
    if (onFocus) onFocus(e);
    if (onShapeSelect) onShapeSelect(e);
  }

  function handleBlur(e) {
    if (onShapeDeselect) onShapeDeselect(e);
  }

  return (
    <StyledChartRendererContainer
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex="0"
      {...other}
    >
      <div
        className={classes.root}
        ref={(elem) => (ref.current.container = elem)}
        // onMouseMove={(e) => {
        //   console.log('appmousemove', e);
        // }}
        // onDrag={(e) => {
        //   console.log('appdrag', e);
        // }}
        // style={{background: 'pink', border: 'red solid 1px'}}
      ></div>
      {loading}
    </StyledChartRendererContainer>
  );
}

export default ChartShapeComponent;
