import React from "react";

import useDAGMProperty from "../hooks/useDAGMProperty";

import TextShapePropertyEditor from "../TextShapePropertyEditor";
import NumberPropertyEditor from "../NumberPropertyEditor";
import FillStrokePropertyEditor from "../FillStrokePropertyEditor";
import HeaderContainer from "../HeaderContainer";

import ExplicitPropertyEditorContainer from "../ExplicitPropertyEditorContainer";
import ExpandingBooleanPropertyEditor from "../ExpandingBooleanPropertyEditor";

import LabelsFormatPropertyEditor from "../LabelsFormatPropertyEditor";

function ChartLabelsPropertyEditor(props) {
  const {
    shape,
    property = "labels",
    children,
    enabledFormatting = true,
    childrenLabelPosition,
    ...other
  } = props;

  const [valueLabels] = useDAGMProperty(shape, "labels");

  return (
    <ExpandingBooleanPropertyEditor
      {...other}
      label={"Labels"}
      shape={valueLabels}
      property={"shown"}
    >
      <TextShapePropertyEditor
        textField={<></>}
        shape={valueLabels.text}
      ></TextShapePropertyEditor>
      {children}
      <ExplicitPropertyEditorContainer>
        <NumberPropertyEditor
          label={"Rotation"}
          shape={valueLabels}
          property="rotation"
        />
      </ExplicitPropertyEditorContainer>
      { enabledFormatting ? (
        <LabelsFormatPropertyEditor
          shape={valueLabels}
        />
      ): <></> }
      {childrenLabelPosition}
      <HeaderContainer label="Background" type="secondaryHeading">
        <FillStrokePropertyEditor
          label="Background"
          shape={valueLabels}
        ></FillStrokePropertyEditor>
      </HeaderContainer>
    </ExpandingBooleanPropertyEditor>
  );
}

export default ChartLabelsPropertyEditor;
